import { useState, useEffect } from 'react';
import axios from 'axios';
import { backendServer } from '../../Const';
import RateItem from "./RateItem";
import Message from './Message';

import {readRateAnswer, checkRateFilled, handleBeforeUnload} from '../../SurveyUtils';
import NextButtonPanel from '../../components/NextButtonPanel';

const Issue4page7 = (props) => {
  const [pageData, setPageData] = useState('');
  const [firstRound, setFirstRound] = useState(true);

  const [p160Rate, setP160Rate] = useState(0);
  const [p160Comment, setP160Comment] = useState('');
  const [p160Results, setP160Results] = useState('');
  const [p161Rate, setP161Rate] = useState(0);
  const [p161Comment, setP161Comment] = useState('');
  const [p161Results, setP161Results] = useState('');
  const [p162Rate, setP162Rate] = useState(0);
  const [p162Comment, setP162Comment] = useState('');
  const [p162Results, setP162Results] = useState('');
  const [p163Rate, setP163Rate] = useState(0);
  const [p163Comment, setP163Comment] = useState('');
  const [p163Results, setP163Results] = useState('');
  const [p164Rate, setP164Rate] = useState(0);
  const [p164Comment, setP164Comment] = useState('');
  const [p164Results, setP164Results] = useState('');
  const [p165Rate, setP165Rate] = useState(0);
  const [p165Comment, setP165Comment] = useState('');
  const [p165Results, setP165Results] = useState('');
  const [p165Other, setP165Other] = useState('');
  const [p166Rate, setP166Rate] = useState(0);
  const [p166Comment, setP166Comment] = useState('');
  const [p166Results, setP166Results] = useState('');
  const [p167Rate, setP167Rate] = useState(0);
  const [p167Comment, setP167Comment] = useState('');
  const [p167Results, setP167Results] = useState('');
  const [p168Rate, setP168Rate] = useState(0);
  const [p168Comment, setP168Comment] = useState('');
  const [p168Results, setP168Results] = useState('');
  const [p169Rate, setP169Rate] = useState(0);
  const [p169Comment, setP169Comment] = useState('');
  const [p169Results, setP169Results] = useState('');
  const [p170Rate, setP170Rate] = useState(0);
  const [p170Comment, setP170Comment] = useState('');
  const [p170Results, setP170Results] = useState('');
  const [p170Other, setP170Other] = useState('');

  const [filled, setFilled] = useState(false);
  const [unchanged, setUnchanged] = useState(true);
  const [message, setMessage] = useState('');
  const [messageColor, setMessageColor] = useState('');

  useEffect( () => {
    if(unchanged) return;
    
    window.addEventListener('beforeunload',handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload',handleBeforeUnload)
    }
    
  },[unchanged]);

  const setErrorMessage = (msg) => {
    setMessageColor("red");
    setMessage(msg);
  }

  const setOkMessage = (msg) => {
    setMessageColor("teal");
    setMessage(msg);
  }


  const resetValues = () => {
    const data = pageData?JSON.parse(pageData):[];
    
    setP160Rate(data.p160Rate?data.p160Rate:0);
    setP160Comment(data.p160Comment?data.p160Comment:'');
    setP161Rate(data.p161Rate?data.p161Rate:0);
    setP161Comment(data.p161Comment?data.p161Comment:'');
    setP162Rate(data.p162Rate?data.p162Rate:0);
    setP162Comment(data.p162Comment?data.p162Comment:'');
    setP163Rate(data.p163Rate?data.p163Rate:0);
    setP163Comment(data.p163Comment?data.p163Comment:'');
    setP164Rate(data.p164Rate?data.p164Rate:0);
    setP164Comment(data.p164Comment?data.p164Comment:'');
    setP165Rate(data.p165Rate?data.p165Rate:0);
    setP165Comment(data.p165Comment?data.p165Comment:'');
    setP165Other(data.p165Other?data.p165Other:'');
    setP166Rate(data.p166Rate?data.p166Rate:0);
    setP166Comment(data.p166Comment?data.p166Comment:'');
    setP167Rate(data.p167Rate?data.p167Rate:0);
    setP167Comment(data.p167Comment?data.p167Comment:'');
    setP168Rate(data.p168Rate?data.p168Rate:0);
    setP168Comment(data.p168Comment?data.p168Comment:'');
    setP169Rate(data.p169Rate?data.p169Rate:0);
    setP169Comment(data.p169Comment?data.p169Comment:'');
    setP170Rate(data.p170Rate?data.p170Rate:0);
    setP170Comment(data.p170Comment?data.p170Comment:'');
    setP170Other(data.p170Other?data.p170Other:'');

    setUnchanged(true);
  }

  const fetchData = async () => {
    axios
      .post(backendServer + "/get_votes.php", {
        user_id: JSON.parse(props.user).user_id,
        problem_ids: ["160", "161", "162", "163", "164", "165", "166", "167", "168", "169", "170"]
      })
      .then((response) => {
        if (response.data.status === "ok") {
          let answers = JSON.parse(response.data.answers);
          let results = JSON.parse(response.data.results);
          let data = {};

          if(answers['160']) {
            setFirstRound(false);
          }
          
          [data['p160Rate'], data['p160Comment']] = readRateAnswer(answers['160']);
          [data['p161Rate'], data['p161Comment']] = readRateAnswer(answers['161']);
          [data['p162Rate'], data['p162Comment']] = readRateAnswer(answers['162']);
          [data['p163Rate'], data['p163Comment']] = readRateAnswer(answers['163']);
          [data['p164Rate'], data['p164Comment']] = readRateAnswer(answers['164']);     
          [data['p165Rate'], data['p165Comment']] = readRateAnswer(answers['165']);
          if(answers['165']&&answers['165'].other) data['p165Other'] = answers['165'].other;
          else data['p165Other'] = '';
          [data['p166Rate'], data['p166Comment']] = readRateAnswer(answers['166']);
          [data['p167Rate'], data['p167Comment']] = readRateAnswer(answers['167']);
          [data['p168Rate'], data['p168Comment']] = readRateAnswer(answers['168']);
          [data['p169Rate'], data['p169Comment']] = readRateAnswer(answers['169']);
          [data['p170Rate'], data['p170Comment']] = readRateAnswer(answers['170']);
          if(answers['170']&&answers['170'].other) data['p170Other'] = answers['170'].other;
          else data['p170Other']='';

          setPageData(JSON.stringify(data));

          setP160Results(results["160"]);
          setP161Results(results["161"]);
          setP162Results(results["162"]);
          setP163Results(results["163"]);
          setP164Results(results["164"]);
          setP165Results(results["165"]);
          setP166Results(results["166"]);
          setP167Results(results["167"]);
          setP168Results(results["168"]);
          setP169Results(results["169"]);
          setP170Results(results["170"]);
          
          checkFilled();
          setUnchanged(true);
        } else {
          setErrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  }  

  useEffect(()=>{
    fetchData(); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    resetValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pageData]);
  
  useEffect(() => {
    setMessage('');
    let data = pageData?JSON.parse(pageData):[];
    
    if (Number(data.p160Rate) !== Number(p160Rate)) { setUnchanged(false); return; }
    if (data.p160Comment !== p160Comment) { setUnchanged(false); return; }
    if (Number(data.p161Rate) !== Number(p161Rate)) { setUnchanged(false); return; }
    if (data.p161Comment !== p161Comment) { setUnchanged(false); return; }
    if (Number(data.p162Rate) !== Number(p162Rate)) { setUnchanged(false); return; }
    if (data.p162Comment !== p162Comment) { setUnchanged(false); return; }
    if (Number(data.p163Rate) !== Number(p163Rate)) { setUnchanged(false); return; }
    if (data.p163Comment !== p163Comment) { setUnchanged(false); return; }
    if (Number(data.p164Rate) !== Number(p164Rate)) { setUnchanged(false); return; }
    if (data.p164Comment !== p164Comment) { setUnchanged(false); return; }
    if (Number(data.p165Rate) !== Number(p165Rate)) { setUnchanged(false); return; }
    if (data.p165Comment !== p165Comment) { setUnchanged(false); return; }
    if (data.p165Other !== p165Other) { setUnchanged(false); return; }     
    if (Number(data.p166Rate) !== Number(p166Rate)) { setUnchanged(false); return; }
    if (data.p166Comment !== p166Comment) { setUnchanged(false); return; }
    if (Number(data.p167Rate) !== Number(p167Rate)) { setUnchanged(false); return; }
    if (data.p167Comment !== p167Comment) { setUnchanged(false); return; }
    if (Number(data.p168Rate) !== Number(p168Rate)) { setUnchanged(false); return; }
    if (data.p168Comment !== p168Comment) { setUnchanged(false); return; }
    if (Number(data.p169Rate) !== Number(p169Rate)) { setUnchanged(false); return; }
    if (data.p169Comment !== p169Comment) { setUnchanged(false); return; }
    if (Number(data.p170Rate) !== Number(p170Rate)) { setUnchanged(false); return; }
    if (data.p170Comment !== p170Comment) { setUnchanged(false); return; }
    if (data.p170Other !== p170Other) { setUnchanged(false); return; }    
    
    setUnchanged(true);
  }, [
    p160Rate, p160Comment,
    p161Rate, p161Comment,
    p162Rate, p162Comment,
    p163Rate, p163Comment,
    p164Rate, p164Comment,
    p165Rate, p165Comment, p165Other,
    p166Rate, p166Comment,
    p167Rate, p167Comment,
    p168Rate, p168Comment,
    p169Rate, p169Comment,
    p170Rate, p170Comment, p170Other, pageData
  ]);

  const checkFilled = () => {
    if(!checkRateFilled(p160Rate, p160Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p161Rate, p161Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p162Rate, p162Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p163Rate, p163Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p164Rate, p164Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p165Rate, p165Comment, 1, 5)) {setFilled(false); return;}
    //if(!p165Other) {setFilled(false); return;}
    if(!checkRateFilled(p166Rate, p166Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p167Rate, p167Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p168Rate, p168Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p169Rate, p169Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p170Rate, p170Comment, 1, 5)) {setFilled(false); return;}
    //if(!p170Other) {setFilled(false); return;}

    setFilled(true);
  }
  
  const navigate = (msg) => {
    window.removeEventListener('beforeunload',handleBeforeUnload); 
    if(!msg) msg = 'Proceeding..';
    setOkMessage(msg);
    setTimeout(()=> {
      window.location.href = '/survey?issue=6&last_issue=4';
    }, 1000);
  }

  useEffect(() => {   
    checkFilled();
  });

  let admin = JSON.parse(props.user).admin;
  let isAdmin = admin&&admin==="1";

  const handleSubmit = (event) => {
    event.preventDefault();
    
    if((filled&&unchanged)||isAdmin) { navigate(); return; }
    
    if(!checkRateFilled(p160Rate, p160Comment, 1, 5,
      "Please answer problem 6a.",
      "Please provide your reasoning for problem 6a.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p161Rate, p161Comment, 1, 5,
      "Please answer problem 6b.",
      "Please provide your reasoning for problem 6b.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p162Rate, p162Comment, 1, 5,
      "Please answer problem 6c.",
      "Please provide your reasoning for problem 6c.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p163Rate, p163Comment, 1, 5,
      "Please answer problem 6d.",
      "Please provide your reasoning for problem 6d.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p164Rate, p164Comment, 1, 5,
      "Please answer problem 6e.",
      "Please provide your reasoning for problem 6e.",
      setErrorMessage
    )) return;
    if(p165Other) {
      if(!checkRateFilled(p165Rate, p165Comment, 1, 5,
        "Please answer problem 6f.",
        "Please provide your reasoning for problem 6f.",
        setErrorMessage
      )) return;
    }
    // if(!p165Other) {
    //   setErrorMessage("Please specify the other field in problem 6f.");
    //   return;
    // }
    if(!checkRateFilled(p166Rate, p166Comment, 1, 5,
      "Please answer problem 7a.",
      "Please provide your reasoning for problem 7a.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p167Rate, p167Comment, 1, 5,
      "Please answer problem 7b.",
      "Please provide your reasoning for problem 7b.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p168Rate, p168Comment, 1, 5,
      "Please answer problem 7c.",
      "Please provide your reasoning for problem 7c.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p169Rate, p169Comment, 1, 5,
      "Please answer problem 7d.",
      "Please provide your reasoning for problem 7d.",
      setErrorMessage
    )) return;
    if(p170Other) {
      if(!checkRateFilled(p170Rate, p170Comment, 1, 5,
        "Please answer problem 7e.",
        "Please provide your reasoning for problem 7e.",
        setErrorMessage
      )) return;
    }
    // if(!p170Other) {
    //   setErrorMessage("Please specify the other field in problem 7e.");
    //   return;
    // }

    let user_id = JSON.parse(props.user).user_id;
    let voteData = [];
    voteData.push({user_id, problem_id:160, vote:p160Rate, comment: p160Comment});
    voteData.push({user_id, problem_id:161, vote:p161Rate, comment: p161Comment});
    voteData.push({user_id, problem_id:162, vote:p162Rate, comment: p162Comment});
    voteData.push({user_id, problem_id:163, vote:p163Rate, comment: p163Comment});
    voteData.push({user_id, problem_id:164, vote:p164Rate, comment: p164Comment});
    if(p165Other)
      voteData.push({user_id, problem_id:165, vote:p165Rate, comment: p165Comment, other:p165Other});
    voteData.push({user_id, problem_id:166, vote:p166Rate, comment: p166Comment});
    voteData.push({user_id, problem_id:167, vote:p167Rate, comment: p167Comment});
    voteData.push({user_id, problem_id:168, vote:p168Rate, comment: p168Comment});
    voteData.push({user_id, problem_id:169, vote:p169Rate, comment: p169Comment});
    if(p170Other) 
      voteData.push({user_id, problem_id:170, vote:p170Rate, comment: p170Comment, other:p170Other});

    axios.post( backendServer+'/vote.php', {vote_data: JSON.stringify(voteData)})
    .then( (response)=>{
      if(response.data.status==='ok') {
        //updatePageData();
        //fetchData();
        setFilled(true);
        setFirstRound(false);
        //setOkMessage("Responses saved.");
        navigate('Responses saved.');

      } else {
        setErrorMessage(response.data.message);
      }
    })
    .catch((error)=>{
      setErrorMessage(error.message);
    });
  }

  return (
    <form onSubmit={handleSubmit}>
      <table width="600" align="center" cellPadding={8}>
        <tbody>
          <tr>
            <td>
              <br />
              6. To what extent do you believe the collaboration among APEC
              economies regarding land-use practices are driving progress
              towards net-zero emissions?
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={160}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p160Rate}
                setRate={setP160Rate}
                comment={p160Comment}
                setComment={setP160Comment}
                text="a. Carbon trade within APEC"
                results={p160Results}
                choices={["Not at all important", "Low important", "Moderately important", "Important", "Very important"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={161}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p161Rate}
                setRate={setP161Rate}
                comment={p161Comment}
                setComment={setP161Comment}
                text="b. Knowledge sharing"
                results={p161Results}
                choices={["Not at all important", "Low important", "Moderately important", "Important", "Very important"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={162}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p162Rate}
                setRate={setP162Rate}
                comment={p162Comment}
                setComment={setP162Comment}
                text="c. Technology transfer"
                results={p162Results}
                choices={["Not at all important", "Low important", "Moderately important", "Important", "Very important"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={163}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p163Rate}
                setRate={setP163Rate}
                comment={p163Comment}
                setComment={setP163Comment}
                text="d. Capacity building"
                results={p163Results}
                choices={["Not at all important", "Low important", "Moderately important", "Important", "Very important"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={164}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p164Rate}
                setRate={setP164Rate}
                comment={p164Comment}
                setComment={setP164Comment}
                text="e. R&D collaboration"
                results={p164Results}
                choices={["Not at all important", "Low important", "Moderately important", "Important", "Very important"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={165}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p165Rate}
                setRate={setP165Rate}
                comment={p165Comment}
                setComment={setP165Comment}
                text="f. Others"
                other={true}
                otherText={p165Other}
                updateOther={setP165Other}
                results={p165Results}
                choices={["Not at all important", "Low important", "Moderately important", "Important", "Very important"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <br />
              7. On a scale of 1 to 5, please rate how much your economy would require the following collaborations from other APEC economies to effectively implement land-use practices for achieving net-zero emissions.
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={166}
                firstRound={firstRound}
                rate={p166Rate}
                setRate={setP166Rate}
                adminMode={isAdmin}
                comment={p166Comment}
                setComment={setP166Comment}
                text="a. Carbon trade within APEC"
                results={p166Results}
                choices={["Low", "Rather low", "Moderate", "Rather high", "High"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={167}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p167Rate}
                setRate={setP167Rate}
                comment={p167Comment}
                setComment={setP167Comment}
                text="b. Capacity building"
                results={p167Results}
                choices={["Low", "Rather low", "Moderate", "Rather high", "High"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={168}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p168Rate}
                setRate={setP168Rate}
                comment={p168Comment}
                setComment={setP168Comment}
                text="c. Technology transfer"
                results={p168Results}
                choices={["Low", "Rather low", "Moderate", "Rather high", "High"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={169}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p169Rate}
                setRate={setP169Rate}
                comment={p169Comment}
                setComment={setP169Comment}
                text="d. R&D collaboration"
                results={p169Results}
                choices={["Low", "Rather low", "Moderate", "Rather high", "High"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={170}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p170Rate}
                setRate={setP170Rate}
                comment={p170Comment}
                setComment={setP170Comment}
                text="e. Other"
                other={true}
                otherText={p170Other}
                updateOther={setP170Other}
                results={p170Results}
                choices={["Low", "Rather low", "Moderate", "Rather high", "High"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td align="right">
            <Message color={messageColor} message={message} />
              <br />
              <NextButtonPanel
                disabled={unchanged}
                onClick={resetValues}
                isEnd={true}
                issue={4}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );
};

export default Issue4page7;