import { useState, useEffect } from 'react';
import axios from 'axios';
import { backendServer, allEconomies } from '../../Const';
import RateItem from "./RateItem";
import SelectItem from "./SelectItem";
import Message from './Message';

import {isDeepEqual, readRateAnswer, readSelectionAnswers, checkRateFilled, checkSelectionFilled, handleBeforeUnload} from '../../SurveyUtils';
import NextButtonPanel from '../../components/NextButtonPanel';


const Issue4page5 = (props) => {
  const [pageData, setPageData] = useState('');
  const [firstRound, setFirstRound] = useState(true);

  const [p144Selections, setP144Selections] = useState([]);
  const [p144Comments, setP144Comments] = useState([]);
  const [p144Results, setP144Results] = useState('');
  const [p145Rate, setP145Rate] = useState(0);
  const [p145Comment, setP145Comment] = useState('');
  const [p145Results, setP145Results] = useState('');
  const [p146Rate, setP146Rate] = useState(0);
  const [p146Comment, setP146Comment] = useState('');
  const [p146Results, setP146Results] = useState('');
  const [p147Rate, setP147Rate] = useState(0);
  const [p147Comment, setP147Comment] = useState('');
  const [p147Results, setP147Results] = useState('');
  const [p148Rate, setP148Rate] = useState(0);
  const [p148Comment, setP148Comment] = useState('');
  const [p148Results, setP148Results] = useState('');
  const [p149Rate, setP149Rate] = useState(0);
  const [p149Comment, setP149Comment] = useState('');
  const [p149Results, setP149Results] = useState('');
  const [p149Other, setP149Other] = useState('');
  const [p150Rate, setP150Rate] = useState(0);
  const [p150Comment, setP150Comment] = useState('');
  const [p150Results, setP150Results] = useState('');
  const [p151Rate, setP151Rate] = useState(0);
  const [p151Comment, setP151Comment] = useState('');
  const [p151Results, setP151Results] = useState('');

  const [filled, setFilled] = useState(false);
  const [unchanged, setUnchanged] = useState(true);
  const [message, setMessage] = useState('');
  const [messageColor, setMessageColor] = useState('');

  useEffect( () => {
    if(unchanged) return;
    
    window.addEventListener('beforeunload',handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload',handleBeforeUnload)
    }
    
  },[unchanged]);

  const setErrorMessage = (msg) => {
    setMessageColor("red");
    setMessage(msg);
  }

  const setOkMessage = (msg) => {
    setMessageColor("teal");
    setMessage(msg);
  }

  const resetValues = () => {
    const data = pageData?JSON.parse(pageData):[];
    
    setP144Selections(data.p144Selections?data.p144Selections:[]);
    setP144Comments(data.p144Comments?data.p144Comments:[]);
    setP145Rate(data.p145Rate?data.p145Rate:0);
    setP145Comment(data.p145Comment?data.p145Comment:'');
    setP146Rate(data.p146Rate?data.p146Rate:0);
    setP146Comment(data.p146Comment?data.p146Comment:'');
    setP147Rate(data.p147Rate?data.p147Rate:0);
    setP147Comment(data.p147Comment?data.p147Comment:'');
    setP148Rate(data.p148Rate?data.p148Rate:0);
    setP148Comment(data.p148Comment?data.p148Comment:'');
    setP149Rate(data.p149Rate?data.p149Rate:0);
    setP149Comment(data.p149Comment?data.p149Comment:'');
    setP149Other(data.p149Other?data.p149Other:'');
    setP150Rate(data.p150Rate?data.p150Rate:0);
    setP150Comment(data.p150Comment?data.p150Comment:'');
    setP151Rate(data.p151Rate?data.p151Rate:0);
    setP151Comment(data.p151Comment?data.p151Comment:'');

    setUnchanged(true);
  }
  
  const fetchData = async () => {
    axios
      .post(backendServer + "/get_votes.php", {
        user_id: JSON.parse(props.user).user_id,
        problem_ids: ["144", "145", "146", "147", "148", "149", "150", "151"]
      })
      .then((response) => {
        if (response.data.status === "ok") {
          let answers = JSON.parse(response.data.answers);
          let results = JSON.parse(response.data.results);
          let data = {};

          if(answers['144']) {
            setFirstRound(false);
          }
          
          [data['p144Selections'], data['p144Comments']] = readSelectionAnswers(answers['144']);
          [data['p145Rate'], data['p145Comment']] = readRateAnswer(answers['145']);
          [data['p146Rate'], data['p146Comment']] = readRateAnswer(answers['146']);
          [data['p147Rate'], data['p147Comment']] = readRateAnswer(answers['147']);
          [data['p148Rate'], data['p148Comment']] = readRateAnswer(answers['148']);
          [data['p149Rate'], data['p149Comment']] = readRateAnswer(answers['149']);
          if(answers['149']&&answers['149'].other) data['p149Other'] = answers['149'].other;
          else data['p149Other']='';
          [data['p150Rate'], data['p150Comment']] = readRateAnswer(answers['150']);
          [data['p151Rate'], data['p151Comment']] = readRateAnswer(answers['151']);

          setPageData(JSON.stringify(data));

          setP144Results(results["144"]);
          setP145Results(results["145"]);
          setP146Results(results["146"]);
          setP147Results(results["147"]);
          setP148Results(results["148"]);
          setP149Results(results["149"]);
          setP150Results(results["150"]);
          setP151Results(results["151"]);
          
          checkFilled();
          setUnchanged(true);
        } else {
          setErrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  }
  
  useEffect(()=>{
    fetchData(); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    resetValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pageData]);

  useEffect(() => {
    setMessage('');
    let data = pageData?JSON.parse(pageData):[];

    if (!isDeepEqual(data.p144Selections, p144Selections)) 
      { setUnchanged(false); return; }
    if (!isDeepEqual(data.p144Comments, p144Comments)) 
      { setUnchanged(false); return; }
    if (Number(data.p145Rate) !== Number(p145Rate)) { setUnchanged(false); return; }
    if (data.p145Comment !== p145Comment) { setUnchanged(false); return; }
    if (Number(data.p146Rate) !== Number(p146Rate)) { setUnchanged(false); return; }
    if (data.p146Comment !== p146Comment) { setUnchanged(false); return; }
    if (Number(data.p147Rate) !== Number(p147Rate)) { setUnchanged(false); return; }
    if (data.p147Comment !== p147Comment) { setUnchanged(false); return; }
    if (Number(data.p148Rate) !== Number(p148Rate)) { setUnchanged(false); return; }
    if (data.p148Comment !== p148Comment) { setUnchanged(false); return; }
    if (Number(data.p149Rate) !== Number(p149Rate)) { setUnchanged(false); return; }
    if (data.p149Comment !== p149Comment) { setUnchanged(false); return; }
    if (data.p149Other !== p149Other) { setUnchanged(false); return; }    
    if (Number(data.p150Rate) !== Number(p150Rate)) { setUnchanged(false); return; }
    if (data.p150Comment !== p150Comment) { setUnchanged(false); return; }
    if (Number(data.p151Rate) !== Number(p151Rate)) { setUnchanged(false); return; }
    if (data.p151Comment !== p151Comment) { setUnchanged(false); return; }
    
    setUnchanged(true);
  }, [    
    p144Selections, p144Comments,
    p145Rate, p145Comment,
    p146Rate, p146Comment,
    p147Rate, p147Comment,
    p148Rate, p148Comment,
    p149Rate, p149Comment, p149Other,
    p150Rate, p150Comment,
    p151Rate, p151Comment, pageData
  ]);

  const checkFilled = () => {
    if(!checkSelectionFilled(p144Selections, p144Comments, 3, 3)) {setFilled(false); return;}
    if(!checkRateFilled(p145Rate, p145Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p146Rate, p146Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p147Rate, p147Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p148Rate, p148Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p149Rate, p149Comment, 1, 5)) {setFilled(false); return;}
    //if(!p149Other) {setFilled(false); return;}
    if(!checkRateFilled(p150Rate, p150Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p151Rate, p151Comment, 1, 5)) {setFilled(false); return;}

    setFilled(true);
  }

  const navigate = (msg) => {
    window.removeEventListener('beforeunload',handleBeforeUnload); 
    if(!msg) msg = 'Proceeding..';
    setOkMessage(msg);
    setTimeout(()=> {
      window.location.href = '/survey?issue=4&page=6';
    }, 1000);
  }
  
  useEffect(() => {   
    checkFilled();
  });
   
  let admin = JSON.parse(props.user).admin;
  let isAdmin = admin&&admin==="1";
  
  const handleSubmit = (event) => {
    event.preventDefault();
    if((filled&&unchanged)||isAdmin) { navigate(); return; }
    
    if(!checkSelectionFilled(p144Selections, p144Comments, 3, 3,
      "Please select three answers for problem 1.",
      "Please provide your reasoning for each selected answer in problem 1.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p145Rate, p145Comment, 1, 5,
      "Please answer problem 2a.",
      "Please provide your reasoning for problem 2a.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p146Rate, p146Comment, 1, 5,
      "Please answer problem 2b.",
      "Please provide your reasoning for problem 2b.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p147Rate, p147Comment, 1, 5,
      "Please answer problem 2c.",
      "Please provide your reasoning for problem 2c.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p148Rate, p148Comment, 1, 5,
      "Please answer problem 2d.",
      "Please provide your reasoning for problem 2d.",
      setErrorMessage
    )) return;
    if(p149Other) {
      if(!checkRateFilled(p149Rate, p149Comment, 1, 5,
        "Please answer problem 2e.",
        "Please provide your reasoning for problem 2e.",
        setErrorMessage
      )) return;
    }
    // if(!p149Other) {
    //   setErrorMessage("Please specify the other field in problem 2e.");
    //   return;
    // }
    if(!checkRateFilled(p150Rate, p150Comment, 1, 5,
      "Please answer problem 3.",
      "Please provide your reasoning for problem 3.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p151Rate, p151Comment, 1, 5,
      "Please answer problem 4.",
      "Please provide your reasoning for problem 4.",
      setErrorMessage
    )) return;

    let user_id = JSON.parse(props.user).user_id;
    let voteData = [];
    p144Selections.forEach((selection,index)=>{
      voteData.push({user_id, problem_id:144, vote:selection, comment: p144Comments[index]});
    });
    voteData.push({user_id, problem_id:145, vote:p145Rate, comment: p145Comment});
    voteData.push({user_id, problem_id:146, vote:p146Rate, comment: p146Comment});
    voteData.push({user_id, problem_id:147, vote:p147Rate, comment: p147Comment});
    voteData.push({user_id, problem_id:148, vote:p148Rate, comment: p148Comment});
    if(p149Other)
      voteData.push({user_id, problem_id:149, vote:p149Rate, comment: p149Comment, other: p149Other});
    voteData.push({user_id, problem_id:150, vote:p150Rate, comment: p150Comment});
    voteData.push({user_id, problem_id:151, vote:p151Rate, comment: p151Comment});

    axios.post( backendServer+'/vote.php', {vote_data: JSON.stringify(voteData)})
    .then( (response)=>{
      if(response.data.status==='ok') {
        //updatePageData();
        //fetchData();
        setFilled(true);
        setFirstRound(false);
        //setOkMessage("Responses saved.");
        navigate('Responses saved.');
      } else {
        setErrorMessage(response.data.message);
      }
    })
    .catch((error)=>{
      setErrorMessage(error.message);
    });
  }

  const updateP144Answer = (selection, add) => {
    if(add) {
      let newSelections = [...p144Selections];
      newSelections.push(selection);
      setP144Selections(newSelections);
      let newComments = [...p144Comments];
      let data = pageData?JSON.parse(pageData):[];
      const index = data.p144Selections?data.p144Selections.indexOf(selection):-1;
      newComments.push(index>=0?data.p144Comments[index]:'');
      setP144Comments(newComments);
    } else {
      let index = p144Selections.indexOf(selection);
      if(index>=0) { // sanity check        
        let newSelections = [...p144Selections];
        newSelections.splice(index,1);
        setP144Selections(newSelections);
        let newComments = [...p144Comments];
        newComments.splice(index,1);
        setP144Comments(newComments);
      }
    }
  }

  const updateP144Comment = (answerIndex, comment) => {
    let newComments = [...p144Comments];
    newComments[answerIndex]=comment;
    setP144Comments(newComments);
  }

  return (
    <form onSubmit={handleSubmit}>
      <table width="600" align="center" cellPadding={8}>
        <tbody>
          <tr>
            <td>
              <h4>4.2 Land-use Practices</h4>
              <p>
                Land-use practices for net-zero emissions refer to activities
                such as reforestation, afforestation, agroforestry, and carbon
                farming which is a holistic farm approach to optimizing carbon
                capture and storage by implementing practices that can improve
                the rate at which CO2 is removed from the atmosphere and stored
                in plants and/or soils. Net-zero emissions require
                transformative changes in land use for direct GHG reduction,
                especially from the agricultural sector and for carbon
                sequestration.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <SelectItem
                problem_id={144}
                firstRound={firstRound}
                adminMode={isAdmin}
                max={3}
                updateAnswer={updateP144Answer}
                updateComment={updateP144Comment}
                selections={p144Selections}
                comments={p144Comments}
                text="1. Based on your justification, please select three APEC economies that have effective land-use policies for net-zero emissions."
                choices={allEconomies}
                other={false}
                results={p144Results}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              2. On a scale of 1 to 5, please rate the effectiveness of land-use
              practices for net-zero emissions in APEC.
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={145}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p145Rate}
                setRate={setP145Rate}
                comment={p145Comment}
                setComment={setP145Comment}
                text="a. Reforestation"
                results={p145Results}
                choices={["Least Effective", "Somehow Effective", "Moderately Effective", "Very Effective", "Most Effective"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={146}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p146Rate}
                setRate={setP146Rate}
                comment={p146Comment}
                setComment={setP146Comment}
                text="b. Afforestation"
                results={p146Results}
                choices={["Least Effective", "Somehow Effective", "Moderately Effective", "Very Effective", "Most Effective"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={147}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p147Rate}
                setRate={setP147Rate}
                comment={p147Comment}
                setComment={setP147Comment}
                text="c. Agroforestry"
                results={p147Results}
                choices={["Least Effective", "Somehow Effective", "Moderately Effective", "Very Effective", "Most Effective"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={148}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p148Rate}
                setRate={setP148Rate}
                comment={p148Comment}
                setComment={setP148Comment}
                text="d. Carbon farming"
                results={p148Results}
                choices={["Least Effective", "Somehow Effective", "Moderately Effective", "Very Effective", "Most Effective"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={149}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p149Rate}
                setRate={setP149Rate}
                comment={p149Comment}
                setComment={setP149Comment}
                text="e. Others"
                other={true}
                otherText={p149Other}
                updateOther={setP149Other}
                results={p149Results}
                choices={["Least Effective", "Somehow Effective", "Moderately Effective", "Very Effective", "Most Effective"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={150}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p150Rate}
                setRate={setP150Rate}
                comment={p150Comment}
                setComment={setP150Comment}
                text="3. Based on your justification, do you agree that forest carbon credits should be included in an Emission Trading Scheme (ETS) to accelerate reforestation and afforestation?"
                results={p150Results}
                choices={["Strongly disagree", "Disagree", "Neutral", "Agree", "Strongly agree"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={151}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p151Rate}
                setRate={setP151Rate}
                comment={p151Comment}
                setComment={setP151Comment}
                text="4. Based on your justification, do you agree that a payment scheme for public goods and services, such as those related to climate mitigation and biodiversity, is a key policy instrument for supporting agroforestry?"
                results={p151Results}
                choices={["Strongly disagree", "Disagree", "Neutral", "Agree", "Strongly agree"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td align="right">
            <Message color={messageColor} message={message} />
              <br />
              <NextButtonPanel
                disabled={unchanged}
                onClick={resetValues}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );
};

export default Issue4page5;