const UserInfo = (props) => {
  const logout = () => {
    console.log("logout");
    props.setUser('');
  };

  return <>
    {
      props.user?
      <>
        Greeting <b>{JSON.parse(props.user).name} {JSON.parse(props.user).family_name}</b>
        &nbsp;
        <input type="button" value='logout' onClick={logout} />
      </> 
      :<div><i>Login to Start Survey</i></div>
    }
  </>;
};

export default UserInfo;