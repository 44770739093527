import './SurveyBar.css';
import PageBar from './PageBar';

const SurveyBar = (props) => {

  return <center>
    <table><tbody><tr align='center'><td>
      {props.admin||
        <>
          {props.issue==="0"?
            <div className='darkGrayButton'>Personal Info</div>:
            <a className='grayButton' href="survey?issue=0">Personal Info</a>
          }
        </>
      }
      {props.issue==="1"?
        <div className='darkGrayButton'>Issue 1</div>:
        <a className='grayButton' href="survey?issue=1">Issue 1</a>
      }
      {props.issue==="2"?
        <div className='darkGrayButton'>Issue 2</div>:
        <a className='grayButton' href="survey?issue=2">Issue 2</a>
      }
      {props.issue==="3"?
        <div className='darkGrayButton'>Issue 3</div>:
        <a className='grayButton' href="survey?issue=3">Issue 3</a>
      }
      {props.issue==="4"?
        <div className='darkGrayButton'>Issue 4</div>:
        <a className='grayButton' href="survey?issue=4">Issue 4</a>
      }
      {props.issue==="5"?
        <div className='darkGrayButton'>Issue 5</div>:
        <a className='grayButton' href="survey?issue=5">Issue 5</a>
      }
      <br />
      <div align='right'><PageBar issue={props.issue} page={props.page} /></div>
    </td></tr></tbody></table>
    
    </center>

};

export default SurveyBar;