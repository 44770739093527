import { useState, useEffect } from 'react';
import axios from 'axios';
import { backendServer, allEconomies } from '../../Const';
import RateItem from "./RateItem";
import SelectItem from "./SelectItem";
import Message from './Message';

import {isDeepEqual, readRateAnswer, readSelectionAnswers, checkRateFilled, checkSelectionFilled, handleBeforeUnload} from '../../SurveyUtils';
import NextButtonPanel from '../../components/NextButtonPanel';

const Issue3page1 = (props) => {
  const [pageData, setPageData] = useState('');
  const [firstRound, setFirstRound] = useState(true);

  const [p63Rate, setP63Rate] = useState(0);
  const [p63Comment, setP63Comment] = useState('');
  const [p63Results, setP63Results] = useState('');
  const [p64Selections, setP64Selections] = useState([]);
  const [p64Comments, setP64Comments] = useState([]);
  const [p64Results, setP64Results] = useState('');
  const [p65Selections, setP65Selections] = useState([]);
  const [p65Comments, setP65Comments] = useState([]);
  const [p65Results, setP65Results] = useState('');
  const [p66Rate, setP66Rate] = useState(0);
  const [p66Comment, setP66Comment] = useState('');
  const [p66Results, setP66Results] = useState('');
  const [p67Selections, setP67Selections] = useState([]);
  const [p67Comments, setP67Comments] = useState([]);
  const [p67Results, setP67Results] = useState('');
  const [p68Selections, setP68Selections] = useState([]);
  const [p68Comments, setP68Comments] = useState([]);
  const [p68Results, setP68Results] = useState('');

  const [filled, setFilled] = useState(false);
  const [unchanged, setUnchanged] = useState(true);
  const [message, setMessage] = useState('');
  const [messageColor, setMessageColor] = useState('');

  useEffect( () => {
    if(unchanged) return;
    
    window.addEventListener('beforeunload',handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload',handleBeforeUnload)
    }
    
  },[unchanged]);

  const setErrorMessage = (msg) => {
    setMessageColor("red");
    setMessage(msg);
  }

  const setOkMessage = (msg) => {
    setMessageColor("teal");
    setMessage(msg);
  }

  const resetValues = () => {
    const data = pageData?JSON.parse(pageData):[];
    setP63Rate(data.p63Rate?data.p63Rate:0);
    setP63Comment(data.p63Comment?data.p63Comment:'');
    setP64Selections(data.p64Selections?data.p64Selections:[]);
    setP64Comments(data.p64Comments?data.p64Comments:[]);
    setP65Selections(data.p65Selections?data.p65Selections:[]);
    setP65Comments(data.p65Comments?data.p65Comments:[]);
    setP66Rate(data.p66Rate?data.p66Rate:0);
    setP66Comment(data.p66Comment?data.p66Comment:'');
    setP67Selections(data.p67Selections?data.p67Selections:[]);
    setP67Comments(data.p67Comments?data.p67Comments:[]);
    setP68Selections(data.p68Selections?data.p68Selections:[]);
    setP68Comments(data.p68Comments?data.p68Comments:[]);
    setUnchanged(true);
  }
  
  const fetchData = async () => {
    axios
      .post(backendServer + "/get_votes.php", {
        user_id: JSON.parse(props.user).user_id,
        problem_ids: ["63", "64", "65", "66", "67", "68"]
      })
      .then((response) => {
        if (response.data.status === "ok") {
          let answers = JSON.parse(response.data.answers);
          let results = JSON.parse(response.data.results);
          let data = {};

          if(answers['63']) {
            setFirstRound(false);
          }
          
          [data['p63Rate'], data['p63Comment']] = readRateAnswer(answers['63']);
          [data['p64Selections'], data['p64Comments']] = readSelectionAnswers(answers['64']);
          [data['p65Selections'], data['p65Comments']] = readSelectionAnswers(answers['65']);
          [data['p66Rate'], data['p66Comment']] = readRateAnswer(answers['66']);
          [data['p67Selections'], data['p67Comments']] = readSelectionAnswers(answers['67']);
          [data['p68Selections'], data['p68Comments']] = readSelectionAnswers(answers['68']);

          setPageData(JSON.stringify(data));

          setP63Results(results["63"]);
          setP64Results(results["64"]);
          setP65Results(results["65"]);
          setP66Results(results["66"]);
          setP67Results(results["67"]);
          setP68Results(results["68"]);

          checkFilled();
          setUnchanged(true);
        } else {
          setErrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  }
  
  useEffect(()=>{
    fetchData(); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    resetValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pageData]);

  useEffect(() => {
    setMessage('');
    let data = pageData?JSON.parse(pageData):[];

    if (Number(data.p63Rate) !== Number(p63Rate)) { setUnchanged(false); return; }
    if (data.p63Comment !== p63Comment) { setUnchanged(false); return; }
    if (!isDeepEqual(data.p64Selections, p64Selections)) 
      { setUnchanged(false); return; }
    if (!isDeepEqual(data.p64Comments, p64Comments)) 
      { setUnchanged(false); return; }
    if (!isDeepEqual(data.p65Selections, p65Selections)) 
      { setUnchanged(false); return; }
    if (!isDeepEqual(data.p65Comments, p65Comments)) 
      { setUnchanged(false); return; }
    if (Number(data.p66Rate) !== Number(p66Rate)) { setUnchanged(false); return; }
    if (data.p66Comment !== p66Comment) { setUnchanged(false); return; }
    if (!isDeepEqual(data.p67Selections, p67Selections)) 
      { setUnchanged(false); return; }
    if (!isDeepEqual(data.p67Comments, p67Comments)) 
      { setUnchanged(false); return; }
    if (!isDeepEqual(data.p68Selections, p68Selections)) 
      { setUnchanged(false); return; }
    if (!isDeepEqual(data.p68Comments, p68Comments)) 
      { setUnchanged(false); return; }
    
    setUnchanged(true);
  }, [
    p63Rate, p63Comment,
    p64Selections, p64Comments,
    p65Selections, p65Comments,
    p66Rate, p66Comment,
    p67Selections, p67Comments,
    p68Selections, p68Comments, pageData
  ]);
  
  const checkFilled = () => {
    if(!checkRateFilled(p63Rate, p63Comment, 1, 5)) {setFilled(false); return;}
    if(!checkSelectionFilled(p64Selections, p64Comments, 3, 3)) {setFilled(false); return;}
    if(!checkSelectionFilled(p65Selections, p65Comments, 1, 1)) {setFilled(false); return;}
    if(!checkRateFilled(p66Rate, p66Comment, 1, 5)) {setFilled(false); return;}
    if(!checkSelectionFilled(p67Selections, p67Comments, 3, 3)) {setFilled(false); return;}
    if(!checkSelectionFilled(p68Selections, p68Comments, 3, 3)) {setFilled(false); return;}

    setFilled(true);
  }

  const navigate = (msg) => {
    window.removeEventListener('beforeunload',handleBeforeUnload);
    if(!msg) msg = 'Proceeding..';
    setOkMessage(msg);
    setTimeout(()=> {
      window.location.href = '/survey?issue=3&page=2';
    }, 1000);
  }
  
  useEffect(() => {   
    checkFilled();
  });
 
  let admin = JSON.parse(props.user).admin;
  let isAdmin = admin&&admin==="1";
  
  const handleSubmit = (event) => {
    event.preventDefault();
    
    if((filled&&unchanged)||isAdmin) { navigate(); return; }
    
    if(!checkRateFilled(p63Rate, p63Comment, 1, 5,
      "Please answer problem 1.",
      "Please provide your reasoning for problem 1.",
      setErrorMessage
    )) return;
    if(!checkSelectionFilled(p64Selections, p64Comments, 3, 3,
      "Please select three answers for problem 2.",
      "Please provide your reasoning for each selected answer in problem 2.",
      setErrorMessage,
      "Please specify the other field in problem 2."
    )) return;
    if(!checkSelectionFilled(p65Selections, p65Comments, 1, 1,
      "Please select three answers for problem 3.",
      "Please provide your reasoning for each selected answer in problem 3.",
      setErrorMessage,
      "Please specify the other field in problem 3."
    )) return;
    if(!checkRateFilled(p66Rate, p66Comment, 1, 5,
      "Please answer problem 4.",
      "Please provide your reasoning for problem 4.",
      setErrorMessage
    )) return;
    if(!checkSelectionFilled(p67Selections, p67Comments, 3, 3,
      "Please select three answers for problem 5.",
      "Please provide your reasoning for each selected answer in problem 5.",
      setErrorMessage
    )) return;
    if(!checkSelectionFilled(p68Selections, p68Comments, 3, 3,
      "Please select three answers for problem 6.",
      "Please provide your reasoning for each selected answer in problem 6.",
      setErrorMessage,
      "Please specify the other field in problem 6."
    )) return;

    let user_id = JSON.parse(props.user).user_id;
    let voteData = [];
    voteData.push({user_id, problem_id:63, vote:p63Rate, comment: p63Comment});
    p64Selections.forEach((selection,index)=>{
      voteData.push({user_id, problem_id:64, vote:selection, comment: p64Comments[index]});
    });
    p65Selections.forEach((selection,index)=>{
      voteData.push({user_id, problem_id:65, vote:selection, comment: p65Comments[index]});
    });
    voteData.push({user_id, problem_id:66, vote:p66Rate, comment: p66Comment});
    p67Selections.forEach((selection,index)=>{
      voteData.push({user_id, problem_id:67, vote:selection, comment: p67Comments[index]});
    });
    p68Selections.forEach((selection,index)=>{
      voteData.push({user_id, problem_id:68, vote:selection, comment: p68Comments[index]});
    });

    axios.post( backendServer+'/vote.php', {vote_data: JSON.stringify(voteData)})
    .then( (response)=>{
      if(response.data.status==='ok') {
        //updatePageData();
        setFilled(true);
        setFirstRound(false);
        //setOkMessage("Responses saved.");
        navigate('Responses saved.');
      } else {
        setErrorMessage(response.data.message);
      }
    })
    .catch((error)=>{
      setErrorMessage(error.message);
    });
  }
  
  const updateP64Answer = (selection, add) => {
    if(add) {
      let newSelections = [...p64Selections];
      newSelections.push(selection);
      setP64Selections(newSelections);
      let newComments = [...p64Comments];
      let data = pageData?JSON.parse(pageData):[];
      const index = data.p64Selections?data.p64Selections.indexOf(selection):-1;
      newComments.push(index>=0?data.p64Comments[index]:'');
      setP64Comments(newComments);
    } else {
      let index = p64Selections.indexOf(selection);
      if(index>=0) { // sanity check        
        let newSelections = [...p64Selections];
        newSelections.splice(index,1);
        setP64Selections(newSelections);
        let newComments = [...p64Comments];
        newComments.splice(index,1);
        setP64Comments(newComments);
      }
    }
  }

  const updateP64Comment = (answerIndex, comment) => {
    let newComments = [...p64Comments];
    newComments[answerIndex]=comment;
    setP64Comments(newComments);
  }

  const updateP64Other = (otherText, answerIndex) => {
    let newSelections = [...p64Selections];
    newSelections[answerIndex]=otherText;
    setP64Selections(newSelections);
  }
  
  const updateP65Answer = (selection, add) => {
    if(add) {
      let newSelections = [...p65Selections];
      newSelections.push(selection);
      setP65Selections(newSelections);
      let newComments = [...p65Comments];
      let data = pageData?JSON.parse(pageData):[];
      const index = data.p65Selections?data.p65Selections.indexOf(selection):-1;
      newComments.push(index>=0?data.p65Comments[index]:'');
      setP65Comments(newComments);
    } else {
      let index = p65Selections.indexOf(selection);
      if(index>=0) { // sanity check        
        let newSelections = [...p65Selections];
        newSelections.splice(index,1);
        setP65Selections(newSelections);
        let newComments = [...p65Comments];
        newComments.splice(index,1);
        setP65Comments(newComments);
      }
    }
  }

  const updateP65Comment = (answerIndex, comment) => {
    let newComments = [...p65Comments];
    newComments[answerIndex]=comment;
    setP65Comments(newComments);
  }

  const updateP65Other = (otherText, answerIndex) => {
    let newSelections = [...p65Selections];
    newSelections[answerIndex]=otherText;
    setP65Selections(newSelections);
  }

  const updateP67Answer = (selection, add) => {
    if(add) {
      let newSelections = [...p67Selections];
      newSelections.push(selection);
      setP67Selections(newSelections);
      let newComments = [...p67Comments];
      let data = pageData?JSON.parse(pageData):[];
      const index = data.p67Selections?data.p67Selections.indexOf(selection):-1;
      newComments.push(index>=0?data.p67Comments[index]:'');
      setP67Comments(newComments);
    } else {
      let index = p67Selections.indexOf(selection);
      if(index>=0) { // sanity check        
        let newSelections = [...p67Selections];
        newSelections.splice(index,1);
        setP67Selections(newSelections);
        let newComments = [...p67Comments];
        newComments.splice(index,1);
        setP67Comments(newComments);
      }
    }
  }

  const updateP67Comment = (answerIndex, comment) => {
    let newComments = [...p67Comments];
    newComments[answerIndex]=comment;
    setP67Comments(newComments);
  }

  const updateP68Answer = (selection, add) => {
    if(add) {
      let newSelections = [...p68Selections];
      newSelections.push(selection);
      setP68Selections(newSelections);
      let newComments = [...p68Comments];
      let data = pageData?JSON.parse(pageData):[];
      const index = data.p68Selections?data.p68Selections.indexOf(selection):-1;
      newComments.push(index>=0?data.p68Comments[index]:'');
      setP68Comments(newComments);
    } else {
      let index = p68Selections.indexOf(selection);
      if(index>=0) { // sanity check        
        let newSelections = [...p68Selections];
        newSelections.splice(index,1);
        setP68Selections(newSelections);
        let newComments = [...p68Comments];
        newComments.splice(index,1);
        setP68Comments(newComments);
      }
    }
  }

  const updateP68Comment = (answerIndex, comment) => {
    let newComments = [...p68Comments];
    newComments[answerIndex]=comment;
    setP68Comments(newComments);
  }

  const updateP68Other = (otherText, answerIndex) => {
    let newSelections = [...p68Selections];
    newSelections[answerIndex]=otherText;
    setP68Selections(newSelections);
  }

  return (
    <form onSubmit={handleSubmit}>
      <table width="600" align="center" cellPadding={8}>
        <tbody>
          <tr>
            <td>
              <h4>Issue 3: Green Economy</h4>
              <p>
                <b>
                  UNEP (The United Nations Environment Program) defined the
                  green economy
                </b>{" "}
                as follows:{" "}
                <i>
                  "A green economy is defined as low carbon, resource efficient,
                  and socially inclusive. In a green economy, growth in
                  employment and income is driven by public and private
                  investment into economic activities, infrastructure, and
                  assets that allow reduced carbon emissions and pollution,
                  enhanced energy and resource efficiency, and prevention of the
                  loss of biodiversity and ecosystem services."
                </i>
              </p>
              <p>
                Green economy encompasses various strategies, policies, and
                business models aimed at reducing negative environmental
                impacts, conserving natural resources, and promoting clean
                energy, all while fostering economic growth and job creation.
                Key strategies of a green economy may include:
              </p>
              <p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.1 Industrial
                Processes <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.2 Agricultural
                Practices <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.3 Waste
                Management <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.4 Green
                Tourism <br />
              </p>
              <br />
              <br />
              <h4>3.1 Industrial Processes</h4>
              <p>In a green economy, industrial processes refer to the methods and techniques used in manufacturing and production that prioritize sustainability, resource efficiency, and environmental responsibility. These processes are designed to minimize negative environmental impacts while optimizing resource utilization and promoting cleaner, more efficient production methods.</p>
              <hr />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={63}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p63Rate}
                setRate={setP63Rate}
                comment={p63Comment}
                setComment={setP63Comment}
                text="1. Based on your justification, how likely it is for sustainable manufacturing practices, such as waste reduction and resource efficiency, be adopted for industries in the next decade?"
                results={p63Results}
                choices={["Very unlikely", "Unlikely", "Neutral", "Likely", "Very likely"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <SelectItem
                problem_id={64}
                firstRound={firstRound}
                adminMode={isAdmin}
                max={3}
                updateAnswer={updateP64Answer}
                updateComment={updateP64Comment}
                selections={p64Selections}
                comments={p64Comments}
                text="2. Based on your justification, please select three technologies that have the potential to significantly improve industrial efficiency in the green economy."
                choices={["IoT (Internet of Things)", "Artificial Intelligence (AI) and Machine Learning", "Renewable Energy Technology", "Energy Storage Systems", "Advanced Robotics", "Advanced Materials", "3D Printing", "Smart Grids", "Blockchain", "Autonomous Vehicles", "CCS technologies", "Waste-to-Energy", "Recycling and waste management", "Bioenergy and biofuels", "Smart buildings"]}
                other={true}
                updateOther={updateP64Other}
                results={p64Results}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <SelectItem
                problem_id={65}
                firstRound={firstRound}
                adminMode={isAdmin}
                max={1}
                updateAnswer={updateP65Answer}
                updateComment={updateP65Comment}
                selections={p65Selections}
                comments={p65Comments}
                text="3. Which industry sector would be most promisingly adopting green technologies?"
                choices={["Transportation and Logistics", "Manufacturing", "Agriculture", "Construction and Real Estate", "Pharmaceuticals and Healthcare", "Food and Beverage", "Chemicals and Petrochemicals", "Energy Production", "Mining and Resource Extraction", "Textiles and Apparels", "Tourism", "Banking / Finance"]}
                other={true}
                updateOther={updateP65Other}
                results={p65Results}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={66}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p66Rate}
                setRate={setP66Rate}
                comment={p66Comment}
                setComment={setP66Comment}
                text="4. Do you agree that the biggest challenge to implementing green industrial processes is initial capital investments?"
                results={p66Results}
                choices={["Strongly disagree", "Disagree", "Neutral", "Agree", "Strongly agree"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <SelectItem
                problem_id={67}
                firstRound={firstRound}
                adminMode={isAdmin}
                max={3}
                updateAnswer={updateP67Answer}
                updateComment={updateP67Comment}
                selections={p67Selections}
                comments={p67Comments}
                text="5. Based on your justification, please select three APEC economies that have effective policies for industrial processes in green economy."
                choices={allEconomies}
                other={false}
                results={p67Results}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <SelectItem
                problem_id={68}
                firstRound={firstRound}
                adminMode={isAdmin}
                max={3}
                updateAnswer={updateP68Answer}
                updateComment={updateP68Comment}
                selections={p68Selections}
                comments={p68Comments}
                text="6. Please select three indicators that should be used to evaluate the extent of green economy adoption."
                choices={["Energy efficiency", "Resource efficiency", "Emissions reduction", "Waste reduction", "Quality and productivity", "Cost savings", "Supply chain impact", "Innovation and technology adoption", "Health and Wellness", "Social awareness", "Behavior change"]}
                other={true}
                updateOther={updateP68Other}
                results={p68Results}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td align="right">
            <Message color={messageColor} message={message} />
              <br />
              <NextButtonPanel
                disabled={unchanged}
                onClick={resetValues}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );
};

export default Issue3page1;