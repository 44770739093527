import { useState, useEffect } from 'react';
import SelectResults from './SelectResults';

const CheckBox = (props) => {
  const [checked, setChecked] = useState(props.answerIndex>=0);
  const [otherText, setOtherText] = useState(props.selection);

  const handleChange = () => {
    if(checked) {
      props.updateAnswer(props.selection, false);
      setChecked(false);
    } else {
      if(!props.selections||(props.selections.length<props.max)) {
        if(props.isOther) {
          props.updateAnswer(otherText, true);
        } else {
          props.updateAnswer(props.selection, true);
        }
        setChecked(true);
      }
    }
  }

  const handleTextChange = (e) => {
    props.updateComment(props.answerIndex, e.target.value);
  }

  const handleOtherChange = (e) => {
    setOtherText(e.target.value);
    if(props.answerIndex>=0) {
      props.updateOther(e.target.value, props.answerIndex);
    }
  }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { 
    setChecked(props.answerIndex>=0);
  });
  
  return (
    <>
      &nbsp;
      <input
        type="checkbox"
        value={props.selection}
        checked={checked}
        name={props.selection}
        onChange={handleChange}
      />{" "}
      {props.isOther ? (
        "Others"
      ) : (
        <>
          {props.hover ? (
            <div className="tooltip">
              {props.selection}
              <span className="tooltiptext" dangerouslySetInnerHTML={{__html:props.hover}} ></span>
            </div>
          ) : (
            props.selection
          )}
        </>
      )}
      {props.isOther ? (
        <span>
          &nbsp;
          <input
            type="text"
            name="others"
            value={
              props.answerIndex >= 0
                ? props.selections[props.answerIndex]
                : otherText
            }
            onChange={(e) => handleOtherChange(e)}
          />
        </span>
      ) : (
        ""
      )}
      {checked ? (
        <>
          <br />
          <i>Please provide your reasoning</i> <br />
          <textarea
            onChange={(e) => handleTextChange(e)}
            name={props.selection + "_comment"}
            value={
              props.answerIndex >= 0 ? props.comments[props.answerIndex] : ""
            }
            rows={2}
            cols={80}
          ></textarea>
        </>
      ) : (
        <span></span>
      )}
      <br />
    </>
  );
} 


const getOtherIndex = (choices, selections) => {
  if(!selections) return -1;
  for(let i=0; i<selections.length; i++) {
    if(choices.indexOf(selections[i])<0) {
      return i;
    }
  }
  return -1;
}

const SelectItem = (props) => {
  const [showResult, setShowResult] = useState(false);

  const toggleResults = (e) => {
    setShowResult(!showResult);
  };

  return (
    <div style={{ background: "#f0f0f0", padding: "9px 15px" }}>
      {props.text}
      <br />
      <table align="right">
        <tbody>
          <tr>
            <td>
              {props.adminMode ? (
                <span>
                  <i>Admin Mode</i>
                </span>
              ) : (
                <>
                  {props.firstRound ? (
                    <>&nbsp;</>
                  ) : (
                    <input
                      type="button"
                      value={
                        showResult ? "resume survey" : "show previous results"
                      }
                      onClick={(e) => toggleResults(e)}
                    />
                  )}
                </>
              )}
            </td>
          </tr>
        </tbody>
      </table>

      <br />
      <span hidden={!showResult && !props.adminMode}>
        <SelectResults
          results={props.results}
          choices={props.choices}
          width={500}
          height={100}
          other={props.other}
        />
      </span>
      <span hidden={showResult || props.adminMode}>
        {props.choices.map((choice, index) => (
          <CheckBox
            key={index + 1}
            selection={choice}
            hover={props.choices_hover ? props.choices_hover[index] : null}
            selections={props.selections}
            answerIndex={
              props.selections ? props.selections.indexOf(choice) : -1
            }
            comments={props.comments}
            max={props.max}
            updateAnswer={props.updateAnswer}
            updateComment={props.updateComment}
          />
        ))}
        {props.other ? (
          <CheckBox
            key={props.choices.length}
            selection={
              getOtherIndex(props.choices, props.selections) >= 0
                ? props.selections[
                    getOtherIndex(props.choices, props.selections)
                  ]
                : ""
            }
            isOther={true}
            updateOther={props.updateOther}
            selections={props.selections}
            answerIndex={getOtherIndex(props.choices, props.selections)}
            comments={props.comments}
            max={props.max}
            updateAnswer={props.updateAnswer}
            updateComment={props.updateComment}
          />
        ) : (
          ""
        )}
      </span>
    </div>
  );
};

export default SelectItem;