import { useState, useEffect } from 'react';
import axios from 'axios';
import { backendServer } from '../../Const';
import RateItem from "./RateItem";
import Message from './Message';

import {readRateAnswer, checkRateFilled, handleBeforeUnload} from '../../SurveyUtils';
import NextButtonPanel from '../../components/NextButtonPanel';

const Issue2page3 = (props) => {  
  const [page7Data, setPage7Data] = useState('');
  const [firstRound, setFirstRound] = useState(true);

  const [p28Rate, setP28Rate] = useState(0);
  const [p28Comment, setP28Comment] = useState('');
  const [p28Results, setP28Results] = useState('');
  const [p29Rate, setP29Rate] = useState(0);
  const [p29Comment, setP29Comment] = useState('');
  const [p29Results, setP29Results] = useState('');
  const [p30Rate, setP30Rate] = useState(0);
  const [p30Comment, setP30Comment] = useState('');
  const [p30Results, setP30Results] = useState('');
  const [p31Rate, setP31Rate] = useState(0);
  const [p31Comment, setP31Comment] = useState('');
  const [p31Results, setP31Results] = useState('');
  const [p32Rate, setP32Rate] = useState(0);
  const [p32Comment, setP32Comment] = useState('');
  const [p32Results, setP32Results] = useState('');
  const [p33Rate, setP33Rate] = useState(0);
  const [p33Comment, setP33Comment] = useState('');
  const [p33Results, setP33Results] = useState('');
  const [p34Rate, setP34Rate] = useState(0);
  const [p34Comment, setP34Comment] = useState('');
  const [p34Results, setP34Results] = useState('');
  const [p35Rate, setP35Rate] = useState(0);
  const [p35Comment, setP35Comment] = useState('');
  const [p35Results, setP35Results] = useState('');
  const [p36Rate, setP36Rate] = useState(0);
  const [p36Comment, setP36Comment] = useState('');
  const [p36Results, setP36Results] = useState('');
  const [p37Rate, setP37Rate] = useState(0);
  const [p37Comment, setP37Comment] = useState('');
  const [p37Results, setP37Results] = useState('');
  const [p37Other, setP37Other] = useState('');

  const [filled, setFilled] = useState(false);
  const [unchanged, setUnchanged] = useState(true);
  const [message, setMessage] = useState('');
  const [messageColor, setMessageColor] = useState('');

  useEffect( () => {
    if(unchanged) return;
    
    window.addEventListener('beforeunload',handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload',handleBeforeUnload)
    }
    
  },[unchanged]);

  const setErrorMessage = (msg) => {
    setMessageColor("red");
    setMessage(msg);
  }

  const setOkMessage = (msg) => {
    setMessageColor("teal");
    setMessage(msg);
  }
  
  const resetValues = () => {
    const data = page7Data?JSON.parse(page7Data):[];
    setP28Rate(data.p28Rate?data.p28Rate:0);
    setP28Comment(data.p28Comment?data.p28Comment:'');
    setP29Rate(data.p29Rate?data.p29Rate:0);
    setP29Comment(data.p29Comment?data.p29Comment:'');
    setP30Rate(data.p30Rate?data.p30Rate:0);
    setP30Comment(data.p30Comment?data.p30Comment:'');
    setP31Rate(data.p31Rate?data.p31Rate:0);
    setP31Comment(data.p31Comment?data.p31Comment:'');
    setP32Rate(data.p32Rate?data.p32Rate:0);
    setP32Comment(data.p32Comment?data.p32Comment:'');
    setP33Rate(data.p33Rate?data.p33Rate:0);
    setP33Comment(data.p33Comment?data.p33Comment:'');
    setP34Rate(data.p34Rate?data.p34Rate:0);
    setP34Comment(data.p34Comment?data.p34Comment:'');
    setP35Rate(data.p35Rate?data.p35Rate:0);
    setP35Comment(data.p35Comment?data.p35Comment:'');
    setP36Rate(data.p36Rate?data.p36Rate:0);
    setP36Comment(data.p36Comment?data.p36Comment:'');
    setP37Rate(data.p37Rate?data.p37Rate:0);
    setP37Comment(data.p37Comment?data.p37Comment:'');
    setP37Other(data.p37Other?data.p37Other:'');
    setUnchanged(true);
  }
  
  const fetchData = async () => {
    axios
      .post(backendServer + "/get_votes.php", {
        user_id: JSON.parse(props.user).user_id,
        problem_ids: ["28", "29", "30", "31", "32", "33", "34", "35", "36", "37"],
      })
      .then((response) => {
        if (response.data.status === "ok") {
          let answers = JSON.parse(response.data.answers);
          let results = JSON.parse(response.data.results);
          let data = {};

          if(answers['28']) {
            setFirstRound(false);
          }
          
          [data['p28Rate'], data['p28Comment']] = readRateAnswer(answers['28']);
          [data['p29Rate'], data['p29Comment']] = readRateAnswer(answers['29']);
          [data['p30Rate'], data['p30Comment']] = readRateAnswer(answers['30']);
          [data['p31Rate'], data['p31Comment']] = readRateAnswer(answers['31']);
          [data['p32Rate'], data['p32Comment']] = readRateAnswer(answers['32']);
          [data['p33Rate'], data['p33Comment']] = readRateAnswer(answers['33']);
          [data['p34Rate'], data['p34Comment']] = readRateAnswer(answers['34']);
          [data['p35Rate'], data['p35Comment']] = readRateAnswer(answers['35']);
          [data['p36Rate'], data['p36Comment']] = readRateAnswer(answers['36']);
          [data['p37Rate'], data['p37Comment']] = readRateAnswer(answers['37']);
          if(answers['37']&&answers['37'].other) data['p37Other'] = answers['37'].other;
          else data['p37Other'] = '';
          
          setPage7Data(JSON.stringify(data));

          setP28Results(results["28"]);
          setP29Results(results["29"]);
          setP30Results(results["30"]);
          setP31Results(results["31"]);
          setP32Results(results["32"]);
          setP33Results(results["33"]);
          setP34Results(results["34"]);
          setP35Results(results["35"]);
          setP36Results(results["36"]);
          setP37Results(results["37"]);

          checkFilled();
          setUnchanged(true);
        } else {
          setErrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  }
  
  useEffect(()=>{
    fetchData(); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    resetValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[page7Data]);
    
  useEffect(() => {
    setMessage('');
    let data = page7Data?JSON.parse(page7Data):[];

    if (Number(data.p28Rate) !== Number(p28Rate)) { setUnchanged(false); return; }
    if (data.p28Comment !== p28Comment) { setUnchanged(false); return; }
    if (Number(data.p29Rate) !== Number(p29Rate)) { setUnchanged(false); return; }
    if (data.p29Comment !== p29Comment) { setUnchanged(false); return; }
    if (Number(data.p30Rate) !== Number(p30Rate)) { setUnchanged(false); return; }
    if (data.p30Comment !== p30Comment) { setUnchanged(false); return; }
    if (Number(data.p31Rate) !== Number(p31Rate)) { setUnchanged(false); return; }
    if (data.p31Comment !== p31Comment) { setUnchanged(false); return; }
    if (Number(data.p32Rate) !== Number(p32Rate)) { setUnchanged(false); return; }
    if (data.p32Comment !== p32Comment) { setUnchanged(false); return; }
    if (Number(data.p33Rate) !== Number(p33Rate)) { setUnchanged(false); return; }
    if (data.p33Comment !== p33Comment) { setUnchanged(false); return; }
    if (Number(data.p34Rate) !== Number(p34Rate)) { setUnchanged(false); return; }
    if (data.p34Comment !== p34Comment) { setUnchanged(false); return; }
    if (Number(data.p35Rate) !== Number(p35Rate)) { setUnchanged(false); return; }
    if (data.p35Comment !== p35Comment) { setUnchanged(false); return; }
    if (Number(data.p36Rate) !== Number(p36Rate)) { setUnchanged(false); return; }
    if (data.p36Comment !== p36Comment) { setUnchanged(false); return; }
    if (Number(data.p37Rate) !== Number(p37Rate)) { setUnchanged(false); return; }
    if (data.p37Comment !== p37Comment) { setUnchanged(false); return; }    
    if (data.p37Other !== p37Other) { setUnchanged(false); return; }    

    setUnchanged(true);
  }, [
    p28Rate, p28Comment,
    p29Rate, p29Comment,
    p30Rate, p30Comment,
    p31Rate, p31Comment,
    p32Rate, p32Comment,
    p33Rate, p33Comment,
    p34Rate, p34Comment,
    p35Rate, p35Comment,
    p36Rate, p36Comment,
    p37Rate, p37Comment, p37Other, page7Data
  ]);
  
  const checkFilled = () => {
    if(!checkRateFilled(p28Rate, p28Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p29Rate, p29Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p30Rate, p30Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p31Rate, p31Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p32Rate, p32Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p33Rate, p33Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p34Rate, p34Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p35Rate, p35Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p36Rate, p36Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p37Rate, p37Comment, 1, 5)) {setFilled(false); return;}
    //if(!p37Other) {setFilled(false); return;}

    setFilled(true);
  }
  
  const navigate = (msg) => {
    window.removeEventListener('beforeunload',handleBeforeUnload);
    if(!msg) msg = 'Proceeding..';
    setOkMessage(msg);
    setTimeout(()=> {
      window.location.href = '/survey?issue=2&page=4';
    }, 1000);
  }

  useEffect(() => {   
    checkFilled();
  });
 
  let admin = JSON.parse(props.user).admin;
  let isAdmin = admin&&admin==="1";
    
  const handleSubmit = (event) => {
    event.preventDefault();
    if((filled&&unchanged)||isAdmin) { navigate(); return; }
    
    if(!checkRateFilled(p28Rate, p28Comment, 1, 5,
      "Please answer problem 4a.",
      "Please provide your reasoning for problem 4a.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p29Rate, p29Comment, 1, 5,
      "Please answer problem 4b.",
      "Please provide your reasoning for problem 4b.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p30Rate, p30Comment, 1, 5,
      "Please answer problem 4c.",
      "Please provide your reasoning for problem 4c.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p31Rate, p31Comment, 1, 5,
      "Please answer problem 4d.",
      "Please provide your reasoning for problem 4d.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p32Rate, p32Comment, 1, 5,
      "Please answer problem 4e.",
      "Please provide your reasoning for problem 4e.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p33Rate, p33Comment, 1, 5,
      "Please answer problem 4f.",
      "Please provide your reasoning for problem 4f.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p34Rate, p34Comment, 1, 5,
      "Please answer problem 4g.",
      "Please provide your reasoning for problem 4g.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p35Rate, p35Comment, 1, 5,
      "Please answer problem 4h.",
      "Please provide your reasoning for problem 4h.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p36Rate, p36Comment, 1, 5,
      "Please answer problem 4i.",
      "Please provide your reasoning for problem 4i.",
      setErrorMessage
    )) return;

    if(p37Other) {
      if(!checkRateFilled(p37Rate, p37Comment, 1, 5,
        "Please answer problem 4j.",
        "Please provide your reasoning for problem 4j.",
        setErrorMessage
      )) return;
    }
    // if(!p37Other) {
    //   setErrorMessage("Please specify the other field in problem 4j.");
    //   return;
    // }
        
    let user_id = JSON.parse(props.user).user_id;
    let voteData = [];
    voteData.push({user_id, problem_id:28, vote:p28Rate, comment: p28Comment});
    voteData.push({user_id, problem_id:29, vote:p29Rate, comment: p29Comment});
    voteData.push({user_id, problem_id:30, vote:p30Rate, comment: p30Comment});
    voteData.push({user_id, problem_id:31, vote:p31Rate, comment: p31Comment});
    voteData.push({user_id, problem_id:32, vote:p32Rate, comment: p32Comment});
    voteData.push({user_id, problem_id:33, vote:p33Rate, comment: p33Comment});
    voteData.push({user_id, problem_id:34, vote:p34Rate, comment: p34Comment});
    voteData.push({user_id, problem_id:35, vote:p35Rate, comment: p35Comment});
    voteData.push({user_id, problem_id:36, vote:p36Rate, comment: p36Comment});
    if(p37Other)
      voteData.push({user_id, problem_id:37, vote:p37Rate, comment: p37Comment, other:p37Other});

    axios.post( backendServer+'/vote.php', {vote_data: JSON.stringify(voteData)})
    .then( (response)=>{
      console.log("response:"+response.data);
      if(response.data.status==='ok') {
        //fetchData();
        //updatePage7Data();
        setFilled(true);
        setFirstRound(false);
        //setOkMessage("Responses saved.");
        navigate('Responses saved.');
      } else {
        setErrorMessage(response.data.message);
      }
    })
    .catch((error)=>{
      setErrorMessage(error.message);
    });
  }

  return (
    <form onSubmit={handleSubmit}>
      <table width="600" align="center" cellPadding={8}>
        <tbody>
          <tr>
            <td><p>4. On a scale of 1 to 5, how significant will the following technologies be in the electricity supply system in your economy to achieve net-zero emissions?</p>
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={28}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p28Rate}
                setRate={setP28Rate}
                comment={p28Comment}
                setComment={setP28Comment}
                text="a. Solar power"
                results={p28Results}
                choices={["Least", "Slightly", "Moderately", "Highly", "Most"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={29}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p29Rate}
                setRate={setP29Rate}
                comment={p29Comment}
                setComment={setP29Comment}
                text="b. Wind power"
                results={p29Results}
                choices={["Least", "Slightly", "Moderately", "Highly", "Most"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={30}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p30Rate}
                setRate={setP30Rate}
                comment={p30Comment}
                setComment={setP30Comment}
                text="c. Hydro power"
                results={p30Results}
                choices={["Least", "Slightly", "Moderately", "Highly", "Most"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={31}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p31Rate}
                setRate={setP31Rate}
                comment={p31Comment}
                setComment={setP31Comment}
                text="d. Nuclear power"
                results={p31Results}
                choices={["Least", "Slightly", "Moderately", "Highly", "Most"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={32}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p32Rate}
                setRate={setP32Rate}
                comment={p32Comment}
                setComment={setP32Comment}
                text="e. Bioenergy"
                results={p32Results}
                choices={["Least", "Slightly", "Moderately", "Highly", "Most"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={33}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p33Rate}
                setRate={setP33Rate}
                comment={p33Comment}
                setComment={setP33Comment}
                text="f. Fossil-based electricity with carbon capture and storage"
                results={p33Results}
                choices={["Least", "Slightly", "Moderately", "Highly", "Most"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={34}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p34Rate}
                setRate={setP34Rate}
                comment={p34Comment}
                setComment={setP34Comment}
                text="g. Lithium battery for electricity storage"
                results={p34Results}
                choices={["Least", "Slightly", "Moderately", "Highly", "Most"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={35}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p35Rate}
                setRate={setP35Rate}
                comment={p35Comment}
                setComment={setP35Comment}
                text="h. Other chemical electricity storage"
                results={p35Results}
                choices={["Least", "Slightly", "Moderately", "Highly", "Most"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={36}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p36Rate}
                setRate={setP36Rate}
                comment={p36Comment}
                setComment={setP36Comment}
                text="i. Decentralized prosumer-centric peer-to-peer electricity trading system"
                results={p36Results}
                choices={["Least", "Slightly", "Moderately", "Highly", "Most"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={37}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p37Rate}
                setRate={setP37Rate}
                comment={p37Comment}
                setComment={setP37Comment}
                text="j. Others"
                other={true}
                otherText={p37Other}
                updateOther={setP37Other}
                results={p37Results}
                choices={["Least", "Slightly", "Moderately", "Highly", "Most"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td align="right">
            <Message color={messageColor} message={message} />
              <br />
              <NextButtonPanel
                disabled={unchanged}
                onClick={resetValues}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );
};

export default Issue2page3;