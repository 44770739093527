

const NextButtonPanel = (props) => {

  let nextText = "Next";
  if(props.isEnd) nextText = "to End of Issue "+props.issue;

  return (
    <>
      <input
        disabled={props.disabled}
        onClick={props.onClick}
        className="blueButton"
        type="button"
        value="Reset"
      />
      &nbsp;&nbsp;
      <input
        className="blueButton"
        type="submit"
        value={props.disabled?"Proceed "+nextText:"Save & Proceed "+nextText}
      />
    </>
  );
};


export default NextButtonPanel;