
export const isDeepEqual = (arr1, arr2) => {
  if(!arr1) return false;
  if(!arr2) return false;

  if (arr1.length !== arr2.length) return false;
  
  for(var e of arr1) {
    if(arr2.indexOf(e)<0) return false;
  }
  return true;
};


export const readSelectOneAnswer = (answer) => {
  let text = '';
  let comment = ''; 
  if (answer) {
    if (answer.votes && answer.votes[0]) {
      text = answer.votes[0];
    } 
    if (answer.comments && answer.comments[0]) {
      comment = answer.comments[0];
    } 
  }
  return [{value:text, label:text}, comment];
}

export const readRateAnswer = (answer) => {
  let rate = 0;
  let comment = ''; 
  if (answer) {
    if (answer.votes && answer.votes[0]) {
      rate = answer.votes[0];
    } 
    if (answer.comments && answer.comments[0]) {
      comment = answer.comments[0];
    } 
  }
  return [rate, comment];
}

export const readSelectionAnswers = (answer) => {
  
  let selections = [];
  let comments = [];
  if (answer) {
    if (answer.votes) {
      selections = [...answer.votes];
    }
    if (answer.comments) {
      comments = answer.comments;        
    } 
  }
  return [selections, comments];
}

export const checkSelectOneFilled = (
  item,
  comment,
  answerText,
  commentText,
  setErrorMsg
) => {
  if (!item||!item.value) {
    if (answerText) setErrorMsg(answerText);
    return false;
  }
  // comment is not required
  // if (!comment) {
  //   if (commentText) setErrorMsg(commentText);
  //   return false;
  // }
  return true;
}

export const checkRateFilled = (
  rate,
  comment,
  min,
  max,
  answerText,
  commentText,
  setErrorMsg
) => {
  if (Number(rate) < min || Number(rate) > max) {
    if (answerText) setErrorMsg(answerText);
    return false;
  }
  // comment is not required
  // if (!comment) {
  //   if (commentText) setErrorMsg(commentText);
  //   return false;
  // }
  return true;
};

export const checkSelectionFilled = (
  selections,
  comments,
  min,
  max,
  answerText,
  commentText,
  setErrorMsg,
  otherText
) => {
  if (!selections || selections.length < min || selections.length > max) {
    if (answerText) setErrorMsg(answerText);
    return false;
  }
  let valid = true;
  selections.forEach((s) => {
    if (!s || s.length === 0) {
      if (otherText) setErrorMsg(otherText);
      valid = false;
      return false;
    }
  });

  if (!valid) return false;

  // comment is not required
  // if (!comments || comments.length !== selections.length) {
  //   if (commentText) setErrorMsg(commentText);
  //   return false;
  // }

  // comments.forEach((c) => {
  //   if (!c || c.length == 0) {
  //     if (commentText) setErrorMsg(commentText);
  //     valid = false;
  //     return false;
  //   }
  // });

  return valid;
};

export const controlTextWidth = (text, length) => {
  let size = 14;
  if(length) size = length;
  return text.length < size ? (
    text
  ) : (
    <div className="tooltip">
      {text.substring(0, size) + "..."}
      <span className="tooltiptext">{text}</span>
    </div>
  );
}

export const  handleBeforeUnload = (event) => {
  event.preventDefault();
  return (event.resetValues='');
}