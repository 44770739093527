import useLocalStorage from 'react-use-localstorage';

import './App.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from './pages/Home';
import Layout from './pages/Layout';
import Survey from './pages/surveys/Survey';
import NoPage from './pages/NoPage';
import Review from './pages/Review';
import Privacy from './pages/Privacy';

function App() {  
  const [user, setUser] = useLocalStorage('user');

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout user={user} setUser={setUser} />}>
          <Route index element={<Home user={user} setUser={setUser} />} />
          <Route path="survey" element={user?<Survey user={user} setUser={setUser} />:<Home user={user} setUser={setUser} />} />
          <Route path="review" element={<Review user={user} />} />
          <Route path="privacy" element={<Privacy user={user} />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
