import './NavBar.css';
import { Link, useLocation } from "react-router-dom";
import UserInfo from './UserInfo';

const NavBar = (props) => {
  const pathname = useLocation().pathname;
 
  let isAdmin=false;
  if(props.user) {
    let admin = JSON.parse(props.user).admin;
    isAdmin = admin&&admin==="1";
  }
  
  return <div className="topnav">    
            <Link className={pathname==='/'?'active':""} to="/">Home</Link>
            {props.user?<Link className={pathname==='/survey'?'active':""} to="/survey">Survey</Link>:""}
            <span hidden={!isAdmin}>
               <a className={pathname==='/review'?'active':""} href='/review'>Review</a></span>
            <div className='userinfo'>
              <UserInfo user={props.user} setUser={props.setUser} />
            </div>               
          </div>
};

export default NavBar;

// {props.user?<Link className={pathname=='/report'?'active':""} to="/report">Report</Link>:""}