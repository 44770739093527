import { useState, useEffect } from 'react';
import axios from 'axios';
import { backendServer } from '../../Const';
import RateItem from "./RateItem";
import Message from './Message';

import {readRateAnswer, checkRateFilled, handleBeforeUnload} from '../../SurveyUtils';
import NextButtonPanel from '../../components/NextButtonPanel';

const Issue4page6 = (props) => {
  const [pageData, setPageData] = useState('');
  const [firstRound, setFirstRound] = useState(true);

  const [p152Rate, setP152Rate] = useState(0);
  const [p152Comment, setP152Comment] = useState('');
  const [p152Results, setP152Results] = useState('');
  const [p153Rate, setP153Rate] = useState(0);
  const [p153Comment, setP153Comment] = useState('');
  const [p153Results, setP153Results] = useState('');
  const [p154Rate, setP154Rate] = useState(0);
  const [p154Comment, setP154Comment] = useState('');
  const [p154Results, setP154Results] = useState('');
  const [p155Rate, setP155Rate] = useState(0);
  const [p155Comment, setP155Comment] = useState('');
  const [p155Results, setP155Results] = useState('');
  const [p156Rate, setP156Rate] = useState(0);
  const [p156Comment, setP156Comment] = useState('');
  const [p156Results, setP156Results] = useState('');
  const [p157Rate, setP157Rate] = useState(0);
  const [p157Comment, setP157Comment] = useState('');
  const [p157Results, setP157Results] = useState('');
  const [p158Rate, setP158Rate] = useState(0);
  const [p158Comment, setP158Comment] = useState('');
  const [p158Results, setP158Results] = useState('');
  const [p159Rate, setP159Rate] = useState(0);
  const [p159Comment, setP159Comment] = useState('');
  const [p159Results, setP159Results] = useState('');
  const [p159Other, setP159Other] = useState('');

  const [filled, setFilled] = useState(false);
  const [unchanged, setUnchanged] = useState(true);
  const [message, setMessage] = useState('');
  const [messageColor, setMessageColor] = useState('');

  useEffect( () => {
    if(unchanged) return;
    
    window.addEventListener('beforeunload',handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload',handleBeforeUnload)
    }
    
  },[unchanged]);

  const setErrorMessage = (msg) => {
    setMessageColor("red");
    setMessage(msg);
  }

  const setOkMessage = (msg) => {
    setMessageColor("teal");
    setMessage(msg);
  }

  const resetValues = () => {
    const data = pageData?JSON.parse(pageData):[];
    
    setP152Rate(data.p152Rate?data.p152Rate:0);
    setP152Comment(data.p152Comment?data.p152Comment:'');
    setP153Rate(data.p153Rate?data.p153Rate:0);
    setP153Comment(data.p153Comment?data.p153Comment:'');
    setP154Rate(data.p154Rate?data.p154Rate:0);
    setP154Comment(data.p154Comment?data.p154Comment:'');
    setP155Rate(data.p155Rate?data.p155Rate:0);
    setP155Comment(data.p155Comment?data.p155Comment:'');
    setP156Rate(data.p156Rate?data.p156Rate:0);
    setP156Comment(data.p156Comment?data.p156Comment:'');
    setP157Rate(data.p157Rate?data.p157Rate:0);
    setP157Comment(data.p157Comment?data.p157Comment:'');
    setP158Rate(data.p158Rate?data.p158Rate:0);
    setP158Comment(data.p158Comment?data.p158Comment:'');
    setP159Rate(data.p159Rate?data.p159Rate:0);
    setP159Comment(data.p159Comment?data.p159Comment:'');
    setP159Other(data.p159Other?data.p159Other:'');

    setUnchanged(true);
  }

  const fetchData = async () => {
    axios
      .post(backendServer + "/get_votes.php", {
        user_id: JSON.parse(props.user).user_id,
        problem_ids: ["152", "153", "154", "155", "156", "157", "158", "159"]
      })
      .then((response) => {
        if (response.data.status === "ok") {
          let answers = JSON.parse(response.data.answers);
          let results = JSON.parse(response.data.results);
          let data = {};

          if(answers['152']) {
            setFirstRound(false);
          }
          
          [data['p152Rate'], data['p152Comment']] = readRateAnswer(answers['152']);
          [data['p153Rate'], data['p153Comment']] = readRateAnswer(answers['153']);
          [data['p154Rate'], data['p154Comment']] = readRateAnswer(answers['154']);     
          [data['p155Rate'], data['p155Comment']] = readRateAnswer(answers['155']);     
          [data['p156Rate'], data['p156Comment']] = readRateAnswer(answers['156']);
          [data['p157Rate'], data['p157Comment']] = readRateAnswer(answers['157']);
          [data['p158Rate'], data['p158Comment']] = readRateAnswer(answers['158']);
          [data['p159Rate'], data['p159Comment']] = readRateAnswer(answers['159']);
          if(answers['159']&&answers['159'].other) data['p159Other'] = answers['159'].other;
          else data['p159Other'] = '';

          setPageData(JSON.stringify(data));

          setP152Results(results["152"]);
          setP153Results(results["153"]);
          setP154Results(results["154"]);
          setP155Results(results["155"]);
          setP156Results(results["156"]);
          setP157Results(results["157"]);
          setP158Results(results["158"]);
          setP159Results(results["159"]);
          
          checkFilled();
          setUnchanged(true);
        } else {
          setErrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  }  

  useEffect(()=>{
    fetchData(); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    resetValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pageData]);
  
  useEffect(() => {
    setMessage('');
    let data = pageData?JSON.parse(pageData):[];

    if (Number(data.p152Rate) !== Number(p152Rate)) { setUnchanged(false); return; }
    if (data.p152Comment !== p152Comment) { setUnchanged(false); return; }
    if (Number(data.p153Rate) !== Number(p153Rate)) { setUnchanged(false); return; }
    if (data.p153Comment !== p153Comment) { setUnchanged(false); return; }
    if (Number(data.p154Rate) !== Number(p154Rate)) { setUnchanged(false); return; }
    if (data.p154Comment !== p154Comment) { setUnchanged(false); return; }
    if (Number(data.p155Rate) !== Number(p155Rate)) { setUnchanged(false); return; }
    if (data.p155Comment !== p155Comment) { setUnchanged(false); return; }
    if (Number(data.p156Rate) !== Number(p156Rate)) { setUnchanged(false); return; }
    if (data.p156Comment !== p156Comment) { setUnchanged(false); return; }
    if (Number(data.p157Rate) !== Number(p157Rate)) { setUnchanged(false); return; }
    if (data.p157Comment !== p157Comment) { setUnchanged(false); return; }
    if (Number(data.p158Rate) !== Number(p158Rate)) { setUnchanged(false); return; }
    if (data.p158Comment !== p158Comment) { setUnchanged(false); return; }
    if (Number(data.p159Rate) !== Number(p159Rate)) { setUnchanged(false); return; }
    if (data.p159Comment !== p159Comment) { setUnchanged(false); return; }
    if (data.p159Other !== p159Other) { setUnchanged(false); return; }    
    
    setUnchanged(true);
  }, [    
    p152Rate, p152Comment,
    p153Rate, p153Comment,
    p154Rate, p154Comment,
    p155Rate, p155Comment,
    p156Rate, p156Comment,
    p157Rate, p157Comment,
    p158Rate, p158Comment,
    p159Rate, p159Comment, p159Other, pageData
  ]);

  const checkFilled = () => {
    if(!checkRateFilled(p152Rate, p152Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p153Rate, p153Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p154Rate, p154Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p155Rate, p155Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p156Rate, p156Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p157Rate, p157Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p158Rate, p158Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p159Rate, p159Comment, 1, 5)) {setFilled(false); return;}
    //if(!p159Other) {setFilled(false); return;}

    setFilled(true);
  }

  const navigate = (msg) => {
    window.removeEventListener('beforeunload',handleBeforeUnload); 
    if(!msg) msg = 'Proceeding..';
    setOkMessage(msg);
    setTimeout(()=> {
      window.location.href = '/survey?issue=4&page=7';
    }, 1000);
  }

  useEffect(() => {   
    checkFilled();
  });

  let admin = JSON.parse(props.user).admin;
  let isAdmin = admin&&admin==="1";
  
  const handleSubmit = (event) => {
    event.preventDefault();
    if((filled&&unchanged)||isAdmin) { navigate(); return; }
    
    if(!checkRateFilled(p152Rate, p152Comment, 1, 5,
      "Please answer problem 5a.",
      "Please provide your reasoning for problem 5a.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p153Rate, p153Comment, 1, 5,
      "Please answer problem 5b.",
      "Please provide your reasoning for problem 5b.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p154Rate, p154Comment, 1, 5,
      "Please answer problem 5c.",
      "Please provide your reasoning for problem 5c.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p155Rate, p155Comment, 1, 5,
      "Please answer problem 5d.",
      "Please provide your reasoning for problem 5d.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p156Rate, p156Comment, 1, 5,
      "Please answer problem 5e.",
      "Please provide your reasoning for problem 5e.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p157Rate, p157Comment, 1, 5,
      "Please answer problem 5f.",
      "Please provide your reasoning for problem 5f.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p158Rate, p158Comment, 1, 5,
      "Please answer problem 5g.",
      "Please provide your reasoning for problem 5g.",
      setErrorMessage
    )) return;
    if(p159Other) {
      if(!checkRateFilled(p159Rate, p159Comment, 1, 5,
        "Please answer problem 5h.",
        "Please provide your reasoning for problem 5h.",
        setErrorMessage
      )) return;
    }
    // if(!p159Other) {
    //   setErrorMessage("Please specify the other field in problem 5h.");
    //   return;
    // }

    let user_id = JSON.parse(props.user).user_id;
    let voteData = [];
    voteData.push({user_id, problem_id:152, vote:p152Rate, comment: p152Comment});
    voteData.push({user_id, problem_id:153, vote:p153Rate, comment: p153Comment});
    voteData.push({user_id, problem_id:154, vote:p154Rate, comment: p154Comment});
    voteData.push({user_id, problem_id:155, vote:p155Rate, comment: p155Comment});
    voteData.push({user_id, problem_id:156, vote:p156Rate, comment: p156Comment});
    voteData.push({user_id, problem_id:157, vote:p157Rate, comment: p157Comment});
    voteData.push({user_id, problem_id:158, vote:p158Rate, comment: p158Comment});
    if(p159Other)
      voteData.push({user_id, problem_id:159, vote:p159Rate, comment: p159Comment, other:p159Other});

    axios.post( backendServer+'/vote.php', {vote_data: JSON.stringify(voteData)})
    .then( (response)=>{
      if(response.data.status==='ok') {
        //updatePageData();
        //fetchData();
        setFilled(true);
        setFirstRound(false);
        //setOkMessage("Responses saved.");
        navigate('Responses saved.');
      } else {
        setErrorMessage(response.data.message);
      }
    })
    .catch((error)=>{
      setErrorMessage(error.message);
    });
  }

  return (
    <form onSubmit={handleSubmit}>
      <table width="600" align="center" cellPadding={8}>
        <tbody>
          <tr>
            <td>
              <br />
              5. On a scale of 1 to 5, please rate the severity of the following
              bottlenecks for implementing carbon farming in APEC.
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={152}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p152Rate}
                setRate={setP152Rate}
                comment={p152Comment}
                setComment={setP152Comment}
                text="a. Policy"
                results={p152Results}
                choices={["Low", "Rather low", "Moderate", "Rather high", "High"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={153}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p153Rate}
                setRate={setP153Rate}
                comment={p153Comment}
                setComment={setP153Comment}
                text="b. Farmer adoption"
                results={p153Results}
                choices={["Low", "Rather low", "Moderate", "Rather high", "High"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={154}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p154Rate}
                setRate={setP154Rate}
                comment={p154Comment}
                setComment={setP154Comment}
                text="c. Incentives"
                results={p154Results}
                choices={["Low", "Rather low", "Moderate", "Rather high", "High"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={155}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p155Rate}
                setRate={setP155Rate}
                comment={p155Comment}
                setComment={setP155Comment}
                text="d. Carbon market"
                results={p155Results}
                choices={["Low", "Rather low", "Moderate", "Rather high", "High"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={156}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p156Rate}
                setRate={setP156Rate}
                comment={p156Comment}
                setComment={setP156Comment}
                text="e. Knowledge"
                results={p156Results}
                choices={["Low", "Rather low", "Moderate", "Rather high", "High"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={157}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p157Rate}
                setRate={setP157Rate}
                comment={p157Comment}
                setComment={setP157Comment}
                text="f. Managerial abilities"
                results={p157Results}
                choices={["Low", "Rather low", "Moderate", "Rather high", "High"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={158}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p158Rate}
                setRate={setP158Rate}
                comment={p158Comment}
                setComment={setP158Comment}
                text="g. Technology"
                results={p158Results}
                choices={["Low", "Rather low", "Moderate", "Rather high", "High"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={159}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p159Rate}
                setRate={setP159Rate}
                comment={p159Comment}
                setComment={setP159Comment}
                text="h. Others"
                other={true}
                otherText={p159Other}
                updateOther={setP159Other}
                results={p159Results}
                choices={["Low", "Rather low", "Moderate", "Rather high", "High"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td align="right">
            <Message color={messageColor} message={message} />
              <br />
              <NextButtonPanel
                disabled={unchanged}
                onClick={resetValues}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );
};

export default Issue4page6;