import { useState, useEffect } from 'react';
import axios from 'axios';
import { backendServer } from '../../Const';
import RateItem from './RateItem';
import SelectItem from './SelectItem';

import {allEconomies} from '../../Const'
import {isDeepEqual, handleBeforeUnload, checkRateFilled, checkSelectionFilled, readRateAnswer, readSelectionAnswers} from '../../SurveyUtils';
import Message from './Message';
import NextButtonPanel from '../../components/NextButtonPanel';

//const page1Data = [];

const Issue1page1 = (props) => {
  const [page1Data, setPage1Data] = useState('');
  const [firstRound, setFirstRound] = useState(true);

  const [p1Rate, setP1Rate] = useState(0);
  const [p1Comment, setP1Comment] = useState('');
  const [p1Results, setP1Results] = useState('');
  const [p2Selections, setP2Selecions] = useState([]);
  const [p2Comments, setP2Comments] = useState([]);
  const [p2Results, setP2Results] = useState('');

  const [filled, setFilled] = useState(false);
  const [unchanged, setUnchanged] = useState(true);
  const [message, setMessage] = useState('');
  const [messageColor, setMessageColor] = useState('');


  useEffect( () => {
    if(unchanged) return;
    
    window.addEventListener('beforeunload',handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload',handleBeforeUnload)
    }
    
  },[unchanged]);

  const setErrorMessage = (msg) => {
    setMessageColor("red");
    setMessage(msg);
  }

  const setOkMessage = (msg) => {
    setMessageColor("teal");
    setMessage(msg);
  }

  useEffect(()=>{
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  
  useEffect(()=>{
    resetValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[page1Data]);

  const resetValues = () => {
    const data = page1Data?JSON.parse(page1Data):[];

    setP1Rate(data.p1Rate?data.p1Rate:0);
    setP1Comment(data.p1Comment?data.p1Comment:'');
    setP2Selecions(data.p2Selections?data.p2Selections:[]);
    setP2Comments(data.p2Comments?data.p2Comments:'');

    setUnchanged(true);
  }

  const fetchData = async () => {
    axios.post(backendServer+'/get_votes.php', {user_id:JSON.parse(props.user).user_id, problem_ids:['1', '2']})
    .then( (response)=>{
      if(response.data.status==='ok') {
      
        let answers = JSON.parse(response.data.answers);
        let results = JSON.parse(response.data.results);

        if(answers['1']) {
          setFirstRound(false);
        }

        let data = {};

        [data['p1Rate'], data['p1Comment']] = readRateAnswer(answers['1']);
        [data['p2Selections'], data['p2Comments']] = readSelectionAnswers(answers['2']);
        
        setPage1Data(JSON.stringify(data));

        setP1Results(results['1']);
        setP2Results(results['2']);

        checkFilled();
        setUnchanged(true);
      } else {
        setErrorMessage(response.data.message);
      }
    })
    .catch((error)=>{
      setErrorMessage(error.message);
    });
  }

  useEffect(()=>{
    setMessage('');
    
    let data = page1Data?JSON.parse(page1Data):[];

    if(Number(data.p1Rate)!==Number(p1Rate)) { setUnchanged(false); return; }
    if(data.p1Comment!==p1Comment) { setUnchanged(false); return; }

    if(!isDeepEqual(data.p2Selections,p2Selections)) { setUnchanged(false); return; }
    if(!isDeepEqual(data.p2Comments,p2Comments)) { setUnchanged(false); return; }
    
    setUnchanged(true);
  },[p1Rate, p1Comment, p2Selections, p2Comments, page1Data]);
  
  
  const checkFilled = () => {    
    if(!checkRateFilled(p1Rate, p1Comment, 1, 5)) { setFilled(false); return; }
    if(!checkSelectionFilled(p2Selections, p2Comments, 1, 3)) {setFilled(false); return;}

    setFilled(true);
  }
  
  useEffect(() => {
    checkFilled();
  });

  const navigate = (msg) => {
    window.removeEventListener('beforeunload',handleBeforeUnload);
    if(!msg) msg = 'Proceeding..';
    setOkMessage(msg);
    setTimeout(()=> {
      window.location.href = '/survey?issue=1&page=2';
    }, 1000);
  }


  const updateP2Answer = (selection, add) => {
    if(add) {
      let newSelections = [...p2Selections];
      newSelections.push(selection);
      setP2Selecions(newSelections);
      let newComments = [...p2Comments];      
      let data = page1Data?JSON.parse(page1Data):[];
      const index = data.p2Selections?data.p2Selections.indexOf(selection):-1;
      newComments.push(index>=0?data.p2Comments[index]:'');
      setP2Comments(newComments);
    } else {
      let index = p2Selections.indexOf(selection);
      if(index>=0) { // sanity check        
        let newSelections = [...p2Selections];
        newSelections.splice(index,1);
        setP2Selecions(newSelections);
        let newComments = [...p2Comments];
        newComments.splice(index,1);
        setP2Comments(newComments);
      }
    }
  }

  const updateP2Comment = (answerIndex, comment) => {
    let newComments = [...p2Comments];
    newComments[answerIndex]=comment;
    setP2Comments(newComments);
  }

  let admin = JSON.parse(props.user).admin;
  let isAdmin = admin&&admin==="1";

  const handleSubmit = (event) => {
    event.preventDefault();
    
    if((filled&&unchanged)||isAdmin) { navigate(); return; }
        
    if(!checkRateFilled(
      p1Rate,
      p1Comment,
      1, 
      5, 
      'Please answer problem 1.',
      'Please provide your reasoning for problem 1.',
      setErrorMessage
    )) return;
    if(!checkSelectionFilled(
      p2Selections,
      p2Comments,
      1,
      3,
      "Please select up to three answers for problem 2.",
      "Please provide your reasoning for each selected answer in problem 2.",
      setErrorMessage,
    )) return;
    
    if(!filled) return;

    let user_id = JSON.parse(props.user).user_id;
    let voteData = [];

    voteData.push({user_id, problem_id:1, vote:p1Rate, comment: p1Comment});
    p2Selections.forEach((selection,index)=>{
      voteData.push({user_id, problem_id:2, vote:selection, comment: p2Comments[index]});
    });
    
    axios.post( backendServer+'/vote.php', {vote_data: JSON.stringify(voteData)})
    .then( (response)=>{
      console.log(response.data);
      if(response.data.status==='ok') {

        //fetchData();        
        //updatePage1Data();
        setFilled(true);
        setFirstRound(false);
        //setOkMessage("Responses saved.");
        navigate('Responses saved.');
      } else {
        setErrorMessage(response.data.message);
      }
    })
    .catch((error)=>{
      setErrorMessage(error.message);
    });
  }

  console.log(firstRound);

  return (
    <form onSubmit={handleSubmit}>
      <table width="600" align="center" cellPadding={8}>
        <tbody>
          <tr>
            <td>
              <h4>
                Issue 1: Energy Demand Management
              </h4>
              <p>
                Energy demand management refers to the strategies and practices
                employed to efficiently control and reduce the amount of energy
                required to meet various needs, whether in residential,
                commercial, or industrial settings. This involves implementing
                measures to balance energy supply and demand, minimize peak
                energy usage, and enhance overall energy efficiency.{" "}
              </p>
              <p>
                Some common methods of energy demand management include load
                shifting, peak shaving, demand response, and the adoption of
                energy-efficient technologies and practices. The goal is to
                achieve a more sustainable and cost-effective energy consumption
                pattern while ensuring reliable energy supply and minimizing the
                environmental impact.
              </p>
              <p>
                This survey focuses on the aspects of energy demand management
                encompassing:
              </p>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.1 Energy
              Efficiency
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.2 Energy
              Intensity
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.3 Electricity
              Demand
              <br />
              <br />
              <hr />
              <br />
              <RateItem
                problem_id={1}
                firstRound={firstRound}
                rate={p1Rate}
                setRate={setP1Rate}
                comment={p1Comment}
                setComment={setP1Comment}
                text="1. On a scale of 1 to 5, please rate the effectiveness of the current energy management system in optimizing energy consumption and cost savings within your economy."
                results={p1Results}
                choices={[
                  "Least Effective",
                  "Somehow Effective",
                  "Moderately Effective",
                  "Very Effective",
                  "Most Effective",
                ]}
                adminMode={isAdmin}
              />
            </td>
          </tr>
          <tr>
            <td>
              <hr />
            </td>
          </tr>
          <tr>
            <td>
              <SelectItem
                problem_id={2}
                firstRound={firstRound}
                max={3}
                updateAnswer={updateP2Answer}
                updateComment={updateP2Comment}
                selections={p2Selections}
                comments={p2Comments}
                text="2. Based on your justification, please select up to three APEC economies that their energy demand management policies are implemented effectively."
                choices={allEconomies}
                other={false}
                results={p2Results}
                adminMode={isAdmin}
              />
            </td>
          </tr>
          <tr>
            <td align="right">
              <Message color={messageColor} message={message} />
              <br />
              <NextButtonPanel 
                disabled={unchanged}
                onClick={resetValues}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );
};

export default Issue1page1;