import { useState, useEffect } from 'react';
import axios from 'axios';
import { backendServer, allEconomies } from '../../Const';
import RateItem from "./RateItem";
import SelectItem from "./SelectItem";
import Message from './Message';

import {isDeepEqual, readRateAnswer, readSelectionAnswers, checkRateFilled, checkSelectionFilled, handleBeforeUnload} from '../../SurveyUtils';
import NextButtonPanel from '../../components/NextButtonPanel';

const Issue2page5 = (props) => {
  const [page9Data, setPage9Data] = useState('');
  const [firstRound, setFirstRound] = useState(true);

  const [p49Selections, setP49Selections] = useState([]);
  const [p49Comments, setP49Comments] = useState([]);
  const [p49Results, setP49Results] = useState('');
  const [p50Rate, setP50Rate] = useState(0);
  const [p50Comment, setP50Comment] = useState('');
  const [p50Results, setP50Results] = useState('');
  const [p51Rate, setP51Rate] = useState(0);
  const [p51Comment, setP51Comment] = useState('');
  const [p51Results, setP51Results] = useState('');
  const [p52Rate, setP52Rate] = useState(0);
  const [p52Comment, setP52Comment] = useState('');
  const [p52Results, setP52Results] = useState('');
  const [p53Rate, setP53Rate] = useState(0);
  const [p53Comment, setP53Comment] = useState('');
  const [p53Results, setP53Results] = useState('');
  const [p54Rate, setP54Rate] = useState(0);
  const [p54Comment, setP54Comment] = useState('');
  const [p54Results, setP54Results] = useState('');
  const [p55Rate, setP55Rate] = useState(0);
  const [p55Comment, setP55Comment] = useState('');
  const [p55Results, setP55Results] = useState('');
  const [p56Rate, setP56Rate] = useState(0);
  const [p56Comment, setP56Comment] = useState('');
  const [p56Results, setP56Results] = useState('');
  const [p57Rate, setP57Rate] = useState(0);
  const [p57Comment, setP57Comment] = useState('');
  const [p57Results, setP57Results] = useState('');
  const [p58Rate, setP58Rate] = useState(0);
  const [p58Comment, setP58Comment] = useState('');
  const [p58Results, setP58Results] = useState('');
  const [p59Rate, setP59Rate] = useState(0);
  const [p59Comment, setP59Comment] = useState('');
  const [p59Results, setP59Results] = useState('');
  const [p60Rate, setP60Rate] = useState(0);
  const [p60Comment, setP60Comment] = useState('');
  const [p60Results, setP60Results] = useState('');
  const [p61Rate, setP61Rate] = useState(0);
  const [p61Comment, setP61Comment] = useState('');
  const [p61Results, setP61Results] = useState('');
  const [p62Rate, setP62Rate] = useState(0);
  const [p62Comment, setP62Comment] = useState('');
  const [p62Results, setP62Results] = useState('');
  const [p62Other, setP62Other] = useState('');
  
  const [filled, setFilled] = useState(false);
  const [unchanged, setUnchanged] = useState(true);
  const [message, setMessage] = useState('');
  const [messageColor, setMessageColor] = useState('');

  useEffect( () => {
    if(unchanged) return;
    
    window.addEventListener('beforeunload',handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload',handleBeforeUnload)
    }
    
  },[unchanged]);

  const setErrorMessage = (msg) => {
    setMessageColor("red");
    setMessage(msg);
  }

  const setOkMessage = (msg) => {
    setMessageColor("teal");
    setMessage(msg);
  }

  const resetValues = () => {
    const data = page9Data?JSON.parse(page9Data):[];
    setP49Selections(data.p49Selections?data.p49Selections:[]);
    setP49Comments(data.p49Comments?data.p49Comments:[]);
    setP50Rate(data.p50Rate?data.p50Rate:0);
    setP50Comment(data.p50Comment?data.p50Comment:'');
    setP51Rate(data.p51Rate?data.p51Rate:0);
    setP51Comment(data.p51Comment?data.p51Comment:'');
    setP52Rate(data.p52Rate?data.p52Rate:0);
    setP52Comment(data.p52Comment?data.p52Comment:'');
    setP53Rate(data.p53Rate?data.p53Rate:0);
    setP53Comment(data.p53Comment?data.p53Comment:'');
    setP54Rate(data.p54Rate?data.p54Rate:0);
    setP54Comment(data.p54Comment?data.p54Comment:'');
    setP55Rate(data.p55Rate?data.p55Rate:0);
    setP55Comment(data.p55Comment?data.p55Comment:'');
    setP56Rate(data.p56Rate?data.p56Rate:0);
    setP56Comment(data.p56Comment?data.p56Comment:'');
    setP57Rate(data.p57Rate?data.p57Rate:0);
    setP57Comment(data.p57Comment?data.p57Comment:'');
    setP58Rate(data.p58Rate?data.p58Rate:0);
    setP58Comment(data.p58Comment?data.p58Comment:'');
    setP59Rate(data.p59Rate?data.p59Rate:0);
    setP59Comment(data.p59Comment?data.p59Comment:'');
    setP60Rate(data.p60Rate?data.p60Rate:0);
    setP60Comment(data.p60Comment?data.p60Comment:'');
    setP61Rate(data.p61Rate?data.p61Rate:0);
    setP61Comment(data.p61Comment?data.p61Comment:'');
    setP62Rate(data.p62Rate?data.p62Rate:0);
    setP62Comment(data.p62Comment?data.p62Comment:'');
    setP62Other(data.p62Other?data.p62Other:'');

    setUnchanged(true);
  }
  
  const fetchData = async () => {
    axios
      .post(backendServer + "/get_votes.php", {
        user_id: JSON.parse(props.user).user_id,
        problem_ids: ["49", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59", "60", "61", "62"]
      })
      .then((response) => {
        if (response.data.status === "ok") {
          let answers = JSON.parse(response.data.answers);
          let results = JSON.parse(response.data.results);
          let data = {};

          if(answers['49']) {
            setFirstRound(false);
          }
          
          [data['p49Selections'], data['p49Comments']] = readSelectionAnswers(answers['49']);
          [data['p50Rate'], data['p50Comment']] = readRateAnswer(answers['50']);
          [data['p51Rate'], data['p51Comment']] = readRateAnswer(answers['51']);
          [data['p52Rate'], data['p52Comment']] = readRateAnswer(answers['52']);
          [data['p53Rate'], data['p53Comment']] = readRateAnswer(answers['53']);
          [data['p54Rate'], data['p54Comment']] = readRateAnswer(answers['54']);
          [data['p55Rate'], data['p55Comment']] = readRateAnswer(answers['55']);
          [data['p56Rate'], data['p56Comment']] = readRateAnswer(answers['56']);
          [data['p57Rate'], data['p57Comment']] = readRateAnswer(answers['57']);
          [data['p58Rate'], data['p58Comment']] = readRateAnswer(answers['58']);
          [data['p59Rate'], data['p59Comment']] = readRateAnswer(answers['59']);
          [data['p60Rate'], data['p60Comment']] = readRateAnswer(answers['60']);
          [data['p61Rate'], data['p61Comment']] = readRateAnswer(answers['61']);
          [data['p62Rate'], data['p62Comment']] = readRateAnswer(answers['62']);
          if(answers['62']&&answers['62'].other) data['p62Other'] = answers['62'].other;
          else data['p62Other'] = '';

          setPage9Data(JSON.stringify(data));

          setP49Results(results["49"]);
          setP50Results(results["50"]);
          setP51Results(results["51"]);
          setP52Results(results["52"]);
          setP53Results(results["53"]);
          setP54Results(results["54"]);
          setP55Results(results["55"]);
          setP56Results(results["56"]);
          setP57Results(results["57"]);
          setP58Results(results["58"]);
          setP59Results(results["59"]);
          setP60Results(results["60"]);
          setP61Results(results["61"]);
          setP62Results(results["62"]);

          checkFilled();
          setUnchanged(true);
        } else {
          setErrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  }
  
  useEffect(()=>{
    fetchData(); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    resetValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[page9Data]);
  
  useEffect(() => {
    let data = page9Data?JSON.parse(page9Data):[];
    setMessage('');

    if (!isDeepEqual(data.p49Selections, p49Selections)) 
      { setUnchanged(false); return; }
    if (!isDeepEqual(data.p49Comments, p49Comments)) 
      { setUnchanged(false); return; }
    if (Number(data.p50Rate) !== Number(p50Rate)) { setUnchanged(false); return; }
    if (data.p50Comment !== p50Comment) { setUnchanged(false); return; }
    if (Number(data.p51Rate) !== Number(p51Rate)) { setUnchanged(false); return; }
    if (data.p51Comment !== p51Comment) { setUnchanged(false); return; }
    if (Number(data.p52Rate) !== Number(p52Rate)) { setUnchanged(false); return; }
    if (data.p52Comment !== p52Comment) { setUnchanged(false); return; }
    if (Number(data.p53Rate) !== Number(p53Rate)) { setUnchanged(false); return; }
    if (data.p53Comment !== p53Comment) { setUnchanged(false); return; }
    if (Number(data.p54Rate) !== Number(p54Rate)) { setUnchanged(false); return; }
    if (data.p54Comment !== p54Comment) { setUnchanged(false); return; }
    if (Number(data.p55Rate) !== Number(p55Rate)) { setUnchanged(false); return; }
    if (data.p55Comment !== p55Comment) { setUnchanged(false); return; }
    if (Number(data.p56Rate) !== Number(p56Rate)) { setUnchanged(false); return; }
    if (data.p56Comment !== p56Comment) { setUnchanged(false); return; }
    if (Number(data.p57Rate) !== Number(p57Rate)) { setUnchanged(false); return; }
    if (data.p57Comment !== p57Comment) { setUnchanged(false); return; }
    if (Number(data.p58Rate) !== Number(p58Rate)) { setUnchanged(false); return; }
    if (data.p58Comment !== p58Comment) { setUnchanged(false); return; }
    if (Number(data.p59Rate) !== Number(p59Rate)) { setUnchanged(false); return; }
    if (data.p59Comment !== p59Comment) { setUnchanged(false); return; }
    if (Number(data.p60Rate) !== Number(p60Rate)) { setUnchanged(false); return; }
    if (data.p60Comment !== p60Comment) { setUnchanged(false); return; }
    if (Number(data.p61Rate) !== Number(p61Rate)) { setUnchanged(false); return; }
    if (data.p61Comment !== p61Comment) { setUnchanged(false); return; }
    if (Number(data.p62Rate) !== Number(p62Rate)) { setUnchanged(false); return; }
    if (data.p62Comment !== p62Comment) { setUnchanged(false); return; }
    if (data.p62Other !== p62Other) { setUnchanged(false); return; }    
    
    setUnchanged(true);
  }, [
    p49Selections, p49Comments,
    p50Rate, p50Comment,
    p51Rate, p51Comment,
    p52Rate, p52Comment,
    p53Rate, p53Comment,
    p54Rate, p54Comment,
    p55Rate, p55Comment,
    p56Rate, p56Comment,
    p57Rate, p57Comment,
    p58Rate, p58Comment, 
    p59Rate, p59Comment, 
    p60Rate, p60Comment, 
    p61Rate, p61Comment, 
    p62Rate, p62Comment, p62Other, page9Data
  ]);
  
  
  const checkFilled = () => {
    if(!checkSelectionFilled(p49Selections, p49Comments, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p50Rate, p50Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p51Rate, p51Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p52Rate, p52Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p53Rate, p53Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p54Rate, p54Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p55Rate, p55Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p56Rate, p56Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p57Rate, p57Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p58Rate, p58Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p59Rate, p59Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p60Rate, p60Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p61Rate, p61Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p62Rate, p62Comment, 1, 5)) {setFilled(false); return;}
    //if(!p62Other) {setFilled(false); return;}

    setFilled(true);
  }

  const navigate = (msg) => {
    window.removeEventListener('beforeunload',handleBeforeUnload);
    if(!msg) msg = 'Proceeding..';
    setOkMessage(msg);
    setTimeout(()=> {
      window.location.href = '/survey?issue=6&last_issue=2';
    }, 1000);
  }
  
  useEffect(() => {   
    checkFilled();
  });

  let admin = JSON.parse(props.user).admin;
  let isAdmin = admin&&admin==="1";
  
  const handleSubmit = (event) => {
    event.preventDefault();
    if((filled&&unchanged)||isAdmin) { navigate(); return; }
    
    if(!checkSelectionFilled(p49Selections, p49Comments, 1, 5,
      "Please select up to five answers for problem 1.",
      "Please provide your reasoning for each selected answer in problem 1.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p50Rate, p50Comment, 1, 5,
      "Please answer problem 2.",
      "Please provide your reasoning for problem 2.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p51Rate, p51Comment, 1, 5,
      "Please answer problem 3.",
      "Please provide your reasoning for problem 3.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p52Rate, p52Comment, 1, 5,
      "Please answer problem 4a.",
      "Please provide your reasoning for problem 4a.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p53Rate, p53Comment, 1, 5,
      "Please answer problem 4b.",
      "Please provide your reasoning for problem 4b.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p54Rate, p54Comment, 1, 5,
      "Please answer problem 4c.",
      "Please provide your reasoning for problem 4c.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p55Rate, p55Comment, 1, 5,
      "Please answer problem 4d.",
      "Please provide your reasoning for problem 4d.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p56Rate, p56Comment, 1, 5,
      "Please answer problem 4e.",
      "Please provide your reasoning for problem 4e.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p57Rate, p57Comment, 1, 5,
      "Please answer problem 4f.",
      "Please provide your reasoning for problem 4f.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p58Rate, p58Comment, 1, 5,
      "Please answer problem 4g.",
      "Please provide your reasoning for problem 4g.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p59Rate, p59Comment, 1, 5,
      "Please answer problem 4h.",
      "Please provide your reasoning for problem 4h.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p60Rate, p60Comment, 1, 5,
      "Please answer problem 4i.",
      "Please provide your reasoning for problem 4i.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p61Rate, p61Comment, 1, 5,
      "Please answer problem 4j.",
      "Please provide your reasoning for problem 4j.",
      setErrorMessage
    )) return;
    if(p62Other) {
      if(!checkRateFilled(p62Rate, p62Comment, 1, 5,
        "Please answer problem 5k.",
        "Please provide your reasoning for problem 5k.",
        setErrorMessage
      )) return;
    }
    // if(!p62Other) {
    //   setErrorMessage("Please specify the other field in problem 5k.");
    //   return;
    // }
  
    let user_id = JSON.parse(props.user).user_id;
    let voteData = [];
    p49Selections.forEach((selection,index)=>{
      voteData.push({user_id, problem_id:49, vote:selection, comment: p49Comments[index]});
    });
    voteData.push({user_id, problem_id:50, vote:p50Rate, comment: p50Comment});
    voteData.push({user_id, problem_id:51, vote:p51Rate, comment: p51Comment});
    voteData.push({user_id, problem_id:52, vote:p52Rate, comment: p52Comment});
    voteData.push({user_id, problem_id:53, vote:p53Rate, comment: p53Comment});
    voteData.push({user_id, problem_id:54, vote:p54Rate, comment: p54Comment});
    voteData.push({user_id, problem_id:55, vote:p55Rate, comment: p55Comment});
    voteData.push({user_id, problem_id:56, vote:p56Rate, comment: p56Comment});
    voteData.push({user_id, problem_id:57, vote:p57Rate, comment: p57Comment});
    voteData.push({user_id, problem_id:58, vote:p58Rate, comment: p58Comment});
    voteData.push({user_id, problem_id:59, vote:p59Rate, comment: p59Comment});
    voteData.push({user_id, problem_id:60, vote:p60Rate, comment: p60Comment});
    voteData.push({user_id, problem_id:61, vote:p61Rate, comment: p61Comment});
    if(p62Other) 
    voteData.push({user_id, problem_id:62, vote:p62Rate, comment: p62Comment, other:p62Other});

    console.log('data:'+JSON.stringify(voteData));

    axios.post( backendServer+'/vote.php', {vote_data: JSON.stringify(voteData)})
    .then( (response)=>{
      console.log('data:'+JSON.stringify(response.data));
      if(response.data.status==='ok') {
        //fetchData();
        //updatePageData();
        setFilled(true);
        setFirstRound(false);
        //setOkMessage("Responses saved.");
        navigate('Responses saved.');
      } else {
        setErrorMessage(response.data.message);
      }
    })
    .catch((error)=>{
      setErrorMessage(error.message);
    });
  }

  const updateP49Answer = (selection, add) => {
    if(add) {
      let newSelections = [...p49Selections];
      newSelections.push(selection);
      setP49Selections(newSelections);
      let newComments = [...p49Comments];
      let data = page9Data?JSON.parse(page9Data):[];
      const index = data.p49Selections?data.p49Selections.indexOf(selection):-1;
      newComments.push(index>=0?data.p49Comments[index]:'');
      setP49Comments(newComments);
    } else {
      let index = p49Selections.indexOf(selection);
      if(index>=0) { // sanity check        
        let newSelections = [...p49Selections];
        newSelections.splice(index,1);
        setP49Selections(newSelections);
        let newComments = [...p49Comments];
        newComments.splice(index,1);
        setP49Comments(newComments);
      }
    }
  }

  const updateP49Comment = (answerIndex, comment) => {
    let newComments = [...p49Comments];
    newComments[answerIndex]=comment;
    setP49Comments(newComments);
  } 

  return (
    <form onSubmit={handleSubmit}>
      <table width="600" align="center" cellPadding={8}>
        <tbody>
          <tr>
            <td>
              <h4>2.2 Hydrogen Economy</h4>
              <p>
                Hydrogen economy refers to an economy that relies on hydrogen as
                the commercial fuel to deliver a substantial fraction of a
                nation’s energy services. It comprises of a linked network of
                chemical processes that produce hydrogen (e.g., electrolyzer),
                store hydrogen chemically or physically, and convert the stored
                hydrogen to electrical energy at the point of use. It is
                considered as the potentially key energy source to decarbonize
                energy systems in the hard-to-abate sectors such as cement,
                steel, and long-haul transport.
              </p>
              <hr />
            </td>
          </tr>
          <tr>
            <td>
              <SelectItem
                problem_id={49}
                firstRound={firstRound}
                adminMode={isAdmin}
                max={5}
                updateAnswer={updateP49Answer}
                updateComment={updateP49Comment}
                selections={p49Selections}
                comments={p49Comments}
                text="1. Based on your justification, please select up to five APEC economies that have the potential to become major producers/exporters of hydrogen energy."
                choices={allEconomies}
                other={false}
                results={p49Results}
              />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={50}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p50Rate}
                setRate={setP50Rate}
                comment={p50Comment}
                setComment={setP50Comment}
                text="2. On a scale of 1 to 5, how desirable is it to increase the role of hydrogen energy in decarbonizing the hard-to-abate sectors in APEC?"
                results={p50Results}
                choices={["Least", "Slightly", "Moderately", "Highly", "Most"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={51}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p51Rate}
                setRate={setP51Rate}
                comment={p51Comment}
                setComment={setP51Comment}
                text="3. On a scale of 1 to 5, how confident are you (i.e., what is the probability) of increasing the role of hydrogen energy to decarbonize the hard-to-abate sectors in APEC?"
                results={p51Results}
                choices={["Least", "Slightly", "Moderately", "Highly", "Most"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              4. Based on your justification, how significant are the following
              barriers to the development of a hydrogen economy across the APEC?
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={52}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p52Rate}
                setRate={setP52Rate}
                comment={p52Comment}
                setComment={setP52Comment}
                text="a. Hydrogen production costs"
                results={p52Results}
                choices={[
                  "Insignificant",
                  "Slightly significant",
                  "Moderately significant",
                  "Significant",
                  "Highly significant",
                ]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={53}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p53Rate}
                setRate={setP53Rate}
                comment={p53Comment}
                setComment={setP53Comment}
                text="b. Energy intensity of hydrogen production"
                results={p53Results}
                choices={[
                  "Insignificant",
                  "Slightly significant",
                  "Moderately significant",
                  "Significant",
                  "Highly significant",
                ]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={54}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p54Rate}
                setRate={setP54Rate}
                comment={p54Comment}
                setComment={setP54Comment}
                text="c. Technical challenges associated with storing and transporting hydrogen"
                results={p54Results}
                choices={[
                  "Insignificant",
                  "Slightly significant",
                  "Moderately significant",
                  "Significant",
                  "Highly significant",
                ]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={55}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p55Rate}
                setRate={setP55Rate}
                comment={p55Comment}
                setComment={setP55Comment}
                text="d. Establishing a hydrogen infrastructure (production facilities, distribution pipelines, and refueling stations)"
                results={p55Results}
                choices={[
                  "Insignificant",
                  "Slightly significant",
                  "Moderately significant",
                  "Significant",
                  "Highly significant",
                ]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={56}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p56Rate}
                setRate={setP56Rate}
                comment={p56Comment}
                setComment={setP56Comment}
                text="e. Standardization and safety of a hydrogen economy"
                results={p56Results}
                choices={[
                  "Insignificant",
                  "Slightly significant",
                  "Moderately significant",
                  "Significant",
                  "Highly significant",
                ]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={57}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p57Rate}
                setRate={setP57Rate}
                comment={p57Comment}
                setComment={setP57Comment}
                text="f. Production of hydrogen from low-carbon or renewable sources"
                results={p57Results}
                choices={[
                  "Insignificant",
                  "Slightly significant",
                  "Moderately significant",
                  "Significant",
                  "Highly significant",
                ]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={58}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p58Rate}
                setRate={setP58Rate}
                comment={p58Comment}
                setComment={setP58Comment}
                text="g. Domestic political constraints (policy and regulation)"
                results={p58Results}
                choices={[
                  "Insignificant",
                  "Slightly significant",
                  "Moderately significant",
                  "Significant",
                  "Highly significant",
                ]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={59}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p59Rate}
                setRate={setP59Rate}
                comment={p59Comment}
                setComment={setP59Comment}
                text="h. Public perceptions and acceptance of hydrogen technology"
                results={p59Results}
                choices={[
                  "Insignificant",
                  "Slightly significant",
                  "Moderately significant",
                  "Significant",
                  "Highly significant",
                ]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={60}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p60Rate}
                setRate={setP60Rate}
                comment={p60Comment}
                setComment={setP60Comment}
                text="i. Insufficient capital to fund hydrogen infrastructure"
                results={p60Results}
                choices={[
                  "Insignificant",
                  "Slightly significant",
                  "Moderately significant",
                  "Significant",
                  "Highly significant",
                ]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={61}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p61Rate}
                setRate={setP61Rate}
                comment={p61Comment}
                setComment={setP61Comment}
                text="j. Geopolitics and energy security"
                results={p61Results}
                choices={[
                  "Insignificant",
                  "Slightly significant",
                  "Moderately significant",
                  "Significant",
                  "Highly significant",
                ]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={62}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p62Rate}
                setRate={setP62Rate}
                comment={p62Comment}
                setComment={setP62Comment}
                text="k. Others"
                other={true}
                otherText={p62Other}
                updateOther={setP62Other}
                results={p62Results}
                choices={[
                  "Insignificant",
                  "Slightly significant",
                  "Moderately significant",
                  "Significant",
                  "Highly significant",
                ]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td align="right">
            <Message color={messageColor} message={message} />
              <br />
              <NextButtonPanel
                disabled={unchanged}
                onClick={resetValues}
                isEnd={true}
                issue={2}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );
};

export default Issue2page5;