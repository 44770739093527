import {useRef, useEffect} from "react";

const Canvas = (props) => {
  const canvas = useRef();
  let offsety = props.offsety?props.offsety:0;
  
  const draw = (context) => {
      context.fillStyle = props.color;
      context.fillRect(0, offsety ,  props.width, props.height);  
  };
  
  useEffect(() => {
    const context = canvas.current.getContext('2d');
    draw(context);
  });

  return (
    <canvas offsety={offsety} ref={canvas} height={String(Number(props.height)+Number(offsety))} width={props.width} />
  );
};

export default Canvas;