import './SurveyBar.css';


const PageBar = (props) => {  
  const maxPages = [1,4,5,4,7,2,1];
  const pages = [];
  for(let p=0; p<=maxPages[props.issue]; p++) {
    pages.push("survey?issue="+props.issue+"&page="+p);
  }

  if(maxPages[props.issue]<2) return;

  return (
    <>
      {pages.map((page, index) =>
        index > 0 ? (
          Number(props.page) === index ? (
            <div key={index} className="darkIssue1Button">
              Page {index}
            </div>
          ) : (
            <a key={index} className="issue1Button" href={pages[index]}>
              Page {index}
            </a>
          )
        ) : (
          ""
        )
      )}
    </>
  ); 
    

};

export default PageBar;