import './Home.css';

const Privacy = (props) => {

  return (
    <div className="content">
      <h3>
        Asia-Pacific Economic Cooperation Center of Technology Foresight (“APEC
        CTF”)
      </h3>
      <h4>Data Protection Notice (the “Notice”)</h4>

      <h4>1. Overview and Scope</h4>
      <p>
        In this Notice, “we”, “us”, “our”, “Company” or “<b>APEC CTF</b>” means{" "}
        <b>Asia-Pacific Economic Cooperation Center of Technology Foresight</b>,
        “you”, “your” or “yours” means the persons to whom this Notice applies.
        “Personal Data” has the same meaning as that defined in the Personal
        Data Protection Act 2012 (the “PDPA”).
      </p>
      <p>
        Your personal data privacy is important to us and we are highly
        committed in protecting and managing your Personal Data in a responsible
        manner in line with this Notice.
      </p>
      <p>
        This Notice sets out the collection, use and disclosure of your Personal
        Data. "Personal Data" means any data or information, whether true or
        not, about an individual who can be identified either (a) from that
        data; or (b) from that data and other information to which{" "}
        <b>APEC CTF</b> is likely to have access to.
      </p>
      <p>
        Personal Data excludes Business Contact Information which means an
        individual's name, position name or title, business telephone number,
        business address, business electronic mail address or business fax
        number and any other similar information about the individual, not
        provided by the individual solely for his personal purposes.
      </p>

      <h4>2. When and what kind of Personal Data do we collect?</h4>
      <p>We may collect, use and disclose the following data about you:</p>
      <ol>
        <li>
          When you register and participate in our events, subscribe to our
          services and publications or access our websites and use our online
          services;
        </li>
        <li>
          If and when you contact <b>APEC CTF</b> (i.e., phone, email, face to
          face meetings, networking, interviews, SMS, fax, mail or electronic
          mail, internet messaging services, etc.) which we may keep a record of
          that correspondence;
        </li>
        <li>
          When you submit requests for changes or updates of your existing
          personal data;
        </li>
        <li>
          When you submit personal data to us to participate in our surveys,
          event organised by <b>APEC CTF</b>;
        </li>
        <li>
          When you request a third party to contact and/or make enquiries from
          <b>APEC CTF</b> with your personal data;
        </li>
      </ol>

      <h4>3. What purposes does APEC CTF, Use and Disclose Personal Data?</h4>
      <p>
        We may use the information we collect from you for any of the following
        purposes:
      </p>
      <ol>
        <li>
          Evaluating your suitability for participation in <b>APEC CTF</b>{" "}
          projects, events, collaborations in our projects;
        </li>
        <li>
          Responding to your requests or queries including requests and queries
          from your authorised representatives and individuals purporting to be
          you;
        </li>
        <li>
          To administer and update your records in our databases; monitoring and
          maintaining a copy of a record of your past transactions;
        </li>
        <li>
          Providing ad-hoc or regular information about your relationship{" "}
          <b>APEC CTF</b>;
        </li>
        <li>Verifying your identity and any information you provide to us;</li>
        <li>Promoting our events and projects;</li>
        <li>Participating and administering events;</li>
        <li>
          Providing, managing, operating, processing and administering our
          services to you;
        </li>
        <li>
          Meeting requirements for <b>APEC CTF</b>’s operations;
        </li>
        <li>
          To communicate with you to update you on <b>APEC CTF</b> mission, work
          or future changes;
        </li>
        <li>For statistical and surveys;</li>
        <li>For data matching and administrative purposes;</li>
        <li>To facilitate data analysis and business planning purposes;</li>
        <li>Purposes incidental to each or all of the above.</li>
      </ol>
      <p>
        We may also contact you by any communication means which you have given
        us your contact details, including but not limited to via phone, email,
        face to face meetings, networking, interviews, SMS, fax, mail or
        electronic mail, internet messaging services, etc., for the purpose of
        getting your feedback or for providing you with information which we
        believe could be of interest to you or your organisation.
      </p>
      <p>
        We only collect, process, use or disclose such Personal Data, in
        accordance with this Notice. If you are acting as an intermediary, or
        otherwise on behalf of a third party, or supply us with information
        regarding a third party, you undertake that you are an authorised
        representative or agent of such third party and that you have obtained
        consent from such third party to our collection, processing, use and
        disclosure of his/her Personal Data. Because we are collecting the third
        party’s data from you, you undertake to make the third party aware of
        all matters listed in this Notice by referring the third party to our
        website.{" "}
      </p>

      <h4>4. Consent for the collection and use of your Personal Data</h4>
      <p>
        You consent to the collection, use and disclosure of your Personal Data
        for the above-mentioned purposes and agree to be bound by the
        obligations it imposes on you, when you accept this Notice. You accept
        this Notice when you continue to browse on <b>APEC CTF</b>’s website or
        continuing to engage with <b>APEC CTF</b>.
      </p>
      <p>
        When you consent to us collecting and/or processing your Personal Data,
        it is on you to ensure that all Personal Data submitted to us is
        complete, accurate, true and correct at the time of submission.{" "}
        <b>
          You are also requested to inform us should there be any changes to the
          Personal Data that you had submitted to us.
        </b>{" "}
        Failure to do so may result in our inability to provide you with
        products and services you have requested.
      </p>
      <p>
        Please note that if you do not consent to any of the above business
        purposes, APEC CTF may not be able to satisfy the purposes for which the
        information was collected.
      </p>

      <h4>5. Does APEC CTF disclose Personal Data to third parties?</h4>
      <p>
        On occasion, we may use third party agents and service providers to
        assist us in the use of your Personal Data as outlined under "What
        purposes does APEC CTF Collect, Use and Disclose Personal Data?" You
        consent to such use of your Personal Data by continuing to browse on
        APEC CTF’s website or continuing to engage with APEC CTF.
      </p>
      <p>
        APEC CTF will not transfer Personal Data within or outside Thailand
        unless we have assurance the Personal Data will be accorded a level of
        protection which is comparable to those of PDPA or when the individual
        whose Personal Data is processed gives the consent.
      </p>

      <h4>6. For how long does APEC CTF retain your Personal Data?</h4>
      <p>
        We will cease retaining your Personal Data, as soon as it is reasonable
        to assume that the purpose for collecting/processing your Personal Data
        has been fulfilled, and there is no other legal or business purposes to
        continue retaining your Personal Data.
      </p>

      <h4>7. How does APEC CTF protect your Personal Data?</h4>
      <p>
        APEC CTF will ensure that there is a variety of reasonable security
        measures to maintain the safety of your Personal Data. All electronic
        storage and transmission of Personal Data is secured and stored on
        managed servers with controlled access and appropriate security
        technologies.
      </p>
      <p>
        Although every reasonable effort has been made to ensure that all
        Personal Data will be so protected, APEC CTF cannot be responsible for
        any unauthorised use or misuse of such information and from risks which
        are inherent in all internet communications.
      </p>
      <p>
        Your Personal Data will only be disclosed for the express purpose of
        delivering the product or service requested and shall not be sold or
        disclosed to any other company for any other reason whatsoever without
        your consent.
      </p>

      <h4>8. Social Media</h4>
      <p>
        You may visit our micro-sites on various social media platforms, and as
        such, may choose to provide us with personal data through these social
        media platforms, such as through forms, account portals, interfaces, and
        interactions with other customer support portals/channels. By doing so,
        you consent to the use of this personal data in accordance with this
        Privacy Policy.
      </p>
      <p>
        APEC CTF may receive information related or connected to an account with
        a third-party service that you use to sign up or log in, or when you
        associate that account. For instance, when you associate your account,
        we may receive your public profile, your friends list, your contacts,
        and your email address.
      </p>
      <p>
        If you delete your profile from a third-party service, such as Google or
        Facebook, information that has been shared with APEC CTF from that
        service may still be retained by APEC CTF. If you wish us to delete such
        information, please contact APEC CTF’s Data Protection Officer (“DPO”)
        at apecctf@nxpo.or.th.
      </p>

      <h4>9. Access and Correction of Personal Data</h4>
      <p>
        Please contact us should you wish to have access to or seek to update,
        correct or withdraw the consent to collect and use your Personal Data.
        Your email should identify yourself and state which Personal Data and
        information about its use and/or disclosure is requested.
      </p>
      <p>
        We will respond to your request as soon as reasonably possible. In the
        event we are not able to respond to your request within thirty (30) days
        after receiving your request, we will inform you before the deadline. If
        we are unable to provide you with any Personal Data or to make a
        correction you have requested, we shall inform you of the reasons unless
        where we are not required to do so under PDPA.
      </p>

      <h4>10. Further Information and contact</h4>
      <p>
        If you are concerned about the handling of your Personal Data, wish to
        be removed from our email subscription or contact lists, or if you have
        any complaints or queries related to your Personal Data or our Notice,
        please contact <b>APEC CTF</b>’s DPO at apecctf@nxpo.or.th. Please
        clearly identify yourself and the purpose of your query.
      </p>

      <h4>11. Amendments and updates of this Notice</h4>
      <p>
        <b>APEC CTF</b> reserves the right to change this Notice with or without
        notice from time to time and will make the updated statement available
        on our website.{" "}
      </p>

      <h4>Contact Us</h4>
      <p>
        For questions and/or comments about our privacy policy, please contact
        us at apecctf@nxpo.or.th
      </p>

      <p>APEC Center for Technology Foresight</p>
      <p>
        The Office of National Higher Education Science Research and Innovation
        Policy Council (NXPO)
      </p>
      <p>
        319 Chamchuri Square Building 14th Floor, Phayathai Rd., Patumwan,
        Bangkok, 10330 Thailand
      </p>
      <p>Tel: +66 2109 5432 Fax: +66 2160 5438</p>
      <p>Email: apecctf@nxpo.or.th</p>

      <div align="center">
        <a className="blueButton" href="./">
          Back to Home
        </a>
      </div>
    </div>
  );
};

export default Privacy;