
import SurveyBar from "./SurveyBar";
import PersonalInfo from "./PersonalInfo";
import Issue1page1 from "./Issue1page1";
import Issue1page2 from "./Issue1page2";
import Issue1page3 from "./Issue1page3";
import Issue1page4 from "./Issue1page4";
import Issue2page1 from "./Issue2page1";
import Issue2page2 from "./Issue2page2";
import Issue2page3 from "./Issue2page3";
import Issue2page4 from "./Issue2page4";
import Issue2page5 from "./Issue2page5";
import Issue3page1 from "./Issue3page1";
import Issue3page2 from "./Issue3page2";
import Issue3page3 from "./Issue3page3";
import Issue3page4 from "./Issue3page4";
import Issue4page1 from "./Issue4page1";
import Issue4page2 from "./Issue4page2";
import Issue4page3 from "./Issue4page3";
import Issue4page4 from "./Issue4page4";
import Issue4page5 from "./Issue4page5";
import Issue4page6 from "./Issue4page6";
import Issue4page7 from "./Issue4page7";
import Issue5page1 from "./Issue5page1";
import Issue5page2 from "./Issue5page2";
import Summarize from "./Summarize";
import Consent from "./Consent";

const Survey = (props) => {  


  let consent = JSON.parse(props.user).consent;
  let hasConsent = consent&&(consent==="1"||consent===true);

  const surveyIssue = (issue) => {
    switch (issue) {
      case "1":
        switch (page) {
          case "2":
            return <Issue1page2 user={props.user} />;
          case "3":
            return <Issue1page3 user={props.user} />;
          case "4":
            return <Issue1page4 user={props.user} />;
          default:
            return <Issue1page1 user={props.user} />;
        }
      case "2":
        switch (page) {
          case "2":
            return <Issue2page2 user={props.user} />;
          case "3":
            return <Issue2page3 user={props.user} />;
          case "4":
            return <Issue2page4 user={props.user} />;
          case "5":
            return <Issue2page5 user={props.user} />;
          default:
            return <Issue2page1 user={props.user} />;
        }
      case "3":
        switch (page) {
          case "2":
            return <Issue3page2 user={props.user} />;
          case "3":
            return <Issue3page3 user={props.user} />;
          case "4":
            return <Issue3page4 user={props.user} />;
          default:
            return <Issue3page1 user={props.user} />;
        }
      case "4":
        switch (page) {
          case "2":
            return <Issue4page2 user={props.user} />;
          case "3":
            return <Issue4page3 user={props.user} />;
          case "4":
            return <Issue4page4 user={props.user} />;
          case "5":
            return <Issue4page5 user={props.user} />;
          case "6":
            return <Issue4page6 user={props.user} />;
          case "7":
            return <Issue4page7 user={props.user} />;
          default:
            return <Issue4page1 user={props.user} />;
        }
      case "5":
        switch (page) {
          case "2":
            return <Issue5page2 user={props.user} />;
          default:
            return <Issue5page1 user={props.user} />;
        }
      case "6":
          return <Summarize user={props.user} />;        
        
      default:
        return (
          <>
            {hasConsent ? (
              <PersonalInfo user={props.user} setUser={props.setUser} />
            ) : (
              <Consent user={props.user} setUser={props.setUser} />
            )}
          </>
        );
    }
  }

  let issue = (new URLSearchParams(window.location.search).get('issue')||"0");
  let page = (new URLSearchParams(window.location.search).get('page')||"1");
  
  let admin = JSON.parse(props.user).admin;
  let isAdmin = admin&&admin==="1";

  if(isAdmin&&(issue==="0")) issue="1";

  if(!isAdmin) return (
    <>
    &nbsp;<br />
    &nbsp;<br />
    &nbsp;<br />
    &nbsp;<br />
    &nbsp;<br />
    &nbsp;<br />
    &nbsp;<br />
    &nbsp;<br />
    <center>Thank you for your participation. The survey period has ended.</center>
    &nbsp;<br />
    &nbsp;<br />
    &nbsp;<br />
    &nbsp;<br />
    &nbsp;<br />
    &nbsp;<br />
    &nbsp;<br />
    &nbsp;<br />
    </>
  );
  
  return  <div className='content'>   
    <br />
    <SurveyBar admin={isAdmin} issue={issue} page={page} />
    {surveyIssue(issue)}
    <br />
    <br />
  </div>
};

export default Survey;