const EconomyDropDown = (props) => {
  const economies = [
    'Please Select',
    'Australia',
    'Brunei Darussalam',
    'Canada',
    'Chile',
    'People\'s Republic of China',
    'Hong Kong, China',
    'Indonesia',
    'Japan',
    'Republic of Korea',
    'Malaysia',
    'Mexico',
    'New Zealand',
    'Papua New Guinea',
    'Peru',
    'The Philippines',
    'Russia',
    'Singapore',
    'Chinese Taipei',
    'Thailand',
    'The United States',
    'Viet Nam'
    ];

  var handleChange = (e) => {
    props.updateEconomy(e.target.value);
  }

  let key = 0;

  return (
    <>
      <select id={props.id} onChange={(e)=>handleChange(e)} defaultValue={props.economy}>
        {economies.map((e)=>(
          <option key={key++} value={e}>{e}</option>
        ))}
      </select>
    </>
  );
  
};

export default EconomyDropDown;