import { useState, useEffect } from 'react';
import axios from 'axios';
import { backendServer, } from '../../Const';
import EconomyDropDown from "../../components/EconomyDropDown";
import Message from './Message';
import { handleBeforeUnload } from '../../SurveyUtils';
import issues from '../../images/issues.png';

const PersonalInfo = (props) => {
  const userObj = JSON.parse(props.user);

  const [name, setName] = useState(userObj.name);
  //const [familyName, setFamilyName] = useState(userObj.family_name);
  const [economy, setEconomy] = useState(userObj.economy);
  const [email, setEmail] = useState(userObj.email);
  const [experience, setExperience] = useState(userObj.experience);
  const [age, setAge] = useState(userObj.age);
  const [gender, setGender] = useState(userObj.gender);
  const [affiliation, setAffiliation] = useState(userObj.affiliation);
  const [companyType, setCompanyType] = useState(userObj.company_type);
  const [jobFunction, setJobFunction] = useState(userObj.job_function);
  
  const [issueText, setIssueText] = useState('');

  const [filled, setFilled] = useState(false);
  const [unchanged, setUnchanged] = useState(true);
  const [message, setMessage] = useState('');
  const [messageColor, setMessageColor] = useState('');

  useEffect( () => {
    if(unchanged) return;
    
    window.addEventListener('beforeunload',handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload',handleBeforeUnload)
    }
  },[unchanged]);

  const setErrorMessage = (msg) => {
    setMessageColor("red");
    setMessage(msg);
  }

  const setOkMessage = (msg) => {
    setMessageColor("teal");
    setMessage(msg);
  }

  useEffect(()=>{
    setMessage('');
    if(name!==userObj.name) { setUnchanged(false); return; }
    //if(familyName!==userObj.family_name) { setUnchanged(false); return; }
    if(economy!==userObj.economy) { setUnchanged(false); return; }
    if(email!==userObj.email) { setUnchanged(false); return; }
    if(experience!==userObj.experience) { setUnchanged(false); return; }
    if(age!==userObj.age) { setUnchanged(false); return; }
    if(gender!==userObj.gender) { setUnchanged(false); return; }
    if(affiliation!==userObj.affiliation) { setUnchanged(false); return; }
    if(companyType!==userObj.company_type) { setUnchanged(false); return; }
    if(jobFunction!==userObj.job_function) { setUnchanged(false); return; }    
    setUnchanged(true);
  },[name, /*familyName, */economy, email, experience, age, gender, affiliation, companyType, jobFunction, userObj]);

  const checkFilled = () => {    
    if(!name) { setFilled(false); return;}
    //if(!familyName) { setFilled(false); return;}
    if(!economy) { setFilled(false); return;}
    if(!email) { setFilled(false); return;}
    if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) { setFilled(false); return;}
    if(!experience) { setFilled(false); return;}
    if(!age) { setFilled(false); return;}
    if(!gender) { setFilled(false); return;}
    if(!affiliation) { setFilled(false); return;}
    if(!companyType) { setFilled(false); return;}
    if(!jobFunction) { setFilled(false); return;}
    setFilled(true);
  }

  useEffect(()=>{
    checkFilled();
  });

  const resetValues = () => {
    setName(userObj.name);
    //setFamilyName(userObj.family_name);
    setEconomy(userObj.economy);
    setEmail(userObj.email);
    setExperience(userObj.experience);
    setAge(userObj.age);
    setGender(userObj.gender);
    setAffiliation(userObj.affiliation);
    setCompanyType(userObj.company_type);
    setJobFunction(userObj.job_function);
    setUnchanged(true);
  }
  
  const updateUserInfo = () => {
    userObj.name=name;
    //userObj.family_name=familyName;
    userObj.economy=economy;
    userObj.email=email;
    userObj.experience=experience;
    userObj.age=age;
    userObj.gender=gender;
    userObj.affiliation=affiliation;
    userObj.company_type=companyType;
    userObj.job_function=jobFunction;
    props.setUser(JSON.stringify(userObj));
    setUnchanged(true);
  }

  const handleSubmit = (event) => {
    event.preventDefault();    
    if(filled&&unchanged) {
      navigate(event.target.value);
      return; 
    }

    if(!name) { setErrorMessage('Please provide your name.'); return;}
    //if(!familyName) { setErrorMessage('Please provide your family name.'); return;}
    if(!economy) { setErrorMessage('Please provide your economy.'); return;}
    if(!email) { setErrorMessage('Please provide your email.'); return;}
    if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) { setErrorMessage('Please provide a valid email.'); return;}
    if(!experience) { setErrorMessage('Please provide your working experience.'); return;}
    if(!age) { setErrorMessage('Please provide your age range.'); return;}
    if(!gender) { setErrorMessage('Please provide your gender.'); return;}
    if(!affiliation) { setErrorMessage('Please provide your affiliation.'); return;}
    if(!companyType) { setErrorMessage('Please provide your company type.'); return;}
    if(!jobFunction) { setErrorMessage('Please provide your job function.'); return;}
    
    axios
      .post(backendServer + "/save_info.php", {
        user_id: JSON.parse(props.user).user_id,
        name,
        economy,
        email,
        experience,
        age,
        gender,
        affiliation,
        company_type: companyType,
        job_function: jobFunction,
        consent: userObj.consent,
        consentOther: userObj.consentOther
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.status === "ok") {
          //setOkMessage("Information saved.");
          updateUserInfo();
          setFilled(true);
          navigate(event.target.value, "Information saved, proceeding..");
        } else {
          setErrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        setErrorMessage(error);
      });
  }

  const handleMouseOver = (e) => {
    switch(e.target.value) {
      case "1": setIssueText('Energy demand management '); break;
      case "2": setIssueText('Energy Transition'); break;
      case "3": setIssueText('Green economy'); break;
      case "4": setIssueText('Carbon emission sinks'); break;
      case "5": setIssueText('Offset policies'); break;
      default: setIssueText('');
    }
  }

  const handleMouseLeave= (e) => {
    setIssueText('');
  }

  const navigate = (issue, msg) => {
    window.removeEventListener('beforeunload',handleBeforeUnload); 
    if(!msg) msg = 'Proceeding..';
    setOkMessage(msg);
    setTimeout(()=> {
      window.location.href = '/survey?issue='+issue+'&page=1';
    }, 1000);
  }
  
  //let admin = JSON.parse(props.user).admin;
  //let isAdmin = admin&&admin==="1";

  return (
    <>
      <br />
      <form >
        <table width="600" align="center" cellPadding={8}>
          <thead>
            <tr>
              <td>
                <h3>Plese provide your information</h3>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Name:{" "}
                <input
                  type="text"
                  name="givenName"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  size={50}
                />{" "}                
              </td>
            </tr>
            <tr>
              <td>
                Economy:{" "}
                <EconomyDropDown
                  id="personalEconomy"
                  economy={economy}
                  updateEconomy={setEconomy}
                />
              </td>
            </tr>
            <tr>
              <td>
                Email:{" "}
                <input
                  type="text"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />{" "}
              </td>
            </tr>
            <tr>
              <td>
                Working experience related to net-zero emissions: <br />
                &nbsp;&nbsp;{" "}
                <input
                  type="radio"
                  name="experience"
                  checked={experience === "1"}
                  onChange={(e) => setExperience(e.target.value)}
                  value="1"
                />{" "}
                Less than 2 years &nbsp;&nbsp;{" "}
                <input
                  type="radio"
                  name="experience"
                  checked={experience === "2"}
                  onChange={(e) => setExperience(e.target.value)}
                  value="2"
                />{" "}
                2-5 years &nbsp;&nbsp;{" "}
                <input
                  type="radio"
                  name="experience"
                  checked={experience === "3"}
                  onChange={(e) => setExperience(e.target.value)}
                  value="3"
                />{" "}
                6-10 years &nbsp;&nbsp;{" "}
                <input
                  type="radio"
                  name="experience"
                  checked={experience === "4"}
                  onChange={(e) => setExperience(e.target.value)}
                  value="4"
                />{" "}
                More than 10 years
              </td>
            </tr>
            <tr>
              <td>
                Age: <br />
                &nbsp;&nbsp;{" "}
                <input
                  type="radio"
                  name="age"
                  checked={age === "1"}
                  onChange={(e) => setAge(e.target.value)}
                  value="1"
                />{" "}
                Below 20 &nbsp;&nbsp;
                <input
                  type="radio"
                  name="age"
                  checked={age === "2"}
                  onChange={(e) => setAge(e.target.value)}
                  value="2"
                />{" "}
                20-30 &nbsp;&nbsp;
                <input
                  type="radio"
                  name="age"
                  checked={age === "3"}
                  onChange={(e) => setAge(e.target.value)}
                  value="3"
                />{" "}
                31-45 &nbsp;&nbsp;
                <input
                  type="radio"
                  name="age"
                  checked={age === "4"}
                  onChange={(e) => setAge(e.target.value)}
                  value="4"
                />{" "}
                46-60 &nbsp;&nbsp;
                <input
                  type="radio"
                  name="age"
                  checked={age === "5"}
                  onChange={(e) => setAge(e.target.value)}
                  value="5"
                />{" "}
                &gt; 60
              </td>
            </tr>
            <tr>
              <td>
                Gender: &nbsp;
                <select
                  id="gender"
                  defaultValue={gender}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <option value="0">Please Select</option>
                  <option value="1">Male</option>
                  <option value="2">Female</option>
                  <option value="3">Prefer Not to Answer</option>
                </select>
                &nbsp;&nbsp;&nbsp;&nbsp;Affiliation: &nbsp;
                <input
                  type="text"
                  name="affiliation"
                  value={affiliation}
                  onChange={(e) => setAffiliation(e.target.value)}
                />{" "}
              </td>
            </tr>
            <tr>
              <td>
                Organization Type: &nbsp;
                <select
                  id="companyType"
                  defaultValue={companyType}
                  onChange={(e) => setCompanyType(e.target.value)}
                >
                  <option value="0">Please Select</option>
                  <option value="1">Government</option>
                  <option value="2">Private</option>
                  <option value="3">Education</option>
                  <option value="4">NGOs</option>
                  <option value="4">N/A</option>
                </select>
              </td>
            </tr>
            <tr>
              <td>
                Job Function:{" "}
                <input
                  type="text"
                  name="jobFunction"
                  value={jobFunction}
                  onChange={(e) => setJobFunction(e.target.value)}
                />{" "}
                <br />
              </td>
            </tr>
            <tr>
              <td align="right">
                <Message color={messageColor} message={message} /> <br />
                
                <span style={{color:"#476e9e"}}>{issueText} </span><br />
                <input
                  disabled={unchanged}
                  onClick={resetValues}
                  className="blueButton"
                  type="button"
                  value="Reset"
                />
                &nbsp;&nbsp; {unchanged?"":"Save and "} Select Issue: &nbsp;&nbsp;
                <input
                  className="grayButton"
                  type="button"
                  value="1"
                  onClick={e=>handleSubmit(e)}
                  onMouseEnter={(e)=>handleMouseOver(e)}
                  onMouseLeave={(e)=>handleMouseLeave(e)}
                />
                &nbsp;
                <input
                  className="grayButton"
                  type="button"
                  value="2"
                  onClick={e=>handleSubmit(e)}
                  onMouseEnter={(e)=>handleMouseOver(e)}
                  onMouseLeave={(e)=>handleMouseLeave(e)}
                />
                &nbsp;
                <input
                  className="grayButton"
                  type="button"
                  value="3"
                  onClick={e=>handleSubmit(e)}
                  onMouseEnter={(e)=>handleMouseOver(e)}
                  onMouseLeave={(e)=>handleMouseLeave(e)}
                />
                &nbsp;
                <input
                  className="grayButton"
                  type="button"
                  value="4"
                  onClick={e=>handleSubmit(e)}
                  onMouseEnter={(e)=>handleMouseOver(e)}
                  onMouseLeave={(e)=>handleMouseLeave(e)}
                />
                &nbsp;
                <input
                  className="grayButton"
                  type="button"
                  value="5"
                  onClick={e=>handleSubmit(e)}
                  onMouseEnter={(e)=>handleMouseOver(e)}
                  onMouseLeave={(e)=>handleMouseLeave(e)}
                />
              </td>
            </tr>
          <tr>
            <td>
              &nbsp;<br />
              <center>
                <img src={issues} width="650" alt="Investigating issues" />
              </center>
            </td>
          </tr>
          </tbody>
        </table>
      </form>
      <br />
    </>
  );
};

export default PersonalInfo;

/*

&nbsp; Family Name:{" "}
                <input
                  type="text"
                  name="familyName"
                  value={familyName}
                  onChange={(e) => setFamilyName(e.target.value)}
                />{" "}

                */