import { useState, useEffect } from 'react';
import axios from 'axios';
import { backendServer } from '../../Const';
import RateItem from "./RateItem";
import SelectItem from "./SelectItem";
import Message from './Message';

import {isDeepEqual, readRateAnswer, readSelectionAnswers, checkRateFilled, checkSelectionFilled, handleBeforeUnload} from '../../SurveyUtils';
import NextButtonPanel from '../../components/NextButtonPanel';

const Issue1page3 = (props) => {
  const [page3Data, setPage3Data] = useState('');
  const [firstRound, setFirstRound] = useState(true);

  const [p9Rate, setP9Rate] = useState(0);
  const [p9Comment, setP9Comment] = useState('');
  const [p9Results, setP9Results] = useState('');  
  const [p10Selections, setP10Selections] = useState([]);
  const [p10Comments, setP10Comments] = useState([]);
  const [p10Results, setP10Results] = useState('');
  const [p11Selections, setP11Selections] = useState([]);
  const [p11Comments, setP11Comments] = useState([]);
  const [p11Results, setP11Results] = useState('');
  const [p12Rate, setP12Rate] = useState(0);
  const [p12Comment, setP12Comment] = useState('');
  const [p12Results, setP12Results] = useState('');
  const [p13Selections, setP13Selections] = useState([]);
  const [p13Comments, setP13Comments] = useState([]);
  const [p13Results, setP13Results] = useState('');

  const [filled, setFilled] = useState(false);
  const [unchanged, setUnchanged] = useState(true);
  const [message, setMessage] = useState('');
  const [messageColor, setMessageColor] = useState('');

  useEffect( () => {
    if(unchanged) return;
    
    window.addEventListener('beforeunload',handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload',handleBeforeUnload)
    }
    
  },[unchanged]);

  const setErrorMessage = (msg) => {
    setMessageColor("red");
    setMessage(msg);
  }

  const setOkMessage = (msg) => {
    setMessageColor("teal");
    setMessage(msg);
  }
  
  const resetValues = () => {
    const data = page3Data?JSON.parse(page3Data):[];
    setP9Rate(data.p9Rate?data.p9Rate:0);
    setP9Comment(data.p9Comment?data.p9Comment:'');
    setP10Selections(data.p10Selections?data.p10Selections:[]);
    setP10Comments(data.p10Comments?data.p10Comments:[]);
    setP11Selections(data.p11Selections?data.p11Selections:[]);
    setP11Comments(data.p11Comments?data.p11Comments:[]);
    setP12Rate(data.p12Rate?data.p12Rate:0);
    setP12Comment(data.p12Comment?data.p12Comment:'');
    setP13Selections(data.p13Selections?data.p13Selections:[]);
    setP13Comments(data.p13Comments?data.p13Comments:[]);
    setUnchanged(true);
  }

  
  const fetchData = async () => {
    axios
      .post(backendServer + "/get_votes.php", {
        user_id: JSON.parse(props.user).user_id,
        problem_ids: ["9", "10", "11", "12", "13"],
      })
      .then((response) => {
        if (response.data.status === "ok") {
          let answers = JSON.parse(response.data.answers);
          let results = JSON.parse(response.data.results);
          let data = {};

          if(answers['9']) {
            setFirstRound(false);
          }
          
          [data['p9Rate'], data['p9Comment']] = readRateAnswer(answers['9']);
          [data['p10Selections'], data['p10Comments']] = readSelectionAnswers(answers['10']);
          [data['p11Selections'], data['p11Comments']] = readSelectionAnswers(answers['11']);
          [data['p12Rate'], data['p12Comment']] = readRateAnswer(answers['12']);
          [data['p13Selections'], data['p13Comments']] = readSelectionAnswers(answers['13']);

          setPage3Data(JSON.stringify(data));

          setP9Results(results["9"]);
          setP10Results(results["10"]);
          setP11Results(results["11"]);
          setP12Results(results["12"]);
          setP13Results(results["13"]);

          checkFilled();
          setUnchanged(true);
        } else {
          setErrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  }

  
  useEffect(()=>{
    fetchData(); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    resetValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[page3Data]);

  
  useEffect(() => {
    setMessage('');
    let data = page3Data?JSON.parse(page3Data):[];
    if (Number(data.p9Rate) !== Number(p9Rate)) {
      setUnchanged(false);
      return;
    }
    if (data.p9Comment !== p9Comment) {
      setUnchanged(false);
      return;
    }
    if (!isDeepEqual(data.p10Selections, p10Selections)) {
      setUnchanged(false);
      return;
    }
    if (!isDeepEqual(data.p10Comments, p10Comments)) {
      setUnchanged(false);
      return;
    }
    if (!isDeepEqual(data.p11Selections, p11Selections)) {      
      setUnchanged(false);
      return;
    }
    if (!isDeepEqual(data.p11Comments, p11Comments)) {
      setUnchanged(false);
      return;
    }
    if (Number(data.p12Rate) !== Number(p12Rate)) {
      setUnchanged(false);
      return;
    }
    if (data.p12Comment !== p12Comment) {
      setUnchanged(false);
      return;
    }
    if (!isDeepEqual(data.p13Selections, p13Selections)) {
      setUnchanged(false);
      return;
    }
    if (!isDeepEqual(data.p13Comments, p13Comments)) {
      setUnchanged(false);
      return;
    }    

    setUnchanged(true);
  }, [
    p9Rate,
    p9Comment,
    p10Selections,
    p10Comments,
    p11Selections,
    p11Comments,
    p12Rate,
    p12Comment,
    p13Selections,
    p13Comments, page3Data
  ]);

  const checkFilled = () => {
    if(!checkRateFilled(p9Rate, p9Comment, 1, 5)) {setFilled(false); return;}
    if(!checkSelectionFilled(p10Selections, p10Comments, 1, 1)) {setFilled(false); return;}
    if(!checkSelectionFilled(p11Selections, p11Comments, 3, 3)) {setFilled(false); return;}
    if(!checkRateFilled(p12Rate, p12Comment, 1, 5)) {setFilled(false); return;}
    if(!checkSelectionFilled(p13Selections, p13Comments, 1, 7)) {setFilled(false); return;}

    setFilled(true);
  }
  
  useEffect(() => {   
    checkFilled();
  });

  const navigate = (msg) => {
    window.removeEventListener('beforeunload',handleBeforeUnload);
    if(!msg) msg = 'Proceeding..';
    setOkMessage(msg);
    setTimeout(()=> {
      window.location.href = '/survey?issue=1&page=4';
    }, 1000);
  }

  const updateP10Answer = (selection, add) => {
    if(add) {
      let newSelections = [...p10Selections];
      newSelections.push(selection);
      setP10Selections(newSelections);
      let newComments = [...p10Comments];
      let data = page3Data?JSON.parse(page3Data):[];
      const index = data.p10Selections?data.p10Selections.indexOf(selection):-1;
      newComments.push(index>=0?data.p10Comments[index]:'');
      setP10Comments(newComments);
    } else {
      let index = p10Selections.indexOf(selection);
      if(index>=0) { // sanity check        
        let newSelections = [...p10Selections];
        newSelections.splice(index,1);
        setP10Selections(newSelections);
        let newComments = [...p10Comments];
        newComments.splice(index,1);
        setP10Comments(newComments);
      }
    }
  }

  const updateP10Comment = (answerIndex, comment) => {
    let newComments = [...p10Comments];
    newComments[answerIndex]=comment;
    setP10Comments(newComments);
  }

  const updateP10Other = (otherText, answerIndex) => {
    let newSelections = [...p10Selections];
    newSelections[answerIndex]=otherText;
    setP10Selections(newSelections);
  }
  
  const updateP11Answer = (selection, add) => {
    if(add) {
      let newSelections = [...p11Selections];
      newSelections.push(selection);
      setP11Selections(newSelections);
      let newComments = [...p11Comments];
      let data = page3Data?JSON.parse(page3Data):[];
      const index = data.p11Selections?data.p11Selections.indexOf(selection):-1;
      newComments.push(index>=0?data.p11Comments[index]:'');
      setP11Comments(newComments);
    } else {
      let index = p11Selections.indexOf(selection);
      if(index>=0) { // sanity check        
        let newSelections = [...p11Selections];
        newSelections.splice(index,1);
        setP11Selections(newSelections);
        let newComments = [...p11Comments];
        newComments.splice(index,1);
        setP11Comments(newComments);
      }
    }
  }

  const updateP11Comment = (answerIndex, comment) => {
    let newComments = [...p11Comments];
    newComments[answerIndex]=comment;
    setP11Comments(newComments);
  }

  const updateP11Other = (otherText, answerIndex) => {
    let newSelections = [...p11Selections];
    newSelections[answerIndex]=otherText;
    setP11Selections(newSelections);
  }

  const updateP13Answer = (selection, add) => {
    if(add) {
      let newSelections = [...p13Selections];
      newSelections.push(selection);
      setP13Selections(newSelections);
      let newComments = [...p13Comments];
      let data = page3Data?JSON.parse(page3Data):[];
      const index = data.p13Selections?data.p13Selections.indexOf(selection):-1;
      newComments.push(index>=0?data.p13Comments[index]:'');
      setP13Comments(newComments);
    } else {
      let index = p13Selections.indexOf(selection);
      if(index>=0) { // sanity check        
        let newSelections = [...p13Selections];
        newSelections.splice(index,1);
        setP13Selections(newSelections);
        let newComments = [...p13Comments];
        newComments.splice(index,1);
        setP13Comments(newComments);
      }
    }
  }

  const updateP13Comment = (answerIndex, comment) => {
    let newComments = [...p13Comments];
    newComments[answerIndex]=comment;
    setP13Comments(newComments);
  }

  const updateP13Other = (otherText, answerIndex) => {
    let newSelections = [...p13Selections];
    newSelections[answerIndex]=otherText;
    setP13Selections(newSelections);
  }

  let admin = JSON.parse(props.user).admin;
  let isAdmin = admin&&admin==="1";

  const handleSubmit = (event) => {
    event.preventDefault();
    
    if((filled&&unchanged)||isAdmin) { navigate(); return; }
    
    if(!checkRateFilled(
      p9Rate,
      p9Comment,
      1,
      5,
      "Please answer problem 1.",
      "Please provide your reasoning for problem 1.",
      setErrorMessage
    )) return;
    if(!checkSelectionFilled(
      p10Selections,
      p10Comments,
      1,
      1,
      "Please select an answer for problem 2.",
      "Please provide your reasoning for the selected answer in problem 2.",
      setErrorMessage,
      "Please specify the other field in problem 2."
    )) return;
    if(!checkSelectionFilled(p11Selections, p11Comments, 3, 3,
      "Please select three answers for problem 3.",
      "Please provide your reasoning for each selected answer in problem 3.",
      setErrorMessage,
      "Please specify the other field in problem 3."
    )) return;
    if(!checkRateFilled(p12Rate, p12Comment, 1, 5,
      "Please answer problem 4.",
      "Please provide your reasoning for problem 4.",
      setErrorMessage
    )) return;
    if(!checkSelectionFilled(p13Selections, p13Comments, 1, 7,
      "Please select some answers for problem 5.",
      "Please provide your reasoning for each selected answer in problem 5.",
      setErrorMessage,
      "Please specify the other field in problem 5."
    )) return;
    
    let user_id = JSON.parse(props.user).user_id;
    let voteData = [];
    voteData.push({user_id, problem_id:9, vote:p9Rate, comment: p9Comment});
    p10Selections.forEach((selection,index)=>{
      voteData.push({user_id, problem_id:10, vote:selection, comment: p10Comments[index]});
    });
    p11Selections.forEach((selection,index)=>{
      voteData.push({user_id, problem_id:11, vote:selection, comment: p11Comments[index]});
    });    
    voteData.push({user_id, problem_id:12, vote:p12Rate, comment: p12Comment});
    p13Selections.forEach((selection,index)=>{
      voteData.push({user_id, problem_id:13, vote:selection, comment: p13Comments[index]});
    });

    axios.post( backendServer+'/vote.php', {vote_data: JSON.stringify(voteData)})
    .then( (response)=>{
      if(response.data.status==='ok') {
        //updatePage3Data();
        //fetchData();
        setFilled(true);
        setFirstRound(false);
        //setOkMessage("Information Saved.");
        navigate('Responses saved.');
      } else {
        setErrorMessage(response.data.message);
      }
    })
    .catch((error)=>{
      setErrorMessage(error.message);
    });
  }

  return (
    <form onSubmit={handleSubmit}>
      <table width="600" align="center" cellPadding={8}>
        <tbody>
          <tr>
            <td>
              <h4>1.2 Energy Intensity</h4>

              <p>
                Energy intensity refers to the amount of energy required to
                produce a unit of output, such as economic output (GDP),
                industrial production, or other measurable indicators. It is a
                metric used to assess the efficiency of energy use in relation
                to economic or productive activities. Lower energy intensity
                indicates a more efficient use of energy resources.
              </p>

              <p>
                In 2007, APEC Leaders proposed a regional goal to reduce energy
                intensity by at least 25 percent by 2030. In 2011, APEC Leaders
                set a higher target to reduce energy intensity by at least 45
                percent by 2035 (Honolulu Declaration).
              </p>
              <hr />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={9}
                firstRound={firstRound}
                rate={p9Rate}
                setRate={setP9Rate}
                comment={p9Comment}
                setComment={setP9Comment}
                text="1. How confident do you perceive the energy intensity policies within APEC as being on track with the net-zero emission goals?"
                results={p9Results}
                choices={[
                  "Not confident",
                  "Slightly confident",
                  "Moderately confident",
                  "Confident",
                  "Very confident",
                ]}
                adminMode={isAdmin}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <SelectItem
                problem_id={10}
                firstRound={firstRound}
                max={1}
                updateAnswer={updateP10Answer}
                updateComment={updateP10Comment}
                selections={p10Selections}
                comments={p10Comments}
                text="2. What specific energy consumption targets should APEC agree upon for the approaching year 2035, in relation to achieving net-zero emissions?"
                choices={[
                  "Reduce energy intensity by at least 45% (Follow the Honolulu declaration, 2011)",
                  "Reduce energy intensity by at least 35% (For post-covid economic growth)",
                  "Reduce energy intensity by at least 30% (if the peak demand reached in 2030)",
                ]}
                other={true}
                updateOther={updateP10Other}
                results={p10Results}
                adminMode={isAdmin}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <SelectItem
                problem_id={11}
                firstRound={firstRound}
                max={3}
                updateAnswer={updateP11Answer}
                updateComment={updateP11Comment}
                selections={p11Selections}
                comments={p11Comments}
                text="3. On the sectors listed below, please select three sectors based on their prominence of energy intensity policy to achieve net-zero emissions."
                choices={[
                  "Industry",
                  "Transport",
                  "Energy production",
                  "Household",
                  "Building",
                  "Trade",
                  "Services",
                  "Tourism",
                ]}
                other={true}
                updateOther={updateP11Other}
                results={p11Results}
                adminMode={isAdmin}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={12}
                firstRound={firstRound}
                rate={p12Rate}
                setRate={setP12Rate}
                comment={p12Comment}
                setComment={setP12Comment}
                text="4. On a scale of 1 to 5, how strong should regulations be applied to reduce energy intensity, in alignment with achieving net-zero emissions?"
                results={p12Results}
                choices={[
                  "Voluntary",
                  "Complementary",
                  "Conditional",
                  "Regulatory",
                  "Mandatory",
                ]}
                adminMode={isAdmin}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <SelectItem
                problem_id={13}
                firstRound={firstRound}
                max={7}
                updateAnswer={updateP13Answer}
                updateComment={updateP13Comment}
                selections={p13Selections}
                comments={p13Comments}
                text="5. Based on your justification, which technologies have strong impact on energy intensity, to accelerate progress towards achieving net-zero emissions?"
                choices={[
                  "Data analytics and machine learning to optimize energy consumption patterns",
                  "Smart heating and cooling systems",
                  "Building insulation",
                  "Energy-efficient lighting",
                  "Public transport",
                  "Electric vehicles",
                ]}
                other={true}
                updateOther={updateP13Other}
                results={p13Results}
                adminMode={isAdmin}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td align="right">
              <Message color={messageColor} message={message} />
              <br />
              <NextButtonPanel disabled={unchanged} onClick={resetValues} />
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );
};

export default Issue1page3;