import { useState, useEffect } from 'react';
import axios from 'axios';
import { backendServer } from '../../Const';
import RateItem from "./RateItem";
import SelectItem from "./SelectItem";
import Message from './Message';

import {isDeepEqual, readRateAnswer, readSelectionAnswers, checkRateFilled, checkSelectionFilled, handleBeforeUnload} from '../../SurveyUtils';
import NextButtonPanel from '../../components/NextButtonPanel';


const Issue1page2 = (props) => {
  const [page2Data, setPage2Data] = useState('');
  const [firstRound, setFirstRound] = useState(true);

  const [p3Rate, setP3Rate] = useState(0);
  const [p3Comment, setP3Comment] = useState('');
  const [p3Results, setP3Results] = useState('');
  const [p4Selections, setP4Selections] = useState([]);
  const [p4Comments, setP4Comments] = useState([]);
  const [p4Results, setP4Results] = useState('');
  const [p5Selections, setP5Selections] = useState([]);
  const [p5Comments, setP5Comments] = useState([]);
  const [p5Results, setP5Results] = useState('');
  const [p6Selections, setP6Selections] = useState([]);
  const [p6Comments, setP6Comments] = useState([]);
  const [p6Results, setP6Results] = useState('');
  const [p7Rate, setP7Rate] = useState(0);
  const [p7Comment, setP7Comment] = useState('');
  const [p7Results, setP7Results] = useState('');
  const [p8Selections, setP8Selections] = useState([]);
  const [p8Comments, setP8Comments] = useState([]);
  const [p8Results, setP8Results] = useState('');

  const [filled, setFilled] = useState(false);
  const [unchanged, setUnchanged] = useState(true);
  const [message, setMessage] = useState('');
  const [messageColor, setMessageColor] = useState('');

  useEffect( () => {
    if(unchanged) return;
    
    window.addEventListener('beforeunload',handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload',handleBeforeUnload)
    }
    
  },[unchanged]);

  const setErrorMessage = (msg) => {
    setMessageColor("red");
    setMessage(msg);
  }

  const setOkMessage = (msg) => {
    setMessageColor("teal");
    setMessage(msg);
  }

  
  
  const resetValues = () => {
    const data = page2Data?JSON.parse(page2Data):[];

    setP3Rate(data.p3Rate?data.p3Rate:0);
    setP3Comment(data.p3Comment?data.p3Comment:'');
    setP4Selections(data.p4Selections?data.p4Selections:[]);
    setP4Comments(data.p4Comments?data.p4Comments:[]);
    setP5Selections(data.p5Selections?data.p5Selections:[]);
    setP5Comments(data.p5Comments?data.p5Comments:[]);
    setP6Selections(data.p6Selections?data.p6Selections:[]);
    setP6Comments(data.p6Comments?data.p6Comments:[]);
    setP7Rate(data.p7Rate?data.p7Rate:0);
    setP7Comment(data.p7Comment?data.p7Comment:'');
    setP8Selections(data.p8Selections?data.p8Selections:[]);
    setP8Comments(data.p8Comments?data.p8Comments:[]);

    setUnchanged(true);
  }
  
  const fetchData = async () => {
    axios
      .post(backendServer + "/get_votes.php", {
        user_id: JSON.parse(props.user).user_id,
        problem_ids: ["3", "4", "5", "6", "7", "8"],
      })
      .then((response) => {
        if (response.data.status === "ok") {
          let answers = JSON.parse(response.data.answers);
          let results = JSON.parse(response.data.results);
          let data = {};
          
          if(answers['3']) {
            setFirstRound(false);
          }

          [data['p3Rate'], data['p3Comment']] = readRateAnswer(answers['3']);
          [data['p4Selections'], data['p4Comments']] = readSelectionAnswers(answers['4']);
          [data['p5Selections'], data['p5Comments']] = readSelectionAnswers(answers['5']);
          [data['p6Selections'], data['p6Comments']] = readSelectionAnswers(answers['6']);
          [data['p7Rate'], data['p7Comment']] = readRateAnswer(answers['7']);
          [data['p8Selections'], data['p8Comments']] = readSelectionAnswers(answers['8']);
          
          setPage2Data(JSON.stringify(data));

          setP3Results(results["3"]);
          setP4Results(results["4"]);
          setP5Results(results["5"]);
          setP6Results(results["6"]);
          setP7Results(results["7"]);
          setP8Results(results["8"]);

          checkFilled();
          setUnchanged(true);
        } else {
          setErrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  }

  useEffect(()=>{
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  
  useEffect(()=>{
    resetValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[page2Data]);

  useEffect(() => {
    setMessage('');
    let data = page2Data?JSON.parse(page2Data):[];
    if (Number(data.p3Rate) !== Number(p3Rate)) {
      setUnchanged(false);
      return;
    }
    if (data.p3Comment !== p3Comment) {
      setUnchanged(false);
      return;
    }
    if (!isDeepEqual(data.p4Selections, p4Selections)) {
      setUnchanged(false);
      return;
    }
    if (!isDeepEqual(data.p4Comments, p4Comments)) {
      setUnchanged(false);
      return;
    }
    if (!isDeepEqual(data.p5Selections, p5Selections)) {
      setUnchanged(false);
      return;
    }
    if (!isDeepEqual(data.p5Comments, p5Comments)) {
      setUnchanged(false);
      return;
    }
    if (!isDeepEqual(data.p6Selections, p6Selections)) {
      setUnchanged(false);
      return;
    }
    if (!isDeepEqual(data.p6Comments, p6Comments)) {
      setUnchanged(false);
      return;
    }
    if (Number(data.p7Rate) !== Number(p7Rate)) {
      setUnchanged(false);
      return;
    }
    if (data.p7Comment!==p7Comment) {
      setUnchanged(false);
      return;
    }
    if (!isDeepEqual(data.p8Selections, p8Selections)) {
      setUnchanged(false);
      return;
    }
    if (!isDeepEqual(data.p8Comments, p8Comments)) {
      setUnchanged(false);
      return;
    }

    setUnchanged(true);
  }, [
    p3Rate,
    p3Comment,
    p4Selections,
    p4Comments,
    p5Selections,
    p5Comments,
    p6Selections,
    p6Comments,
    p7Rate,
    p7Comment,
    p8Selections,
    p8Comments, page2Data
  ]);
  
  const checkFilled = () => {
    if(!checkRateFilled(p3Rate, p3Comment, 1, 5)) { setFilled(false); return; }
    if(!checkSelectionFilled(p4Selections, p4Comments, 3, 3)) {setFilled(false); return;}
    if(!checkSelectionFilled(p5Selections, p5Comments, 1, 8)) {setFilled(false); return;}
    if(!checkSelectionFilled(p6Selections, p6Comments, 1, 7)) {setFilled(false); return;}
    if(!checkRateFilled(p7Rate, p7Comment, 1, 5)) { setFilled(false); return; }
    if(!checkSelectionFilled(p8Selections, p8Comments, 1, 6)) {setFilled(false); return;}

    setFilled(true);
  }
  
  useEffect(() => {
    checkFilled();
  });

  const navigate = (msg) => {
    window.removeEventListener('beforeunload',handleBeforeUnload);
    if(!msg) msg = 'Proceeding..';
    setOkMessage(msg);
    setTimeout(()=> {
      window.location.href = '/survey?issue=1&page=3';
    }, 1000);
  }


  const updateP4Answer = (selection, add) => {
    if(add) {
      let newSelections = [...p4Selections];
      newSelections.push(selection);
      setP4Selections(newSelections);
      let newComments = [...p4Comments];
      let data = page2Data?JSON.parse(page2Data):[];
      const index = data.p4Selections?data.p4Selections.indexOf(selection):-1;
      newComments.push(index>=0?data.p4Comments[index]:'');
      setP4Comments(newComments);
    } else {
      let index = p4Selections.indexOf(selection);
      if(index>=0) { // sanity check        
        let newSelections = [...p4Selections];
        newSelections.splice(index,1);
        setP4Selections(newSelections);
        let newComments = [...p4Comments];
        newComments.splice(index,1);
        setP4Comments(newComments);
      }
    }
  }

  const updateP4Comment = (answerIndex, comment) => {
    let newComments = [...p4Comments];
    newComments[answerIndex]=comment;
    setP4Comments(newComments);
  }

  const updateP4Other = (otherText, answerIndex) => {
    let newSelections = [...p4Selections];
    newSelections[answerIndex]=otherText;
    setP4Selections(newSelections);
  }

  
  const updateP5Answer = (selection, add) => {
    if(add) {
      let newSelections = [...p5Selections];
      newSelections.push(selection);
      setP5Selections(newSelections);
      let newComments = [...p5Comments];
      let data = page2Data?JSON.parse(page2Data):[];
      const index = data.p5Selections?data.p5Selections.indexOf(selection):-1;
      newComments.push(index>=0?data.p5Comments[index]:'');
      setP5Comments(newComments);
    } else {
      let index = p5Selections.indexOf(selection);
      if(index>=0) { // sanity check        
        let newSelections = [...p5Selections];
        newSelections.splice(index,1);
        setP5Selections(newSelections);
        let newComments = [...p5Comments];
        newComments.splice(index,1);
        setP5Comments(newComments);
      }
    }
  }

  const updateP5Comment = (answerIndex, comment) => {
    let newComments = [...p5Comments];
    newComments[answerIndex]=comment;
    setP5Comments(newComments);
  }

  const updateP5Other = (otherText, answerIndex) => {
    let newSelections = [...p5Selections];
    newSelections[answerIndex]=otherText;
    setP5Selections(newSelections);
  }

  const updateP6Answer = (selection, add) => {
    if(add) {
      let newSelections = [...p6Selections];
      newSelections.push(selection);
      setP6Selections(newSelections);
      let newComments = [...p6Comments];
      let data = page2Data?JSON.parse(page2Data):[];
      const index = data.p6Selections?data.p6Selections.indexOf(selection):-1;
      newComments.push(index>=0?data.p6Comments[index]:'');
      setP6Comments(newComments);
    } else {
      let index = p6Selections.indexOf(selection);
      if(index>=0) { // sanity check        
        let newSelections = [...p6Selections];
        newSelections.splice(index,1);
        setP6Selections(newSelections);
        let newComments = [...p6Comments];
        newComments.splice(index,1);
        setP6Comments(newComments);
      }
    }
  }

  const updateP6Comment = (answerIndex, comment) => {
    let newComments = [...p6Comments];
    newComments[answerIndex]=comment;
    setP6Comments(newComments);
  }

  const updateP6Other = (otherText, answerIndex) => {
    let newSelections = [...p6Selections];
    newSelections[answerIndex]=otherText;
    setP6Selections(newSelections);
  }

  const updateP8Answer = (selection, add) => {
    if(add) {
      let newSelections = [...p8Selections];
      newSelections.push(selection);
      setP8Selections(newSelections);
      let newComments = [...p8Comments];
      let data = page2Data?JSON.parse(page2Data):[];
      const index = data.p8Selections?data.p8Selections.indexOf(selection):-1;
      newComments.push(index>=0?data.p8Comments[index]:'');
      setP8Comments(newComments);
    } else {
      let index = p8Selections.indexOf(selection);
      if(index>=0) { // sanity check        
        let newSelections = [...p8Selections];
        newSelections.splice(index,1);
        setP8Selections(newSelections);
        let newComments = [...p8Comments];
        newComments.splice(index,1);
        setP8Comments(newComments);
      }
    }
  }

  const updateP8Comment = (answerIndex, comment) => {
    let newComments = [...p8Comments];
    newComments[answerIndex]=comment;
    setP8Comments(newComments);
  }

  const updateP8Other = (otherText, answerIndex) => {
    let newSelections = [...p8Selections];
    newSelections[answerIndex]=otherText;
    setP8Selections(newSelections);
  }

  let admin = JSON.parse(props.user).admin;
  let isAdmin = admin&&admin==="1";

  const handleSubmit = (event) => {
    event.preventDefault();
    
    if((filled&&unchanged)||isAdmin) { navigate(); return; }
    
    if(!checkRateFilled(
      p3Rate,
      p3Comment,
      1, 
      5, 
      'Please answer problem 1.',
      'Please provide your reasoning for problem 1.',
      setErrorMessage
    )) return;   
    if(!checkSelectionFilled(
      p4Selections,
      p4Comments,
      3,
      3,
      "Please select three answer for problem 2.",
      "Please provide your reasoning for each selected answer in problem 2.",
      setErrorMessage,
      "Please specify the other field in problem 2."
    )) return;
    if(!checkSelectionFilled(
      p5Selections,
      p5Comments,
      1,
      8,
      "Please select some answer for problem 3.",
      "Please provide your reasoning for each selected answer in problem 3.",
      setErrorMessage,
      "Please specify the other field in problem 3."
    )) return;
    if(!checkSelectionFilled(
      p6Selections,
      p6Comments,
      1,
      7,
      "Please select some answer for problem 4.",
      "Please provide your reasoning for each selected answer in problem 4.",
      setErrorMessage,
      "Please specify the other field in problem 4."
    )) return;
    if(!checkRateFilled(
      p7Rate,
      p7Comment,
      1, 
      5, 
      'Please answer problem 5.',
      'Please provide your reasoning for problem 5.',
      setErrorMessage
    )) return;
    if(!checkSelectionFilled(
      p8Selections,
      p8Comments,
      1,
      6,
      "Please select some answer for problem 6.",
      "Please provide your reasoning for each selected answer in problem 6.",
      setErrorMessage,
      "Please specify the other field in problem 6."
    )) return;

    console.log("check:"+checkSelectionFilled(
      p8Selections,
      p8Comments,
      1,
      6,
      "Please select some answer for problem 6.",
      "Please provide your reasoning for each selected answer in problem 6.",
      setErrorMessage,
      "Please specify the other field in problem 6."
    ));

    if(!filled) return;

    let user_id = JSON.parse(props.user).user_id;
    let voteData = [];
    voteData.push({user_id, problem_id:3, vote:p3Rate, comment: p3Comment});
    p4Selections.forEach((selection,index)=>{
      voteData.push({user_id, problem_id:4, vote:selection, comment: p4Comments[index]});
    });
    p5Selections.forEach((selection,index)=>{
      voteData.push({user_id, problem_id:5, vote:selection, comment: p5Comments[index]});
    });
    p6Selections.forEach((selection,index)=>{
      voteData.push({user_id, problem_id:6, vote:selection, comment: p6Comments[index]});
    });
    voteData.push({user_id, problem_id:7, vote:p7Rate, comment: p7Comment});
    p8Selections.forEach((selection,index)=>{
      voteData.push({user_id, problem_id:8, vote:selection, comment: p8Comments[index]});
    });

    axios.post( backendServer+'/vote.php', {vote_data: JSON.stringify(voteData)})
    .then( (response)=>{
      console.log(response.data);
      if(response.data.status==='ok') {
        //fetchData();
        //updatePage2Data();
        setFilled(true);
        setFirstRound(false);
        //setOkMessage("Information Saved.");
        navigate('Responses saved.');
      } else {
        setErrorMessage(response.data.message);
      }
    })
    .catch((error)=>{
      setErrorMessage(error.message);
    });
  }
  
  return (
    <form onSubmit={handleSubmit}>
      <table width="600" align="center" cellPadding={8}>
        <tbody>
          <tr>
            <td>
              <h4>1.1 Energy Efficiency</h4>

              <p>
                Energy efficiency refers to the process of using less energy to
                perform the same task or achieve the same outcome. It involves
                adopting technologies, practices, and measures that reduce
                energy waste while maintaining or enhancing productivity and
                comfort.
              </p>

              <p>
                The APEC Expert Group on Energy Efficiency and Conservation
                (EGEEC) was established by the APEC Energy Working Group (EWG)
                in 1993 to assist in achieving energy security, advance economic
                and social well-being, and realize environmental benefits in the
                Asia-Pacific region through energy conservation and the
                application of energy-efficiency practices and technologies.
              </p>
              <hr />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={3}
                firstRound={firstRound}
                rate={p3Rate}
                setRate={setP3Rate}
                comment={p3Comment}
                setComment={setP3Comment}
                text="1. Based on your justification, do you agree that the energy efficiency policies within APEC are effectively working towards achieving net-zero emissions?"
                results={p3Results}
                choices={[
                  "Strongly disagree",
                  "Disagree",
                  "Neutral",
                  "Agree",
                  "Strong agree",
                ]}
                adminMode={isAdmin}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <SelectItem
                problem_id={4}
                firstRound={firstRound}
                max={3}
                updateAnswer={updateP4Answer}
                updateComment={updateP4Comment}
                selections={p4Selections}
                comments={p4Comments}
                text="2. On the sectors listed below, please select three sectors based on their prominence of energy efficiency policy  towards achieving net-zero emissions."
                choices={[
                  "Energy generation",
                  "Industrial process",
                  "Transportation",
                  "Household",
                  "Building",
                ]}
                other={true}
                updateOther={updateP4Other}
                results={p4Results}
                adminMode={isAdmin}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <SelectItem
                problem_id={5}
                firstRound={firstRound}
                max={8}
                updateAnswer={updateP5Answer}
                updateComment={updateP5Comment}
                selections={p5Selections}
                comments={p5Comments}
                text="3. Which specific energy efficiency measures within APEC demonstrate a strong commitment to advancing net-zero emissions?"
                choices={[
                  "Measures for Factory and Building Management and Control",
                  "Building Energy Code (BEC)",
                  "Energy Efficiency Resource Standard (EERS)",
                  "Energy-saving equipment labeling policy",
                  "Promoting energy conservation through supporting investment in upgrading energy-efficient machinery and equipment",
                  "Zero emission vehicles",
                  "Efficient burners",
                ]}
                other={true}
                updateOther={updateP5Other}
                results={p5Results}
                adminMode={isAdmin}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <SelectItem
                problem_id={6}
                firstRound={firstRound}
                max={7}
                updateAnswer={updateP6Answer}
                updateComment={updateP6Comment}
                selections={p6Selections}
                comments={p6Comments}
                text="4. What measures should be integrated into energy efficiency policy to accelerate progress towards achieving net-zero emissions?"
                choices={[
                  "Data analytics and machine learning to optimize energy consumption patterns",
                  "Smart heating and cooling systems",
                  "Building insulation",
                  "Energy-efficient lighting",
                  "Public transport",
                  "Electric vehicles",
                ]}
                other={true}
                updateOther={updateP6Other}
                results={p6Results}
                adminMode={isAdmin}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={7}
                firstRound={firstRound}
                rate={p7Rate}
                setRate={setP7Rate}
                comment={p7Comment}
                setComment={setP7Comment}
                text="5. Based on your justification, please indicate the level of urgency for investment in implementing those existing energy efficiency initiatives within APEC towards achieving net-zero emissions."
                results={p7Results}
                choices={[
                  "Least urgent",
                  "Low urgency",
                  "Moderate urgency",
                  "High urgency",
                  "Most urgent",
                ]}
                adminMode={isAdmin}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <SelectItem
                problem_id={8}
                firstRound={firstRound}
                max={6}
                updateAnswer={updateP8Answer}
                updateComment={updateP8Comment}
                selections={p8Selections}
                comments={p8Comments}
                text="6. Please identify the collaboration among APEC economies regarding energy efficiency are driving progress towards net-zero emissions."
                choices={[
                  "Technology transfer",
                  "Capacity building",
                  "APEC Energy Efficiency Standards",
                  "Cross-Border Energy Trade",
                  "Knowledge sharing",
                ]}
                other={true}
                updateOther={updateP8Other}
                results={p8Results}
                adminMode={isAdmin}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td align="right">
              <Message color={messageColor} message={message} />
              <br />
              <NextButtonPanel disabled={unchanged} onClick={resetValues} />
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );
};

export default Issue1page2;