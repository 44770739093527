// components/FooterStyles.js

import styled from "styled-components";

export const Box = styled.div`
	padding-top: 1px;
	padding-left: 2.5%;
	padding-right: 1.5%;
	padding-bottom: 10px;
	background: #1f3044;
	//position: absolute;
	bottom: 0;
	// width: 95%;
	left: 0;
	right: 0;

	@media (max-width: 1000px) {
		// padding: 70px 30px;
	}
`;

// export const FooterContainer = styled.div`
// 	display: flex;
// 	flex-direction: column;
// 	justify-content: center;
// 	max-width: 1000px;
// 	margin: 0 auto;
// 	/* background: red; */
// `;

export const Column = styled.div`
	display: flex;
	flex-direction: column;
	text-align: left;
	margin-left: 60px;
`;

export const Row = styled.div`
	display: grid;
	grid-template-columns: repeat(
		auto-fill,
		minmax(185px, 1fr)
	);
	grid-gap: 20px;

	@media (max-width: 1000px) {
		grid-template-columns: repeat(
			auto-fill,
			minmax(200px, 1fr)
		);
	}
`;

export const FooterLink = styled.a`
	color: #fff;
	margin-bottom: 1px;
	font-size: 16px;
	text-decoration: none;

	&:hover {
		color: green;
		transition: 200ms ease-in;
	}
`;

export const FooterText = styled.p`
color: #fff;
`;


export const Heading = styled.p`
	font-size: 18px;
	color: #fff;
	margin-bottom: 10px;
	font-weight: bold;
`;
