import './Survey.css';
import { useState } from 'react';
import Select from 'react-select'
import { nonAECCountries } from '../../Const';
import Comments from './Comments';

const SelectOneItem = (props) => {
  const [showResult, setShowResult] = useState(false);

  const toggleResults = (e) => {
    setShowResult(!showResult);
  }

  const handleTextChange = (e) => {
    props.setComment(e.target.value);
  }

  return (
    <div style={{ background: "#f0f0f0", padding: "9px 15px" }}>
      {props.text}
      <br />
      <table align="right">
        <tbody>
          <tr>
            <td>
              {props.adminMode ? (
                <span>
                  <i>Admin Mode</i>
                </span>
              ) : (
                <>
                  {props.firstRound ? (
                    <>&nbsp;</>
                  ) : (
                    <input
                      type="button"
                      value={
                        showResult ? "resume survey" : "show previous results"
                      }
                      onClick={(e) => toggleResults(e)}
                    />
                  )}
                </>
              )}
            </td>
          </tr>
        </tbody>
      </table>
      <span hidden={!showResult && !props.adminMode}>
        <table width={props.width} className="center">
          <tbody>
            <tr>
              <td>
                <Comments
                  voteWidth={140}
                  comments={props.results ? props.results.comments : []}
                  width={500}
                />
              </td>
            </tr>

            <tr>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td></td>
            </tr>
          </tbody>
        </table>
      </span>
      <span hidden={showResult || props.adminMode}>
        <table cellPadding={10}>
          <tbody>
            <tr>
              <td colSpan={5}>
                <Select
                  isSearchable={true}
                  onChange={(val) => props.setAnswer(val)}
                  value={props.answer}
                  placeholder={props.placeholder}
                  options={nonAECCountries.map((c) => ({
                    value: c,
                    label: c,
                  }))}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={5}>
                <i>Please provide your reasoning</i> <br />
                <center>
                  <textarea
                    type="textarea"
                    rows={4}
                    cols={80}
                    size={500}
                    onChange={(e) => handleTextChange(e)}
                    value={props.comment}
                  ></textarea>
                </center>
              </td>
            </tr>
          </tbody>
        </table>
      </span>
    </div>
  );
};

export default SelectOneItem;