import { useState, useEffect } from 'react';
import axios from 'axios';
import { backendServer, allEconomies, nonAECCountries } from '../../Const';
import RateItem from "./RateItem";
import SelectItem from "./SelectItem";
import SelectOneItem from './SelectOneItem';
import Message from './Message';


import {isDeepEqual, readSelectOneAnswer, readRateAnswer, readSelectionAnswers, checkRateFilled, checkSelectionFilled, checkSelectOneFilled, handleBeforeUnload} from '../../SurveyUtils';
import NextButtonPanel from '../../components/NextButtonPanel';

const Issue5page2 = (props) => {
  const [pageData, setPageData] = useState('');
  const [firstRound, setFirstRound] = useState(true);
  
  const [p176Selections, setP176Selections] = useState([]);
  const [p176Comments, setP176Comments] = useState([]);
  const [p176Results, setP176Results] = useState('');
  const [p177Selections, setP177Selections] = useState([]);
  const [p177Comments, setP177Comments] = useState([]);
  const [p177Results, setP177Results] = useState('');
  const [p178Selections, setP178Selections] = useState([]);
  const [p178Comments, setP178Comments] = useState([]);
  const [p178Results, setP178Results] = useState('');
  const [p179Answer, setP179Answer] = useState('');
  const [p179Comment, setP179Comment] = useState('');
  const [p179Results, setP179Results] = useState('');
  const [p180Rate, setP180Rate] = useState(0);
  const [p180Comment, setP180Comment] = useState('');
  const [p180Results, setP180Results] = useState('');
  
  const [filled, setFilled] = useState(false);
  const [unchanged, setUnchanged] = useState(true);
  const [message, setMessage] = useState('');
  const [messageColor, setMessageColor] = useState('');

  useEffect( () => {
    if(unchanged) return;
    
    window.addEventListener('beforeunload',handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload',handleBeforeUnload)
    }
    
  },[unchanged]);

  const setErrorMessage = (msg) => {
    setMessageColor("red");
    setMessage(msg);
  }

  const setOkMessage = (msg) => {
    setMessageColor("teal");
    setMessage(msg);
  }


  const resetValues = () => {
    const data = pageData?JSON.parse(pageData):[];
    
    setP176Selections(data.p176Selections?data.p176Selections:[]);
    setP176Comments(data.p176Comments?data.p176Comments:[]);
    setP177Selections(data.p177Selections?data.p177Selections:[]);
    setP177Comments(data.p177Comments?data.p177Comments:[]);
    setP178Selections(data.p178Selections?data.p178Selections:[]);
    setP178Comments(data.p178Comments?data.p178Comments:[]);
    setP179Answer(data.p179Answer?data.p179Answer:'');
    setP179Comment(data.p179Comment?data.p179Comment:'');
    setP180Rate(data.p180Rate?data.p180Rate:0);
    setP180Comment(data.p180Comment?data.p180Comment:'');

    setUnchanged(true);
  }

  const fetchData = async () => {
    axios
      .post(backendServer + "/get_votes.php", {
        user_id: JSON.parse(props.user).user_id,
        problem_ids: ["176", "177", "178", "179", "180"]
      })
      .then((response) => {
        if (response.data.status === "ok") {
          let answers = JSON.parse(response.data.answers);
          let results = JSON.parse(response.data.results);
          let data = {};

          if(answers['176']) {
            setFirstRound(false);
          }

          [data['p176Selections'], data['p176Comments']] = readSelectionAnswers(answers['176']);
          [data['p177Selections'], data['p177Comments']] = readSelectionAnswers(answers['177']);
          [data['p178Selections'], data['p178Comments']] = readSelectionAnswers(answers['178']);
          [data['p179Answer'], data['p179Comment']] = readSelectOneAnswer(answers['179']);
          [data['p180Rate'], data['p180Comment']] = readRateAnswer(answers['180']);

          setPageData(JSON.stringify(data));

          setP176Results(results["176"]);
          setP177Results(results["177"]);
          setP178Results(results["178"]);
          setP179Results(results["179"]);
          setP180Results(results["180"]);
          
          checkFilled();
          setUnchanged(true);
        } else {
          setErrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  }
  
  useEffect(()=>{
    fetchData();   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    resetValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pageData]);

  useEffect(() => {
    setMessage('');
    let data = pageData?JSON.parse(pageData):[];

    if (!isDeepEqual(data.p176Selections, p176Selections)) 
      { setUnchanged(false); return; }
    if (!isDeepEqual(data.p176Comments, p176Comments)) 
      { setUnchanged(false); return; }
      if (!isDeepEqual(data.p177Selections, p177Selections)) 
      { setUnchanged(false); return; }
    if (!isDeepEqual(data.p177Comments, p177Comments)) 
      { setUnchanged(false); return; }
    if (!isDeepEqual(data.p178Selections, p178Selections)) 
      { setUnchanged(false); return; }
    if (!isDeepEqual(data.p178Comments, p178Comments)) 
      { setUnchanged(false); return; }
    if((!data.p179Answer)&&(p179Answer)) { setUnchanged(false); return; }
    if((data.p179Answer)&&(!p179Answer)) { setUnchanged(false); return; }
    if (data.p179Answer&&p179Answer&&(data.p179Answer.value !== p179Answer.value)) { setUnchanged(false); return; }
    if (data.p179Comment !== p179Comment) { setUnchanged(false); return; }
    if (Number(data.p180Rate) !== Number(p180Rate)) { setUnchanged(false); return; }
    if (data.p180Comment !== p180Comment) { setUnchanged(false); return; }
      
    setUnchanged(true);
  }, [    
    p176Selections, p176Comments,
    p177Selections, p177Comments,
    p178Selections, p178Comments,
    p179Answer, p179Comment,
    p180Rate, p180Comment, pageData
  ]);

  const checkFilled = () => {
    if(!checkSelectionFilled(p176Selections, p176Comments, 1, 1)) {setFilled(false); return;}
    if(!checkSelectionFilled(p177Selections, p177Comments, 1, 1)) {setFilled(false); return;}
    if(!checkSelectionFilled(p178Selections, p178Comments, 1, 1)) {setFilled(false); return;}
    if(!checkSelectOneFilled(p179Answer, p179Comment)) {setFilled(false); return;}
    if(!checkRateFilled(p180Rate, p180Comment, 1, 5)) {setFilled(false); return;}

    setFilled(true);
  }

  const navigate = (msg) => {
    window.removeEventListener('beforeunload',handleBeforeUnload); 
    if(!msg) msg = 'Proceeding..';
    setOkMessage(msg);
    setTimeout(()=> {
      window.location.href = '/survey?issue=6&last_issue=5';
    }, 1000);
  }
  
  useEffect(() => {   
    checkFilled();
  });
   
  let admin = JSON.parse(props.user).admin;
  let isAdmin = admin&&admin==="1";
  
  const handleSubmit = (event) => {
    event.preventDefault();
    if((filled&&unchanged)||isAdmin) { navigate(); return; }
    
    if(!checkSelectionFilled(p176Selections, p176Comments, 1, 1,
      "Please select an answer for problem 1.",
      "Please provide your reasoning for each selected answer in problem 1.",
      setErrorMessage
    )) return;
    if(!checkSelectionFilled(p177Selections, p177Comments, 1, 1,
      "Please select an answer for problem 2.",
      "Please provide your reasoning for each selected answer in problem 2.",
      setErrorMessage,
      "Please specify the other field in problem 2."
    )) return;
    if(!checkSelectionFilled(p178Selections, p178Comments, 1, 1,
      "Please select an answer for problem 3.",
      "Please provide your reasoning for each selected answer in problem 3.",
      setErrorMessage
    )) return;
    if(!checkSelectOneFilled(p179Answer, p179Comment,
      "Please answer problem 4.",
      "Please provide your reasoning for problem 4.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p180Rate, p180Comment, 1, 5,
      "Please answer problem 5.",
      "Please provide your reasoning for problem 5.",
      setErrorMessage
    )) return;

    let user_id = JSON.parse(props.user).user_id;
    let voteData = [];
    p176Selections.forEach((selection,index)=>{
      voteData.push({user_id, problem_id:176, vote:selection, comment: p176Comments[index]});
    });
    p177Selections.forEach((selection,index)=>{
      voteData.push({user_id, problem_id:177, vote:selection, comment: p177Comments[index]});
    });
    p178Selections.forEach((selection,index)=>{
      voteData.push({user_id, problem_id:178, vote:selection, comment: p178Comments[index]});
    });
    voteData.push({user_id, problem_id:179, vote:p179Answer.label, comment: p179Comment});
    voteData.push({user_id, problem_id:180, vote:p180Rate, comment: p180Comment});

    axios.post( backendServer+'/vote.php', {vote_data: JSON.stringify(voteData)})
    .then( (response)=>{
      if(response.data.status==='ok') {
        //updatePageData();
        //fetchData();
        setFilled(true);
        setFirstRound(false);
        //setOkMessage("Responses saved.");
        navigate('Responses saved.');
      } else {
        setErrorMessage(response.data.message);
      }
    })
    .catch((error)=>{
      setErrorMessage(error.message);
    });
  }
  
  
  const updateP176Answer = (selection, add) => {
    if(add) {
      let newSelections = [...p176Selections];
      newSelections.push(selection);
      setP176Selections(newSelections);
      let newComments = [...p176Comments];
      let data = pageData?JSON.parse(pageData):[];
      const index = data.p176Selections?data.p176Selections.indexOf(selection):-1;
      newComments.push(index>=0?data.p176Comments[index]:'');
      setP176Comments(newComments);
    } else {
      let index = p176Selections.indexOf(selection);
      if(index>=0) { // sanity check        
        let newSelections = [...p176Selections];
        newSelections.splice(index,1);
        setP176Selections(newSelections);
        let newComments = [...p176Comments];
        newComments.splice(index,1);
        setP176Comments(newComments);
      }
    }
  }

  const updateP176Comment = (answerIndex, comment) => {
    let newComments = [...p176Comments];
    newComments[answerIndex]=comment;
    setP176Comments(newComments);
  }
  
  const updateP177Answer = (selection, add) => {
    if(add) {
      let newSelections = [...p177Selections];
      newSelections.push(selection);
      setP177Selections(newSelections);
      let newComments = [...p177Comments];
      let data = pageData?JSON.parse(pageData):[];
      const index = data.p177Selections?data.p177Selections.indexOf(selection):-1;
      newComments.push(index>=0?data.p177Comments[index]:'');
      setP177Comments(newComments);
    } else {
      let index = p177Selections.indexOf(selection);
      if(index>=0) { // sanity check        
        let newSelections = [...p177Selections];
        newSelections.splice(index,1);
        setP177Selections(newSelections);
        let newComments = [...p177Comments];
        newComments.splice(index,1);
        setP177Comments(newComments);
      }
    }
  }

  const updateP177Comment = (answerIndex, comment) => {
    let newComments = [...p177Comments];
    newComments[answerIndex]=comment;
    setP177Comments(newComments);
  }

  const updateP177Other = (otherText, answerIndex) => {
    let newSelections = [...p177Selections];
    newSelections[answerIndex]=otherText;
    setP177Selections(newSelections);
  }

  const updateP178Answer = (selection, add) => {
    if(add) {
      let newSelections = [...p178Selections];
      newSelections.push(selection);
      setP178Selections(newSelections);
      let newComments = [...p178Comments];
      let data = pageData?JSON.parse(pageData):[];
      const index = data.p178Selections?data.p178Selections.indexOf(selection):-1;
      newComments.push(index>=0?data.p178Comments[index]:'');
      setP178Comments(newComments);
    } else {
      let index = p178Selections.indexOf(selection);
      if(index>=0) { // sanity check        
        let newSelections = [...p178Selections];
        newSelections.splice(index,1);
        setP178Selections(newSelections);
        let newComments = [...p178Comments];
        newComments.splice(index,1);
        setP178Comments(newComments);
      }
    }
  }

  const updateP178Comment = (answerIndex, comment) => {
    let newComments = [...p178Comments];
    newComments[answerIndex]=comment;
    setP178Comments(newComments);
  }

  return (
    <form onSubmit={handleSubmit}>
      <table width="600" align="center" cellPadding={8}>
        <tbody>
          <tr>
            <td>
              <h4>5.2 Cross Border Adjustment Mechanism (CBAM)</h4>
              <p>The EU has policies and guidelines to reduce Greenhouse Gas (GHG) emissions through a tax mechanism called “the Cross Border Adjustment Mechanism (CBAM)” by controlling the pricing of some imported products to prevent the import of high GHG emissions into the EU. Non-EU enterprises must improve their production processes to be environmentally friendly. This can cause disruption in the global supply chain, especially for industries that rely on the import or export of products with high GHG emission. Each economy must have feasible policies to maintain competitiveness in international markets. If the products imported into the EU have GHG emissions that are higher than the specified value, importers will have to offset GHG by purchasing CBAM Certificates as proof of payment according to EU standards, resulting in higher prices of products. Consumers within the EU may turn to EU-made products more due to lower prices. The GHG emissions of imported goods (Embedded Emission) are calculated from direct GHG emissions generated from the production process and indirect GHG emissions generated from electricity used in the manufacturing process of that product.</p>
              <hr />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <SelectItem
                problem_id={176}
                firstRound={firstRound}
                adminMode={isAdmin}
                max={1}
                updateAnswer={updateP176Answer}
                updateComment={updateP176Comment}
                selections={p176Selections}
                comments={p176Comments}
                text="1. At what level would CBAM affect APEC economies when it comes to full implementation?"
                choices={["The export of most products entirely stops.", "The export of some products entirely stops.", "Most product exports diminish significantly.", "Some product exports diminish significantly.", "Most product exports decrease slightly.", "Some product exports decrease slightly.", "Most product exports return to normal."]}
                other={false}
                results={p176Results}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <SelectItem
                problem_id={177}
                firstRound={firstRound}
                adminMode={isAdmin}
                max={1}
                updateAnswer={updateP177Answer}
                updateComment={updateP177Comment}
                selections={p177Selections}
                comments={p177Comments}
                text="2. Based on your justification, which industry would be most affected by CBAM? (Select one)"
                choices={["Cement", "Fertilizers", "Petroleum", "Petrochemicals", "Adipic Acid", "Iron and Steel", "Aluminum", "Hydrogen", "Glass", "Pulp and Paper", "Ethanol", "Electricity"]}
                other={true}
                updateOther={updateP177Other}
                results={p177Results}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <SelectItem
                problem_id={178}
                firstRound={firstRound}
                adminMode={isAdmin}
                max={1}
                updateAnswer={updateP178Answer}
                updateComment={updateP178Comment}
                selections={p178Selections}
                comments={p178Comments}
                text="3. Based on your justification, which APEC economy has the most effective policies in response to CBAM?"
                choices={allEconomies}
                results={p178Results}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <SelectOneItem
                placeholder="Select a country"
                problem_id={179}
                firstRound={firstRound}
                adminMode={isAdmin}
                answer={p179Answer}
                setAnswer={setP179Answer}
                comment={p179Comment}
                setComment={setP179Comment}
                text="4.	Based on your justification, which country outside APEC has the most effective policies in response to CBAM? "
                commentText="Please specify which policy(s) and/or good practice(s)."
                choices={nonAECCountries}
                results={p179Results}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={180}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p180Rate}
                setRate={setP180Rate}
                comment={p180Comment}
                setComment={setP180Comment}
                text="5. Based on your justification, what is the likelihood that CBAM will lead to global carbon market?"
                results={p180Results}
                choices={["Least likely", "Somehow", "Moderately", "Very", "Most likely"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td align="right">
            <Message color={messageColor} message={message} />
              <br />
              <NextButtonPanel
                disabled={unchanged}
                onClick={resetValues}
                isEnd={true}
                issue={5}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );
};

export default Issue5page2;