import { useState, useEffect } from 'react';
import axios from 'axios';
import { backendServer, allEconomies } from '../../Const';
import RateItem from "./RateItem";
import SelectItem from "./SelectItem";
import Message from './Message';

import {isDeepEqual, readRateAnswer, readSelectionAnswers, checkRateFilled, checkSelectionFilled, handleBeforeUnload} from '../../SurveyUtils';
import NextButtonPanel from '../../components/NextButtonPanel';

const Issue4page1 = (props) => {
  const [pageData, setPageData] = useState('');
  const [firstRound, setFirstRound] = useState(true);
  
  const [p98Selections, setP98Selections] = useState([]);
  const [p98Comments, setP98Comments] = useState([]);
  const [p98Results, setP98Results] = useState('');
  const [p99Selections, setP99Selections] = useState([]);
  const [p99Comments, setP99Comments] = useState([]);
  const [p99Results, setP99Results] = useState('');
  const [p100Rate, setP100Rate] = useState(0);
  const [p100Comment, setP100Comment] = useState('');
  const [p100Results, setP100Results] = useState('');
  const [p101Rate, setP101Rate] = useState(0);
  const [p101Comment, setP101Comment] = useState('');
  const [p101Results, setP101Results] = useState('');
  const [p102Rate, setP102Rate] = useState(0);
  const [p102Comment, setP102Comment] = useState('');
  const [p102Results, setP102Results] = useState('');
  const [p103Rate, setP103Rate] = useState(0);
  const [p103Comment, setP103Comment] = useState('');
  const [p103Results, setP103Results] = useState('');
  const [p104Rate, setP104Rate] = useState(0);
  const [p104Comment, setP104Comment] = useState('');
  const [p104Results, setP104Results] = useState('');
  const [p105Rate, setP105Rate] = useState(0);
  const [p105Comment, setP105Comment] = useState('');
  const [p105Results, setP105Results] = useState('');
  const [p106Rate, setP106Rate] = useState(0);
  const [p106Comment, setP106Comment] = useState('');
  const [p106Results, setP106Results] = useState('');
  const [p107Rate, setP107Rate] = useState(0);
  const [p107Comment, setP107Comment] = useState('');
  const [p107Results, setP107Results] = useState('');
  const [p108Rate, setP108Rate] = useState(0);
  const [p108Comment, setP108Comment] = useState('');
  const [p108Results, setP108Results] = useState('');
  const [p108Other, setP108Other] = useState('');

  const [filled, setFilled] = useState(false);
  const [unchanged, setUnchanged] = useState(true);
  const [message, setMessage] = useState('');
  const [messageColor, setMessageColor] = useState('');

  useEffect( () => {
    if(unchanged) return;
    
    window.addEventListener('beforeunload',handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload',handleBeforeUnload)
    }
    
  },[unchanged]);

  const setErrorMessage = (msg) => {
    setMessageColor("red");
    setMessage(msg);
  }

  const setOkMessage = (msg) => {
    setMessageColor("teal");
    setMessage(msg);
  }

  const resetValues = () => {
    const data = pageData?JSON.parse(pageData):[];
    
    setP98Selections(data.p98Selections?data.p98Selections:[]);
    setP98Comments(data.p98Comments?data.p98Comments:[]);
    setP99Selections(data.p99Selections?data.p99Selections:[]);
    setP99Comments(data.p99Comments?data.p99Comments:[]);
    setP100Rate(data.p100Rate?data.p100Rate:0);
    setP100Comment(data.p100Comment?data.p100Comment:'');
    setP101Rate(data.p101Rate?data.p101Rate:0);
    setP101Comment(data.p101Comment?data.p101Comment:'');
    setP102Rate(data.p102Rate?data.p102Rate:0);
    setP102Comment(data.p102Comment?data.p102Comment:'');
    setP103Rate(data.p103Rate?data.p103Rate:0);
    setP103Comment(data.p103Comment?data.p103Comment:'');
    setP104Rate(data.p104Rate?data.p104Rate:0);
    setP104Comment(data.p104Comment?data.p104Comment:'');
    setP105Rate(data.p105Rate?data.p105Rate:0);
    setP105Comment(data.p105Comment?data.p105Comment:'');
    setP106Rate(data.p106Rate?data.p106Rate:0);
    setP106Comment(data.p106Comment?data.p106Comment:'');
    setP107Rate(data.p107Rate?data.p107Rate:0);
    setP107Comment(data.p107Comment?data.p107Comment:'');
    setP108Rate(data.p108Rate?data.p108Rate:0);
    setP108Comment(data.p108Comment?data.p108Comment:'');
    setP108Other(data.p108Other?data.p108Other:'');

    setUnchanged(true);
  }
  
  const fetchData = async () => {
    axios
      .post(backendServer + "/get_votes.php", {
        user_id: JSON.parse(props.user).user_id,
        problem_ids: ["98", "99", "100", "101", "102", "103", "104", "105", "106", "107", "108"]
      })
      .then((response) => {
        if (response.data.status === "ok") {
          let answers = JSON.parse(response.data.answers);
          let results = JSON.parse(response.data.results);
          let data = {};

          if(answers['98']) {
            setFirstRound(false);
          }
          
          [data['p98Selections'], data['p98Comments']] = readSelectionAnswers(answers['98']);
          [data['p99Selections'], data['p99Comments']] = readSelectionAnswers(answers['99']);
          [data['p100Rate'], data['p100Comment']] = readRateAnswer(answers['100']);
          [data['p101Rate'], data['p101Comment']] = readRateAnswer(answers['101']);
          [data['p102Rate'], data['p102Comment']] = readRateAnswer(answers['102']);
          [data['p103Rate'], data['p103Comment']] = readRateAnswer(answers['103']);
          [data['p104Rate'], data['p104Comment']] = readRateAnswer(answers['104']);
          [data['p105Rate'], data['p105Comment']] = readRateAnswer(answers['105']);
          [data['p106Rate'], data['p106Comment']] = readRateAnswer(answers['106']);
          [data['p107Rate'], data['p107Comment']] = readRateAnswer(answers['107']);
          [data['p108Rate'], data['p108Comment']] = readRateAnswer(answers['108']);
          if(answers['108']&&answers['108'].other) data['p108Other'] = answers['108'].other;
          else data['p108Other']='';

          setPageData(JSON.stringify(data));

          setP98Results(results["98"]);
          setP99Results(results["99"]);
          setP100Results(results["100"]);
          setP101Results(results["101"]);
          setP102Results(results["102"]);
          setP103Results(results["103"]);
          setP104Results(results["104"]);
          setP105Results(results["105"]);
          setP106Results(results["106"]);
          setP107Results(results["107"]);
          setP108Results(results["108"]);
          
          checkFilled();
          setUnchanged(true);
        } else {
          setErrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  }
  
  useEffect(()=>{
    fetchData(); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    resetValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pageData]);

  useEffect(() => {
    setMessage('');
    let data = pageData?JSON.parse(pageData):[];

    if (!isDeepEqual(data.p98Selections, p98Selections)) 
      { setUnchanged(false); return; }
    if (!isDeepEqual(data.p98Comments, p98Comments)) 
      { setUnchanged(false); return; }
    if (!isDeepEqual(data.p99Selections, p99Selections)) 
      { setUnchanged(false); return; }
    if (!isDeepEqual(data.p99Comments, p99Comments)) 
      { setUnchanged(false); return; }
    if (Number(data.p100Rate) !== Number(p100Rate)) { setUnchanged(false); return; }
    if (data.p100Comment !== p100Comment) { setUnchanged(false); return; }
    if (Number(data.p101Rate) !== Number(p101Rate)) { setUnchanged(false); return; }
    if (data.p101Comment !== p101Comment) { setUnchanged(false); return; }
    if (Number(data.p102Rate) !== Number(p102Rate)) { setUnchanged(false); return; }
    if (data.p102Comment !== p102Comment) { setUnchanged(false); return; }
    if (Number(data.p103Rate) !== Number(p103Rate)) { setUnchanged(false); return; }
    if (data.p103Comment !== p103Comment) { setUnchanged(false); return; }
    if (Number(data.p104Rate) !== Number(p104Rate)) { setUnchanged(false); return; }
    if (data.p104Comment !== p104Comment) { setUnchanged(false); return; }
    if (Number(data.p105Rate) !== Number(p105Rate)) { setUnchanged(false); return; }
    if (data.p105Comment !== p105Comment) { setUnchanged(false); return; }
    if (Number(data.p106Rate) !== Number(p106Rate)) { setUnchanged(false); return; }
    if (data.p106Comment !== p106Comment) { setUnchanged(false); return; }
    if (Number(data.p107Rate) !== Number(p107Rate)) { setUnchanged(false); return; }
    if (data.p107Comment !== p107Comment) { setUnchanged(false); return; }
    if (Number(data.p108Rate) !== Number(p108Rate)) { setUnchanged(false); return; }
    if (data.p108Comment !== p108Comment) { setUnchanged(false); return; }
    if (data.p108Other !== p108Other) { setUnchanged(false); return; }    
    
    setUnchanged(true);
  }, [    
    p98Selections, p98Comments,
    p99Selections, p99Comments,
    p100Rate, p100Comment,
    p101Rate, p101Comment,
    p102Rate, p102Comment,
    p103Rate, p103Comment,
    p104Rate, p104Comment,
    p105Rate, p105Comment,
    p106Rate, p106Comment,
    p107Rate, p107Comment,
    p108Rate, p108Comment, p108Other, pageData
  ]);

  const checkFilled = () => {
    if(!checkSelectionFilled(p98Selections, p98Comments, 3, 3)) {setFilled(false); return;}
    if(!checkSelectionFilled(p99Selections, p99Comments, 1, 4)) {setFilled(false); return;}
    if(!checkRateFilled(p100Rate, p100Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p101Rate, p101Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p102Rate, p102Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p103Rate, p103Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p104Rate, p104Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p105Rate, p105Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p106Rate, p106Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p107Rate, p107Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p108Rate, p108Comment, 1, 5)) {setFilled(false); return;}
    //if(!p108Other) {setFilled(false); return;}

    setFilled(true);
  }

  const navigate = (msg) => {
    window.removeEventListener('beforeunload',handleBeforeUnload); 
    if(!msg) msg = 'Proceeding..';
    setOkMessage(msg);
    setTimeout(()=> {
      window.location.href = '/survey?issue=4&page=2';
    }, 1000);
  }  
  
  useEffect(() => {   
    checkFilled();
  });

  let admin = JSON.parse(props.user).admin;
  let isAdmin = admin&&admin==="1";
  
  const handleSubmit = (event) => {
    event.preventDefault();
    
    if((filled&&unchanged)||isAdmin) { navigate(); return; }
    
    if(!checkSelectionFilled(p98Selections, p98Comments, 3, 3,
      "Please select three answers for problem 1.",
      "Please provide your reasoning for each selected answer in problem 1.",
      setErrorMessage
    )) return;
    if(!checkSelectionFilled(p99Selections, p99Comments, 1, 4,
      "Please select some answers for problem 2.",
      "Please provide your reasoning for each selected answer in problem 2.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p100Rate, p100Comment, 1, 5,
      "Please answer problem 3a.",
      "Please provide your reasoning for problem 3a.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p101Rate, p101Comment, 1, 5,
      "Please answer problem 3b.",
      "Please provide your reasoning for problem 3b.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p102Rate, p102Comment, 1, 5,
      "Please answer problem 3c.",
      "Please provide your reasoning for problem 3c.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p103Rate, p103Comment, 1, 5,
      "Please answer problem 3d.",
      "Please provide your reasoning for problem 3d.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p104Rate, p104Comment, 1, 5,
      "Please answer problem 3e.",
      "Please provide your reasoning for problem 3e.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p105Rate, p105Comment, 1, 5,
      "Please answer problem 3f.",
      "Please provide your reasoning for problem 3f.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p106Rate, p106Comment, 1, 5,
      "Please answer problem 3g.",
      "Please provide your reasoning for problem 3g.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p107Rate, p107Comment, 1, 5,
      "Please answer problem 3h.",
      "Please provide your reasoning for problem 3h.",
      setErrorMessage
    )) return;
    if(p108Other) {
      if(!checkRateFilled(p108Rate, p108Comment, 1, 5,
        "Please answer problem 3i.",
        "Please provide your reasoning for problem 3i.",
        setErrorMessage
      )) return;
    }
    // if(!p108Other) {
    //   setErrorMessage("Please specify the other field in problem 3i.");
    //   return;
    // }

    let user_id = JSON.parse(props.user).user_id;
    let voteData = [];
    p98Selections.forEach((selection,index)=>{
      voteData.push({user_id, problem_id:98, vote:selection, comment: p98Comments[index]});
    });
    p99Selections.forEach((selection,index)=>{
      voteData.push({user_id, problem_id:99, vote:selection, comment: p99Comments[index]});
    });
    voteData.push({user_id, problem_id:100, vote:p100Rate, comment: p100Comment});
    voteData.push({user_id, problem_id:101, vote:p101Rate, comment: p101Comment});
    voteData.push({user_id, problem_id:102, vote:p102Rate, comment: p102Comment});
    voteData.push({user_id, problem_id:103, vote:p103Rate, comment: p103Comment});
    voteData.push({user_id, problem_id:104, vote:p104Rate, comment: p104Comment});
    voteData.push({user_id, problem_id:105, vote:p105Rate, comment: p105Comment});
    voteData.push({user_id, problem_id:106, vote:p106Rate, comment: p106Comment});
    voteData.push({user_id, problem_id:107, vote:p107Rate, comment: p107Comment});
    if(p108Other)
      voteData.push({user_id, problem_id:108, vote:p108Rate, comment: p108Comment, other:p108Other});

    axios.post( backendServer+'/vote.php', {vote_data: JSON.stringify(voteData)})
    .then( (response)=>{
      if(response.data.status==='ok') {
        //fetchData();
        setFilled(true);  
        setFirstRound(false);
        //setOkMessage("Responses saved.");
        navigate('Responses saved.');
      } else {
        setErrorMessage(response.data.message);
      }
    })
    .catch((error)=>{
      setErrorMessage(error.message);
    });
  }
  
  const updateP98Answer = (selection, add) => {
    if(add) {
      let newSelections = [...p98Selections];
      newSelections.push(selection);
      setP98Selections(newSelections);
      let newComments = [...p98Comments];
      let data = pageData?JSON.parse(pageData):[];
      const index = data.p98Selections?data.p98Selections.indexOf(selection):-1;
      newComments.push(index>=0?data.p98Comments[index]:'');
      setP98Comments(newComments);
    } else {
      let index = p98Selections.indexOf(selection);
      if(index>=0) { // sanity check        
        let newSelections = [...p98Selections];
        newSelections.splice(index,1);
        setP98Selections(newSelections);
        let newComments = [...p98Comments];
        newComments.splice(index,1);
        setP98Comments(newComments);
      }
    }
  }

  const updateP98Comment = (answerIndex, comment) => {
    let newComments = [...p98Comments];
    newComments[answerIndex]=comment;
    setP98Comments(newComments);
  }

  const updateP99Answer = (selection, add) => {
    if(add) {
      let newSelections = [...p99Selections];
      newSelections.push(selection);
      setP99Selections(newSelections);
      let newComments = [...p99Comments];
      let data = pageData?JSON.parse(pageData):[];
      const index = data.p99Selections?data.p99Selections.indexOf(selection):-1;
      newComments.push(index>=0?data.p99Comments[index]:'');
      setP99Comments(newComments);
    } else {
      let index = p99Selections.indexOf(selection);
      if(index>=0) { // sanity check        
        let newSelections = [...p99Selections];
        newSelections.splice(index,1);
        setP99Selections(newSelections);
        let newComments = [...p99Comments];
        newComments.splice(index,1);
        setP99Comments(newComments);
      }
    }
  }

  const updateP99Comment = (answerIndex, comment) => {
    let newComments = [...p99Comments];
    newComments[answerIndex]=comment;
    setP99Comments(newComments);
  }
  
  return (
    <form onSubmit={handleSubmit}>
      <table width="600" align="center" cellPadding={8}>
        <tbody>
          <tr>
            <td>
              <h4>Issue 4: Carbon Emission Sinks</h4>
              <p>
                Carbon emission sinks are natural or artificial mediums that can
                absorb and store carbon dioxide (CO2) for a long period of time
                and thereby remove CO2 from the atmosphere. Carbon sinks take up
                more CO2 from the atmosphere than they release.
              </p>
              <p>
                This survey focuses on the aspects of carbon emission sinks
                encompassing
              </p>
              <p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4.1 Carbon
                Capture, Utilization and Storage (CCUS) <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4.2 Land-use
                practices
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <h4>4.1 Carbon Capture, Utilization and Storage (CCUS)</h4>
              <p>
                Carbon Capture, Utilization and Storage (CCUS) refers to the
                process of capturing CO2 emissions from sources such as fossil
                power generation and industrial processes and either storing
                captured CO2 in permanent geological storages or using captured
                CO2 for various applications such as synthetic fuels and
                building materials.
              </p>
              <p>
                Carbon Capture and Storage (CCS) is the process of capturing,
                transporting, and storing CO2 at a long-term storage location.
              </p>
              <p>
                Carbon Capture and Utilization (CCU) is the process of capturing
                CO2 for further use in creating CO2-based products while
                maintaining the overall carbon neutrality of the production
                process.
              </p>
              <hr />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <SelectItem
                problem_id={98}
                firstRound={firstRound}
                adminMode={isAdmin}
                max={3}
                updateAnswer={updateP98Answer}
                updateComment={updateP98Comment}
                selections={p98Selections}
                comments={p98Comments}
                text="1. Based on your justification, please select three APEC economies that have effective CCUS policies."
                choices={allEconomies}
                other={false}
                results={p98Results}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <SelectItem
                problem_id={99}
                firstRound={firstRound}
                adminMode={isAdmin}
                max={4}
                min={1}
                updateAnswer={updateP99Answer}
                updateComment={updateP99Comment}
                selections={p99Selections}
                comments={p99Comments}
                text="2. What part of the CCUS processes require urgent technological development or breakthrough? (select at least one choice)"
                choices={[
                  "Capture",
                  "Transportation",
                  "Storage",
                  "Utilization",
                ]}
                choices_hover={[
                  "Examples of key topics: <br /> - Reducing costs of CO2 capture at industrial sites and power plants<br /> - Increase the capture rate",
                  "Examples of key topics:<br /> - Technical specifications for CO2 streams, CO2 purity, CO2 flows<br /> - Design of pipelines, ships, and other parts of the transport value chain<br /> - Assessment of the quality of CO2 streams, as well as CO2 density and pressure",
                  "Examples of key topics:<br /> - CO2 liability risks for long-term storage<br /> - Develop effective CO2 storage offshore or onshore<br /> - Development of CO2 storage sites for small emitters",
                  "Examples of key topics:<br /> - Novel and cost-effective materials with high durability and recyclability for capture tailored to the purity requirements of the subsequent use<br /> - New catalysts and materials for energy- and resource-efficient conversion technologies into fuels and chemicals",
                ]}
                other={false}
                results={p99Results}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              3. On a scale of 1 to 5, please rate the severity of the following
              bottlenecks for Carbon Capture and Storage (CCS) deployment in
              APEC.
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={100}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p100Rate}
                setRate={setP100Rate}
                comment={p100Comment}
                setComment={setP100Comment}
                text="a. Policy"
                results={p100Results}
                choices={[
                  "Low",
                  "Rather low",
                  "Moderate",
                  "Rather high",
                  "High",
                ]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={101}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p101Rate}
                setRate={setP101Rate}
                comment={p101Comment}
                setComment={setP101Comment}
                text="b. Regulation"
                results={p101Results}
                choices={[
                  "Low",
                  "Rather low",
                  "Moderate",
                  "Rather high",
                  "High",
                ]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={102}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p102Rate}
                setRate={setP102Rate}
                comment={p102Comment}
                setComment={setP102Comment}
                text="c. Investment"
                results={p102Results}
                choices={[
                  "Low",
                  "Rather low",
                  "Moderate",
                  "Rather high",
                  "High",
                ]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={103}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p103Rate}
                setRate={setP103Rate}
                comment={p103Comment}
                setComment={setP103Comment}
                text="d. Infrastructure"
                results={p103Results}
                choices={[
                  "Low",
                  "Rather low",
                  "Moderate",
                  "Rather high",
                  "High",
                ]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={104}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p104Rate}
                setRate={setP104Rate}
                comment={p104Comment}
                setComment={setP104Comment}
                text="e. Technology"
                results={p104Results}
                choices={[
                  "Low",
                  "Rather low",
                  "Moderate",
                  "Rather high",
                  "High",
                ]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={105}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p105Rate}
                setRate={setP105Rate}
                comment={p105Comment}
                setComment={setP105Comment}
                text="f. Manpower"
                results={p105Results}
                choices={[
                  "Low",
                  "Rather low",
                  "Moderate",
                  "Rather high",
                  "High",
                ]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={106}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p106Rate}
                setRate={setP106Rate}
                comment={p106Comment}
                setComment={setP106Comment}
                text="g. Safety concerns"
                results={p106Results}
                choices={[
                  "Low",
                  "Rather low",
                  "Moderate",
                  "Rather high",
                  "High",
                ]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={107}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p107Rate}
                setRate={setP107Rate}
                comment={p107Comment}
                setComment={setP107Comment}
                text="h. Public perception"
                results={p107Results}
                choices={[
                  "Low",
                  "Rather low",
                  "Moderate",
                  "Rather high",
                  "High",
                ]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={108}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p108Rate}
                setRate={setP108Rate}
                comment={p108Comment}
                setComment={setP108Comment}
                text="i. Others"
                other={true}
                otherText={p108Other}
                updateOther={setP108Other}
                results={p108Results}
                choices={[
                  "Low",
                  "Rather low",
                  "Moderate",
                  "Rather high",
                  "High",
                ]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td align="right">
              <Message color={messageColor} message={message} />
              <br />
              <NextButtonPanel
                disabled={unchanged}
                onClick={resetValues}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );
};

export default Issue4page1;