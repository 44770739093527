import { useState, useEffect } from 'react';
import axios from 'axios';
import { backendServer } from '../../Const';
import RateItem from "./RateItem";
import Message from './Message';

import {readRateAnswer, checkRateFilled, handleBeforeUnload} from '../../SurveyUtils';
import NextButtonPanel from '../../components/NextButtonPanel';

const Issue4page4 = (props) => {
  const [pageData, setPageData] = useState('');
  const [firstRound, setFirstRound] = useState(true);

  const [p132Rate, setP132Rate] = useState(0);
  const [p132Comment, setP132Comment] = useState('');
  const [p132Results, setP132Results] = useState('');
  const [p133Rate, setP133Rate] = useState(0);
  const [p133Comment, setP133Comment] = useState('');
  const [p133Results, setP133Results] = useState('');
  const [p134Rate, setP134Rate] = useState(0);
  const [p134Comment, setP134Comment] = useState('');
  const [p134Results, setP134Results] = useState('');
  const [p135Rate, setP135Rate] = useState(0);
  const [p135Comment, setP135Comment] = useState('');
  const [p135Results, setP135Results] = useState('');
  const [p136Rate, setP136Rate] = useState(0);
  const [p136Comment, setP136Comment] = useState('');
  const [p136Results, setP136Results] = useState('');
  const [p137Rate, setP137Rate] = useState(0);
  const [p137Comment, setP137Comment] = useState('');
  const [p137Results, setP137Results] = useState('');
  const [p137Other, setP137Other] = useState('');
  const [p138Rate, setP138Rate] = useState(0);
  const [p138Comment, setP138Comment] = useState('');
  const [p138Results, setP138Results] = useState('');
  const [p139Rate, setP139Rate] = useState(0);
  const [p139Comment, setP139Comment] = useState('');
  const [p139Results, setP139Results] = useState('');
  const [p140Rate, setP140Rate] = useState(0);
  const [p140Comment, setP140Comment] = useState('');
  const [p140Results, setP140Results] = useState('');
  const [p141Rate, setP141Rate] = useState(0);
  const [p141Comment, setP141Comment] = useState('');
  const [p141Results, setP141Results] = useState('');
  const [p142Rate, setP142Rate] = useState(0);
  const [p142Comment, setP142Comment] = useState('');
  const [p142Results, setP142Results] = useState('');
  const [p143Rate, setP143Rate] = useState(0);
  const [p143Comment, setP143Comment] = useState('');
  const [p143Results, setP143Results] = useState('');
  const [p143Other, setP143Other] = useState('');

  const [filled, setFilled] = useState(false);
  const [unchanged, setUnchanged] = useState(true);
  const [message, setMessage] = useState('');
  const [messageColor, setMessageColor] = useState('');

  useEffect( () => {
    if(unchanged) return;
    
    window.addEventListener('beforeunload',handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload',handleBeforeUnload)
    }
  },[unchanged]);

  const setErrorMessage = (msg) => {
    setMessageColor("red");
    setMessage(msg);
  }

  const setOkMessage = (msg) => {
    setMessageColor("teal");
    setMessage(msg);
  }

  const resetValues = () => {
    const data = pageData?JSON.parse(pageData):[];
    
    setP132Rate(data.p132Rate?data.p132Rate:0);
    setP132Comment(data.p132Comment?data.p132Comment:'');
    setP133Rate(data.p133Rate?data.p133Rate:0);
    setP133Comment(data.p133Comment?data.p133Comment:'');
    setP134Rate(data.p134Rate?data.p134Rate:0);
    setP134Comment(data.p134Comment?data.p134Comment:'');
    setP135Rate(data.p135Rate?data.p135Rate:0);
    setP135Comment(data.p135Comment?data.p135Comment:'');
    setP136Rate(data.p136Rate?data.p136Rate:0);
    setP136Comment(data.p136Comment?data.p136Comment:'');
    setP137Rate(data.p137Rate?data.p137Rate:0);
    setP137Comment(data.p137Comment?data.p137Comment:'');
    setP137Other(data.p137Other?data.p137Other:'');
    setP138Rate(data.p138Rate?data.p138Rate:0);
    setP138Comment(data.p138Comment?data.p138Comment:'');
    setP139Rate(data.p139Rate?data.p139Rate:0);
    setP139Comment(data.p139Comment?data.p139Comment:'');
    setP140Rate(data.p140Rate?data.p140Rate:0);
    setP140Comment(data.p140Comment?data.p140Comment:'');
    setP141Rate(data.p141Rate?data.p141Rate:0);
    setP141Comment(data.p141Comment?data.p141Comment:'');
    setP142Rate(data.p142Rate?data.p142Rate:0);
    setP142Comment(data.p142Comment?data.p142Comment:'');
    setP143Rate(data.p143Rate?data.p143Rate:0);
    setP143Comment(data.p143Comment?data.p143Comment:'');
    setP143Other(data.p143Other?data.p143Other:'');

    setUnchanged(true);
  }

  const fetchData = async () => {
    axios
      .post(backendServer + "/get_votes.php", {
        user_id: JSON.parse(props.user).user_id,
        problem_ids: ["132", "133", "134", "135", "136", "137", "138", "139", "140", "141", "142", "143"]
      })
      .then((response) => {
        if (response.data.status === "ok") {
          let answers = JSON.parse(response.data.answers);
          let results = JSON.parse(response.data.results);
          let data = {};

          if(answers['132']) {
            setFirstRound(false);
          }
          
          [data['p132Rate'], data['p132Comment']] = readRateAnswer(answers['132']);
          [data['p133Rate'], data['p133Comment']] = readRateAnswer(answers['133']);
          [data['p134Rate'], data['p134Comment']] = readRateAnswer(answers['134']);     
          [data['p135Rate'], data['p135Comment']] = readRateAnswer(answers['135']);     
          [data['p136Rate'], data['p136Comment']] = readRateAnswer(answers['136']);
          [data['p137Rate'], data['p137Comment']] = readRateAnswer(answers['137']);
          if(answers['137']&&answers['137'].other) data['p137Other'] = answers['137'].other;
          else data['p137Other']='';
          [data['p138Rate'], data['p138Comment']] = readRateAnswer(answers['138']);
          [data['p139Rate'], data['p139Comment']] = readRateAnswer(answers['139']);
          [data['p140Rate'], data['p140Comment']] = readRateAnswer(answers['140']);
          [data['p141Rate'], data['p141Comment']] = readRateAnswer(answers['141']);
          [data['p142Rate'], data['p142Comment']] = readRateAnswer(answers['142']);
          [data['p143Rate'], data['p143Comment']] = readRateAnswer(answers['143']);
          if(answers['143']&&answers['143'].other) data['p143Other'] = answers['143'].other;
          else data['p143Other']='';

          setPageData(JSON.stringify(data));

          setP132Results(results["132"]);
          setP133Results(results["133"]);
          setP134Results(results["134"]);
          setP135Results(results["135"]);
          setP136Results(results["136"]);
          setP137Results(results["137"]);
          setP138Results(results["138"]);
          setP139Results(results["139"]);
          setP140Results(results["140"]);
          setP141Results(results["141"]);
          setP142Results(results["142"]);
          setP143Results(results["143"]);
          
          checkFilled();
          setUnchanged(true);
        } else {
          setErrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  }  

  useEffect(()=>{
    fetchData(); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    resetValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pageData]);
  
  useEffect(() => {
    setMessage('');
    let data = pageData?JSON.parse(pageData):[];

    if (Number(data.p132Rate) !== Number(p132Rate)) { setUnchanged(false); return; }
    if (data.p132Comment !== p132Comment) { setUnchanged(false); return; }
    if (Number(data.p133Rate) !== Number(p133Rate)) { setUnchanged(false); return; }
    if (data.p133Comment !== p133Comment) { setUnchanged(false); return; }
    if (Number(data.p134Rate) !== Number(p134Rate)) { setUnchanged(false); return; }
    if (data.p134Comment !== p134Comment) { setUnchanged(false); return; }
    if (Number(data.p135Rate) !== Number(p135Rate)) { setUnchanged(false); return; }
    if (data.p135Comment !== p135Comment) { setUnchanged(false); return; }
    if (Number(data.p136Rate) !== Number(p136Rate)) { setUnchanged(false); return; }
    if (data.p136Comment !== p136Comment) { setUnchanged(false); return; }
    if (Number(data.p137Rate) !== Number(p137Rate)) { setUnchanged(false); return; }
    if (data.p137Comment !== p137Comment) { setUnchanged(false); return; }
    if (data.p137Other !== p137Other) { setUnchanged(false); return; }    
    if (Number(data.p138Rate) !== Number(p138Rate)) { setUnchanged(false); return; }
    if (data.p138Comment !== p138Comment) { setUnchanged(false); return; }
    if (Number(data.p139Rate) !== Number(p139Rate)) { setUnchanged(false); return; }
    if (data.p139Comment !== p139Comment) { setUnchanged(false); return; }
    if (Number(data.p140Rate) !== Number(p140Rate)) { setUnchanged(false); return; }
    if (data.p140Comment !== p140Comment) { setUnchanged(false); return; }
    if (Number(data.p141Rate) !== Number(p141Rate)) { setUnchanged(false); return; }
    if (data.p141Comment !== p141Comment) { setUnchanged(false); return; }
    if (Number(data.p142Rate) !== Number(p142Rate)) { setUnchanged(false); return; }
    if (data.p142Comment !== p142Comment) { setUnchanged(false); return; }
    if (Number(data.p143Rate) !== Number(p143Rate)) { setUnchanged(false); return; }
    if (data.p143Comment !== p143Comment) { setUnchanged(false); return; }
    if (data.p143Other !== p143Other) { setUnchanged(false); return; }    
    
    setUnchanged(true);
  }, [    
    p132Rate, p132Comment,
    p133Rate, p133Comment,
    p134Rate, p134Comment,
    p135Rate, p135Comment,
    p136Rate, p136Comment,
    p137Rate, p137Comment, p137Other,
    p138Rate, p138Comment,
    p139Rate, p139Comment,
    p140Rate, p140Comment,
    p141Rate, p141Comment,
    p142Rate, p142Comment,
    p143Rate, p143Comment, p143Other, pageData
  ]);

  const checkFilled = () => {
    if(!checkRateFilled(p132Rate, p132Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p133Rate, p133Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p134Rate, p134Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p135Rate, p135Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p136Rate, p136Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p137Rate, p137Comment, 1, 5)) {setFilled(false); return;}
    //if(!p137Other) {setFilled(false); return;}
    if(!checkRateFilled(p138Rate, p138Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p139Rate, p139Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p140Rate, p140Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p141Rate, p141Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p142Rate, p142Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p143Rate, p143Comment, 1, 5)) {setFilled(false); return;}
    //if(!p143Other) {setFilled(false); return;}

    setFilled(true);
  }

  const navigate = (msg) => {
    window.removeEventListener('beforeunload',handleBeforeUnload); 
    if(!msg) msg = 'Proceeding..';
    setOkMessage(msg);
    setTimeout(()=> {
      window.location.href = '/survey?issue=4&page=5';
    }, 1000);
  }
  
  useEffect(() => {   
    checkFilled();
  });
 
  let admin = JSON.parse(props.user).admin;
  let isAdmin = admin&&admin==="1";
  
  const handleSubmit = (event) => {
    event.preventDefault();
    if((filled&&unchanged)||isAdmin) { navigate(); return; }
    
    if(!checkRateFilled(p132Rate, p132Comment, 1, 5,
      "Please answer problem 8a.",
      "Please provide your reasoning for problem 8a.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p133Rate, p133Comment, 1, 5,
      "Please answer problem 8b.",
      "Please provide your reasoning for problem 8b.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p134Rate, p134Comment, 1, 5,
      "Please answer problem 8c.",
      "Please provide your reasoning for problem 8c.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p135Rate, p135Comment, 1, 5,
      "Please answer problem 8d.",
      "Please provide your reasoning for problem 8d.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p136Rate, p136Comment, 1, 5,
      "Please answer problem 8e.",
      "Please provide your reasoning for problem 8e.",
      setErrorMessage
    )) return;
    if(p137Other) {
      if(!checkRateFilled(p137Rate, p137Comment, 1, 5,
        "Please answer problem 8f.",
        "Please provide your reasoning for problem 8f.",
        setErrorMessage
      )) return;
    }
    // if(!p137Other) {
    //   setErrorMessage("Please specify the other field in problem 8f.");
    //   return;
    // }
    if(!checkRateFilled(p138Rate, p138Comment, 1, 5,
      "Please answer problem 9a.",
      "Please provide your reasoning for problem 9a.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p139Rate, p139Comment, 1, 5,
      "Please answer problem 9b.",
      "Please provide your reasoning for problem 9b.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p140Rate, p140Comment, 1, 5,
      "Please answer problem 9c.",
      "Please provide your reasoning for problem 9c.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p141Rate, p141Comment, 1, 5,
      "Please answer problem 9d.",
      "Please provide your reasoning for problem 9d.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p142Rate, p142Comment, 1, 5,
      "Please answer problem 9e.",
      "Please provide your reasoning for problem 9e.",
      setErrorMessage
    )) return;
    if(p143Other) {
      if(!checkRateFilled(p143Rate, p143Comment, 1, 5,
        "Please answer problem 9f.",
        "Please provide your reasoning for problem 9f.",
        setErrorMessage
      )) return;
    }
    // if(!p143Other) {
    //   setErrorMessage("Please specify the other field in problem 9f.");
    //   return;
    // }

    let user_id = JSON.parse(props.user).user_id;
    let voteData = [];
    voteData.push({user_id, problem_id:132, vote:p132Rate, comment: p132Comment});
    voteData.push({user_id, problem_id:133, vote:p133Rate, comment: p133Comment});
    voteData.push({user_id, problem_id:134, vote:p134Rate, comment: p134Comment});
    voteData.push({user_id, problem_id:135, vote:p135Rate, comment: p135Comment});
    voteData.push({user_id, problem_id:136, vote:p136Rate, comment: p136Comment});
    if(p137Other) 
      voteData.push({user_id, problem_id:137, vote:p137Rate, comment: p137Comment, other:p137Other});
    voteData.push({user_id, problem_id:138, vote:p138Rate, comment: p138Comment});
    voteData.push({user_id, problem_id:139, vote:p139Rate, comment: p139Comment});
    voteData.push({user_id, problem_id:140, vote:p140Rate, comment: p140Comment});
    voteData.push({user_id, problem_id:141, vote:p141Rate, comment: p141Comment});
    voteData.push({user_id, problem_id:142, vote:p142Rate, comment: p142Comment});
    if(p143Other) 
      voteData.push({user_id, problem_id:143, vote:p143Rate, comment: p143Comment, other:p143Other});

    axios.post( backendServer+'/vote.php', {vote_data: JSON.stringify(voteData)})
    .then( (response)=>{
      if(response.data.status==='ok') {
        //updatePageData();
        //fetchData();
        setFilled(true);
        setFirstRound(false);
        //setOkMessage("Responses saved.");
        navigate('Responses saved.');
      } else {
        setErrorMessage(response.data.message);
      }
    })
    .catch((error)=>{
      setErrorMessage(error.message);
    });
  }

  return (
    <form onSubmit={handleSubmit}>
      <table width="600" align="center" cellPadding={8}>
        <tbody>
          <tr>
            <td>
              <br />
              8. To what extent do you believe the collaboration among APEC
              economies regarding CCU are driving progress towards net-zero
              emissions?
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={132}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p132Rate}
                setRate={setP132Rate}
                comment={p132Comment}
                setComment={setP132Comment}
                text="a. Market opportunities within APEC"
                results={p132Results}
                choices={[
                  "Not at all important",
                  "Low important",
                  "Moderately important",
                  "Important",
                  "Very important",
                ]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={133}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p133Rate}
                setRate={setP133Rate}
                comment={p133Comment}
                setComment={setP133Comment}
                text="b. Knowledge sharing"
                results={p133Results}
                choices={[
                  "Not at all important",
                  "Low important",
                  "Moderately important",
                  "Important",
                  "Very important",
                ]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={134}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p134Rate}
                setRate={setP134Rate}
                comment={p134Comment}
                setComment={setP134Comment}
                text="c. Technology transfer"
                results={p134Results}
                choices={[
                  "Not at all important",
                  "Low important",
                  "Moderately important",
                  "Important",
                  "Very important",
                ]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={135}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p135Rate}
                setRate={setP135Rate}
                comment={p135Comment}
                setComment={setP135Comment}
                text="d. Capacity building"
                results={p135Results}
                choices={[
                  "Not at all important",
                  "Low important",
                  "Moderately important",
                  "Important",
                  "Very important",
                ]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={136}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p136Rate}
                setRate={setP136Rate}
                comment={p136Comment}
                setComment={setP136Comment}
                text="e. R&D collaboration"
                results={p136Results}
                choices={[
                  "Not at all important",
                  "Low important",
                  "Moderately important",
                  "Important",
                  "Very important",
                ]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={137}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p137Rate}
                setRate={setP137Rate}
                comment={p137Comment}
                setComment={setP137Comment}
                text="f. Others"
                other={true}
                otherText={p137Other}
                updateOther={setP137Other}
                results={p137Results}
                choices={[
                  "Not at all important",
                  "Low important",
                  "Moderately important",
                  "Important",
                  "Very important",
                ]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <br />
              9. On a scale of 1 to 5, please rate the following collaborations
              that your economy requires from APEC economies to successfully
              implement CCU.
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={138}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p138Rate}
                setRate={setP138Rate}
                comment={p138Comment}
                setComment={setP138Comment}
                text="a. Market opportunities within APEC"
                results={p138Results}
                choices={["Low", "Rather low", "Moderate", "Rather high", "High", ]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={139}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p139Rate}
                setRate={setP139Rate}
                comment={p139Comment}
                setComment={setP139Comment}
                text="b. Knowledge sharing"
                results={p139Results}
                choices={["Low", "Rather low", "Moderate", "Rather high", "High", ]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={140}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p140Rate}
                setRate={setP140Rate}
                comment={p140Comment}
                setComment={setP140Comment}
                text="c. Technology transfer"
                results={p140Results}
                choices={["Low", "Rather low", "Moderate", "Rather high", "High", ]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={141}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p141Rate}
                setRate={setP141Rate}
                comment={p141Comment}
                setComment={setP141Comment}
                text="d. Capacity building"
                results={p141Results}
                choices={["Low", "Rather low", "Moderate", "Rather high", "High", ]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={142}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p142Rate}
                setRate={setP142Rate}
                comment={p142Comment}
                setComment={setP142Comment}
                text="e. Joint R&D"
                results={p142Results}
                choices={["Low", "Rather low", "Moderate", "Rather high", "High", ]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={143}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p143Rate}
                setRate={setP143Rate}
                comment={p143Comment}
                setComment={setP143Comment}
                text="f. Others"
                other={true}
                otherText={p143Other}
                updateOther={setP143Other}
                results={p143Results}
                choices={["Low", "Rather low", "Moderate", "Rather high", "High", ]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td align="right">
            <Message color={messageColor} message={message} />
              <br />
              <NextButtonPanel
                disabled={unchanged}
                onClick={resetValues}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );
};

export default Issue4page4;