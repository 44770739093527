import { useState } from 'react';
import axios from 'axios';
import { backendServer, } from '../../Const';
import Message from './Message';

const Consent = (props) => {
  const userObj = JSON.parse(props.user);

  const [consent1, setConsent1] = useState(false);
  //const [consent2, setConsent2] = useState(false);
  //const [consentOther, setConsentOther] = useState('');
  
  const [message, setMessage] = useState('');
  const [messageColor, setMessageColor] = useState('');

  const setErrorMessage = (msg) => {
    setMessageColor("red");
    setMessage(msg);
  }

  const setOkMessage = (msg) => {
    setMessageColor("teal");
    setMessage(msg);
  }


  const handleSubmit = (event) => {
    event.preventDefault();

    if(!consent1) { setErrorMessage('Please acknowledge your consent to proceed.'); return;}
    //if((!consent1)&&(!consent2)) { setErrorMessage('Please acknowledge your consent to proceed.'); return;}
    //if(consent2&&!consentOther) { setErrorMessage('Please specify your consent.'); return;}

    userObj.consent = true;
    //if (consentOther) userObj.consent_other = consentOther;
    
    axios
      .post(backendServer + "/save_info.php", {
        user_id: userObj.user_id,
        name: userObj.name,
        family_name: userObj.familyName,
        economy: userObj.economy,
        email: userObj.email,
        experience: userObj.experience,
        age: userObj.age,
        gender: userObj.gender,
        affiliation: userObj.affiliation,
        company_type: userObj.company_type,
        job_function: userObj.job_function,
        consent: userObj.consent,
        //consent_other: userObj.consent_other
      })
      .then((response) => {
        //console.log(response.data);
        if (response.data.status === "ok") {
          setOkMessage("Consent information saved.");
          props.setUser(JSON.stringify(userObj));
          navigate();
        } else {
          setErrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        setErrorMessage(error);
      });
  }

  const navigate = (issue) => {    
    window.location.href = '/survey?issue=0';
  }

  const navigateHome = (issue) => {
    setMessage('');
    window.location.href = '/';
  }

  const consent1Change = () => {
    setConsent1(!consent1);
  }

  // const consent2Change = () => {
  //   setConsent2(!consent2);
  // }
  // const consentOtherChange = (e) => {
  //   setConsentOther(e.target.value);
  // }
  

  return (
    <>
      <form onSubmit={handleSubmit}>
        <table width="600" align="center" cellPadding={8}>
          <tbody>
            <tr>
              <td>
                <h4>
                  Consent for the Collection, Use, and Disclosure of Personal
                  Data
                </h4>
                <p>
                  I hereby provide my consent to APEC Center for Technology
                  Foresight, located at The Office of National Higher Education
                  Science Research and Innovation Policy Council (NXPO),
                  Bangkok, Thailand, for the collection, use, and disclosure of
                  my personal data in accordance with the Personal Data
                  Protection Act (PDPA) and any applicable data protection laws
                  and regulations.
                </p>
                <p>1. Purpose of Data Collection:</p>
                <p>
                  To process the Delphi online survey on Net Zero in
                  Decarbonization Strategies to achieve net-zero emissions by
                  2050
                </p>
                <p>2. Types of Personal Data Collected:</p>
                Specify the types of personal data that will be collected. This
                may include details like name, email, etc.
                <p>3. Consent Duration:</p>
                <p>This consent is valid until revoked in writing.</p>
                <p>4. Disclosure of Personal Data:</p>
                <p>
                  We will not disclose any personal data to other third parties.
                </p>
                <p>5. Consent Withdrawal:</p>
                <p>
                  "You may withdraw your consent by contacting our Data
                  Protection Officer at apecctf@nxpo.or.th"
                </p>
                <p>6. Access and Correction:</p>
                <p>
                  You may need the access to correct the data, please contact
                  apecctf@nxpo.or.th
                </p>
                <p>7. Security Measures:</p>
                <p>
                  We prioritize your data security. We employ robust measures,
                  including encryption protocols for data in transit and at
                  rest, restricted access controls, and regular security audits.
                  Our staff receives training on data protection best practices,
                  and we maintain a clear data breach response plan. We comply
                  with legal requirements and keep abreast of evolving privacy
                  threats to ensure your personal data remains confidential and
                  secure. Your privacy and data security are paramount to us,
                  and we are committed to safeguarding your information.
                </p>
                <p>8. Questions and Contact Information:</p>
                <p>
                  contact apecctf@nxpo.or.th for any questions and concerns.
                </p>
                <p>
                  By signing this consent form, I acknowledge that I have read
                  and understood its contents and voluntarily consent to the
                  collection, use, and disclosure of my personal data for the
                  purposes outlined herein following the dates and time you
                  submit.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <input
                  type="checkbox"
                  checked={consent1}
                  onChange={consent1Change}
                  value="acknowledge"
                />{" "}
                I acknowledge <br />
              </td>
            </tr>
            <tr>
              <td align='right'>
                <Message color={messageColor} message={message} /> <br />
                <input
                  className="blueButton"
                  type="button"
                  value="Return Home"
                  onClick={navigateHome}
                />
                &nbsp;
                <input
                  className="blueButton"
                  type="submit"
                  value="Proceed to Survey"
                  onClick={(e) => handleSubmit(e)}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </form>
      <br />
    </>
  );
};

export default Consent;


/*

                <input
                  type="checkbox"
                  checked={consent2}
                  onChange={consent2Change}
                  value="other"
                />{" "}
                Other: &nbsp;
                <input
                  type="text"
                  value={consentOther}
                  onChange={(e) => consentOtherChange(e)}
                  placeholder="please specify"
                  disabled={!consent2}
                />
                */