import { useState, useEffect } from 'react';
import axios from 'axios';
import { backendServer } from '../../Const';
import RateItem from "./RateItem";
import Message from './Message';

import {readRateAnswer, checkRateFilled, handleBeforeUnload} from '../../SurveyUtils';
import NextButtonPanel from '../../components/NextButtonPanel';

const Issue4page3 = (props) => {
  const [pageData, setPageData] = useState('');
  const [firstRound, setFirstRound] = useState(true);

  const [p123Rate, setP123Rate] = useState(0);
  const [p123Comment, setP123Comment] = useState('');
  const [p123Results, setP123Results] = useState('');
  const [p124Rate, setP124Rate] = useState(0);
  const [p124Comment, setP124Comment] = useState('');
  const [p124Results, setP124Results] = useState('');
  const [p125Rate, setP125Rate] = useState(0);
  const [p125Comment, setP125Comment] = useState('');
  const [p125Results, setP125Results] = useState('');
  const [p126Rate, setP126Rate] = useState(0);
  const [p126Comment, setP126Comment] = useState('');
  const [p126Results, setP126Results] = useState('');
  const [p127Rate, setP127Rate] = useState(0);
  const [p127Comment, setP127Comment] = useState('');
  const [p127Results, setP127Results] = useState('');
  const [p128Rate, setP128Rate] = useState(0);
  const [p128Comment, setP128Comment] = useState('');
  const [p128Results, setP128Results] = useState('');
  const [p129Rate, setP129Rate] = useState(0);
  const [p129Comment, setP129Comment] = useState('');
  const [p129Results, setP129Results] = useState('');
  const [p130Rate, setP130Rate] = useState(0);
  const [p130Comment, setP130Comment] = useState('');
  const [p130Results, setP130Results] = useState('');
  const [p131Rate, setP131Rate] = useState(0);
  const [p131Comment, setP131Comment] = useState('');
  const [p131Results, setP131Results] = useState('');
  const [p131Other, setP131Other] = useState('');

  const [filled, setFilled] = useState(false);
  const [unchanged, setUnchanged] = useState(true);
  const [message, setMessage] = useState('');
  const [messageColor, setMessageColor] = useState('');

  useEffect( () => {
    if(unchanged) return;
    
    window.addEventListener('beforeunload',handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload',handleBeforeUnload)
    }
    
  },[unchanged]);

  const setErrorMessage = (msg) => {
    setMessageColor("red");
    setMessage(msg);
  }

  const setOkMessage = (msg) => {
    setMessageColor("teal");
    setMessage(msg);
  }

  const resetValues = () => {
    const data = pageData?JSON.parse(pageData):[];
    
    setP123Rate(data.p123Rate?data.p123Rate:0);
    setP123Comment(data.p123Comment?data.p123Comment:'');
    setP124Rate(data.p124Rate?data.p124Rate:0);
    setP124Comment(data.p124Comment?data.p124Comment:'');
    setP125Rate(data.p125Rate?data.p125Rate:0);
    setP125Comment(data.p125Comment?data.p125Comment:'');
    setP126Rate(data.p126Rate?data.p126Rate:0);
    setP126Comment(data.p126Comment?data.p126Comment:'');
    setP127Rate(data.p127Rate?data.p127Rate:0);
    setP127Comment(data.p127Comment?data.p127Comment:'');
    setP128Rate(data.p128Rate?data.p128Rate:0);
    setP128Comment(data.p128Comment?data.p128Comment:'');
    setP129Rate(data.p129Rate?data.p129Rate:0);
    setP129Comment(data.p129Comment?data.p129Comment:'');
    setP130Rate(data.p130Rate?data.p130Rate:0);
    setP130Comment(data.p130Comment?data.p130Comment:'');
    setP131Rate(data.p131Rate?data.p131Rate:0);
    setP131Comment(data.p131Comment?data.p131Comment:'');
    setP131Other(data.p131Other?data.p131Other:'');

    setUnchanged(true);
  }

  const fetchData = async () => {
    axios
      .post(backendServer + "/get_votes.php", {
        user_id: JSON.parse(props.user).user_id,
        problem_ids: ["123", "124", "125", "126", "127", "128", "129", "130", "131"]
      })
      .then((response) => {
        if (response.data.status === "ok") {
          let answers = JSON.parse(response.data.answers);
          let results = JSON.parse(response.data.results);
          let data = {};

          if(answers['123']) {
            setFirstRound(false);
          }
          
          [data['p123Rate'], data['p123Comment']] = readRateAnswer(answers['123']);
          [data['p124Rate'], data['p124Comment']] = readRateAnswer(answers['124']);     
          [data['p125Rate'], data['p125Comment']] = readRateAnswer(answers['125']);     
          [data['p126Rate'], data['p126Comment']] = readRateAnswer(answers['126']);
          [data['p127Rate'], data['p127Comment']] = readRateAnswer(answers['127']);
          [data['p128Rate'], data['p128Comment']] = readRateAnswer(answers['128']);
          [data['p129Rate'], data['p129Comment']] = readRateAnswer(answers['129']);
          [data['p130Rate'], data['p130Comment']] = readRateAnswer(answers['130']);
          [data['p131Rate'], data['p131Comment']] = readRateAnswer(answers['131']);
          if(answers['131']&&answers['131'].other) data['p131Other'] = answers['131'].other;
          else data['p131Other']='';

          setPageData(JSON.stringify(data));

          setP123Results(results["123"]);
          setP124Results(results["124"]);
          setP125Results(results["125"]);
          setP126Results(results["126"]);
          setP127Results(results["127"]);
          setP128Results(results["128"]);
          setP129Results(results["129"]);
          setP130Results(results["130"]);
          setP131Results(results["131"]);
          
          checkFilled();
          setUnchanged(true);
        } else {
          setErrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  }  

  useEffect(()=>{
    fetchData(); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    resetValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pageData]);
  
  useEffect(() => {
    setMessage('');
    let data = pageData?JSON.parse(pageData):[];

    if (Number(data.p123Rate) !== Number(p123Rate)) { setUnchanged(false); return; }
    if (data.p123Comment !== p123Comment) { setUnchanged(false); return; }
    if (Number(data.p124Rate) !== Number(p124Rate)) { setUnchanged(false); return; }
    if (data.p124Comment !== p124Comment) { setUnchanged(false); return; }
    if (Number(data.p125Rate) !== Number(p125Rate)) { setUnchanged(false); return; }
    if (data.p125Comment !== p125Comment) { setUnchanged(false); return; }
    if (Number(data.p126Rate) !== Number(p126Rate)) { setUnchanged(false); return; }
    if (data.p126Comment !== p126Comment) { setUnchanged(false); return; }
    if (Number(data.p127Rate) !== Number(p127Rate)) { setUnchanged(false); return; }
    if (data.p127Comment !== p127Comment) { setUnchanged(false); return; }
    if (Number(data.p128Rate) !== Number(p128Rate)) { setUnchanged(false); return; }
    if (data.p128Comment !== p128Comment) { setUnchanged(false); return; }
    if (Number(data.p129Rate) !== Number(p129Rate)) { setUnchanged(false); return; }
    if (data.p129Comment !== p129Comment) { setUnchanged(false); return; }
    if (Number(data.p130Rate) !== Number(p130Rate)) { setUnchanged(false); return; }
    if (data.p130Comment !== p130Comment) { setUnchanged(false); return; }
    if (Number(data.p131Rate) !== Number(p131Rate)) { setUnchanged(false); return; }
    if (data.p131Comment !== p131Comment) { setUnchanged(false); return; }
    if (data.p131Other !== p131Other) { setUnchanged(false); return; }    
    
    setUnchanged(true);
  }, [    
    p123Rate, p123Comment,
    p124Rate, p124Comment,
    p125Rate, p125Comment,
    p126Rate, p126Comment,
    p127Rate, p127Comment,
    p128Rate, p128Comment,
    p129Rate, p129Comment,
    p130Rate, p130Comment,
    p131Rate, p131Comment, p131Other, pageData
  ]);

  const checkFilled = () => {
    if(!checkRateFilled(p123Rate, p123Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p124Rate, p124Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p125Rate, p125Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p126Rate, p126Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p127Rate, p127Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p128Rate, p128Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p129Rate, p129Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p130Rate, p130Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p131Rate, p131Comment, 1, 5)) {setFilled(false); return;}
    //if(!p131Other) {setFilled(false); return;}

    setFilled(true);
  }

  const navigate = (msg) => {
    window.removeEventListener('beforeunload',handleBeforeUnload); 
    if(!msg) msg = 'Proceeding..';
    setOkMessage(msg);
    setTimeout(()=> {
      window.location.href = '/survey?issue=4&page=4';
    }, 1000);
  }

  
  useEffect(() => {   
    checkFilled();
  });
 
  let admin = JSON.parse(props.user).admin;
  let isAdmin = admin&&admin==="1";
  

  const handleSubmit = (event) => {
    event.preventDefault();
    if((filled&&unchanged)||isAdmin) { navigate(); return; }
    
    if(!checkRateFilled(p123Rate, p123Comment, 1, 5,
      "Please answer problem 7a.",
      "Please provide your reasoning for problem 7a.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p124Rate, p124Comment, 1, 5,
      "Please answer problem 7b.",
      "Please provide your reasoning for problem 7b.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p125Rate, p125Comment, 1, 5,
      "Please answer problem 7c.",
      "Please provide your reasoning for problem 7c.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p126Rate, p126Comment, 1, 5,
      "Please answer problem 7d.",
      "Please provide your reasoning for problem 7d.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p127Rate, p127Comment, 1, 5,
      "Please answer problem 7e.",
      "Please provide your reasoning for problem 7e.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p128Rate, p128Comment, 1, 5,
      "Please answer problem 7f.",
      "Please provide your reasoning for problem 7f.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p129Rate, p129Comment, 1, 5,
      "Please answer problem 7g.",
      "Please provide your reasoning for problem 7g.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p130Rate, p130Comment, 1, 5,
      "Please answer problem 7h.",
      "Please provide your reasoning for problem 7h.",
      setErrorMessage
    )) return;
    if(p131Other) {
      if(!checkRateFilled(p131Rate, p131Comment, 1, 5,
        "Please answer problem 7i.",
        "Please provide your reasoning for problem 7i.",
        setErrorMessage
      )) return;
    }
    // if(!p131Other) {
    //   setErrorMessage("Please specify the other field in problem 6i.");
    //   return;
    // }

    let user_id = JSON.parse(props.user).user_id;
    let voteData = [];
    voteData.push({user_id, problem_id:123, vote:p123Rate, comment: p123Comment});
    voteData.push({user_id, problem_id:124, vote:p124Rate, comment: p124Comment});
    voteData.push({user_id, problem_id:125, vote:p125Rate, comment: p125Comment});
    voteData.push({user_id, problem_id:126, vote:p126Rate, comment: p126Comment});
    voteData.push({user_id, problem_id:127, vote:p127Rate, comment: p127Comment});
    voteData.push({user_id, problem_id:128, vote:p128Rate, comment: p128Comment});
    voteData.push({user_id, problem_id:129, vote:p129Rate, comment: p129Comment});
    voteData.push({user_id, problem_id:130, vote:p130Rate, comment: p130Comment});
    if(p131Other) 
      voteData.push({user_id, problem_id:131, vote:p131Rate, comment: p131Comment, other:p131Other});

    axios.post( backendServer+'/vote.php', {vote_data: JSON.stringify(voteData)})
    .then( (response)=>{
      if(response.data.status==='ok') {
        //updatePageData();
        //fetchData();
        setFilled(true);
        setFirstRound(false);
        //setOkMessage("Responses saved.");
        navigate('Responses saved.');
      } else {
        setErrorMessage(response.data.message);
      }
    })
    .catch((error)=>{
      setErrorMessage(error.message);
    });
  }

  return (
    <form onSubmit={handleSubmit}>
      <table width="600" align="center" cellPadding={8}>
        <tbody>
          <tr>
            <td>
              <br />
              7. On a scale of 1 to 5, please rate the severity of the following bottlenecks for Carbon Capture and Utilization (CCU) deployment in APEC.
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={123}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p123Rate}
                setRate={setP123Rate}
                comment={p123Comment}
                setComment={setP123Comment}
                text="a. Policy"
                results={p123Results}
                choices={["Low", "Rather low", "Moderate", "Rather high", "High"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={124}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p124Rate}
                setRate={setP124Rate}
                comment={p124Comment}
                setComment={setP124Comment}
                text="b. Regulation"
                results={p124Results}
                choices={["Low", "Rather low", "Moderate", "Rather high", "High"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={125}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p125Rate}
                setRate={setP125Rate}
                comment={p125Comment}
                setComment={setP125Comment}
                text="c. Investment"
                results={p125Results}
                choices={["Low", "Rather low", "Moderate", "Rather high", "High"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={126}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p126Rate}
                setRate={setP126Rate}
                comment={p126Comment}
                setComment={setP126Comment}
                text="d. Incentives"
                results={p126Results}
                choices={["Low", "Rather low", "Moderate", "Rather high", "High"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={127}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p127Rate}
                setRate={setP127Rate}
                comment={p127Comment}
                setComment={setP127Comment}
                text="e. Market demands"
                results={p127Results}
                choices={["Low", "Rather low", "Moderate", "Rather high", "High"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={128}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p128Rate}
                setRate={setP128Rate}
                comment={p128Comment}
                setComment={setP128Comment}
                text="f. Technology"
                results={p128Results}
                choices={["Low", "Rather low", "Moderate", "Rather high", "High"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={129}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p129Rate}
                setRate={setP129Rate}
                comment={p129Comment}
                setComment={setP129Comment}
                text="g. Manpower"
                results={p129Results}
                choices={["Low", "Rather low", "Moderate", "Rather high", "High"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={130}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p130Rate}
                setRate={setP130Rate}
                comment={p130Comment}
                setComment={setP130Comment}
                text="h. Safety concerns"
                results={p130Results}
                choices={["Low", "Rather low", "Moderate", "Rather high", "High"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={131}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p131Rate}
                setRate={setP131Rate}
                comment={p131Comment}
                setComment={setP131Comment}
                text="i. Others"
                other={true}
                otherText={p131Other}
                updateOther={setP131Other}
                results={p131Results}
                choices={["Low", "Rather low", "Moderate", "Rather high", "High"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td align="right">
            <Message color={messageColor} message={message} />
              <br />
              <NextButtonPanel
                disabled={unchanged}
                onClick={resetValues}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );
};

export default Issue4page3;