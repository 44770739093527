import { Outlet } from "react-router-dom";

import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

const Layout = (props) => {
  return (
    <>
      <NavBar user={props.user} setUser={props.setUser} />

      <Outlet />

      <Footer />
    </>
  )
};

export default Layout;