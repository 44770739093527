import { useState, useEffect } from 'react';
import axios from 'axios';
import { backendServer } from '../../Const';
import RateItem from "./RateItem";
import Message from './Message';

import {readRateAnswer, checkRateFilled, handleBeforeUnload} from '../../SurveyUtils';
import NextButtonPanel from '../../components/NextButtonPanel';

const Issue2page1 = (props) => {
  const [page5Data, setPage5Data] = useState('');
  const [firstRound, setFirstRound] = useState(true);

  const [p17Rate, setP17Rate] = useState(0);
  const [p17Comment, setP17Comment] = useState('');
  const [p17Results, setP17Results] = useState('');
  const [p18Rate, setP18Rate] = useState(0);
  const [p18Comment, setP18Comment] = useState('');
  const [p18Results, setP18Results] = useState('');
  const [p19Rate, setP19Rate] = useState(0);
  const [p19Comment, setP19Comment] = useState('');
  const [p19Results, setP19Results] = useState('');
  const [p20Rate, setP20Rate] = useState(0);
  const [p20Comment, setP20Comment] = useState('');
  const [p20Results, setP20Results] = useState('');
  const [p21Rate, setP21Rate] = useState(0);
  const [p21Comment, setP21Comment] = useState('');
  const [p21Results, setP21Results] = useState('');
  const [p22Rate, setP22Rate] = useState(0);
  const [p22Comment, setP22Comment] = useState('');
  const [p22Results, setP22Results] = useState('');
  const [p23Rate, setP23Rate] = useState(0);
  const [p23Comment, setP23Comment] = useState('');
  const [p23Results, setP23Results] = useState('');
  
  const [filled, setFilled] = useState(false);
  const [unchanged, setUnchanged] = useState(true);
  const [message, setMessage] = useState('');
  const [messageColor, setMessageColor] = useState('');

  useEffect( () => {
    if(unchanged) return;
    
    window.addEventListener('beforeunload',handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload',handleBeforeUnload)
    }
    
  },[unchanged]);

  const setErrorMessage = (msg) => {
    setMessageColor("red");
    setMessage(msg);
  }

  const setOkMessage = (msg) => {
    setMessageColor("teal");
    setMessage(msg);
  }

  const resetValues = () => {
    const data = page5Data?JSON.parse(page5Data):[];
    setP17Rate(data.p17Rate?data.p17Rate:0);
    setP17Comment(data.p17Comment?data.p17Comment:'');
    setP18Rate(data.p18Rate?data.p18Rate:0);
    setP18Comment(data.p18Comment?data.p18Comment:'');
    setP19Rate(data.p19Rate?data.p19Rate:0);
    setP19Comment(data.p19Comment?data.p19Comment:'');
    setP20Rate(data.p20Rate?data.p20Rate:0);
    setP20Comment(data.p20Comment?data.p20Comment:'');
    setP21Rate(data.p21Rate?data.p21Rate:0);
    setP21Comment(data.p21Comment?data.p21Comment:'');
    setP22Rate(data.p22Rate?data.p22Rate:0);
    setP22Comment(data.p22Comment?data.p22Comment:'');
    setP23Rate(data.p23Rate?data.p23Rate:0);
    setP23Comment(data.p23Comment?data.p23Comment:'');
    setUnchanged(true);
  }

  const fetchData = async () => {
    axios
      .post(backendServer + "/get_votes.php", {
        user_id: JSON.parse(props.user).user_id,
        problem_ids: ["17", "18", "19", "20", "21", "22", "23"],
      })
      .then((response) => {
        if (response.data.status === "ok") {
          let answers = JSON.parse(response.data.answers);
          let results = JSON.parse(response.data.results);
          let data = {};

          if(answers['17']) {
            setFirstRound(false);
          }
          
          [data['p17Rate'], data['p17Comment']] = readRateAnswer(answers['17']);
          [data['p18Rate'], data['p18Comment']] = readRateAnswer(answers['18']);
          [data['p19Rate'], data['p19Comment']] = readRateAnswer(answers['19']);
          [data['p20Rate'], data['p20Comment']] = readRateAnswer(answers['20']);
          [data['p21Rate'], data['p21Comment']] = readRateAnswer(answers['21']);
          [data['p22Rate'], data['p22Comment']] = readRateAnswer(answers['22']);
          [data['p23Rate'], data['p23Comment']] = readRateAnswer(answers['23']);

          setPage5Data(JSON.stringify(data));

          setP17Results(results["17"]);
          setP18Results(results["18"]);
          setP19Results(results["19"]);
          setP20Results(results["20"]);
          setP21Results(results["21"]);
          setP22Results(results["22"]);
          setP23Results(results["23"]);

          checkFilled();
          setUnchanged(true);
        } else {
          setErrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  }

  useEffect(()=>{
    fetchData(); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    resetValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[page5Data]);
  
  useEffect(() => {
    setMessage('');
    let data = page5Data?JSON.parse(page5Data):[];
    if (Number(data.p17Rate) !== Number(p17Rate)) { setUnchanged(false); return; }
    if (data.p17Comment !== p17Comment) { setUnchanged(false); return; }
    if (Number(data.p18Rate) !== Number(p18Rate)) { setUnchanged(false); return; }
    if (data.p18Comment !== p18Comment) { setUnchanged(false); return; }
    if (Number(data.p19Rate) !== Number(p19Rate)) { setUnchanged(false); return; }
    if (data.p19Comment !== p19Comment) { setUnchanged(false); return; }
    if (Number(data.p20Rate) !== Number(p20Rate)) { setUnchanged(false); return; }
    if (data.p20Comment !== p20Comment) { setUnchanged(false); return; }
    if (Number(data.p21Rate) !== Number(p21Rate)) { setUnchanged(false); return; }
    if (data.p21Comment !== p21Comment) { setUnchanged(false); return; }
    if (Number(data.p22Rate) !== Number(p22Rate)) { setUnchanged(false); return; }
    if (data.p22Comment !== p22Comment) { setUnchanged(false); return; }
    if (Number(data.p23Rate) !== Number(p23Rate)) { setUnchanged(false); return; }
    if (data.p23Comment !== p23Comment) { setUnchanged(false); return; }

    setUnchanged(true);
  }, [
    p17Rate, p17Comment,
    p18Rate, p18Comment,
    p19Rate, p19Comment,
    p20Rate, p20Comment,
    p21Rate, p21Comment,
    p22Rate, p22Comment,
    p23Rate, p23Comment, page5Data
  ]);

  const checkFilled = () => {
    if(!checkRateFilled(p17Rate, p17Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p18Rate, p18Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p19Rate, p19Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p20Rate, p20Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p21Rate, p21Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p22Rate, p22Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p23Rate, p23Comment, 1, 5)) {setFilled(false); return;}

    setFilled(true);
  }

  
  const navigate = (msg) => {
    window.removeEventListener('beforeunload',handleBeforeUnload);
    if(!msg) msg = 'Proceeding..';
    setOkMessage(msg);
    setTimeout(()=> {
      window.location.href = '/survey?issue=2&page=2';
    }, 1000);
  }
  
  useEffect(() => {   
    checkFilled();
  });

  let admin = JSON.parse(props.user).admin;
  let isAdmin = admin&&admin==="1";
  
  const handleSubmit = (event) => {
    event.preventDefault();    
    if((filled&&unchanged)||isAdmin) { navigate(); return; }
    
    if(!checkRateFilled(p17Rate, p17Comment, 1, 5,
      "Please answer problem 1.",
      "Please provide your reasoning for problem 1.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p18Rate, p18Comment, 1, 5,
      "Please answer problem 2a.",
      "Please provide your reasoning for problem 2a.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p19Rate, p19Comment, 1, 5,
      "Please answer problem 2b.",
      "Please provide your reasoning for problem 2b.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p20Rate, p20Comment, 1, 5,
      "Please answer problem 2c.",
      "Please provide your reasoning for problem 2c.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p21Rate, p21Comment, 1, 5,
      "Please answer problem 2d.",
      "Please provide your reasoning for problem 2d.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p22Rate, p22Comment, 1, 5,
      "Please answer problem 4.",
      "Please provide your reasoning for problem 3.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p23Rate, p23Comment, 1, 5,
      "Please answer problem 4.",
      "Please provide your reasoning for problem 4.",
      setErrorMessage
    )) return;
        
    let user_id = JSON.parse(props.user).user_id;
    let voteData = [];
    voteData.push({user_id, problem_id:17, vote:p17Rate, comment: p17Comment});
    voteData.push({user_id, problem_id:18, vote:p18Rate, comment: p18Comment});
    voteData.push({user_id, problem_id:19, vote:p19Rate, comment: p19Comment});
    voteData.push({user_id, problem_id:20, vote:p20Rate, comment: p20Comment});
    voteData.push({user_id, problem_id:21, vote:p21Rate, comment: p21Comment});
    voteData.push({user_id, problem_id:22, vote:p22Rate, comment: p22Comment});
    voteData.push({user_id, problem_id:23, vote:p23Rate, comment: p23Comment});

    axios.post( backendServer+'/vote.php', {vote_data: JSON.stringify(voteData)})
    .then( (response)=>{
      if(response.data.status==='ok') {
        //fetchData();
        //updatePage5Data();
        setFilled(true);
        setFirstRound(false);
        //setOkMessage("Information Saved.");
        navigate('Responses saved.');
      } else {
        setErrorMessage(response.data.message);
      }
    })
    .catch((error)=>{
      setErrorMessage(error.message);
    });
  } 

  return (
    <form onSubmit={handleSubmit}>
      <table width="600" align="center" cellPadding={8}>
        <tbody>
          <tr>
            <td>
              <h4>Issue 2: Energy Transition</h4>

              <p>
                Energy transition refers to a broad shift in technologies and
                behavior that are needed to replace one source of energy with
                another. The current episode of energy transition is driven by
                significant decarbonization of the energy sector, specifically a
                shift away from fossil-based primary energy sources (including
                coal, oil, and natural gas) to primary renewable energy sources
                (such as solar and wind).
              </p>

              <p>
                APEC’s energy mix is dominated by fossil fuels - 86 percent of
                the total primary energy supply and 75 percent of electricity
                supply. Nonetheless, the region is also experiencing rapid
                transitions to clean energy, including increased renewable
                energy within the electricity supply mix, and a choice of
                hydrogen in final energy. The management and governance of
                energy transitions involve both technological changes and
                socio-economic-environmental aspects. They are characterized by
                large uncertainties and ambiguities that would require an
                understanding of how these transitions will evolve in the
                future.
              </p>

              <p>
                Hence, this part of the survey will focus primarily on the
                supply-side of the energy transition, namely, electricity supply
                (Part 2.1) and hydrogen economy (Part 2.2).
              </p>

              <hr />

              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={17}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p17Rate}
                setRate={setP17Rate}
                comment={p17Comment}
                setComment={setP17Comment}
                text="1. When are you likely to see the energy supply transition fully take place (i.e., in line with net-zero emissions) in APEC?"
                results={p17Results}
                choices={["2030", "2040", "2050", "beyond 2050", "never"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <p>
                2. On a scale of 1 to 5, how significant will the following
                technologies be in the decarbonization of your economy by 2050?
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={18}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p18Rate}
                setRate={setP18Rate}
                comment={p18Comment}
                setComment={setP18Comment}
                text="&nbsp;&nbsp;a. Electrification with renewable energies"
                results={p18Results}
                choices={["Insignificant", "Slightly significant", "Moderately significant", "Significant", "Highly significant"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={19}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p19Rate}
                setRate={setP19Rate}
                comment={p19Comment}
                setComment={setP19Comment}
                text="&nbsp;&nbsp;b. Electrification with fossil fuel and CCS"
                results={p19Results}
                choices={["Insignificant", "Slightly significant", "Moderately significant", "Significant", "Highly significant"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={20}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p20Rate}
                setRate={setP20Rate}
                comment={p20Comment}
                setComment={setP20Comment}
                text="&nbsp;&nbsp;c. Electrification with nuclear power"
                results={p20Results}
                choices={["Insignificant", "Slightly significant", "Moderately significant", "Significant", "Highly significant"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={21}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p21Rate}
                setRate={setP21Rate}
                comment={p21Comment}
                setComment={setP21Comment}
                text="&nbsp;&nbsp;d. Fossil-based hydrogen economy and CCS"
                results={p21Results}
                choices={["Insignificant", "Slightly significant", "Moderately significant", "Significant", "Highly significant"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={22}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p22Rate}
                setRate={setP22Rate}
                comment={p22Comment}
                setComment={setP22Comment}
                text="3. On a scale of 1 to 5, how likely will there be an earlier phase-out of fossil fuel infrastructure (i.e., earlier than its economic life) to meet net-zero emissions in APEC?"
                results={p22Results}
                choices={["Least", "Slightly", "Moderately", "Highly", "Most"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={23}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p23Rate}
                setRate={setP23Rate}
                comment={p23Comment}
                setComment={setP23Comment}
                text="4. Based on your answer in Question 3 (above), and on a scale of 1 to 5, how likely would there be tangible impacts of phasing out from fossil fuel infrastructures on small local communities across APEC economies?"
                results={p23Results}
                choices={["Least", "Slightly", "Moderately", "Highly", "Most"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td align="right">
            <Message color={messageColor} message={message} />
              <br />
              <NextButtonPanel
                disabled={unchanged}
                onClick={resetValues}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );
};

export default Issue2page1;