import './Home.css';

import issues from '../images/issues.png';

import StartSurvey from '../components/StartSurvey.js';

const Home = (props) => {

  // const topFunction = () => {
  //   document.body.scrollTop = 0; // For Safari
  //   document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  // }

  return (
    <div className="content">
      <br />

      <h2>Introduction</h2>

      <p>
        Global warming is one of the greatest challenges for mankind. In 2018,
        the Intergovernmental Panel on Climate Change (IPCC) stated that the
        world needs to reach net zero by 2050 to meet the Paris Agreement target
        of limiting global warming to 1.5°C. The term ‘net zero’ refers to the
        target of reducing the greenhouse gas emissions that cause global
        warming to zero by balancing the amount of greenhouse gas released into
        the atmosphere with the amount of greenhouse gas removed and stored by
        carbon sinks.
      </p>

      <p>
        The APEC Center for Technology Foresight (APEC CTF), a center under
        APEC’s Policy Partnership for Science, Technology and Innovation
        (PPSTI), has initiated a project to identify future challenges, key
        issues, and key enabling technologies for net-zero emissions in APEC. A
        Delphi survey, titled “Decarbonization strategies to achieve net-zero
        emissions by 2050,” has been created to obtain opinions and insights
        from experts. Subsequently, a workshop will be conducted to gain a
        deeper understanding of results and to formulate recommendations for
        APEC economies.
      </p>

      <p>
        The results obtained from this project can be used as a reference for
        the next PPSTI’s strategic plan, as appropriate. This is in line with
        the recent recommendation for PPSTI to adopt a mission-oriented policy
        approach to address challenges in APEC.
      </p>
      <p>
        We sincerely thank you for participating in this Delphi study on Net
        Zero in Decarbonization Strategies to achieve net-zero emissions by
        2050. Your expertise and insights are invaluable in helping us gain a
        deeper understanding of the strategies and technologies related to
        decarbonization in APEC.
      </p>

      <br />

      <h3>Investigation areas for Delphi survey</h3>

      <p>There are 5 issues, namely:</p>

      <p>
        &nbsp; &nbsp; 1. Energy demand management <br />
        &nbsp; &nbsp; 2. Energy Transition <br />
        &nbsp; &nbsp; 3. Green economy <br />
        &nbsp; &nbsp; 4. Carbon emission sinks <br />
        &nbsp; &nbsp; 5. Offset policies <br />
      </p>

      <p>
        Each of these issues is further classified into sub-issues, as shown in
        the picture below. You can choose any of these areas to complete the
        survey and participate more than once. The estimated time to complete
        each round is 10 – 15 minutes. Please feel free to provide any
        additional comments you may have.
      </p>

      <center>
        <img src={issues} width="80%"  alt="Investigating issues" />
      </center>

      <p><i>Delphi is a research or decision-making technique that involves obtaining input from a group of experts through a series of questionnaires, often conducted anonymously. The process aims to reach a consensus on a particular issue by aggregating and analyzing the responses from the participants over multiple rounds. It is commonly used in fields such as forecasting, policy planning, and technology assessment to gather and synthesize expert opinions and insights.</i>
      </p>
      
      <div align="right" style={{ padding: "0px 25px 20px 0px" }}>
        {props.user ? <></> : <b>Plese login to start survey.</b>}
        <div
          align="right"
          style={{ background: "#FFF", padding: "10px 0px", width: "250px" }}
        >
          <StartSurvey user={props.user} setUser={props.setUser} />
        </div>
      </div>
      
      

    </div>
  );
};

export default Home;