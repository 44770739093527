import { useState, useEffect } from 'react';
import axios from 'axios';
import { backendServer, allEconomies } from '../../Const';
import RateItem from "./RateItem";
import SelectItem from "./SelectItem";
import Message from './Message';

import {isDeepEqual, readRateAnswer, readSelectionAnswers, checkRateFilled, checkSelectionFilled, handleBeforeUnload} from '../../SurveyUtils';
import NextButtonPanel from '../../components/NextButtonPanel';

const Issue3page2 = (props) => {
  const [pageData, setPageData] = useState('');
  const [firstRound, setFirstRound] = useState(true);

  const [p69Rate, setP69Rate] = useState(0);
  const [p69Comment, setP69Comment] = useState('');
  const [p69Results, setP69Results] = useState('');
  const [p70Selections, setP70Selections] = useState([]);
  const [p70Comments, setP70Comments] = useState([]);
  const [p70Results, setP70Results] = useState('');
  const [p71Rate, setP71Rate] = useState(0);
  const [p71Comment, setP71Comment] = useState('');
  const [p71Results, setP71Results] = useState('');
  const [p72Selections, setP72Selections] = useState([]);
  const [p72Comments, setP72Comments] = useState([]);
  const [p72Results, setP72Results] = useState('');
  const [p73Selections, setP73Selections] = useState([]);
  const [p73Comments, setP73Comments] = useState([]);
  const [p73Results, setP73Results] = useState('');

  const [filled, setFilled] = useState(false);
  const [unchanged, setUnchanged] = useState(true);
  const [message, setMessage] = useState('');
  const [messageColor, setMessageColor] = useState('');

  useEffect( () => {
    if(unchanged) return;
    
    window.addEventListener('beforeunload',handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload',handleBeforeUnload)
    }
    
  },[unchanged]);

  const setErrorMessage = (msg) => {
    setMessageColor("red");
    setMessage(msg);
  }

  const setOkMessage = (msg) => {
    setMessageColor("teal");
    setMessage(msg);
  }

  const resetValues = () => {
    const data = pageData?JSON.parse(pageData):[];
    setP69Rate(data.p69Rate?data.p69Rate:0);
    setP69Comment(data.p69Comment?data.p69Comment:'');
    setP70Selections(data.p70Selections?data.p70Selections:[]);
    setP70Comments(data.p70Comments?data.p70Comments:[]);
    setP71Rate(data.p71Rate?data.p71Rate:0);
    setP71Comment(data.p71Comment?data.p71Comment:'');
    setP72Selections(data.p72Selections?data.p72Selections:[]);
    setP72Comments(data.p72Comments?data.p72Comments:[]);
    setP73Selections(data.p73Selections?data.p73Selections:[]);
    setP73Comments(data.p73Comments?data.p73Comments:[]);
    setUnchanged(true);
  }
  
  const fetchData = async () => {
    axios
      .post(backendServer + "/get_votes.php", {
        user_id: JSON.parse(props.user).user_id,
        problem_ids: ["69", "70", "71", "72", "73"]
      })
      .then((response) => {
        if (response.data.status === "ok") {
          let answers = JSON.parse(response.data.answers);
          let results = JSON.parse(response.data.results);
          let data = {};

          if(answers['69']) {
            setFirstRound(false);
          }
          
          [data['p69Rate'], data['p69Comment']] = readRateAnswer(answers['69']);
          [data['p70Selections'], data['p70Comments']] = readSelectionAnswers(answers['70']);
          [data['p71Rate'], data['p71Comment']] = readRateAnswer(answers['71']);
          [data['p72Selections'], data['p72Comments']] = readSelectionAnswers(answers['72']);
          [data['p73Selections'], data['p73Comments']] = readSelectionAnswers(answers['73']);

          setPageData(JSON.stringify(data));

          setP69Results(results["69"]);
          setP70Results(results["70"]);
          setP71Results(results["71"]);
          setP72Results(results["72"]);
          setP73Results(results["73"]);

          checkFilled();
          setUnchanged(true);
        } else {
          setErrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  }
  
  useEffect(()=>{
    fetchData(); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    resetValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pageData]);

  useEffect(() => {
    setMessage('');
    let data = pageData?JSON.parse(pageData):[];

    if (Number(data.p69Rate) !== Number(p69Rate)) { setUnchanged(false); return; }
    if (data.p69Comment !== p69Comment) { setUnchanged(false); return; }
    if (!isDeepEqual(data.p70Selections, p70Selections)) 
      { setUnchanged(false); return; }
    if (!isDeepEqual(data.p70Comments, p70Comments)) 
      { setUnchanged(false); return; }    
    if (Number(data.p71Rate) !== Number(p71Rate)) { setUnchanged(false); return; }
    if (data.p71Comment !== p71Comment) { setUnchanged(false); return; }
    if (!isDeepEqual(data.p72Selections, p72Selections)) 
      { setUnchanged(false); return; }
    if (!isDeepEqual(data.p72Comments, p72Comments)) 
      { setUnchanged(false); return; }
    if (!isDeepEqual(data.p73Selections, p73Selections)) 
      { setUnchanged(false); return; }
    if (!isDeepEqual(data.p73Comments, p73Comments)) 
      { setUnchanged(false); return; }
    
    setUnchanged(true);
  }, [
    p69Rate, p69Comment,
    p70Selections, p70Comments,
    p71Rate, p71Comment,
    p72Selections, p72Comments,
    p73Selections, p73Comments, pageData
  ]);

  const checkFilled = () => {
    if(!checkRateFilled(p69Rate, p69Comment, 1, 5)) {setFilled(false); return;}
    if(!checkSelectionFilled(p70Selections, p70Comments, 3, 3)) {setFilled(false); return;}
    if(!checkRateFilled(p71Rate, p71Comment, 1, 5)) {setFilled(false); return;}
    if(!checkSelectionFilled(p72Selections, p72Comments, 3, 3)) {setFilled(false); return;}
    if(!checkSelectionFilled(p73Selections, p73Comments, 1, 1)) {setFilled(false); return;}

    setFilled(true);
  }

  const navigate = (msg) => {
    window.removeEventListener('beforeunload',handleBeforeUnload);
    if(!msg) msg = 'Proceeding..';
    setOkMessage(msg);
    setTimeout(()=> {
      window.location.href = '/survey?issue=3&page=3';
    }, 1000);
  }

  useEffect(() => {   
    checkFilled();
  });
  
  let admin = JSON.parse(props.user).admin;
  let isAdmin = admin&&admin==="1";

  const handleSubmit = (event) => {
    event.preventDefault();
    if((filled&&unchanged)||isAdmin) { navigate(); return; }
    
    if(!checkRateFilled(p69Rate, p69Comment, 1, 5,
      "Please answer problem 1.",
      "Please provide your reasoning for problem 1.",
      setErrorMessage
    )) return;
    if(!checkSelectionFilled(p70Selections, p70Comments, 3, 3,
      "Please select three answers for problem 2.",
      "Please provide your reasoning for each selected answer in problem 2.",
      setErrorMessage,
      "Please specify the other field in problem 2."
    )) return;
    if(!checkRateFilled(p71Rate, p71Comment, 1, 5,
      "Please answer problem 3.",
      "Please provide your reasoning for problem 3.",
      setErrorMessage
    )) return;
    if(!checkSelectionFilled(p72Selections, p72Comments, 3, 3,
      "Please select three answers for problem 4.",
      "Please provide your reasoning for each selected answer in problem 4.",
      setErrorMessage
    )) return;
    if(!checkSelectionFilled(p73Selections, p73Comments, 1, 1,
      "Please select an answer for problem 5.",
      "Please provide your reasoning for each selected answer in problem 5.",
      setErrorMessage,
      "Please specify the other field in problem 5."
    )) return;

    let user_id = JSON.parse(props.user).user_id;
    let voteData = [];
    voteData.push({user_id, problem_id:69, vote:p69Rate, comment: p69Comment});
    p70Selections.forEach((selection,index)=>{
      voteData.push({user_id, problem_id:70, vote:selection, comment: p70Comments[index]});
    });
    voteData.push({user_id, problem_id:71, vote:p71Rate, comment: p71Comment});
    p72Selections.forEach((selection,index)=>{
      voteData.push({user_id, problem_id:72, vote:selection, comment: p72Comments[index]});
    });
    p73Selections.forEach((selection,index)=>{
      voteData.push({user_id, problem_id:73, vote:selection, comment: p73Comments[index]});
    });

    axios.post( backendServer+'/vote.php', {vote_data: JSON.stringify(voteData)})
    .then( (response)=>{
      if(response.data.status==='ok') {
        //fetchData();
        //updatePageData();
        setFilled(true);
        setFirstRound(false);
        //setOkMessage("Responses saved.");
        navigate('Responses saved.');        
      } else {
        setErrorMessage(response.data.message);
      }
    })
    .catch((error)=>{
      setErrorMessage(error.message);
    });
  }
  
  const updateP70Answer = (selection, add) => {
    if(add) {
      let newSelections = [...p70Selections];
      newSelections.push(selection);
      setP70Selections(newSelections);
      let newComments = [...p70Comments];
      let data = pageData?JSON.parse(pageData):[];
      const index = data.p70Selections?data.p70Selections.indexOf(selection):-1;
      newComments.push(index>=0?data.p70Comments[index]:'');
      setP70Comments(newComments);
    } else {
      let index = p70Selections.indexOf(selection);
      if(index>=0) { // sanity check        
        let newSelections = [...p70Selections];
        newSelections.splice(index,1);
        setP70Selections(newSelections);
        let newComments = [...p70Comments];
        newComments.splice(index,1);
        setP70Comments(newComments);
      }
    }
  }

  const updateP70Comment = (answerIndex, comment) => {
    let newComments = [...p70Comments];
    newComments[answerIndex]=comment;
    setP70Comments(newComments);
  }

  const updateP70Other = (otherText, answerIndex) => {
    let newSelections = [...p70Selections];
    newSelections[answerIndex]=otherText;
    setP70Selections(newSelections);
  }
  
  
  const updateP72Answer = (selection, add) => {
    if(add) {
      let newSelections = [...p72Selections];
      newSelections.push(selection);
      setP72Selections(newSelections);
      let newComments = [...p72Comments];
      let data = pageData?JSON.parse(pageData):[];
      const index = data.p72Selections?data.p72Selections.indexOf(selection):-1;
      newComments.push(index>=0?data.p72Comments[index]:'');
      setP72Comments(newComments);
    } else {
      let index = p72Selections.indexOf(selection);
      if(index>=0) { // sanity check        
        let newSelections = [...p72Selections];
        newSelections.splice(index,1);
        setP72Selections(newSelections);
        let newComments = [...p72Comments];
        newComments.splice(index,1);
        setP72Comments(newComments);
      }
    }
  }

  const updateP72Comment = (answerIndex, comment) => {
    let newComments = [...p72Comments];
    newComments[answerIndex]=comment;
    setP72Comments(newComments);
  }

  
  const updateP73Answer = (selection, add) => {
    if(add) {
      let newSelections = [...p73Selections];
      newSelections.push(selection);
      setP73Selections(newSelections);
      let newComments = [...p73Comments];
      let data = pageData?JSON.parse(pageData):[];
      const index = data.p73Selections?data.p73Selections.indexOf(selection):-1;
      newComments.push(index>=0?data.p73Comments[index]:'');
      setP73Comments(newComments);
    } else {
      let index = p73Selections.indexOf(selection);
      if(index>=0) { // sanity check        
        let newSelections = [...p73Selections];
        newSelections.splice(index,1);
        setP73Selections(newSelections);
        let newComments = [...p73Comments];
        newComments.splice(index,1);
        setP73Comments(newComments);
      }
    }
  }

  const updateP73Comment = (answerIndex, comment) => {
    let newComments = [...p73Comments];
    newComments[answerIndex]=comment;
    setP73Comments(newComments);
  }

  const updateP73Other = (otherText, answerIndex) => {
    let newSelections = [...p73Selections];
    newSelections[answerIndex]=otherText;
    setP73Selections(newSelections);
  }

  return (
    <form onSubmit={handleSubmit}>
      <table width="600" align="center" cellPadding={8}>
        <tbody>
          <tr>
            <td>
              <h4>3.2 Agricultural Practices</h4>
              <p>
                Agricultural practices in a green economy refer to the adoption
                of sustainable and environmentally friendly technologies and
                methods within the agricultural sector. These practices
                prioritize the efficient use of resources, minimize negative
                environmental impacts, promote biodiversity, and ensure
                long-term food security while supporting economic viability for
                farmers.
              </p>
              <hr />
              <br />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={69}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p69Rate}
                setRate={setP69Rate}
                comment={p69Comment}
                setComment={setP69Comment}
                text="1. On a scale of 1 to 5, how strongly do you believe sustainable agriculture is essential for addressing global food security and environmental challenges?"
                results={p69Results}
                choices={["Weak", "Rather weak", "Moderate", "Rather strong", "Very strong"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <SelectItem
                problem_id={70}
                firstRound={firstRound}
                adminMode={isAdmin}
                max={3}
                updateAnswer={updateP70Answer}
                updateComment={updateP70Comment}
                selections={p70Selections}
                comments={p70Comments}
                text="2. Based on your justification, please select three technologies with the potential to significantly enhance the sustainability of agricultural practices."
                choices={["Precision Agriculture", "IoT and Sensors", "Drones and UAVs", "Blockchain", "Vertical Farming", "Aquaponics and Hydroponics", "Biotechnology and Bioengineering", "Robotics and Automation", "Soil Health Monitoring", "Water Management Systems", "Waste-to-energy"]}
                other={true}
                updateOther={updateP70Other}
                results={p70Results}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={71}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p71Rate}
                setRate={setP71Rate}
                comment={p71Comment}
                setComment={setP71Comment}
                text="3. Based on your justification, do you agree that accessing affordable smart-farming technologies is the challenging factor for small-scale farmers?"
                results={p71Results}
                choices={["Strongly disagree", "Disagree", "Neutral", "Agree", "Strongly agree"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <SelectItem
                problem_id={72}
                firstRound={firstRound}
                adminMode={isAdmin}
                max={3}
                updateAnswer={updateP72Answer}
                updateComment={updateP72Comment}
                selections={p72Selections}
                comments={p72Comments}
                text="4. Please select three APEC economies that have effective policies for agricultural practices towards green economy."
                choices={allEconomies}
                other={false}
                results={p72Results}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <SelectItem
                problem_id={73}
                firstRound={firstRound}
                adminMode={isAdmin}
                max={1}
                updateAnswer={updateP73Answer}
                updateComment={updateP73Comment}
                selections={p73Selections}
                comments={p73Comments}
                text="5. Based on your justification, what would likely be the most significant change in agricultural practices towards green economy in the next decade?"
                choices={[
                  "Vertical farming expansion",
                  "Digital farming", 
                  "Blockchain-assisted traceability", 
                  "AI and Machine Learning", 
                  "Urban farming", 
                  "Renewable energy integration",
                  "Biotechnology advances", 
                  "Circular economy approach", 
                  "Sustainable Livestock Management", 
                  "Agroforestry Promotion"
                ]}
                choices_hover={[
                  "",
                  "Adoption of digital technologies e.g. IoT sensors, drones to enhance precision farming practices, optimize resource use and monitor crop health in realtime.", 
                  "Transparent and traceable supply chains, ensuring food safety and sustainability.",
                  "For crop prediction, disease detection, resource optimization.",
                  "Rooftop gardens and hydroponics for supporting local food production and reduction of food miles.", 
                  "Rse renewable energy sources to reduce carbon emission.",
                  "For crop improvement and disease resistance with responsible use of GMO crops.",
                  "Reduce waste and promote recycling and reuse in agricultural production system.",
                  "Improve livestock practices, including better animal welfare, reduced methane emissions, and sustainable feed sourcing.",
                  "Enhance integration of agroforestry practices to improve land use efficiency, conserve water, and enhance carbon sequestration."
                ]}
                other={true}
                updateOther={updateP73Other}
                results={p73Results}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td align="right">
            <Message color={messageColor} message={message} />
              <br />
              <NextButtonPanel
                disabled={unchanged}
                onClick={resetValues}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );
};

export default Issue3page2;