import './Survey.css';
import RateResults from './RateResults';
import { useState } from 'react';

const Radio = (props) => { 
  const handleChange = () => {
    props.onChange(props.id);
  }

  return (
    <>
      <input
        type="radio"
        value={props.choice}
        checked={props.checked}
        name={props.name}
        onChange={handleChange}
        disabled={props.disabled}
      />
      <div>{props.choice}</div>
    </>
  );
}

const RateItem = (props) => {
  const rateChanged = (rate) => {
    props.setRate(rate);
  }

  const handleTextChange = (e) => {
    props.setComment(e.target.value);
  }

  const [showResult, setShowResult] = useState(false);

  const toggleResults = (e) => {
    setShowResult(!showResult);
  }

  const handleOtherChange = (e) => {
    props.updateOther(e.target.value);
  }

  const unsetOther = () => {
    props.updateOther('');    
    props.setRate(0);
  }

  return (
    <div style={{ background: "#f0f0f0", padding: "9px 15px" }}>
      {props.text}&nbsp;
      <>
        {props.other ? (
          <span hidden={props.adminMode}>
            <input
              type="text"
              value={props.otherText}
              placeholder="please specify"
              onChange={(e) => handleOtherChange(e)}
            />
            &nbsp;
            <input
              hidden={!props.otherText}
              type="button"
              value="unset"
              onClick={unsetOther}
            />
          </span>
        ) : (
          <></>
        )}
      </>
      <br />
      <table align="right">
        <tbody>
          <tr>
            <td>
              {props.adminMode ? (
                <span>
                  <i>Admin Mode</i>
                </span>
              ) : (
                <>
                  {props.firstRound ? (
                    <>&nbsp;</>
                  ) : (
                    <input
                      type="button"
                      value={
                        showResult ? "resume survey" : "show previous results"
                      }
                      onClick={(e) => toggleResults(e)}
                    />
                  )}
                </>
              )}
            </td>
          </tr>
        </tbody>
      </table>
      <span hidden={!showResult && !props.adminMode}>
        <table width={props.width} className="center">
          <tbody>
            <tr>
              <td>
                <RateResults
                  results={props.results}
                  choices={props.choices}
                  width="500"
                  height="100"
                  other={props.other}
                  otherText={props.otherText}
                />
              </td>
            </tr>

            <tr>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td></td>
            </tr>
          </tbody>
        </table>
      </span>
      <span hidden={showResult | props.adminMode}>
        <table cellPadding={10}>
          <tbody>
            <tr>
              {props.choices.map((choice, index) => (
                <td key={index + 1} width={120} valign="top" align="center">
                  <Radio
                    key={index + 1}
                    id={index + 1}
                    onChange={rateChanged}
                    choice={index + 1 + ". " + choice}
                    checked={Number(props.rate) === index + 1}
                    disabled={props.other && !props.otherText}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <td colSpan={5}>
                <i>Please provide your reasoning</i> <br />
                <center>
                  <textarea
                    type="textarea"
                    rows={4}
                    cols={80}
                    size={500}
                    onChange={(e) => handleTextChange(e)}
                    value={props.comment}
                  ></textarea>
                </center>
              </td>
            </tr>
          </tbody>
        </table>
      </span>
    </div>
  );
};

export default RateItem;