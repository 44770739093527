import { useState, useEffect } from 'react';
import axios from 'axios';
import { backendServer } from '../../Const';
import RateItem from "./RateItem";
import SelectItem from "./SelectItem";
import Message from './Message';

import {allEconomies} from '../../Const'
import {isDeepEqual, readRateAnswer, readSelectionAnswers, checkRateFilled, checkSelectionFilled, handleBeforeUnload} from '../../SurveyUtils';
import NextButtonPanel from '../../components/NextButtonPanel';


const Issue2page2 = (props) => {
  const [page6Data, setPage6Data] = useState('');
  const [firstRound, setFirstRound] = useState(true);

  const [p24Selections, setP24Selections] = useState([]);
  const [p24Comments, setP24Comments] = useState([]);
  const [p24Results, setP24Results] = useState('');  
  const [p25Selections, setP25Selections] = useState([]);
  const [p25Comments, setP25Comments] = useState([]);
  const [p25Results, setP25Results] = useState('');
  const [p26Rate, setP26Rate] = useState(0);
  const [p26Comment, setP26Comment] = useState('');
  const [p26Results, setP26Results] = useState('');
  const [p27Rate, setP27Rate] = useState(0);
  const [p27Comment, setP27Comment] = useState('');
  const [p27Results, setP27Results] = useState('');
  
  const [filled, setFilled] = useState(false);
  const [unchanged, setUnchanged] = useState(true);  
  const [message, setMessage] = useState('');
  const [messageColor, setMessageColor] = useState('');

  useEffect( () => {
    if(unchanged) return;
    
    window.addEventListener('beforeunload',handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload',handleBeforeUnload)
    }
    
  },[unchanged]);

  const setErrorMessage = (msg) => {
    setMessageColor("red");
    setMessage(msg);
  }

  const setOkMessage = (msg) => {
    setMessageColor("teal");
    setMessage(msg);
  }


  const resetValues = () => {
    const data = page6Data?JSON.parse(page6Data):[];
    setP24Selections(data.p24Selections?data.p24Selections:[]);
    setP24Comments(data.p24Comments?data.p24Comments:[]);
    setP25Selections(data.p25Selections?data.p25Selections:[]);
    setP25Comments(data.p25Comments?data.p25Comments:[]);
    setP26Rate(data.p26Rate?data.p26Rate:0);
    setP26Comment(data.p26Comment?data.p26Comment:'');
    setP27Rate(data.p27Rate?data.p27Rate:0);
    setP27Comment(data.p27Comment?data.p27Comment:'');
    setUnchanged(true);
  }
  
  const fetchData = async () => {
    axios
      .post(backendServer + "/get_votes.php", {
        user_id: JSON.parse(props.user).user_id,
        problem_ids: ["24", "25", "26", "27"],
      })
      .then((response) => {
        if (response.data.status === "ok") {
          let answers = JSON.parse(response.data.answers);
          let results = JSON.parse(response.data.results);
          let data = {};

          if(answers['24']) {
            setFirstRound(false);
          }
          
          [data['p24Selections'], data['p24Comments']] = readSelectionAnswers(answers['24']);
          [data['p25Selections'], data['p25Comments']] = readSelectionAnswers(answers['25']);          
          [data['p26Rate'], data['p26Comment']] = readRateAnswer(answers['26']);
          [data['p27Rate'], data['p27Comment']] = readRateAnswer(answers['27']);

          setPage6Data(JSON.stringify(data));

          setP24Results(results["24"]);
          setP25Results(results["25"]);
          setP26Results(results["26"]);
          setP27Results(results["27"]);

          checkFilled();
          setUnchanged(true);
        } else {
          setErrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  }
    
  useEffect(()=>{
    fetchData(); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    resetValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[page6Data]);
    
  useEffect(() => {
    setMessage('');
    let data = page6Data?JSON.parse(page6Data):[];

    if (!isDeepEqual(data.p24Selections, p24Selections)) 
      { setUnchanged(false); return; }
    if (!isDeepEqual(data.p24Comments, p24Comments)) 
      { setUnchanged(false); return; }
    if (!isDeepEqual(data.p25Selections, p25Selections)) 
      { setUnchanged(false); return; }
    if (!isDeepEqual(data.p25Comments, p25Comments)) 
      { setUnchanged(false); return; }
    if (Number(data.p26Rate) !== Number(p26Rate)) { setUnchanged(false); return; }
    if (data.p26Comment !== p26Comment) { setUnchanged(false); return; }
    if (Number(data.p27Rate) !== Number(p27Rate)) { setUnchanged(false); return; }
    if (data.p27Comment !== p27Comment) { setUnchanged(false); return; }

    setUnchanged(true);
  }, [
    p24Selections, p24Comments,
    p25Selections, p25Comments,
    p26Rate, p26Comment,
    p27Rate, p27Comment, page6Data
  ]);

  const checkFilled = () => {    
    if(!checkSelectionFilled(p24Selections, p24Comments, 1, 1)) 
      {setFilled(false); return;}
    if(!checkSelectionFilled(p25Selections, p25Comments, 1, 1)) 
      {setFilled(false); return;}
      if(!checkRateFilled(p26Rate, p26Comment, 1, 5)) {setFilled(false); return;}
      if(!checkRateFilled(p27Rate, p27Comment, 1, 5)) {setFilled(false); return;}

    setFilled(true);
  }

  const navigate = (msg) => {
    window.removeEventListener('beforeunload',handleBeforeUnload);
    if(!msg) msg = 'Proceeding..';
    setOkMessage(msg);
    setTimeout(()=> {
      window.location.href = '/survey?issue=2&page=3';
    }, 1000);
  }

  useEffect(() => {   
    checkFilled();
  });
  
  const updateP24Answer = (selection, add) => {
    if(add) {
      let newSelections = [...p24Selections];
      newSelections.push(selection);
      setP24Selections(newSelections);
      let newComments = [...p24Comments];
      let data = page6Data?JSON.parse(page6Data):[];
      const index = data.p24Selections?data.p24Selections.indexOf(selection):-1;
      newComments.push(index>=0?data.p24Comments[index]:'');
      setP24Comments(newComments);
    } else {
      let index = p24Selections.indexOf(selection);
      if(index>=0) { // sanity check        
        let newSelections = [...p24Selections];
        newSelections.splice(index,1);
        setP24Selections(newSelections);
        let newComments = [...p24Comments];
        newComments.splice(index,1);
        setP24Comments(newComments);
      }
    }
  }

  const updateP24Comment = (answerIndex, comment) => {
    let newComments = [...p24Comments];
    newComments[answerIndex]=comment;
    setP24Comments(newComments);
  }
  
  const updateP25Answer = (selection, add) => {
    if(add) {
      let newSelections = [...p25Selections];
      newSelections.push(selection);
      setP25Selections(newSelections);
      let newComments = [...p25Comments];
      let data = page6Data?JSON.parse(page6Data):[];
      const index = data.p25Selections?data.p25Selections.indexOf(selection):-1;
      newComments.push(index>=0?data.p25Comments[index]:'');
      setP25Comments(newComments);
    } else {
      let index = p25Selections.indexOf(selection);
      if(index>=0) { // sanity check        
        let newSelections = [...p25Selections];
        newSelections.splice(index,1);
        setP25Selections(newSelections);
        let newComments = [...p25Comments];
        newComments.splice(index,1);
        setP25Comments(newComments);
      }
    }
  }

  const updateP25Comment = (answerIndex, comment) => {
    let newComments = [...p25Comments];
    newComments[answerIndex]=comment;
    setP25Comments(newComments);
  }

  let admin = JSON.parse(props.user).admin;
  let isAdmin = admin&&admin==="1";
    
  const handleSubmit = (event) => {
    event.preventDefault();
    if((filled&&unchanged)||isAdmin) { navigate(); return; }
    
    if(!checkSelectionFilled(p24Selections, p24Comments, 1, 1,
      "Please select an answer for problem 1a.",
      "Please provide your reasoning for the selected answer in problem 1a.",
      setErrorMessage,
    )) return;
    if(!checkSelectionFilled(p25Selections, p25Comments, 1, 1,
      "Please select an answer for problem 1b.",
      "Please provide your reasoning for each selected answer in problem 1b.",
      setErrorMessage,
    )) return;
    if(!checkRateFilled(p26Rate, p26Comment, 1, 5,
      "Please answer problem 2.",
      "Please provide your reasoning for problem 2.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p27Rate, p27Comment, 1, 5,
      "Please answer problem 3.",
      "Please provide your reasoning for problem 3.",
      setErrorMessage
    )) return;
        
    let user_id = JSON.parse(props.user).user_id;
    let voteData = [];
    p24Selections.forEach((selection,index)=>{
      voteData.push({user_id, problem_id:24, vote:selection, comment: p24Comments[index]});
    });
    p25Selections.forEach((selection,index)=>{
      voteData.push({user_id, problem_id:25, vote:selection, comment: p25Comments[index]});
    });
    voteData.push({user_id, problem_id:26, vote:p26Rate, comment: p26Comment});
    voteData.push({user_id, problem_id:27, vote:p27Rate, comment: p27Comment});

    axios.post( backendServer+'/vote.php', {vote_data: JSON.stringify(voteData)})
    .then( (response)=>{
      if(response.data.status==='ok') {
        //fetchData();
        //updatePage6Data();
        setFilled(true);
        setFirstRound(false);
        //setOkMessage("Responses saved.");
        navigate('Responses saved.');
      } else {
        setErrorMessage(response.data.message);
      }
    })
    .catch((error)=>{
      setErrorMessage(error.message);
    });
  } 

  return (
    <form onSubmit={handleSubmit}>
      <table width="600" align="center" cellPadding={8}>
        <tbody>
          <tr>
            <td>
              <h4>2.1 Electricity Supply</h4>

              <p>
                Electricity supply refers to the network of components needed to
                generate, transmit, distribute, and supply electric energy to
                various demand sectors of the economy. It comprises of an
                electricity-generation system that converts primary energy
                (fossil and non-fossil) into electrical energy, and
                transmission-and-distribution systems (centralized and
                decentralized networks) to deliver electrical energy for final
                usage.
              </p>
              <hr />
            </td>
          </tr>
          <tr>
            <td>
              1. Based on your justification, please select APEC economies that
              are most and least responsive to decarbonizing their electricity
              system towards net zero by 2050.
            </td>
          </tr>
          <tr>
            <td>
              <SelectItem
                problem_id={24}
                firstRound={firstRound}
                adminMode={isAdmin}
                max={1}
                updateAnswer={updateP24Answer}
                updateComment={updateP24Comment}
                selections={p24Selections}
                comments={p24Comments}
                text="&nbsp;&nbsp;a. Most responsive"
                choices={allEconomies}
                other={false}
                results={p24Results}
              />
            </td>
          </tr>
          <tr>
            <td>
              <SelectItem
                problem_id={25}
                firstRound={firstRound}
                adminMode={isAdmin}
                max={1}
                updateAnswer={updateP25Answer}
                updateComment={updateP25Comment}
                selections={p25Selections}
                comments={p25Comments}
                text="&nbsp;&nbsp;b. Least responsive"
                choices={allEconomies}
                other={false}
                results={p25Results}
              />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={26}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p26Rate}
                setRate={setP26Rate}
                comment={p26Comment}
                setComment={setP26Comment}
                text="2. On a scale of 1 to 5, how confident do you perceive APEC economies to effectively collaborate on cross-border electricity trading towards achieving net-zero emissions?"
                results={p26Results}
                choices={["Not confident", "Slightly confident", "Moderately confident", "Confident", "Very confident"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={27}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p27Rate}
                setRate={setP27Rate}
                comment={p27Comment}
                setComment={setP27Comment}
                text="3. On a scale of 1 to 5, how desirable is it to achieve a 100% renewable energy target in electricity generation in your economy?"
                results={p27Results}
                choices={["Insignificant", "Slightly significant", "Moderately significant", "Significant", "Highly significant"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td align="right">
            <Message color={messageColor} message={message} />
              <br />
              <NextButtonPanel
                disabled={unchanged}
                onClick={resetValues}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );
};

export default Issue2page2;