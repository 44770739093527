import axios from "axios";
import { useEffect, useState } from "react";
import { backendServer } from "../Const";
import { controlTextWidth } from "../SurveyUtils";


const Review = (props) => {
  const [comments, setComments] = useState('[]');
  const [approvedList, setApprovedList] = useState([]);
  
  const fetchData = async () => {
    axios.post(backendServer+"/get_comments_to_approved.php")
    .then((response)=>{
      setComments(response.data.comments)
    }).catch((error)=>{
      console.log("Error:"+error.message);
    });
  }

  useEffect(() => { 
    fetchData();      
  }, []);

  let isAdmin = false;
  
  if(props.user) {
    let admin = JSON.parse(props.user).admin;
    isAdmin = admin&&admin==="1";
  }

  const approveComment = (e) => {
    let vote_id = e.target.getAttribute("vote_id");

    let index = approvedList.indexOf(vote_id);
    if(index<0) {
      axios.post(backendServer+"/approve.php",{vote_id:vote_id, approve:true})
      .then((response)=>{
        console.log("a:"+JSON.stringify(response.data));
        if(response.data.status==='ok') 
          setApprovedList([...approvedList,vote_id]);        
        else console.log("Error 1");
      }).catch((error)=>{
        console.log("Error 2:"+error.message);        
      });
    } else {
      axios.post(backendServer+"/approve.php",{vote_id:vote_id, approve:false})
      .then((response)=>{
        let newList = [...approvedList];
        newList.splice(index,1);
        setApprovedList(newList);
      }).catch((error)=>{
        console.log("Error 3:"+error.message);        
      });
    }
  }

  const refreshList = (e) => {
    console.log("refresh list");
  }

  let key = 1;

  if(!isAdmin) return <div align="center">Not authorized</div>

  return (
    <div className="content">
      <br />
      <center>
        <input
          className="blueButton"
          onClick={(e) => refreshList(e)}
          type="button"
          value="Refrest List"
        />
      </center>
      <br />
      <table width={600} align="center" cellPadding={6} cellSpacing={1}>
        <thead>
          <tr bgcolor="#AAAAAA">
            <td width={70} align="center">
              Question
            </td>
            <td width={75} align="center">
              Response
            </td>
            <td align="center">Comment</td>
            <td width={80}>&nbsp;</td>
          </tr>
        </thead>
        <tbody bgcolor="#CCCCCC">
          {JSON.parse(comments).map((comment) => (
            <tr key={key++} id={comment.vote_id} bgcolor={approvedList.indexOf(comment.vote_id)<0?"#CCCCCC":"#F0F0F0"} >
              <td colSpan={2}>
                {comment.problem_id}. &nbsp;{" "}
                {controlTextWidth(comment.vote, 12)}
              </td>
              <td>{comment.comment}</td>
              <td align="center">
                <input
                  type="button"
                  value={approvedList.indexOf(comment.vote_id)<0?"approve":"unapprove"}
                  approved="false"
                  vote_id={comment.vote_id}
                  onClick={(e) => approveComment(e)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <br />

      <center>
        <input
          className="blueButton"
          onClick={(e) => refreshList(e)}
          type="button"
          value="Refrest List"
        />
      </center>
    </div>
  );
}

export default Review;