import { useState, useEffect } from 'react';
import axios from 'axios';
import { backendServer, allEconomies } from '../../Const';
import RateItem from "./RateItem";
import SelectItem from "./SelectItem";
import Message from './Message';

import {isDeepEqual, readRateAnswer, readSelectionAnswers, checkRateFilled, checkSelectionFilled, handleBeforeUnload} from '../../SurveyUtils';
import NextButtonPanel from '../../components/NextButtonPanel';


const Issue3page3 = (props) => {
  const [pageData, setPageData] = useState('');
  const [firstRound, setFirstRound] = useState(true);

  const [p74Rate, setP74Rate] = useState(0);
  const [p74Comment, setP74Comment] = useState('');
  const [p74Results, setP74Results] = useState('');
  const [p75Selections, setP75Selections] = useState([]);
  const [p75Comments, setP75Comments] = useState([]);
  const [p75Results, setP75Results] = useState('');
  const [p76Selections, setP76Selections] = useState([]);
  const [p76Comments, setP76Comments] = useState([]);
  const [p76Results, setP76Results] = useState('');
  const [p77Selections, setP77Selections] = useState([]);
  const [p77Comments, setP77Comments] = useState([]);
  const [p77Results, setP77Results] = useState('');
  const [p78Rate, setP78Rate] = useState(0);
  const [p78Comment, setP78Comment] = useState('');
  const [p78Results, setP78Results] = useState('');
  const [p79Rate, setP79Rate] = useState(0);
  const [p79Comment, setP79Comment] = useState('');
  const [p79Results, setP79Results] = useState('');
  const [p80Rate, setP80Rate] = useState(0);
  const [p80Comment, setP80Comment] = useState('');
  const [p80Results, setP80Results] = useState('');
  const [p81Rate, setP81Rate] = useState(0);
  const [p81Comment, setP81Comment] = useState('');
  const [p81Results, setP81Results] = useState('');
  const [p82Rate, setP82Rate] = useState(0);
  const [p82Comment, setP82Comment] = useState('');
  const [p82Results, setP82Results] = useState('');
  const [p83Rate, setP83Rate] = useState(0);
  const [p83Comment, setP83Comment] = useState('');
  const [p83Results, setP83Results] = useState('');
  const [p83Other, setP83Other] = useState('');
  
  const [filled, setFilled] = useState(false);
  const [unchanged, setUnchanged] = useState(true);
  const [message, setMessage] = useState('');
  const [messageColor, setMessageColor] = useState('');

  useEffect( () => {
    if(unchanged) return;    
    
    window.addEventListener('beforeunload',handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload',handleBeforeUnload)
    }
    
  },[unchanged]);

  const setErrorMessage = (msg) => {
    setMessageColor("red");
    setMessage(msg);
  }

  const setOkMessage = (msg) => {
    setMessageColor("teal");
    setMessage(msg);
  }

  const resetValues = () => {
    const data = pageData?JSON.parse(pageData):[];
    setP74Rate(data.p74Rate?data.p74Rate:0);
    setP74Comment(data.p74Comment?data.p74Comment:'');
    setP75Selections(data.p75Selections?data.p75Selections:[]);
    setP75Comments(data.p75Comments?data.p75Comments:[]);
    setP76Selections(data.p76Selections?data.p76Selections:[]);
    setP76Comments(data.p76Comments?data.p76Comments:[]);
    setP77Selections(data.p77Selections?data.p77Selections:[]);
    setP77Comments(data.p77Comments?data.p77Comments:[]);
    setP78Rate(data.p78Rate?data.p78Rate:0);
    setP78Comment(data.p78Comment?data.p78Comment:'');
    setP79Rate(data.p79Rate?data.p79Rate:0);
    setP79Comment(data.p79Comment?data.p79Comment:'');
    setP80Rate(data.p80Rate?data.p80Rate:0);
    setP80Comment(data.p80Comment?data.p80Comment:'');
    setP81Rate(data.p81Rate?data.p81Rate:0);
    setP81Comment(data.p81Comment?data.p81Comment:'');
    setP82Rate(data.p82Rate?data.p82Rate:0);
    setP82Comment(data.p82Comment?data.p82Comment:'');
    setP83Rate(data.p83Rate?data.p83Rate:0);
    setP83Comment(data.p83Comment?data.p83Comment:'');
    setP83Other(data.p83Other?data.p83Other:'');

    setUnchanged(true);
  }
  
  const fetchData = async () => {
    axios
      .post(backendServer + "/get_votes.php", {
        user_id: JSON.parse(props.user).user_id,
        problem_ids: ["74", "75", "76", "77", "78", "79", "80", "81", "82", "83"]
      })
      .then((response) => {
        if (response.data.status === "ok") {
          let answers = JSON.parse(response.data.answers);
          let results = JSON.parse(response.data.results);
          let data = {};

          if(answers['74']) {
            setFirstRound(false);
          }

          
          [data['p74Rate'], data['p74Comment']] = readRateAnswer(answers['74']);
          [data['p75Selections'], data['p75Comments']] = readSelectionAnswers(answers['75']);
          [data['p76Selections'], data['p76Comments']] = readSelectionAnswers(answers['76']);
          [data['p77Selections'], data['p77Comments']] = readSelectionAnswers(answers['77']);
          [data['p78Rate'], data['p78Comment']] = readRateAnswer(answers['78']);
          [data['p79Rate'], data['p79Comment']] = readRateAnswer(answers['79']);
          [data['p80Rate'], data['p80Comment']] = readRateAnswer(answers['80']);
          [data['p81Rate'], data['p81Comment']] = readRateAnswer(answers['81']);
          [data['p82Rate'], data['p82Comment']] = readRateAnswer(answers['82']);
          [data['p83Rate'], data['p83Comment']] = readRateAnswer(answers['83']);
          if(answers['83']&&answers['83'].other) data['p83Other'] = answers['83'].other;
          else data['p83Other']='';

          setPageData(JSON.stringify(data));

          setP74Results(results["74"]);
          setP75Results(results["75"]);
          setP76Results(results["76"]);
          setP77Results(results["77"]);
          setP78Results(results["78"]);
          setP79Results(results["79"]);
          setP80Results(results["80"]);
          setP81Results(results["81"]);
          setP82Results(results["82"]);
          setP83Results(results["83"]);
          
          checkFilled();
          setUnchanged(true);
        } else {
          setErrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  }
  
  useEffect(()=>{
    fetchData(); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    resetValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pageData]);

  useEffect(() => {
    setMessage('');
    let data = pageData?JSON.parse(pageData):[];

    if (Number(data.p74Rate) !== Number(p74Rate)) { setUnchanged(false); return; }
    if (data.p74Comment !== p74Comment) { setUnchanged(false); return; }
    if (!isDeepEqual(data.p75Selections, p75Selections)) 
      { setUnchanged(false); return; }
    if (!isDeepEqual(data.p75Comments, p75Comments)) 
      { setUnchanged(false); return; }
    if (!isDeepEqual(data.p76Selections, p76Selections)) 
      { setUnchanged(false); return; }
    if (!isDeepEqual(data.p76Comments, p76Comments)) 
      { setUnchanged(false); return; }
    if (!isDeepEqual(data.p77Selections, p77Selections)) 
      { setUnchanged(false); return; }
    if (!isDeepEqual(data.p77Comments, p77Comments)) 
      { setUnchanged(false); return; }
    if (Number(data.p78Rate) !== Number(p78Rate)) { setUnchanged(false); return; }
    if (data.p78Comment !== p78Comment) { setUnchanged(false); return; }
    if (Number(data.p79Rate) !== Number(p79Rate)) { setUnchanged(false); return; }
    if (data.p79Comment !== p79Comment) { setUnchanged(false); return; }
    if (Number(data.p80Rate) !== Number(p80Rate)) { setUnchanged(false); return; }
    if (data.p80Comment !== p80Comment) { setUnchanged(false); return; }
    if (Number(data.p81Rate) !== Number(p81Rate)) { setUnchanged(false); return; }
    if (data.p81Comment !== p81Comment) { setUnchanged(false); return; }
    if (Number(data.p82Rate) !== Number(p82Rate)) { setUnchanged(false); return; }
    if (data.p82Comment !== p82Comment) { setUnchanged(false); return; }
    if (Number(data.p83Rate) !== Number(p83Rate)) { setUnchanged(false); return; }
    if (data.p83Comment !== p83Comment) { setUnchanged(false); return; }
    if (data.p83Other !== p83Other) { setUnchanged(false); return; }    

    setUnchanged(true);
  }, [
    p74Rate, p74Comment,
    p75Selections, p75Comments,
    p76Selections, p76Comments,
    p77Selections, p77Comments,
    p78Rate, p78Comment,
    p79Rate, p79Comment,
    p80Rate, p80Comment,
    p81Rate, p81Comment,
    p82Rate, p82Comment,
    p83Rate, p83Comment, p83Other, pageData
  ]);

  const checkFilled = () => {
    if(!checkRateFilled(p74Rate, p74Comment, 1, 5)) {setFilled(false); return;}
    if(!checkSelectionFilled(p75Selections, p75Comments, 3, 3)) {setFilled(false); return;}
    if(!checkSelectionFilled(p76Selections, p76Comments, 5, 5)) {setFilled(false); return;}
    if(!checkSelectionFilled(p77Selections, p77Comments, 1, 1)) {setFilled(false); return;}
    if(!checkRateFilled(p78Rate, p78Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p79Rate, p79Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p80Rate, p80Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p81Rate, p81Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p82Rate, p82Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p83Rate, p83Comment, 1, 5)) {setFilled(false); return;}
    //if(!p83Other) {setFilled(false); return;}

    setFilled(true);
  }

  const navigate = (msg) => {
    window.removeEventListener('beforeunload',handleBeforeUnload); 
    if(!msg) msg = 'Proceeding..';
    setOkMessage(msg);
    setTimeout(()=> {
      window.location.href = '/survey?issue=3&page=4';
    }, 1000);
  }

  useEffect(() => {   
    checkFilled();
  });
 
  let admin = JSON.parse(props.user).admin;
  let isAdmin = admin&&admin==="1";

  const handleSubmit = (event) => {
    event.preventDefault();
    if((filled&&unchanged)||isAdmin) { navigate(); return; }
    
    if(!checkRateFilled(p74Rate, p74Comment, 1, 5,
      "Please answer problem 1.",
      "Please provide your reasoning for problem 1.",
      setErrorMessage
    )) return;
    if(!checkSelectionFilled(p75Selections, p75Comments, 3, 3,
      "Please select three answers for problem 2.",
      "Please provide your reasoning for each selected answer in problem 2.",
      setErrorMessage
    )) return;
    if(!checkSelectionFilled(p76Selections, p76Comments, 5, 5,
      "Please select five answers for problem 3.",
      "Please provide your reasoning for each selected answer in problem 3.",
      setErrorMessage,
      "Please specify the other field in problem 3."
    )) return;
    if(!checkSelectionFilled(p77Selections, p77Comments, 1, 1,
      "Please select an answer for problem 4.",
      "Please provide your reasoning for each selected answer in problem 4.",
      setErrorMessage,
      "Please specify the other field in problem 4."
    )) return;
    if(!checkRateFilled(p78Rate, p78Comment, 1, 5,
      "Please answer problem 5a.",
      "Please provide your reasoning for problem 5a.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p79Rate, p79Comment, 1, 5,
      "Please answer problem 5b.",
      "Please provide your reasoning for problem 5b.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p80Rate, p80Comment, 1, 5,
      "Please answer problem 5c.",
      "Please provide your reasoning for problem 5c.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p81Rate, p81Comment, 1, 5,
      "Please answer problem 5d.",
      "Please provide your reasoning for problem 5d.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p82Rate, p82Comment, 1, 5,
      "Please answer problem 5e.",
      "Please provide your reasoning for problem 5e.",
      setErrorMessage
    )) return;
    if(p83Other) {
      if(!checkRateFilled(p83Rate, p83Comment, 1, 5,
        "Please answer problem 5f.",
        "Please provide your reasoning for problem 5f.",
        setErrorMessage
      )) return;
    }
    // if(!p83Other) {
    //   setErrorMessage("Please specify the other field in problem 5f.");
    //   return;
    // }

    let user_id = JSON.parse(props.user).user_id;
    let voteData = [];
    voteData.push({user_id, problem_id:74, vote:p74Rate, comment: p74Comment});
    p75Selections.forEach((selection,index)=>{
      voteData.push({user_id, problem_id:75, vote:selection, comment: p75Comments[index]});
    });
    p76Selections.forEach((selection,index)=>{
      voteData.push({user_id, problem_id:76, vote:selection, comment: p76Comments[index]});
    });
    p77Selections.forEach((selection,index)=>{
      voteData.push({user_id, problem_id:77, vote:selection, comment: p77Comments[index]});
    });
    voteData.push({user_id, problem_id:78, vote:p78Rate, comment: p78Comment});
    voteData.push({user_id, problem_id:79, vote:p79Rate, comment: p79Comment});
    voteData.push({user_id, problem_id:80, vote:p80Rate, comment: p80Comment});
    voteData.push({user_id, problem_id:81, vote:p81Rate, comment: p81Comment});
    voteData.push({user_id, problem_id:82, vote:p82Rate, comment: p82Comment});
    if(p83Other) 
      voteData.push({user_id, problem_id:83, vote:p83Rate, comment: p83Comment, other:p83Other});

    axios.post( backendServer+'/vote.php', {vote_data: JSON.stringify(voteData)})
    .then( (response)=>{
      if(response.data.status==='ok') {
        //fetchData();
        //updatePageData();
        setFilled(true);
        setFirstRound(false);
        //setOkMessage("Responses saved.");
        navigate('Responses saved.');
      } else {
        setErrorMessage(response.data.message);
      }
    })
    .catch((error)=>{
      setErrorMessage(error.message);
    });
  }
  
  const updateP75Answer = (selection, add) => {
    if(add) {
      let newSelections = [...p75Selections];
      newSelections.push(selection);
      setP75Selections(newSelections);
      let newComments = [...p75Comments];
      let data = pageData?JSON.parse(pageData):[];
      const index = data.p75Selections?data.p75Selections.indexOf(selection):-1;
      newComments.push(index>=0?data.p75Comments[index]:'');
      setP75Comments(newComments);
    } else {
      let index = p75Selections.indexOf(selection);
      if(index>=0) { // sanity check        
        let newSelections = [...p75Selections];
        newSelections.splice(index,1);
        setP75Selections(newSelections);
        let newComments = [...p75Comments];
        newComments.splice(index,1);
        setP75Comments(newComments);
      }
    }
  }

  const updateP75Comment = (answerIndex, comment) => {
    let newComments = [...p75Comments];
    newComments[answerIndex]=comment;
    setP75Comments(newComments);
  }

  const updateP76Answer = (selection, add) => {
    if(add) {
      let newSelections = [...p76Selections];
      newSelections.push(selection);
      setP76Selections(newSelections);
      let newComments = [...p76Comments];
      let data = pageData?JSON.parse(pageData):[];
      const index = data.p76Selections?data.p76Selections.indexOf(selection):-1;
      newComments.push(index>=0?data.p76Comments[index]:'');
      setP76Comments(newComments);
    } else {
      let index = p76Selections.indexOf(selection);
      if(index>=0) { // sanity check        
        let newSelections = [...p76Selections];
        newSelections.splice(index,1);
        setP76Selections(newSelections);
        let newComments = [...p76Comments];
        newComments.splice(index,1);
        setP76Comments(newComments);
      }
    }
  }

  const updateP76Comment = (answerIndex, comment) => {
    let newComments = [...p76Comments];
    newComments[answerIndex]=comment;
    setP76Comments(newComments);
  }

  const updateP76Other = (otherText, answerIndex) => {
    let newSelections = [...p76Selections];
    newSelections[answerIndex]=otherText;
    setP76Selections(newSelections);
  }

  const updateP77Answer = (selection, add) => {
    if(add) {
      let newSelections = [...p77Selections];
      newSelections.push(selection);
      setP77Selections(newSelections);
      let newComments = [...p77Comments];
      let data = pageData?JSON.parse(pageData):[];
      const index = data.p77Selections?data.p77Selections.indexOf(selection):-1;
      newComments.push(index>=0?data.p77Comments[index]:'');
      setP77Comments(newComments);
    } else {
      let index = p77Selections.indexOf(selection);
      if(index>=0) { // sanity check        
        let newSelections = [...p77Selections];
        newSelections.splice(index,1);
        setP77Selections(newSelections);
        let newComments = [...p77Comments];
        newComments.splice(index,1);
        setP77Comments(newComments);
      }
    }
  }

  const updateP77Comment = (answerIndex, comment) => {
    let newComments = [...p77Comments];
    newComments[answerIndex]=comment;
    setP77Comments(newComments);
  }

  const updateP77Other = (otherText, answerIndex) => {
    let newSelections = [...p77Selections];
    newSelections[answerIndex]=otherText;
    setP77Selections(newSelections);
  }

  return (
    <form onSubmit={handleSubmit}>
      <table width="600" align="center" cellPadding={8}>
        <tbody>
          <tr>
            <td>
              <h4>3.3 Waste Management</h4>
              <p>
                Waste-disposal management can be defined as the actions required
                to manage wastes from the first production stages up to the
                final disposal stage. This includes collection, transportation,
                treatments, and disposal of waste along with monitoring and
                regulating. It also encompasses the legal and regulatory
                frameworks that are related to waste management, including
                guidance on recycling. Wastes are expensive and sometimes
                unavoidable results of human activities, which could have
                serious consequences on the environment and the quality of life
                if not adequately addressed. To achieve green growth,
                investments on environmental management should be an engine of
                economic growth. Modern waste-management practices involve not
                only the treatment of wastes but also the conversion into useful
                substances.
              </p>
              <hr />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={74}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p74Rate}
                setRate={setP74Rate}
                comment={p74Comment}
                setComment={setP74Comment}
                text="1. On a scale of 1 to 5, please rate the effectiveness of waste management policies within your economy."
                results={p74Results}
                choices={[
                  "Least Effective",
                  "Somehow Effective",
                  "Moderately Effective",
                  "Very Effective",
                  "Most Effective",
                ]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <SelectItem
                problem_id={75}
                firstRound={firstRound}
                adminMode={isAdmin}
                max={3}
                updateAnswer={updateP75Answer}
                updateComment={updateP75Comment}
                selections={p75Selections}
                comments={p75Comments}
                text="2. Based on your justification, please select three APEC economies that have effective waste-management policies."
                choices={allEconomies}
                results={p75Results}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <SelectItem
                problem_id={76}
                firstRound={firstRound}
                adminMode={isAdmin}
                max={5}
                updateAnswer={updateP76Answer}
                updateComment={updateP76Comment}
                selections={p76Selections}
                comments={p76Comments}
                text="3. From the choices below, please select five sources of waste in terms of environmental impact urgency in APEC economies."
                choices={[
                  "Packaging and packaging waste",
                  "Construction and demolition waste",
                  "Waste Electrical and Electronic Equipment (WEEE)",
                  "Hazardous waste",
                  "Biodegradable waste",
                  "Food waste",
                  "Agriculture",
                  "Municipal solid waste",
                  "Industrial waste",
                  "Biomedical waste",
                  "Chemical waste",
                ]}
                other={true}
                updateOther={updateP76Other}
                results={p76Results}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <SelectItem
                problem_id={77}
                firstRound={firstRound}
                adminMode={isAdmin}
                max={1}
                updateAnswer={updateP77Answer}
                updateComment={updateP77Comment}
                selections={p77Selections}
                comments={p77Comments}
                text="4. Which specific waste-management targets should APEC agree upon for the approaching year 2050 to achieve net-zero emissions?"
                choices={[
                  "Recovery target of 100%",
                  "Recovery target of 90%",
                  "Recovery target of 80%",
                  "Recovery target of 70%",
                ]}
                other={true}
                updateOther={updateP77Other}
                results={p77Results}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
                5. To what extent do you believe the collaboration among APEC
                economies regarding waste management are driving progress
                towards net-zero emissions?
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={78}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p78Rate}
                setRate={setP78Rate}
                comment={p78Comment}
                setComment={setP78Comment}
                text="a. Technology transfer"
                results={p78Results}
                choices={["Not at all important", "Low important", "Moderately important", "Important", "Very important"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={79}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p79Rate}
                setRate={setP79Rate}
                comment={p79Comment}
                setComment={setP79Comment}
                text="b. Capacity building"
                results={p79Results}
                choices={["Not at all important", "Low important", "Moderately important", "Important", "Very important"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={80}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p80Rate}
                setRate={setP80Rate}
                comment={p80Comment}
                setComment={setP80Comment}
                text="c. APEC Waste Management Targets"
                results={p80Results}
                choices={["Not at all important", "Low important", "Moderately important", "Important", "Very important"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={81}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p81Rate}
                setRate={setP81Rate}
                comment={p81Comment}
                setComment={setP81Comment}
                text="d. Collaborative R&D"
                results={p81Results}
                choices={["Not at all important", "Low important", "Moderately important", "Important", "Very important"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={82}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p82Rate}
                setRate={setP82Rate}
                comment={p82Comment}
                setComment={setP82Comment}
                text="e. Financial instruments"
                results={p82Results}
                choices={["Not at all important", "Low important", "Moderately important", "Important", "Very important"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={83}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p83Rate}
                setRate={setP83Rate}
                comment={p83Comment}
                setComment={setP83Comment}
                text="f. Others"
                other={true}
                otherText={p83Other}
                updateOther={setP83Other}
                results={p83Results}
                choices={["Not at all important", "Low important", "Moderately important", "Important", "Very important"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td align="right">
            <Message color={messageColor} message={message} />
              <br />
              <NextButtonPanel
                disabled={unchanged}
                onClick={resetValues}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );
};

export default Issue3page3;