import { useState, useEffect } from 'react';
import axios from 'axios';
import { backendServer, allEconomies } from '../../Const';
import RateItem from "./RateItem";
import SelectItem from "./SelectItem";
import Message from './Message';

import {isDeepEqual, readRateAnswer, readSelectionAnswers, checkRateFilled, checkSelectionFilled, handleBeforeUnload} from '../../SurveyUtils';
import NextButtonPanel from '../../components/NextButtonPanel';

const Issue3page4 = (props) => {
  const [pageData, setPageData] = useState('');
  const [firstRound, setFirstRound] = useState(true);

  const [p84Rate, setP84Rate] = useState(0);
  const [p84Comment, setP84Comment] = useState('');
  const [p84Results, setP84Results] = useState('');
  const [p85Rate, setP85Rate] = useState(0);
  const [p85Comment, setP85Comment] = useState('');
  const [p85Results, setP85Results] = useState('');
  const [p86Selections, setP86Selections] = useState([]);
  const [p86Comments, setP86Comments] = useState([]);
  const [p86Results, setP86Results] = useState('');
  const [p87Rate, setP87Rate] = useState(0);
  const [p87Comment, setP87Comment] = useState('');
  const [p87Results, setP87Results] = useState('');
  const [p88Rate, setP88Rate] = useState(0);
  const [p88Comment, setP88Comment] = useState('');
  const [p88Results, setP88Results] = useState('');
  const [p89Rate, setP89Rate] = useState(0);
  const [p89Comment, setP89Comment] = useState('');
  const [p89Results, setP89Results] = useState('');
  const [p90Selections, setP90Selections] = useState([]);
  const [p90Comments, setP90Comments] = useState([]);
  const [p90Results, setP90Results] = useState('');
  const [p91Selections, setP91Selections] = useState([]);
  const [p91Comments, setP91Comments] = useState([]);
  const [p91Results, setP91Results] = useState('');
  const [p92Rate, setP92Rate] = useState(0);
  const [p92Comment, setP92Comment] = useState('');
  const [p92Results, setP92Results] = useState('');
  const [p93Rate, setP93Rate] = useState(0);
  const [p93Comment, setP93Comment] = useState('');
  const [p93Results, setP93Results] = useState('');
  const [p94Rate, setP94Rate] = useState(0);
  const [p94Comment, setP94Comment] = useState('');
  const [p94Results, setP94Results] = useState('');
  const [p95Rate, setP95Rate] = useState(0);
  const [p95Comment, setP95Comment] = useState('');
  const [p95Results, setP95Results] = useState('');
  const [p96Rate, setP96Rate] = useState(0);
  const [p96Comment, setP96Comment] = useState('');
  const [p96Results, setP96Results] = useState('');
  const [p97Rate, setP97Rate] = useState(0);
  const [p97Comment, setP97Comment] = useState('');
  const [p97Results, setP97Results] = useState('');
  const [p97Other, setP97Other] = useState('');

  const [filled, setFilled] = useState(false);
  const [unchanged, setUnchanged] = useState(true);
  const [message, setMessage] = useState('');
  const [messageColor, setMessageColor] = useState('');

  useEffect( () => {
    if(unchanged) return;
    
    window.addEventListener('beforeunload',handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload',handleBeforeUnload)
    }
    
  },[unchanged]);

  const setErrorMessage = (msg) => {
    setMessageColor("red");
    setMessage(msg);
  }

  const setOkMessage = (msg) => {
    setMessageColor("teal");
    setMessage(msg);
  }


  const resetValues = () => {
    const data = pageData?JSON.parse(pageData):[];
    
    setP84Rate(data.p84Rate?data.p84Rate:0);
    setP84Comment(data.p84Comment?data.p84Comment:'');
    setP85Rate(data.p85Rate?data.p85Rate:0);
    setP85Comment(data.p85Comment?data.p85Comment:'');
    setP86Selections(data.p86Selections?data.p86Selections:[]);
    setP86Comments(data.p86Comments?data.p86Comments:[]);
    setP87Rate(data.p87Rate?data.p87Rate:0);
    setP87Comment(data.p87Comment?data.p87Comment:'');
    setP88Rate(data.p88Rate?data.p88Rate:0);
    setP88Comment(data.p88Comment?data.p88Comment:'');
    setP89Rate(data.p89Rate?data.p89Rate:0);
    setP89Comment(data.p89Comment?data.p89Comment:'');
    setP90Selections(data.p90Selections?data.p90Selections:[]);
    setP90Comments(data.p90Comments?data.p90Comments:[]);
    setP91Selections(data.p91Selections?data.p91Selections:[]);
    setP91Comments(data.p91Comments?data.p91Comments:[]);
    setP92Rate(data.p92Rate?data.p92Rate:0);
    setP92Comment(data.p92Comment?data.p92Comment:'');
    setP93Rate(data.p93Rate?data.p93Rate:0);
    setP93Comment(data.p93Comment?data.p93Comment:'');
    setP94Rate(data.p94Rate?data.p94Rate:0);
    setP94Comment(data.p94Comment?data.p94Comment:'');
    setP95Rate(data.p95Rate?data.p95Rate:0);
    setP95Comment(data.p95Comment?data.p95Comment:'');
    setP96Rate(data.p96Rate?data.p96Rate:0);
    setP96Comment(data.p96Comment?data.p96Comment:'');
    setP97Rate(data.p97Rate?data.p97Rate:0);
    setP97Comment(data.p97Comment?data.p97Comment:'');
    setP97Other(data.p97Other?data.p97Other:'');

    setUnchanged(true);
  }
  
  const fetchData = async () => {
    axios
      .post(backendServer + "/get_votes.php", {
        user_id: JSON.parse(props.user).user_id,
        problem_ids: ["84", "85", "86", "87", "88", "89", "90", "91", "92", "93", "94", "95", "96", "97"]
      })
      .then((response) => {
        if (response.data.status === "ok") {
          let answers = JSON.parse(response.data.answers);
          let results = JSON.parse(response.data.results);
          let data = {};

          if(answers['84']) {
            setFirstRound(false);
          }
          
          [data['p84Rate'], data['p84Comment']] = readRateAnswer(answers['84']);
          [data['p85Rate'], data['p85Comment']] = readRateAnswer(answers['85']);
          [data['p86Selections'], data['p86Comments']] = readSelectionAnswers(answers['86']);
          [data['p87Rate'], data['p87Comment']] = readRateAnswer(answers['87']);
          [data['p88Rate'], data['p88Comment']] = readRateAnswer(answers['88']);
          [data['p89Rate'], data['p89Comment']] = readRateAnswer(answers['89']);
          [data['p90Selections'], data['p90Comments']] = readSelectionAnswers(answers['90']);
          [data['p91Selections'], data['p91Comments']] = readSelectionAnswers(answers['91']);
          [data['p92Rate'], data['p92Comment']] = readRateAnswer(answers['92']);
          [data['p93Rate'], data['p93Comment']] = readRateAnswer(answers['93']);
          [data['p94Rate'], data['p94Comment']] = readRateAnswer(answers['94']);
          [data['p95Rate'], data['p95Comment']] = readRateAnswer(answers['95']);
          [data['p96Rate'], data['p96Comment']] = readRateAnswer(answers['96']);
          [data['p97Rate'], data['p97Comment']] = readRateAnswer(answers['97']);
          if(answers['97']&&answers['97'].other) data['p97Other'] = answers['97'].other;
          else data['p97Other'] = '';

          setPageData(JSON.stringify(data));

          setP84Results(results["84"]);
          setP85Results(results["85"]);
          setP86Results(results["86"]);
          setP87Results(results["87"]);
          setP88Results(results["88"]);
          setP89Results(results["89"]);
          setP90Results(results["90"]);
          setP91Results(results["91"]);
          setP92Results(results["92"]);
          setP93Results(results["93"]);
          setP94Results(results["94"]);
          setP95Results(results["95"]);
          setP96Results(results["96"]);
          setP97Results(results["97"]);
          
          checkFilled();
          setUnchanged(true);
        } else {
          setErrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  }
  
  useEffect(()=>{
    fetchData(); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    resetValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pageData]);

  useEffect(() => {
    setMessage('');
    let data = pageData?JSON.parse(pageData):[];

    if (Number(data.p84Rate) !== Number(p84Rate)) { setUnchanged(false); return; }
    if (data.p84Comment !== p84Comment) { setUnchanged(false); return; }
    if (Number(data.p85Rate) !== Number(p85Rate)) { setUnchanged(false); return; }
    if (data.p85Comment !== p85Comment) { setUnchanged(false); return; }
    if (!isDeepEqual(data.p86Selections, p86Selections)) 
      { setUnchanged(false); return; }
    if (!isDeepEqual(data.p86Comments, p86Comments)) 
      { setUnchanged(false); return; }
    if (Number(data.p87Rate) !== Number(p87Rate)) { setUnchanged(false); return; }
    if (data.p87Comment !== p87Comment) { setUnchanged(false); return; }
    if (Number(data.p88Rate) !== Number(p88Rate)) { setUnchanged(false); return; }
    if (data.p88Comment !== p88Comment) { setUnchanged(false); return; }
    if (Number(data.p89Rate) !== Number(p89Rate)) { setUnchanged(false); return; }
    if (data.p89Comment !== p89Comment) { setUnchanged(false); return; }
    if (!isDeepEqual(data.p90Selections, p90Selections)) 
      { setUnchanged(false); return; }
    if (!isDeepEqual(data.p90Comments, p90Comments)) 
      { setUnchanged(false); return; }
    if (!isDeepEqual(data.p91Selections, p91Selections)) 
      { setUnchanged(false); return; }
    if (!isDeepEqual(data.p91Comments, p91Comments)) 
      { setUnchanged(false); return; }
    if (Number(data.p92Rate) !== Number(p92Rate)) { setUnchanged(false); return; }
    if (data.p92Comment !== p92Comment) { setUnchanged(false); return; }
    if (Number(data.p93Rate) !== Number(p93Rate)) { setUnchanged(false); return; }
    if (data.p93Comment !== p93Comment) { setUnchanged(false); return; }
    if (Number(data.p94Rate) !== Number(p94Rate)) { setUnchanged(false); return; }
    if (data.p94Comment !== p94Comment) { setUnchanged(false); return; }
    if (Number(data.p95Rate) !== Number(p95Rate)) { setUnchanged(false); return; }
    if (data.p95Comment !== p95Comment) { setUnchanged(false); return; }
    if (Number(data.p96Rate) !== Number(p96Rate)) { setUnchanged(false); return; }
    if (data.p96Comment !== p96Comment) { setUnchanged(false); return; }
    if (Number(data.p97Rate) !== Number(p97Rate)) { setUnchanged(false); return; }
    if (data.p97Comment !== p97Comment) { setUnchanged(false); return; }
    if (data.p97Other !== p97Other) { setUnchanged(false); return; }    
    
    setUnchanged(true);
  }, [
    p84Rate, p84Comment,
    p85Rate, p85Comment,
    p86Selections, p86Comments,
    p87Rate, p87Comment,
    p88Rate, p88Comment,
    p89Rate, p89Comment,
    p90Selections, p90Comments,
    p91Selections, p91Comments,
    p92Rate, p92Comment,
    p93Rate, p93Comment,
    p94Rate, p94Comment,
    p95Rate, p95Comment,
    p96Rate, p96Comment,
    p97Rate, p97Comment, p97Other, pageData
  ]);

  const checkFilled = () => {
    if(!checkRateFilled(p84Rate, p84Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p85Rate, p85Comment, 1, 5)) {setFilled(false); return;}
    if(!checkSelectionFilled(p86Selections, p86Comments, 3, 3)) {setFilled(false); return;}
    if(!checkRateFilled(p87Rate, p87Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p88Rate, p88Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p89Rate, p89Comment, 1, 5)) {setFilled(false); return;}
    if(!checkSelectionFilled(p90Selections, p90Comments, 3, 3)) {setFilled(false); return;}
    if(!checkSelectionFilled(p91Selections, p91Comments, 5, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p92Rate, p92Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p93Rate, p93Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p94Rate, p94Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p95Rate, p95Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p96Rate, p96Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p97Rate, p97Comment, 1, 5)) {setFilled(false); return;}
    //if(!p97Other) {setFilled(false); return;}

    setFilled(true);
  }

  const navigate = (msg) => {
    window.removeEventListener('beforeunload',handleBeforeUnload); 
    if(!msg) msg = 'Proceeding..';
    setOkMessage(msg);
    setTimeout(()=> {
      window.location.href = '/survey?issue=6&last_issue=3';
    }, 1000);
  }
  
  useEffect(() => {   
    checkFilled();
  });
 
  let admin = JSON.parse(props.user).admin;
  let isAdmin = admin&&admin==="1";

  const handleSubmit = (event) => {
    event.preventDefault();
    if((filled&&unchanged)||isAdmin) { navigate(); return; }
    
    if(!checkRateFilled(p84Rate, p84Comment, 1, 5,
      "Please answer problem 1.",
      "Please provide your reasoning for problem 1.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p85Rate, p85Comment, 1, 5,
      "Please answer problem 2.",
      "Please provide your reasoning for problem 2.",
      setErrorMessage
    )) return;
    if(!checkSelectionFilled(p86Selections, p86Comments, 3, 3,
      "Please select three answers for problem 3.",
      "Please provide your reasoning for each selected answer in problem 3.",
      setErrorMessage,
      "Please specify the other field in problem 3."
    )) return;
    if(!checkRateFilled(p87Rate, p87Comment, 1, 5,
      "Please answer problem 4.",
      "Please provide your reasoning for problem 4.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p88Rate, p88Comment, 1, 5,
      "Please answer problem 5.",
      "Please provide your reasoning for problem 5.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p89Rate, p89Comment, 1, 5,
      "Please answer problem 6.",
      "Please provide your reasoning for problem 6.",
      setErrorMessage
    )) return;
    if(!checkSelectionFilled(p90Selections, p90Comments, 3, 3,
      "Please select three answers for problem 7.",
      "Please provide your reasoning for each selected answer in problem 7.",
      setErrorMessage
    )) return;
    if(!checkSelectionFilled(p91Selections, p91Comments, 5, 5,
      "Please select five answers for problem 8.",
      "Please provide your reasoning for each selected answer in problem 8.",
      setErrorMessage,
      "Please specify the other field in problem 8."
    )) return;
    if(!checkRateFilled(p92Rate, p92Comment, 1, 5,
      "Please answer problem 9a.",
      "Please provide your reasoning for problem 9a.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p93Rate, p93Comment, 1, 5,
      "Please answer problem 9b.",
      "Please provide your reasoning for problem 9b.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p94Rate, p94Comment, 1, 5,
      "Please answer problem 9c.",
      "Please provide your reasoning for problem 9c.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p95Rate, p95Comment, 1, 5,
      "Please answer problem 9d.",
      "Please provide your reasoning for problem 9d.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p96Rate, p96Comment, 1, 5,
      "Please answer problem 9e.",
      "Please provide your reasoning for problem 9e.",
      setErrorMessage
    )) return;
    if(p97Other) {
      if(!checkRateFilled(p97Rate, p97Comment, 1, 5,
        "Please answer problem 9f.",
        "Please provide your reasoning for problem 9f.",
        setErrorMessage
      )) return;
    }
    // if(!p97Other) {
    //   setErrorMessage("Please specify the other field in problem 9f.");
    //   return;
    // }

    let user_id = JSON.parse(props.user).user_id;
    let voteData = [];
    voteData.push({user_id, problem_id:84, vote:p84Rate, comment: p84Comment});
    voteData.push({user_id, problem_id:85, vote:p85Rate, comment: p85Comment});
    p86Selections.forEach((selection,index)=>{
      voteData.push({user_id, problem_id:86, vote:selection, comment: p86Comments[index]});
    });
    voteData.push({user_id, problem_id:87, vote:p87Rate, comment: p87Comment});
    voteData.push({user_id, problem_id:88, vote:p88Rate, comment: p88Comment});
    voteData.push({user_id, problem_id:89, vote:p89Rate, comment: p89Comment});
    p90Selections.forEach((selection,index)=>{
      voteData.push({user_id, problem_id:90, vote:selection, comment: p90Comments[index]});
    });
    p91Selections.forEach((selection,index)=>{
      voteData.push({user_id, problem_id:91, vote:selection, comment: p91Comments[index]});
    });
    voteData.push({user_id, problem_id:92, vote:p92Rate, comment: p92Comment});
    voteData.push({user_id, problem_id:93, vote:p93Rate, comment: p93Comment});
    voteData.push({user_id, problem_id:94, vote:p94Rate, comment: p94Comment});
    voteData.push({user_id, problem_id:95, vote:p95Rate, comment: p95Comment});
    voteData.push({user_id, problem_id:96, vote:p96Rate, comment: p96Comment});
    if(p97Other) 
      voteData.push({user_id, problem_id:97, vote:p97Rate, comment: p97Comment, other:p97Other});

    axios.post( backendServer+'/vote.php', {vote_data: JSON.stringify(voteData)})
    .then( (response)=>{
      if(response.data.status==='ok') {
        //fetchData();
        //updatePageData();
        setFilled(true);
        setFirstRound(false);
        //setOkMessage("Responses saved.");
        navigate('Responses saved.');
      } else {
        setErrorMessage(response.data.message);
      }
    })
    .catch((error)=>{
      setErrorMessage(error.message);
    });
  }
  
  const updateP86Answer = (selection, add) => {
    if(add) {
      let newSelections = [...p86Selections];
      newSelections.push(selection);
      setP86Selections(newSelections);
      let newComments = [...p86Comments];
      let data = pageData?JSON.parse(pageData):[];
      const index = data.p86Selections?data.p86Selections.indexOf(selection):-1;
      newComments.push(index>=0?data.p86Comments[index]:'');
      setP86Comments(newComments);
    } else {
      let index = p86Selections.indexOf(selection);
      if(index>=0) { // sanity check        
        let newSelections = [...p86Selections];
        newSelections.splice(index,1);
        setP86Selections(newSelections);
        let newComments = [...p86Comments];
        newComments.splice(index,1);
        setP86Comments(newComments);
      }
    }
  }

  const updateP86Comment = (answerIndex, comment) => {
    let newComments = [...p86Comments];
    newComments[answerIndex]=comment;
    setP86Comments(newComments);
  }

  const updateP86Other = (otherText, answerIndex) => {
    let newSelections = [...p86Selections];
    newSelections[answerIndex]=otherText;
    setP86Selections(newSelections);
  }

  const updateP90Answer = (selection, add) => {
    if(add) {
      let newSelections = [...p90Selections];
      newSelections.push(selection);
      setP90Selections(newSelections);
      let newComments = [...p90Comments];
      let data = pageData?JSON.parse(pageData):[];
      const index = data.p90Selections?data.p90Selections.indexOf(selection):-1;
      newComments.push(index>=0?data.p90Comments[index]:'');
      setP90Comments(newComments);
    } else {
      let index = p90Selections.indexOf(selection);
      if(index>=0) { // sanity check        
        let newSelections = [...p90Selections];
        newSelections.splice(index,1);
        setP90Selections(newSelections);
        let newComments = [...p90Comments];
        newComments.splice(index,1);
        setP90Comments(newComments);
      }
    }
  }

  const updateP90Comment = (answerIndex, comment) => {
    let newComments = [...p90Comments];
    newComments[answerIndex]=comment;
    setP90Comments(newComments);
  }
  
  const updateP91Answer = (selection, add) => {
    if(add) {
      let newSelections = [...p91Selections];
      newSelections.push(selection);
      setP91Selections(newSelections);
      let newComments = [...p91Comments];
      let data = pageData?JSON.parse(pageData):[];
      const index = data.p91Selections?data.p91Selections.indexOf(selection):-1;
      newComments.push(index>=0?data.p91Comments[index]:'');
      setP91Comments(newComments);
    } else {
      let index = p91Selections.indexOf(selection);
      if(index>=0) { // sanity check        
        let newSelections = [...p91Selections];
        newSelections.splice(index,1);
        setP91Selections(newSelections);
        let newComments = [...p91Comments];
        newComments.splice(index,1);
        setP91Comments(newComments);
      }
    }
  }

  const updateP91Comment = (answerIndex, comment) => {
    let newComments = [...p91Comments];
    newComments[answerIndex]=comment;
    setP91Comments(newComments);
  }

  const updateP91Other = (otherText, answerIndex) => {
    let newSelections = [...p91Selections];
    newSelections[answerIndex]=otherText;
    setP91Selections(newSelections);
  }

  return (
    <form onSubmit={handleSubmit}>
      <table width="600" align="center" cellPadding={8}>
        <tbody>
          <tr>
            <td>
              <h4>3.4 Green Tourism</h4>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                Green tourism, also known as sustainable tourism, eco-tourism,
                or responsible tourism, is a type of travel and tourism that
                focus on minimizing the negative impacts of tourism and
                promoting environmental conservation, cultural preservation, and
                social responsibility. Green tourism is also well known as
                small-scale tourism that encourages people to visit natural
                areas and minimize the impacts of tourism on the environment.
                The term is used for businesses with environmentally friendly
                activities. The rising demand for sustainability is not specific
                to the tourism industry but is part of a larger movement towards
                a greener economy.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={84}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p84Rate}
                setRate={setP84Rate}
                comment={p84Comment}
                setComment={setP84Comment}
                text="1. On a scale of 1 to 5, how strongly do you agree that green tourism plays an important role towards net-zero emissions?"
                results={p84Results}
                choices={[
                  "Strongly disagree",
                  "Disagree",
                  "Neutral",
                  "Agree",
                  "Strongly agree",
                ]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={85}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p85Rate}
                setRate={setP85Rate}
                comment={p85Comment}
                setComment={setP85Comment}
                text="2. On a scale of 1 to 5, how strongly do you agree that well-managed tourist attractions in their natural state play a vital role in promoting green tourism?"
                results={p85Results}
                choices={[
                  "Strongly disagree",
                  "Disagree",
                  "Neutral",
                  "Agree",
                  "Strongly agree",
                ]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <SelectItem
                problem_id={86}
                firstRound={firstRound}
                adminMode={isAdmin}
                max={3}
                updateAnswer={updateP86Answer}
                updateComment={updateP86Comment}
                selections={p86Selections}
                comments={p86Comments}
                text="3. Which areas would be most prominently adopted for green tourism to achieve net-zero emissions? Please select three choices and provide reasons briefly."
                choices={[
                  "Logistics management",
                  "Energy management",
                  "Water management",
                  "Waste management",
                  "Food and Agriculture",
                  "Infrastructure",
                ]}
                other={true}
                updateOther={updateP86Other}
                results={p86Results}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={87}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p87Rate}
                setRate={setP87Rate}
                comment={p87Comment}
                setComment={setP87Comment}
                text="4. Do you agree that local communities would benefit from green tourism?"
                results={p87Results}
                choices={[
                  "Strongly disagree",
                  "Disagree",
                  "Neutral",
                  "Agree",
                  "Strongly agree",
                ]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={88}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p88Rate}
                setRate={setP88Rate}
                comment={p88Comment}
                setComment={setP88Comment}
                text="5. How would the demand for green tourism in your economy change by 2050?"
                results={p88Results}
                choices={[
                  "Significantly decrease",
                  "Slightly decrease",
                  "Not change",
                  "Slightly increase",
                  "Significantly increase",
                ]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={89}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p89Rate}
                setRate={setP89Rate}
                comment={p89Comment}
                setComment={setP89Comment}
                text="6. On a scale of 1 to 5, please rate the effectiveness of green tourism policies in your economy."
                results={p89Results}
                choices={[
                  "Least Effective",
                  "Somehow Effective",
                  "Moderately Effective",
                  "Very Effective",
                  "Most Effective",
                ]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <SelectItem
                problem_id={90}
                firstRound={firstRound}
                adminMode={isAdmin}
                max={3}
                updateAnswer={updateP90Answer}
                updateComment={updateP90Comment}
                selections={p90Selections}
                comments={p90Comments}
                text="7. Based on your justification, please select three APEC economies that have effective green tourism policies."
                choices={allEconomies}
                other={false}
                results={p90Results}
              />
            </td>
          </tr>
          <tr>
            <td>
              <SelectItem
                problem_id={91}
                firstRound={firstRound}
                adminMode={isAdmin}
                max={5}
                updateAnswer={updateP91Answer}
                updateComment={updateP91Comment}
                selections={p91Selections}
                comments={p91Comments}
                text="8. Please select five areas of development in green tourism that require immediate measures in your economy."
                choices={[
                  "Resource management",
                  "Educate and engage locals",
                  "Educate and engage tourists",
                  "Awareness of cultural and social contexts",
                  "Geographic information systems",
                  "Public involvement",
                  "Preservation of heritage sites",
                  "Hospitality management",
                  "Landscape architecture",
                  "Community planning/transportation",
                ]}
                other={true}
                updateOther={updateP91Other}
                results={p91Results}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
            9. To what extent do you believe the collaboration among APEC economies regarding green tourism are driving progress towards net-zero emissions?
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={92}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p92Rate}
                setRate={setP92Rate}
                comment={p92Comment}
                setComment={setP92Comment}
                text="a. Technology transfer"
                results={p92Results}
                choices={["Not at all important", "Low important", "Moderately important", "Important", "Very important"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={93}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p93Rate}
                setRate={setP93Rate}
                comment={p93Comment}
                setComment={setP93Comment}
                text="b. Capacity building"
                results={p93Results}
                choices={["Not at all important", "Low important", "Moderately important", "Important", "Very important"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={94}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p94Rate}
                setRate={setP94Rate}
                comment={p94Comment}
                setComment={setP94Comment}
                text="c. Knowledge sharing"
                results={p94Results}
                choices={["Not at all important", "Low important", "Moderately important", "Important", "Very important"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={95}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p95Rate}
                setRate={setP95Rate}
                comment={p95Comment}
                setComment={setP95Comment}
                text="d. Collaborative R&D"
                results={p95Results}
                choices={["Not at all important", "Low important", "Moderately important", "Important", "Very important"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={96}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p96Rate}
                setRate={setP96Rate}
                comment={p96Comment}
                setComment={setP96Comment}
                text="e. Financial instruments"
                results={p96Results}
                choices={["Not at all important", "Low important", "Moderately important", "Important", "Very important"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={97}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p97Rate}
                setRate={setP97Rate}
                comment={p97Comment}
                setComment={setP97Comment}
                text="f. Others"
                other={true}
                otherText={p97Other}
                updateOther={setP97Other}
                results={p97Results}
                choices={["Not at all important", "Low important", "Moderately important", "Important", "Very important"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td align="right">
            <Message color={messageColor} message={message} />
              <br />
              <NextButtonPanel
                disabled={unchanged}
                onClick={resetValues}
                isEnd={true}
                issue={3}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );
};

export default Issue3page4;