//import {Chart as ChartJS} from 'chart.js/auto'
import Canvas from './Canvas';
import Comments from './Comments';

const RateResults = (props) => {

  let data = [0,0,0,0,0];
  let total = 0;
  if(props.results&&props.results.frequency) {
    for(var key in props.results.frequency) {
      data[Number(key)-1]=props.results.frequency[key];
      total+=data[Number(key)-1];
    }
  }
  if(total===0) total=1;

  const getColor = (index) => {
    let maxBlue = 180;
    let step = 40;
    return "rgb("+(maxBlue-index*step)+","+(maxBlue-index*step)+","+(maxBlue-index*step)+")";
  }

  let rateValue = 1;
  let space = 40;
  return (
    <>
      <table width={props.width} >
        <tbody>
          <tr height="100">
            {data.map((f, index) => (
              <td key={index} align="center" valign='bottom'>
                {f}<br />
                <Canvas
                  width={props.width / data.length - space}
                  height={props.height*f/total+1}
                  color={getColor(index)}
                />
              </td>
            ))}
          </tr>
          <tr>
            {props.choices.map((choice, index) => (
              <td key={rateValue++} width={props.width/5} valign="top" align="center">
                {(index+1)+". "+choice}
              </td>
            ))}
          </tr>
          <tr>
            <td colSpan={5}>
              <Comments
                other={props.other}
                choices={props.choices}
                isRate={true}
                comments={props.results?props.results.comments:[]}
                width={props.width}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default RateResults;