import Canvas from './Canvas';
import Comments from './Comments';

import './Survey.css'

import { controlTextWidth } from '../../SurveyUtils';

const SelectResults = (props) => {

  const barHeight = 16;
  const barLengthMax = 250;

  let data = [];  
  let total = 0;
  props.choices.forEach(vote => {
    data[vote]=0;
  });
  if(props.other) data["Others"]=0;

  if(props.results) {    
    for(var key in props.results.frequency) {
      if(props.choices.indexOf(key)<0) data["Others"]+=props.results.frequency[key];
      else data[key]=props.results.frequency[key];
      total+=props.results.frequency[key];
    }
  }
  if(total===0) total=1;
  
  return (
    <>
      <table width={props.width} className="center">
        <thead>
          {props.choices.map((c, index) => (
            <tr key={index}>
              <td width={140} align="right" valign="buttom">
                {controlTextWidth(c)}
              </td>
              <td valign="bottom">
                <Canvas
                  offsety="8"
                  width={String(1 + (barLengthMax * data[c]) / total)}
                  height={String(barHeight)}
                  color="rgb(0,64,128)"
                />{" "}
                {data[c]}
              </td>
            </tr>
          ))}

          {props.other && (
            <tr>
              <td width={140} align="right" valign="buttom">
                {controlTextWidth("Others")}
              </td>
              <td valign="bottom">
                <Canvas
                  offsety="8"
                  width={String(1 + (barLengthMax * data["Others"]) / total)}
                  height={String(barHeight)}
                  color="rgb(0,64,128)"
                />{" "}
                {data["Others"]}
              </td>
            </tr>
          )}
          <tr>
            <td colSpan={5}>
              <Comments
                choices={props.choices}
                comments={props.results ? props.results.comments : []}
                width={props.width}
                voteWidth={140}
              />
            </td>
          </tr>
        </thead>
      </table>
    </>
  );
};

export default SelectResults;