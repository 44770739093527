import { useState, useEffect } from 'react';
import axios from 'axios';
import { backendServer } from '../../Const';
import RateItem from "./RateItem";
import Message from './Message';

import {readRateAnswer, checkRateFilled, handleBeforeUnload} from '../../SurveyUtils';
import NextButtonPanel from '../../components/NextButtonPanel';

const Issue2page4 = (props) => {
  const [page8Data, setPage8Data] = useState('');
  const [firstRound, setFirstRound] = useState(true);

  const [p38Rate, setP38Rate] = useState(0);
  const [p38Comment, setP38Comment] = useState('');
  const [p38Results, setP38Results] = useState('');
  const [p39Rate, setP39Rate] = useState(0);
  const [p39Comment, setP39Comment] = useState('');
  const [p39Results, setP39Results] = useState('');
  const [p40Rate, setP40Rate] = useState(0);
  const [p40Comment, setP40Comment] = useState('');
  const [p40Results, setP40Results] = useState('');
  const [p41Rate, setP41Rate] = useState(0);
  const [p41Comment, setP41Comment] = useState('');
  const [p41Results, setP41Results] = useState('');
  const [p42Rate, setP42Rate] = useState(0);
  const [p42Comment, setP42Comment] = useState('');
  const [p42Results, setP42Results] = useState('');
  const [p43Rate, setP43Rate] = useState(0);
  const [p43Comment, setP43Comment] = useState('');
  const [p43Results, setP43Results] = useState('');
  const [p44Rate, setP44Rate] = useState(0);
  const [p44Comment, setP44Comment] = useState('');
  const [p44Results, setP44Results] = useState('');
  const [p45Rate, setP45Rate] = useState(0);
  const [p45Comment, setP45Comment] = useState('');
  const [p45Results, setP45Results] = useState('');
  const [p46Rate, setP46Rate] = useState(0);
  const [p46Comment, setP46Comment] = useState('');
  const [p46Results, setP46Results] = useState('');
  const [p47Rate, setP47Rate] = useState(0);
  const [p47Comment, setP47Comment] = useState('');
  const [p47Results, setP47Results] = useState('');
  const [p48Rate, setP48Rate] = useState(0);
  const [p48Comment, setP48Comment] = useState('');
  const [p48Results, setP48Results] = useState('');
  const [p48Other, setP48Other] = useState('');

  const [filled, setFilled] = useState(false);
  const [unchanged, setUnchanged] = useState(true);
  const [message, setMessage] = useState('');
  const [messageColor, setMessageColor] = useState('');

  useEffect( () => {
    if(unchanged) return;
    
    window.addEventListener('beforeunload',handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload',handleBeforeUnload)
    }
    
  },[unchanged]);

  const setErrorMessage = (msg) => {
    setMessageColor("red");
    setMessage(msg);
  }

  const setOkMessage = (msg) => {
    setMessageColor("teal");
    setMessage(msg);
  }

  const resetValues = () => {
    const data = page8Data?JSON.parse(page8Data):[];
    setP38Rate(data.p38Rate?data.p38Rate:0);
    setP38Comment(data.p38Comment?data.p38Comment:'');
    setP39Rate(data.p39Rate?data.p39Rate:0);
    setP39Comment(data.p39Comment?data.p39Comment:'');
    setP40Rate(data.p40Rate?data.p40Rate:0);
    setP40Comment(data.p40Comment?data.p40Comment:'');
    setP41Rate(data.p41Rate?data.p41Rate:0);
    setP41Comment(data.p41Comment?data.p41Comment:'');
    setP42Rate(data.p42Rate?data.p42Rate:0);
    setP42Comment(data.p42Comment?data.p42Comment:'');
    setP43Rate(data.p43Rate?data.p43Rate:0);
    setP43Comment(data.p43Comment?data.p43Comment:'');
    setP44Rate(data.p44Rate?data.p44Rate:0);
    setP44Comment(data.p44Comment?data.p44Comment:'');
    setP45Rate(data.p45Rate?data.p45Rate:0);
    setP45Comment(data.p45Comment?data.p45Comment:'');
    setP46Rate(data.p46Rate?data.p46Rate:0);
    setP46Comment(data.p46Comment?data.p46Comment:'');
    setP47Rate(data.p47Rate?data.p47Rate:0);
    setP47Comment(data.p47Comment?data.p47Comment:'');
    setP48Rate(data.p48Rate?data.p48Rate:0);
    setP48Comment(data.p48Comment?data.p48Comment:'');
    setP48Other(data.p48Other?data.p48Other:'');
    setUnchanged(true);
  }
  
  const fetchData = async () => {
    axios
      .post(backendServer + "/get_votes.php", {
        user_id: JSON.parse(props.user).user_id,
        problem_ids: ["38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48"],
      })
      .then((response) => {
        if (response.data.status === "ok") {
          let answers = JSON.parse(response.data.answers);
          let results = JSON.parse(response.data.results);
          let data = {};

          if(answers['38']) {
            setFirstRound(false);
          }
          
          [data['p38Rate'], data['p38Comment']] = readRateAnswer(answers['38']);
          [data['p39Rate'], data['p39Comment']] = readRateAnswer(answers['39']);
          [data['p40Rate'], data['p40Comment']] = readRateAnswer(answers['40']);
          [data['p41Rate'], data['p41Comment']] = readRateAnswer(answers['41']);
          [data['p42Rate'], data['p42Comment']] = readRateAnswer(answers['42']);
          [data['p43Rate'], data['p43Comment']] = readRateAnswer(answers['43']);
          [data['p44Rate'], data['p44Comment']] = readRateAnswer(answers['44']);
          [data['p45Rate'], data['p45Comment']] = readRateAnswer(answers['45']);
          [data['p46Rate'], data['p46Comment']] = readRateAnswer(answers['46']);
          [data['p47Rate'], data['p47Comment']] = readRateAnswer(answers['47']);
          [data['p48Rate'], data['p48Comment']] = readRateAnswer(answers['48']);
          if(answers['48']&&answers['48'].other) data['p48Other'] = answers['48'].other;
          else data['p48Other'] = '';
          
          setPage8Data(JSON.stringify(data));

          setP38Results(results["38"]);
          setP39Results(results["39"]);
          setP40Results(results["40"]);
          setP41Results(results["41"]);
          setP42Results(results["42"]);
          setP43Results(results["43"]);
          setP44Results(results["44"]);
          setP45Results(results["45"]);
          setP46Results(results["46"]);
          setP47Results(results["47"]);
          setP48Results(results["48"]);

          checkFilled();
          setUnchanged(true);
        } else {
          setErrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  }
  
  useEffect(()=>{
    fetchData(); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    resetValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[page8Data]);
  
  useEffect(() => {
    setMessage('');
    let data = page8Data?JSON.parse(page8Data):[];

    if (Number(data.p38Rate) !== Number(p38Rate)) { setUnchanged(false); return; }
    if (data.p38Comment !== p38Comment) { setUnchanged(false); return; }
    if (Number(data.p39Rate) !== Number(p39Rate)) { setUnchanged(false); return; }
    if (data.p39Comment !== p39Comment) { setUnchanged(false); return; }
    if (Number(data.p40Rate) !== Number(p40Rate)) { setUnchanged(false); return; }
    if (data.p40Comment !== p40Comment) { setUnchanged(false); return; }
    if (Number(data.p41Rate) !== Number(p41Rate)) { setUnchanged(false); return; }
    if (data.p41Comment !== p41Comment) { setUnchanged(false); return; }
    if (Number(data.p42Rate) !== Number(p42Rate)) { setUnchanged(false); return; }
    if (data.p42Comment !== p42Comment) { setUnchanged(false); return; }
    if (Number(data.p43Rate) !== Number(p43Rate)) { setUnchanged(false); return; }
    if (data.p43Comment !== p43Comment) { setUnchanged(false); return; }
    if (Number(data.p44Rate) !== Number(p44Rate)) { setUnchanged(false); return; }
    if (data.p44Comment !== p44Comment) { setUnchanged(false); return; }
    if (Number(data.p45Rate) !== Number(p45Rate)) { setUnchanged(false); return; }
    if (data.p45Comment !== p45Comment) { setUnchanged(false); return; }
    if (Number(data.p46Rate) !== Number(p46Rate)) { setUnchanged(false); return; }
    if (data.p46Comment !== p46Comment) { setUnchanged(false); return; }
    if (Number(data.p47Rate) !== Number(p47Rate)) { setUnchanged(false); return; }
    if (data.p47Comment !== p47Comment) { setUnchanged(false); return; }
    if (Number(data.p48Rate) !== Number(p48Rate)) { setUnchanged(false); return; }
    if (data.p48Comment !== p48Comment) { setUnchanged(false); return; }    
    if (data.p48Other !== p48Other) { setUnchanged(false); return; }    

    setUnchanged(true);
  }, [
    p38Rate, p38Comment,
    p39Rate, p39Comment,
    p40Rate, p40Comment,
    p41Rate, p41Comment,
    p42Rate, p42Comment,
    p43Rate, p43Comment,
    p44Rate, p44Comment,
    p45Rate, p45Comment,
    p46Rate, p46Comment,
    p47Rate, p47Comment,
    p48Rate, p48Comment, p48Other, page8Data
  ]);
  
  const checkFilled = () => {
    if(!checkRateFilled(p38Rate, p38Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p39Rate, p39Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p40Rate, p40Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p41Rate, p41Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p42Rate, p42Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p43Rate, p43Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p44Rate, p44Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p45Rate, p45Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p46Rate, p46Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p47Rate, p47Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p48Rate, p48Comment, 1, 5)) {setFilled(false); return;}
    //if(!p48Other) {setFilled(false); return;}

    setFilled(true);
  }

  const navigate = (msg) => {
    window.removeEventListener('beforeunload',handleBeforeUnload);
    if(!msg) msg = 'Proceeding..';
    setOkMessage(msg);
    setTimeout(()=> {
      window.location.href = '/survey?issue=2&page=5';
    }, 1000);
  }

  useEffect(() => {   
    checkFilled();
  });
   
  let admin = JSON.parse(props.user).admin;
  let isAdmin = admin&&admin==="1";
  
  const handleSubmit = (event) => {
    event.preventDefault();
    if((filled&&unchanged)||isAdmin) { navigate(); return; }
    
    if(!checkRateFilled(p38Rate, p38Comment, 1, 5,
      "Please answer problem 5a.",
      "Please provide your reasoning for problem 5a.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p39Rate, p39Comment, 1, 5,
      "Please answer problem 5b.",
      "Please provide your reasoning for problem 5b.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p40Rate, p40Comment, 1, 5,
      "Please answer problem 5c.",
      "Please provide your reasoning for problem 5c.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p41Rate, p41Comment, 1, 5,
      "Please answer problem 5d.",
      "Please provide your reasoning for problem 5d.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p42Rate, p42Comment, 1, 5,
      "Please answer problem 5e.",
      "Please provide your reasoning for problem 5e.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p43Rate, p43Comment, 1, 5,
      "Please answer problem 5f.",
      "Please provide your reasoning for problem 5f.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p44Rate, p44Comment, 1, 5,
      "Please answer problem 5g.",
      "Please provide your reasoning for problem 5g.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p45Rate, p45Comment, 1, 5,
      "Please answer problem 5h.",
      "Please provide your reasoning for problem 5h.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p46Rate, p46Comment, 1, 5,
      "Please answer problem 5i.",
      "Please provide your reasoning for problem 5i.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p47Rate, p47Comment, 1, 5,
      "Please answer problem 5j.",
      "Please provide your reasoning for problem 5j.",
      setErrorMessage
    )) return;
    
    if(p48Other) {
      if(!checkRateFilled(p48Rate, p48Comment, 1, 5,
        "Please answer problem 5k.",
        "Please provide your reasoning for problem 5k.",
        setErrorMessage
      )) return;
    }
    // if(!p48Other) {
    //   setErrorMessage("Please specify the other field in problem 5k.");
    //   return;
    // }
  
    let user_id = JSON.parse(props.user).user_id;
    let voteData = [];
    voteData.push({user_id, problem_id:38, vote:p38Rate, comment: p38Comment});
    voteData.push({user_id, problem_id:39, vote:p39Rate, comment: p39Comment});
    voteData.push({user_id, problem_id:40, vote:p40Rate, comment: p40Comment});
    voteData.push({user_id, problem_id:41, vote:p41Rate, comment: p41Comment});
    voteData.push({user_id, problem_id:42, vote:p42Rate, comment: p42Comment});
    voteData.push({user_id, problem_id:43, vote:p43Rate, comment: p43Comment});
    voteData.push({user_id, problem_id:44, vote:p44Rate, comment: p44Comment});
    voteData.push({user_id, problem_id:45, vote:p45Rate, comment: p45Comment});
    voteData.push({user_id, problem_id:46, vote:p46Rate, comment: p46Comment});
    voteData.push({user_id, problem_id:47, vote:p47Rate, comment: p47Comment});
    if(p48Other) 
    voteData.push({user_id, problem_id:48, vote:p48Rate, comment: p48Comment, other:p48Other});

    axios.post( backendServer+'/vote.php', {vote_data: JSON.stringify(voteData)})
    .then( (response)=>{
      if(response.data.status==='ok') {
        //fetchData();
        //updatePageData();
        setFilled(true);
        setFirstRound(false);
        //setOkMessage("Responses saved.");
        navigate('Responses saved.');
      } else {
        setErrorMessage(response.data.message);
      }
    })
    .catch((error)=>{
      setErrorMessage(error.message);
    });
  }

  return (
    <form onSubmit={handleSubmit}>
      <table width="600" align="center" cellPadding={8}>
        <tbody>
          <tr>
            <td>
              <p>5. Based on your justification, how significant are the following barriers to achieving lowcarbon electricity systems across APEC economies?</p>
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={38}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p38Rate}
                setRate={setP38Rate}
                comment={p38Comment}
                setComment={setP38Comment}
                text="a. Intermittency of renewable energy sources"
                results={p38Results}
                choices={["Insignificant", "Slightly significant", "Moderately significant", "Significant", "Highly significant"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={39}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p39Rate}
                setRate={setP39Rate}
                comment={p39Comment}
                setComment={setP39Comment}
                text="b. Insufficient energy storage capacities"
                results={p39Results}
                choices={["Insignificant", "Slightly significant", "Moderately significant", "Significant", "Highly significant"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={40}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p40Rate}
                setRate={setP40Rate}
                comment={p40Comment}
                setComment={setP40Comment}
                text="c. Modernization of grid infrastructure"
                results={p40Results}
                choices={["Insignificant", "Slightly significant", "Moderately significant", "Significant", "Highly significant"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={41}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p41Rate}
                setRate={setP41Rate}
                comment={p41Comment}
                setComment={setP41Comment}
                text='d. Inadequacy of existing infrastructure (i.e., risks of stranded assets)'
                results={p41Results}
                choices={["Insignificant", "Slightly significant", "Moderately significant", "Significant", "Highly significant"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={42}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p42Rate}
                setRate={setP42Rate}
                comment={p42Comment}
                setComment={setP42Comment}
                text='e. Continuation of investments in “old and dirty” technology'
                results={p42Results}
                choices={["Insignificant", "Slightly significant", "Moderately significant", "Significant", "Highly significant"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={43}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p43Rate}
                setRate={setP43Rate}
                comment={p43Comment}
                setComment={setP43Comment}
                text='f. Domestic political constraints (policy and regulation)'
                results={p43Results}
                choices={["Insignificant", "Slightly significant", "Moderately significant", "Significant", "Highly significant"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={44}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p44Rate}
                setRate={setP44Rate}
                comment={p44Comment}
                setComment={setP44Comment}
                text='g. International climate agreements'
                results={p44Results}
                choices={["Insignificant", "Slightly significant", "Moderately significant", "Significant", "Highly significant"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={45}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p45Rate}
                setRate={setP45Rate}
                comment={p45Comment}
                setComment={setP45Comment}
                text='h. Public perceptions about climate change'
                results={p45Results}
                choices={["Insignificant", "Slightly significant", "Moderately significant", "Significant", "Highly significant"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={46}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p46Rate}
                setRate={setP46Rate}
                comment={p46Comment}
                setComment={setP46Comment}
                text='i. Insufficient capital to fund “new and clean” energy projects'
                results={p46Results}
                choices={["Insignificant", "Slightly significant", "Moderately significant", "Significant", "Highly significant"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={47}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p47Rate}
                setRate={setP47Rate}
                comment={p47Comment}
                setComment={setP47Comment}
                text='j. Geopolitics and energy security'
                results={p47Results}
                choices={["Insignificant", "Slightly significant", "Moderately significant", "Significant", "Highly significant"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={48}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p48Rate}
                setRate={setP48Rate}
                comment={p48Comment}
                setComment={setP48Comment}
                text='k. Others'
                other={true}
                otherText={p48Other}
                updateOther={setP48Other}
                results={p48Results}
                choices={["Insignificant", "Slightly significant", "Moderately significant", "Significant", "Highly significant"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td align="right">
            <Message color={messageColor} message={message} />
              <br />
              <NextButtonPanel
                disabled={unchanged}
                onClick={resetValues}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );
};

export default Issue2page4;