
import { useState } from 'react';
import axios from 'axios';
import { backendServer } from '../Const';

const StartSurvey = (props) => {
  const [loginName, setLoginName] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const loginNameChanged = (src) => {
    setLoginName(src.target.value);
    setErrorMessage('');
  }
  
  const passwordChanged = (src) => {
    setPassword(src.target.value);
    setErrorMessage('');
  }
  
  const handleSubmit = (event) => {
    event.preventDefault();

    axios.post( backendServer+'/login.php', {login_name:loginName, password})
    .then(function (response) {      
      if(response.data.status==='ok') { 
        let userString = response.data.user;
        props.setUser(userString);
        setErrorMessage('');
      } else {
        setErrorMessage(response.data.message);
      }
    })
    .catch(function (error) {
      setErrorMessage(error);  
    });
  }

  return (
    <>
      {props.user ? (
        <>
        <a href="survey?issue=0" className="blueButton">
          Start Survey
        </a>
        </>
      ) : (
        <>
          <form onSubmit={handleSubmit}>
            <i>Username or email </i>{" "}
            <input
              type="text"
              name="loginName"
              value={loginName}
              size="8"
              onChange={(e) => loginNameChanged(e)}
            />{" "}
            <br />
            <i>Password</i>{" "}
            <input
              type="password"
              name="password"
              value={password}
              size="8"
              onChange={(e) => passwordChanged(e)}
            />{" "}
            <br />
            
            <span style={{color:"red"}} hidden={!errorMessage}> {errorMessage} </span><br />          
            
            <input type="submit" name="submit" value="Login" /> <br />
          </form>
        </>
      )}
    </>
  );
};
export default StartSurvey;