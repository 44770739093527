import { useState, useEffect } from 'react';
import axios from 'axios';
import { backendServer } from '../../Const';
import issues from '../../images/issues.png';

import Message from './Message';
import { handleBeforeUnload } from '../../SurveyUtils';


const Summarize = (props) => {
  const [p999Answer, setP999Answer] = useState('');

  const [issueText, setIssueText] = useState('');
  
  const [message, setMessage] = useState('');
  const [messageColor, setMessageColor] = useState('');

  const setErrorMessage = (msg) => {
    setMessageColor("red");
    setMessage(msg);
  }

  const setOkMessage = (msg) => {
    setMessageColor("teal");
    setMessage(msg);
  }
  
  useEffect(()=>{
    if(!p999Answer) return;
    if(p999Answer) setMessage('');
    
    window.addEventListener('beforeunload',handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload',handleBeforeUnload)
    }
    
  }, [p999Answer]);

  const navigate = (issue, msg) => {
    window.removeEventListener('beforeunload',handleBeforeUnload); 
    if(!msg) msg = 'Proceeding..';
    setOkMessage(msg);
    setTimeout(()=> {
      window.location.href = '/survey?issue='+issue+'&page=1';
    }, 1000);
  }

  const handleNavigate = (e) => {
    navigate(e.target.value);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    
    let user_id = JSON.parse(props.user).user_id;
    let voteData = [];
    voteData.push({user_id, problem_id:999, vote:p999Answer, comment:''});

    axios.post( backendServer+'/vote.php', {vote_data: JSON.stringify(voteData)})
    .then( (response)=>{
      if(response.data.status==='ok') {        
        setP999Answer('');
        setOkMessage("Comment saved.")

      } else {
        setErrorMessage(response.data.message);
      }
    })
    .catch((error)=>{
      setErrorMessage(error.message);
    });
  }

  const handleTextChange = (e) => {
    setP999Answer(e.target.value);
  }

  const handleMouseOver = (e) => {
    switch(e.target.value) {
      case "1": setIssueText('Energy demand management '); break;
      case "2": setIssueText('Energy Transition'); break;
      case "3": setIssueText('Green economy'); break;
      case "4": setIssueText('Carbon emission sinks'); break;
      case "5": setIssueText('Offset policies'); break;
      default: setIssueText('');
    }
  }

  const handleMouseLeave= (e) => {
    setIssueText('');
  }
 
  let admin = JSON.parse(props.user).admin;
  let isAdmin = admin&&admin==="1";
  
  let last_issue = (new URLSearchParams(window.location.search).get('last_issue')||"0");

  return (
    <form onSubmit={handleSubmit}>
      <table width="600" align="center" cellPadding={8}>
        <tbody>
          <tr>
            <td>
              <h4>{last_issue > 0 ? "Issue " + last_issue : ""} Finish!</h4>
              <p>
                Thank you for taking the time to complete our survey. Your
                feedback is crucial in helping us provide better insights. If
                you have any additional comments or suggestions, please feel
                free to share them below.
              </p>
            </td>
          </tr>
          {isAdmin ? (
            <></>
          ) : (
            <tr>
              <td align="right">
                Addition Comments (if any)
                <textarea
                  type="textarea"
                  rows={4}
                  cols={80}
                  size={500}
                  onChange={(e) => handleTextChange(e)}
                  value={p999Answer}
                ></textarea>
                <br />
                <Message color={messageColor} message={message} /> <br />
                &nbsp;&nbsp;
                <input
                  disabled={!p999Answer}
                  className="blueButton"
                  type="submit"
                  value="Send Addition Comment"
                />
              </td>
            </tr>
          )}
          <tr>
            <td>
              &nbsp;
              <br />
              <i>You're encouraged to answer other interested issues and also
              re-visit the questionnaire to observe others' reasoning as an
              essential part of Delphi methodology which participants could
              alter their responses over multiple rounds.</i>
            </td>
          </tr>
          <tr>
            <td align="right">
              <span style={{ color: "#476e9e" }}>{issueText} </span>
              <br />
              &nbsp;&nbsp; Select Issue: &nbsp;&nbsp;
              <input
                className="grayButton"
                type="button"
                value="1"
                onClick={(e) => handleNavigate(e)}
                onMouseEnter={(e) => handleMouseOver(e)}
                onMouseLeave={(e) => handleMouseLeave(e)}
              />
              &nbsp;
              <input
                className="grayButton"
                type="button"
                value="2"
                onClick={(e) => handleNavigate(e)}
                onMouseEnter={(e) => handleMouseOver(e)}
                onMouseLeave={(e) => handleMouseLeave(e)}
              />
              &nbsp;
              <input
                className="grayButton"
                type="button"
                value="3"
                onClick={(e) => handleNavigate(e)}
                onMouseEnter={(e) => handleMouseOver(e)}
                onMouseLeave={(e) => handleMouseLeave(e)}
              />
              &nbsp;
              <input
                className="grayButton"
                type="button"
                value="4"
                onClick={(e) => handleNavigate(e)}
                onMouseEnter={(e) => handleMouseOver(e)}
                onMouseLeave={(e) => handleMouseLeave(e)}
              />
              &nbsp;
              <input
                className="grayButton"
                type="button"
                value="5"
                onClick={(e) => handleNavigate(e)}
                onMouseEnter={(e) => handleMouseOver(e)}
                onMouseLeave={(e) => handleMouseLeave(e)}
              />
            </td>
          </tr>
          <tr>
            <td align="right">
              <a className="blueButton" href="./">
                Finish
              </a>
            </td>
          </tr>
          <tr>
            <td>
              &nbsp;
              <br />
              <center>
                <img src={issues} width="650" alt="Investigating issues" />
              </center>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );
};

export default Summarize;

