import { useState, useEffect } from 'react';
import axios from 'axios';
import { backendServer } from '../../Const';
import RateItem from "./RateItem";
import SelectItem from "./SelectItem";
import Message from './Message';

import {isDeepEqual, readRateAnswer, readSelectionAnswers, checkRateFilled, checkSelectionFilled, handleBeforeUnload} from '../../SurveyUtils';
import NextButtonPanel from '../../components/NextButtonPanel';

const Issue1page4 = (props) => {
  const [page4Data, setPage4Data] = useState('');
  const [firstRound, setFirstRound] = useState(true);

  const [p14Rate, setP14Rate] = useState(0);
  const [p14Comment, setP14Comment] = useState('');
  const [p14Results, setP14Results] = useState('');  
  const [p15Selections, setP15Selections] = useState([]);
  const [p15Comments, setP15Comments] = useState([]);
  const [p15Results, setP15Results] = useState('');  
  const [p16Selections, setP16Selections] = useState([]);
  const [p16Comments, setP16Comments] = useState([]);
  const [p16Results, setP16Results] = useState('');
  
  const [filled, setFilled] = useState(false);
  const [unchanged, setUnchanged] = useState(true);
  const [message, setMessage] = useState('');
  const [messageColor, setMessageColor] = useState('');

  useEffect( () => {
    if(unchanged) return;
    
    window.addEventListener('beforeunload',handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload',handleBeforeUnload)
    }
    
  },[unchanged]);

  const setErrorMessage = (msg) => {
    setMessageColor("red");
    setMessage(msg);
  }

  const setOkMessage = (msg) => {
    setMessageColor("teal");
    setMessage(msg);
  }

  const resetValues = () => {
    const data = page4Data?JSON.parse(page4Data):[];
    setP14Rate(data.p14Rate?data.p14Rate:0);
    setP14Comment(data.p14Comment?data.p14Comment:'');
    setP15Selections(data.p15Selections?data.p15Selections:[]);
    setP15Comments(data.p15Comments?data.p15Comments:[]);
    setP16Selections(data.p16Selections?data.p16Selections:[]);
    setP16Comments(data.p16Comments?data.p16Comments:[]);
    setUnchanged(true);
  }

  const fetchData = async () => {
    axios
      .post(backendServer + "/get_votes.php", {
        user_id: JSON.parse(props.user).user_id,
        problem_ids: ["14", "15", "16"],
      })
      .then((response) => {
        if (response.data.status === "ok") {
          let answers = JSON.parse(response.data.answers);
          let results = JSON.parse(response.data.results);
          let data = {};

          if(answers['14']) {
            setFirstRound(false);
          }

          [data['p14Rate'], data['p14Comment']] = readRateAnswer(answers['14']);
          [data['p15Selections'], data['p15Comments']] = readSelectionAnswers(answers['15']);
          [data['p16Selections'], data['p16Comments']] = readSelectionAnswers(answers['16']);

          setPage4Data(JSON.stringify(data));

          setP14Results(results["14"]);
          setP15Results(results["15"]);
          setP16Results(results["16"]);

          checkFilled();
          setUnchanged(true);
        } else {
          setErrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  }
    
  useEffect(()=>{
    fetchData(); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    resetValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[page4Data]);
  
  useEffect(() => {
    setMessage('');
    let data = page4Data?JSON.parse(page4Data):[];
    if (Number(data.p14Rate) !== Number(p14Rate)) {
      setUnchanged(false);
      return;
    }
    if (data.p14Comment !== p14Comment) {
      setUnchanged(false);
      return;
    }
    if (!isDeepEqual(data.p15Selections, p15Selections)) {
      setUnchanged(false);
      return;
    }
    if (!isDeepEqual(data.p15Comments, p15Comments)) {
      setUnchanged(false);
      return;
    }
    if (!isDeepEqual(data.p16Selections, p16Selections)) {
      setUnchanged(false);
      return;
    }
    if (!isDeepEqual(data.p16Comments, p16Comments)) {
      setUnchanged(false);
      return;
    }

    setUnchanged(true);
  }, [
    p14Rate,
    p14Comment,
    p15Selections,
    p15Comments,
    p16Selections,
    p16Comments, page4Data
  ]);

  const checkFilled = () => {
    if(!checkRateFilled(p14Rate, p14Comment, 1, 5)) {setFilled(false); return;}
    if(!checkSelectionFilled(p15Selections, p15Comments, 3, 3)) {setFilled(false); return;}
    if(!checkSelectionFilled(p16Selections, p16Comments, 1, 1)) {setFilled(false); return;}

    setFilled(true);
  }
  
  const navigate = (msg) => {
    window.removeEventListener('beforeunload',handleBeforeUnload);
    if(!msg) msg = 'Proceeding..';
    setOkMessage(msg);
    setTimeout(()=> {
      window.location.href = '/survey?issue=6&last_issue=1';
    }, 1000);
  }

  useEffect(() => {   
    checkFilled();
  });
  
  const updateP15Answer = (selection, add) => {
    if(add) {
      let newSelections = [...p15Selections];
      newSelections.push(selection);
      setP15Selections(newSelections);
      let newComments = [...p15Comments];
      let data = page4Data?JSON.parse(page4Data):[];
      const index = data.p15Selections?data.p15Selections.indexOf(selection):-1;
      newComments.push(index>=0?data.p15Comments[index]:'');
      setP15Comments(newComments);
    } else {
      let index = p15Selections.indexOf(selection);
      if(index>=0) { // sanity check        
        let newSelections = [...p15Selections];
        newSelections.splice(index,1);
        setP15Selections(newSelections);
        let newComments = [...p15Comments];
        newComments.splice(index,1);
        setP15Comments(newComments);
      }
    }
  }

  const updateP15Comment = (answerIndex, comment) => {
    let newComments = [...p15Comments];
    newComments[answerIndex]=comment;
    setP15Comments(newComments);
  }

  const updateP15Other = (otherText, answerIndex) => {
    let newSelections = [...p15Selections];
    newSelections[answerIndex]=otherText;
    setP15Selections(newSelections);
  }
  
  const updateP16Answer = (selection, add) => {
    if(add) {
      let newSelections = [...p16Selections];
      newSelections.push(selection);
      setP16Selections(newSelections);
      let newComments = [...p16Comments];
      let data = page4Data?JSON.parse(page4Data):[];
      const index = data.p16Selections?data.p16Selections.indexOf(selection):-1;
      newComments.push(index>=0?data.p16Comments[index]:'');
      setP16Comments(newComments);
    } else {
      let index = p16Selections.indexOf(selection);
      if(index>=0) { // sanity check        
        let newSelections = [...p16Selections];
        newSelections.splice(index,1);
        setP16Selections(newSelections);
        let newComments = [...p16Comments];
        newComments.splice(index,1);
        setP16Comments(newComments);
      }
    }
  }

  const updateP16Comment = (answerIndex, comment) => {
    let newComments = [...p16Comments];
    newComments[answerIndex]=comment;
    setP16Comments(newComments);
  }

  const updateP16Other = (otherText, answerIndex) => {
    let newSelections = [...p16Selections];
    newSelections[answerIndex]=otherText;
    setP16Selections(newSelections);
  }
   
  let admin = JSON.parse(props.user).admin;
  let isAdmin = admin&&admin==="1";
  
  const handleSubmit = (event) => {
    event.preventDefault();
    if((filled&&unchanged)||isAdmin) { navigate(); return; }
    
    if(!checkRateFilled(
      p14Rate,
      p14Comment,
      1,
      5,
      "Please answer problem 1.",
      "Please provide your reasoning for problem 1.",
      setErrorMessage
    )) return;
    if(!checkSelectionFilled(p15Selections, p15Comments, 3, 3,
      "Please select three answers for problem 2.",
      "Please provide your reasoning for the selected answer in problem 2.",
      setErrorMessage,
      "Please specify the other field in problem 2."
    )) return;
    if(!checkSelectionFilled(p16Selections, p16Comments, 1, 1,
      "Please select three answers for problem 3.",
      "Please provide your reasoning for each selected answer in problem 3.",
      setErrorMessage,
      "Please specify the other field in problem 3."
    )) return;
        
    let user_id = JSON.parse(props.user).user_id;
    let voteData = [];
    voteData.push({user_id, problem_id:14, vote:p14Rate, comment: p14Comment});
    p15Selections.forEach((selection,index)=>{
      voteData.push({user_id, problem_id:15, vote:selection, comment: p15Comments[index]});
    });
    p16Selections.forEach((selection,index)=>{
      voteData.push({user_id, problem_id:16, vote:selection, comment: p16Comments[index]});
    });

    axios.post( backendServer+'/vote.php', {vote_data: JSON.stringify(voteData)})
    .then( (response)=>{
      if(response.data.status==='ok') {
        //fetchData();
        //updatePage4Data();
        setFilled(true);
        setFirstRound(false);
        //setOkMessage("Information Saved.");
        navigate('Responses saved.');
      } else {
        setErrorMessage(response.data.message);
      }
    })
    .catch((error)=>{
      setErrorMessage(error.message);
    });
  }

  return (
    <form onSubmit={handleSubmit}>
      <table width="600" align="center" cellPadding={8}>
        <tbody>
          <tr>
            <td>
              <h4>1.3 Electricity Demand</h4>

              <p>
                Electricity demand refers to the quantity of electrical energy
                required by consumers, businesses, industries, and other
                entities within a timeframe.
              </p>
              <hr />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={14}
                firstRound={firstRound}
                rate={p14Rate}
                setRate={setP14Rate}
                comment={p14Comment}
                setComment={setP14Comment}
                text="1. On a scale of 1 to 5, how concerned are you on the potential increase in electricity demand as the dominant form of energy in which APEC economies are working towards achieving net-zero emissions?"
                results={p14Results}
                choices={[
                  "Least concerned",
                  "Slightly concerned",
                  "Moderately concerned",
                  "Very concerned",
                  "Most concerned",
                ]}
                adminMode={isAdmin}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <SelectItem
                problem_id={15}
                firstRound={firstRound}
                max={3}
                updateAnswer={updateP15Answer}
                updateComment={updateP15Comment}
                selections={p15Selections}
                comments={p15Comments}
                text="2. On the sectors listed below, please select three sectors based on their important in electricity demand."
                choices={[
                  "Industry",
                  "Transport",
                  "Energy production",
                  "Household",
                  "Building",
                ]}
                other={true}
                updateOther={updateP15Other}
                results={p15Results}
                adminMode={isAdmin}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <SelectItem
                problem_id={16}
                firstRound={firstRound}
                adminMode={isAdmin}
                max={1}
                updateAnswer={updateP16Answer}
                updateComment={updateP16Comment}
                selections={p16Selections}
                comments={p16Comments}
                text="3. Please select the alternative source of electricity which would have the greatest impact on electricity demand in APEC economies to accelerate progress towards achieving net-zero emissions?"
                choices={["Nuclear", "Hydro", "Wind", "Solar"]}
                other={true}
                updateOther={updateP16Other}
                results={p16Results}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td align="right">
            <Message color={messageColor} message={message} />
              <br />
              <NextButtonPanel
                disabled={unchanged}
                onClick={resetValues}
                isEnd={true}
                issue={1}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );
};

export default Issue1page4;