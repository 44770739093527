import Logo_NXPO from '../images/Logo_NXPO.png';
import APEC_main from '../images/APEC_main.png';

import React, { useEffect, useState } from "react";
import {
  Box,
  FooterLink,
  FooterText,
  Heading,
} from "./FooterStyles";
 
const Footer = () => {
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const logoWidth = () => {
    if(windowSize[0]<480) return "80%";
    if(windowSize[0]<960) return "200";
    return "260";
  }

  return (
    <>
      <br />
      <Box>
        <table width="100%">
          <tbody>
            <tr>
              <td valign="top">
                <Heading>APEC Center for Technology Foresight</Heading>
                <FooterText>
                  <b>Contact Information:</b> <br />
                  Titima Songkroh, D.Eng. Policy specialist, APEC CTF, NXPO.{" "}
                  <br />
                  Email: apecctf@nxpo.or.th
                </FooterText>
                <br />
                <FooterLink href="./Privacy">
                  <b>Privacy Policy</b>
                </FooterLink>
                <br />
                &nbsp;
                <br />
              </td>
              <td width="60%" valign="center" align="right">
                <p>
                  <img src={APEC_main} width={logoWidth()} alt="APEC Logo" /> {windowSize[0]<960?<br />:<>&nbsp;</>}
                  <img src={Logo_NXPO} width={logoWidth()} alt="NXPO Logo"  />
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </Box>
    </>
  );
};
export default Footer;

/*


          <Row>
            <FooterContainer>
              <img
                style={{ background: "white", padding: "10px" }}
                color="white"
                src={APEC_main}
                width="180"
              />
            </FooterContainer>
            
            <FooterContainer>
              <FooterText>
                <div style={{padding:'0px 10px'}}>
                <b>Contact Information:</b> <br />
                Titima Songkroh, D.Eng. Policy specialist, APEC CTF, NXPO.{" "}
                <br />
                Email: apecctf@nxpo.or.th
                </div>
              </FooterText>
            </FooterContainer>
          </Row>

          */