import { useState, useEffect } from 'react';
import axios from 'axios';
import { backendServer, allEconomies } from '../../Const';
import RateItem from "./RateItem";
import SelectItem from "./SelectItem";
import Message from './Message';

import {isDeepEqual, readRateAnswer, readSelectionAnswers, checkRateFilled, checkSelectionFilled, handleBeforeUnload} from '../../SurveyUtils';
import NextButtonPanel from '../../components/NextButtonPanel';


const Issue5page1 = (props) => {
  const [pageData, setPageData] = useState('');
  const [firstRound, setFirstRound] = useState(true);
  
  const [p171Rate, setP171Rate] = useState(0);
  const [p171Comment, setP171Comment] = useState('');
  const [p171Results, setP171Results] = useState('');
  const [p172Selections, setP172Selections] = useState([]);
  const [p172Comments, setP172Comments] = useState([]);
  const [p172Results, setP172Results] = useState('');
  const [p173Selections, setP173Selections] = useState([]);
  const [p173Comments, setP173Comments] = useState([]);
  const [p173Results, setP173Results] = useState('');
  const [p174Rate, setP174Rate] = useState(0);
  const [p174Comment, setP174Comment] = useState('');
  const [p174Results, setP174Results] = useState('');
  const [p175Selections, setP175Selections] = useState([]);
  const [p175Comments, setP175Comments] = useState([]);
  const [p175Results, setP175Results] = useState('');

  const [filled, setFilled] = useState(false);
  const [unchanged, setUnchanged] = useState(true);
  const [message, setMessage] = useState('');
  const [messageColor, setMessageColor] = useState('');

  useEffect( () => {
    if(unchanged) return;
    
    window.addEventListener('beforeunload',handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload',handleBeforeUnload)
    }
    
  },[unchanged]);

  const setErrorMessage = (msg) => {
    setMessageColor("red");
    setMessage(msg);
  }

  const setOkMessage = (msg) => {
    setMessageColor("teal");
    setMessage(msg);
  }
  
  const resetValues = () => {
    const data = pageData?JSON.parse(pageData):[];
    
    setP171Rate(data.p171Rate?data.p171Rate:0);
    setP171Comment(data.p171Comment?data.p171Comment:'');
    setP172Selections(data.p172Selections?data.p172Selections:[]);
    setP172Comments(data.p172Comments?data.p172Comments:[]);
    setP173Selections(data.p173Selections?data.p173Selections:[]);
    setP173Comments(data.p173Comments?data.p173Comments:[]);
    setP174Rate(data.p174Rate?data.p174Rate:0);
    setP174Comment(data.p174Comment?data.p174Comment:'');
    setP175Selections(data.p175Selections?data.p175Selections:[]);
    setP175Comments(data.p175Comments?data.p175Comments:[]);

    setUnchanged(true);
  }

  const fetchData = async () => {
    axios
      .post(backendServer + "/get_votes.php", {
        user_id: JSON.parse(props.user).user_id,
        problem_ids: ["171", "172", "173", "174", "175"]
      })
      .then((response) => {
        if (response.data.status === "ok") {
          let answers = JSON.parse(response.data.answers);
          let results = JSON.parse(response.data.results);
          let data = {};

          if(answers['171']) {
            setFirstRound(false);
          }

          [data['p171Rate'], data['p171Comment']] = readRateAnswer(answers['171']);
          [data['p172Selections'], data['p172Comments']] = readSelectionAnswers(answers['172']);
          [data['p173Selections'], data['p173Comments']] = readSelectionAnswers(answers['173']);
          [data['p174Rate'], data['p174Comment']] = readRateAnswer(answers['174']);
          [data['p175Selections'], data['p175Comments']] = readSelectionAnswers(answers['175']);

          setPageData(JSON.stringify(data));

          setP171Results(results["171"]);
          setP172Results(results["172"]);
          setP173Results(results["173"]);
          setP174Results(results["174"]);
          setP175Results(results["175"]);
          
          checkFilled();
          setUnchanged(true);
        } else {
          setErrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  }
  
  useEffect(()=>{
    fetchData(); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    resetValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pageData]);

  useEffect(() => {
    setMessage('');
    let data = pageData?JSON.parse(pageData):[];

    if (Number(data.p171Rate) !== Number(p171Rate)) { setUnchanged(false); return; }
    if (data.p171Comment !== p171Comment) { setUnchanged(false); return; }
    if (!isDeepEqual(data.p172Selections, p172Selections)) 
      { setUnchanged(false); return; }
    if (!isDeepEqual(data.p172Comments, p172Comments)) 
      { setUnchanged(false); return; }
    if (!isDeepEqual(data.p173Selections, p173Selections)) 
      { setUnchanged(false); return; }
    if (!isDeepEqual(data.p173Comments, p173Comments)) 
      { setUnchanged(false); return; }
    if (Number(data.p174Rate) !== Number(p174Rate)) { setUnchanged(false); return; }
    if (data.p174Comment !== p174Comment) { setUnchanged(false); return; }
    if (!isDeepEqual(data.p175Selections, p175Selections)) 
      { setUnchanged(false); return; }
    if (!isDeepEqual(data.p175Comments, p175Comments)) 
      { setUnchanged(false); return; }
    
    setUnchanged(true);
  }, [    
    p171Rate, p171Comment,
    p172Selections, p172Comments,
    p173Selections, p173Comments,
    p174Rate, p174Comment,
    p175Selections, p175Comments, pageData
  ]);

  const checkFilled = () => {
    if(!checkRateFilled(p171Rate, p171Comment, 1, 5)) {setFilled(false); return;}
    if(!checkSelectionFilled(p172Selections, p172Comments, 3, 3)) {setFilled(false); return;}
    if(!checkSelectionFilled(p173Selections, p173Comments, 3, 3)) {setFilled(false); return;}
    if(!checkRateFilled(p174Rate, p174Comment, 1, 5)) {setFilled(false); return;}
    if(!checkSelectionFilled(p175Selections, p175Comments, 1, 1)) {setFilled(false); return;}

    setFilled(true);
  }
  
  const navigate = (msg) => {
    window.removeEventListener('beforeunload',handleBeforeUnload); 
    if(!msg) msg = 'Proceeding..';
    setOkMessage(msg);
    setTimeout(()=> {
      window.location.href = '/survey?issue=5&page=2';
    }, 1000);
  }
  
  useEffect(() => {   
    checkFilled();
  });
   
  let admin = JSON.parse(props.user).admin;
  let isAdmin = admin&&admin==="1";

  const handleSubmit = (event) => {
    event.preventDefault();
    if((filled&&unchanged)||isAdmin) { navigate(); return; }
    
    if(!checkRateFilled(p171Rate, p171Comment, 1, 5,
      "Please answer problem 1.",
      "Please provide your reasoning for problem 1.",
      setErrorMessage
    )) return;
    if(!checkSelectionFilled(p172Selections, p172Comments, 3, 3,
      "Please select three answers for problem 2.",
      "Please provide your reasoning for each selected answer in problem 2.",
      setErrorMessage
    )) return;
    if(!checkSelectionFilled(p173Selections, p173Comments, 3, 3,
      "Please select three answers for problem 3.",
      "Please provide your reasoning for each selected answer in problem 3.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p174Rate, p174Comment, 1, 5,
      "Please answer problem 4.",
      "Please provide your reasoning for problem 4.",
      setErrorMessage
    )) return;
    if(!checkSelectionFilled(p175Selections, p175Comments, 1, 1,
      "Please select an answer for problem 5.",
      "Please provide your reasoning for each selected answer in problem 5.",
      setErrorMessage,
      "Please specify the other field in problem 5."
    )) return;

    let user_id = JSON.parse(props.user).user_id;
    let voteData = [];
    voteData.push({user_id, problem_id:171, vote:p171Rate, comment: p171Comment});
    p172Selections.forEach((selection,index)=>{
      voteData.push({user_id, problem_id:172, vote:selection, comment: p172Comments[index]});
    });
    p173Selections.forEach((selection,index)=>{
      voteData.push({user_id, problem_id:173, vote:selection, comment: p173Comments[index]});
    });
    voteData.push({user_id, problem_id:174, vote:p174Rate, comment: p174Comment});
    p175Selections.forEach((selection,index)=>{
      voteData.push({user_id, problem_id:175, vote:selection, comment: p175Comments[index]});
    });

    axios.post( backendServer+'/vote.php', {vote_data: JSON.stringify(voteData)})
    .then( (response)=>{
      if(response.data.status==='ok') {
        //updatePageData();
        //fetchData();
        setFilled(true);
        setFirstRound(false);
        //setOkMessage("Responses saved.");
        navigate('Responses saved.');
      } else {
        setErrorMessage(response.data.message);
      }
    })
    .catch((error)=>{
      setErrorMessage(error.message);
    });
  }
  
  const updateP172Answer = (selection, add) => {
    if(add) {
      let newSelections = [...p172Selections];
      newSelections.push(selection);
      setP172Selections(newSelections);
      let newComments = [...p172Comments];
      let data = pageData?JSON.parse(pageData):[];
      const index = data.p172Selections?data.p172Selections.indexOf(selection):-1;
      newComments.push(index>=0?data.p172Comments[index]:'');
      setP172Comments(newComments);
    } else {
      let index = p172Selections.indexOf(selection);
      if(index>=0) { // sanity check        
        let newSelections = [...p172Selections];
        newSelections.splice(index,1);
        setP172Selections(newSelections);
        let newComments = [...p172Comments];
        newComments.splice(index,1);
        setP172Comments(newComments);
      }
    }
  }

  const updateP172Comment = (answerIndex, comment) => {
    let newComments = [...p172Comments];
    newComments[answerIndex]=comment;
    setP172Comments(newComments);
  }
  
  const updateP173Answer = (selection, add) => {
    if(add) {
      let newSelections = [...p173Selections];
      newSelections.push(selection);
      setP173Selections(newSelections);
      let newComments = [...p173Comments];
      let data = pageData?JSON.parse(pageData):[];
      const index = data.p173Selections?data.p173Selections.indexOf(selection):-1;
      newComments.push(index>=0?data.p173Comments[index]:'');
      setP173Comments(newComments);
    } else {
      let index = p173Selections.indexOf(selection);
      if(index>=0) { // sanity check        
        let newSelections = [...p173Selections];
        newSelections.splice(index,1);
        setP173Selections(newSelections);
        let newComments = [...p173Comments];
        newComments.splice(index,1);
        setP173Comments(newComments);
      }
    }
  }

  const updateP173Comment = (answerIndex, comment) => {
    let newComments = [...p173Comments];
    newComments[answerIndex]=comment;
    setP173Comments(newComments);
  }
  
  const updateP175Answer = (selection, add) => {
    if(add) {
      let newSelections = [...p175Selections];
      newSelections.push(selection);
      setP175Selections(newSelections);
      let newComments = [...p175Comments];
      let data = pageData?JSON.parse(pageData):[];
      const index = data.p175Selections?data.p175Selections.indexOf(selection):-1;
      newComments.push(index>=0?data.p175Comments[index]:'');
      setP175Comments(newComments);
    } else {
      let index = p175Selections.indexOf(selection);
      if(index>=0) { // sanity check        
        let newSelections = [...p175Selections];
        newSelections.splice(index,1);
        setP175Selections(newSelections);
        let newComments = [...p175Comments];
        newComments.splice(index,1);
        setP175Comments(newComments);
      }
    }
  }

  const updateP175Comment = (answerIndex, comment) => {
    let newComments = [...p175Comments];
    newComments[answerIndex]=comment;
    setP175Comments(newComments);
  }

  const updateP175Other = (otherText, answerIndex) => {
    let newSelections = [...p175Selections];
    newSelections[answerIndex]=otherText;
    setP175Selections(newSelections);
  }
  
  return (
    <form onSubmit={handleSubmit}>
      <table width="600" align="center" cellPadding={8}>
        <tbody>
          <tr>
            <td>
              <h4>Issue 5: Offset Policies</h4>
              <p>
                Offset policies for net-zero emissions refer to strategies and
                regulations implemented by governments, organizations, or
                industries to balance their greenhouse gas {"("}GHG{")"}{" "}
                emissions by either cutting emissions elsewhere or removing an
                equivalent amount of GHGs from the atmosphere. These policies
                are integral to the larger goal of reaching net-zero emissions,
                where emissions reductions or removals equal the total GHG
                emissions produced.
              </p>
              <p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.1 Clean
                Development Mechanism {"("}CDM{")"} <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5.2 Carbon
                Border Adjustment Mechanism {"("}CBAM{")"}
              </p>
              <h4>
                5.1 Clean Development Mechanism {"("}CDM{")"}
              </h4>
              <p>
                Clean Development Mechanism {"("}CDM{")"} refers to strategies
                employed by economies to achieve their greenhouse gas {"("}GHG
                {")"} reduction objectives by investing in emission-reduction
                projects located in other economies. These corresponding
                initiatives generally generate carbon credits, which can be
                traded domestics or internationally.
              </p>
              <p>The CDM examples, in this survey, are as follows.</p>
              <p>
                <b>
                  i{")"} Trading and Compliance {"("}Emission Trading System{" "}
                  {"("}ETS{"))"}
                </b>
              </p>
              <p>
                ETS often involves a carbon pricing mechanism, such as the
                auctioning or trading of emission allowances. The projects that
                generate the verified carbon credits {"("}Certified Emission
                Reductions or CERs{")"} can enter these markets.
              </p>
              <p>
                <b>
                  ii{")"} Certification and Verification {"("}Renewable Energy
                  Certificates {"("}RECs{"))"}
                </b>
              </p>
              <p>
                Certification and verification processes, such as the issuance
                of RECs, are critical for maintaining the environmental
                integrity of CDM projects. They help confirm that the renewable
                energy generated and associated emission reductions are genuine,
                measurable, and comply with international standards.
              </p>
              <p>
                In 2023, APERC (Asia Pacific Energy Research Centre) published a
                series of reports on “Renewable Energy Certificates (RECs) in
                six APEC Southeast Asian economies”, along with several case
                studies on RECs across the APEC region.
              </p>
              <p>
                Concerns have been raised about the pros and cons of RECs. Such
                concerns likely stem from the desire to ensure that the economic
                and environmental benefits of renewable energy production remain
                within economies where the energy is generated. When unbundled
                RECs are traded across borders, it can result in economic value
                associated with renewable energy generation flowing out of
                producing economies.
              </p>
              <hr />
              <br></br>
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={171}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p171Rate}
                setRate={setP171Rate}
                comment={p171Comment}
                setComment={setP171Comment}
                text="1. On a scale of 1 to 5, please rate the level of significance of the CDM implementation in progressing towards achieving net-zero emissions."
                results={p171Results}
                choices={["Not Significant", "Some significant", "Moderately significant", "Very significant", "Most significant"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <SelectItem
                problem_id={172}
                firstRound={firstRound}
                adminMode={isAdmin}
                max={3}
                updateAnswer={updateP172Answer}
                updateComment={updateP172Comment}
                selections={p172Selections}
                comments={p172Comments}
                text="2. Based on your justification, please select three APEC economies that their emission trading scheme (ETS) are implemented effectively."
                choices={allEconomies}
                results={p172Results}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <SelectItem
                problem_id={173}
                firstRound={firstRound}
                adminMode={isAdmin}
                max={3}
                updateAnswer={updateP173Answer}
                updateComment={updateP173Comment}
                selections={p173Selections}
                comments={p173Comments}
                text="3. Based on your justification, please select three APEC economies that their Renewable Energy Certificates (RECs) are implemented effectively."
                choices={allEconomies}
                results={p173Results}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={174}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p174Rate}
                setRate={setP174Rate}
                comment={p174Comment}
                setComment={setP174Comment}
                text="4. On a scale of 1 to 5, please indicate the level of urgency for the establishment of APEC standards, particularly ETS and REC to achieve net-zero emissions."
                results={p174Results}
                choices={["Not urgent", "Low urgency", "Moderate urgency", "High urgency", "Extremely urgent"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <SelectItem
                problem_id={175}
                firstRound={firstRound}
                adminMode={isAdmin}
                max={1}
                updateAnswer={updateP175Answer}
                updateComment={updateP175Comment}
                selections={p175Selections}
                comments={p175Comments}
                text="5. To what extent do you believe the collaboration amongst APEC economies needed regarding CDM are driving progress towards net-zero emissions?"
                choices={["APEC Measurement and verification standards", "APEC carbon price", "Revenue recycling scheme", "Capacity building", "Knowledge sharing"]}
                other={true}
                updateOther={updateP175Other}
                results={p175Results}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td align="right">
            <Message color={messageColor} message={message} />
              <br />
              <NextButtonPanel
                disabled={unchanged}
                onClick={resetValues}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );
};

export default Issue5page1;