import { useState, useEffect } from 'react';
import axios from 'axios';
import { backendServer } from '../../Const';
import RateItem from "./RateItem";
import SelectItem from "./SelectItem";
import Message from './Message';

import {isDeepEqual, readRateAnswer, readSelectionAnswers, checkRateFilled, checkSelectionFilled, handleBeforeUnload} from '../../SurveyUtils';
import NextButtonPanel from '../../components/NextButtonPanel';

const Issue4page2 = (props) => {
  const [pageData, setPageData] = useState('');
  const [firstRound, setFirstRound] = useState(true);
  
  const [p109Rate, setP109Rate] = useState(0);
  const [p109Comment, setP109Comment] = useState('');
  const [p109Results, setP109Results] = useState('');
  const [p110Rate, setP110Rate] = useState(0);
  const [p110Comment, setP110Comment] = useState('');
  const [p110Results, setP110Results] = useState('');
  const [p111Rate, setP111Rate] = useState(0);
  const [p111Comment, setP111Comment] = useState('');
  const [p111Results, setP111Results] = useState('');
  const [p112Rate, setP112Rate] = useState(0);
  const [p112Comment, setP112Comment] = useState('');
  const [p112Results, setP112Results] = useState('');
  const [p113Rate, setP113Rate] = useState(0);
  const [p113Comment, setP113Comment] = useState('');
  const [p113Results, setP113Results] = useState('');
  const [p114Rate, setP114Rate] = useState(0);
  const [p114Comment, setP114Comment] = useState('');
  const [p114Results, setP114Results] = useState('');
  const [p114Other, setP114Other] = useState('');
  const [p115Selections, setP115Selections] = useState([]);
  const [p115Comments, setP115Comments] = useState([]);
  const [p115Results, setP115Results] = useState('');
  const [p116Rate, setP116Rate] = useState(0);
  const [p116Comment, setP116Comment] = useState('');
  const [p116Results, setP116Results] = useState('');
  const [p117Rate, setP117Rate] = useState(0);
  const [p117Comment, setP117Comment] = useState('');
  const [p117Results, setP117Results] = useState('');
  const [p118Rate, setP118Rate] = useState(0);
  const [p118Comment, setP118Comment] = useState('');
  const [p118Results, setP118Results] = useState('');
  const [p119Rate, setP119Rate] = useState(0);
  const [p119Comment, setP119Comment] = useState('');
  const [p119Results, setP119Results] = useState('');
  const [p120Rate, setP120Rate] = useState(0);
  const [p120Comment, setP120Comment] = useState('');
  const [p120Results, setP120Results] = useState('');
  const [p121Rate, setP121Rate] = useState(0);
  const [p121Comment, setP121Comment] = useState('');
  const [p121Results, setP121Results] = useState('');
  const [p122Rate, setP122Rate] = useState(0);
  const [p122Comment, setP122Comment] = useState('');
  const [p122Results, setP122Results] = useState('');
  const [p122Other, setP122Other] = useState('');  

  const [filled, setFilled] = useState(false);
  const [unchanged, setUnchanged] = useState(true);
  const [message, setMessage] = useState('');
  const [messageColor, setMessageColor] = useState('');

  useEffect( () => {
    if(unchanged) return;
    
    window.addEventListener('beforeunload',handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload',handleBeforeUnload)
    }
    
  },[unchanged]);

  const setErrorMessage = (msg) => {
    setMessageColor("red");
    setMessage(msg);
  }

  const setOkMessage = (msg) => {
    setMessageColor("teal");
    setMessage(msg);
  }

    
  const resetValues = () => {
    const data = pageData?JSON.parse(pageData):[];
    
    setP109Rate(data.p109Rate?data.p109Rate:0);
    setP109Comment(data.p109Comment?data.p109Comment:'');
    setP110Rate(data.p110Rate?data.p110Rate:0);
    setP110Comment(data.p110Comment?data.p110Comment:'');
    setP111Rate(data.p111Rate?data.p111Rate:0);
    setP111Comment(data.p111Comment?data.p111Comment:'');
    setP112Rate(data.p112Rate?data.p112Rate:0);
    setP112Comment(data.p112Comment?data.p112Comment:'');
    setP113Rate(data.p113Rate?data.p113Rate:0);
    setP113Comment(data.p113Comment?data.p113Comment:'');
    setP114Rate(data.p114Rate?data.p114Rate:0);
    setP114Comment(data.p114Comment?data.p114Comment:'');
    setP114Other(data.p114Other?data.p114Other:'');
    setP115Selections(data.p115Selections?data.p115Selections:[]);
    setP115Comments(data.p115Comments?data.p115Comments:[]);
    setP116Rate(data.p116Rate?data.p116Rate:0);
    setP116Comment(data.p116Comment?data.p116Comment:'');
    setP117Rate(data.p117Rate?data.p117Rate:0);
    setP117Comment(data.p117Comment?data.p117Comment:'');
    setP118Rate(data.p118Rate?data.p118Rate:0);
    setP118Comment(data.p118Comment?data.p118Comment:'');
    setP119Rate(data.p119Rate?data.p119Rate:0);
    setP119Comment(data.p119Comment?data.p119Comment:'');
    setP120Rate(data.p120Rate?data.p120Rate:0);
    setP120Comment(data.p120Comment?data.p120Comment:'');
    setP121Rate(data.p121Rate?data.p121Rate:0);
    setP121Comment(data.p121Comment?data.p121Comment:'');
    setP122Rate(data.p122Rate?data.p122Rate:0);
    setP122Comment(data.p122Comment?data.p122Comment:'');
    setP122Other(data.p122Other?data.p122Other:'');

    setUnchanged(true);
  }
  
  const fetchData = async () => {
    axios
      .post(backendServer + "/get_votes.php", {
        user_id: JSON.parse(props.user).user_id,
        problem_ids: ["109", "110", "111", "112", "113", "114", "115", "116", "117", "118", "119", "120", "121", "122"]
      })
      .then((response) => {
        if (response.data.status === "ok") {
          let answers = JSON.parse(response.data.answers);
          let results = JSON.parse(response.data.results);
          let data = {};
          
          if(answers['109']) {
            setFirstRound(false);
          }

          [data['p109Rate'], data['p109Comment']] = readRateAnswer(answers['109']);
          [data['p110Rate'], data['p110Comment']] = readRateAnswer(answers['110']);
          [data['p111Rate'], data['p111Comment']] = readRateAnswer(answers['111']);
          [data['p112Rate'], data['p112Comment']] = readRateAnswer(answers['112']);
          [data['p113Rate'], data['p113Comment']] = readRateAnswer(answers['113']);
          [data['p114Rate'], data['p114Comment']] = readRateAnswer(answers['114']);     
          if(answers['114']&&answers['114'].other) data['p114Other'] = answers['114'].other;
          else data['p114Other']='';
          [data['p115Selections'], data['p115Comments']] = readSelectionAnswers(answers['115']);
          [data['p116Rate'], data['p116Comment']] = readRateAnswer(answers['116']);
          [data['p117Rate'], data['p117Comment']] = readRateAnswer(answers['117']);
          [data['p118Rate'], data['p118Comment']] = readRateAnswer(answers['118']);
          [data['p119Rate'], data['p119Comment']] = readRateAnswer(answers['119']);
          [data['p120Rate'], data['p120Comment']] = readRateAnswer(answers['120']);
          [data['p121Rate'], data['p121Comment']] = readRateAnswer(answers['121']);
          [data['p122Rate'], data['p122Comment']] = readRateAnswer(answers['122']);
          if(answers['122']&&answers['122'].other) data['p122Other'] = answers['122'].other;
          else data['p122Other'] = '';

          setPageData(JSON.stringify(data));

          setP109Results(results["109"]);
          setP110Results(results["110"]);
          setP111Results(results["111"]);
          setP112Results(results["112"]);
          setP113Results(results["113"]);
          setP114Results(results["114"]);
          setP115Results(results["115"]);
          setP116Results(results["116"]);
          setP117Results(results["117"]);
          setP118Results(results["118"]);
          setP119Results(results["119"]);
          setP120Results(results["120"]);
          setP121Results(results["121"]);
          setP122Results(results["122"]);
          
          checkFilled();
          setUnchanged(true);
        } else {
          setErrorMessage(response.data.message);
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  }

  useEffect(()=>{
    fetchData(); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    resetValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pageData]);
  
  useEffect(() => {
    setMessage('');
    let data = pageData?JSON.parse(pageData):[];

    if (Number(data.p109Rate) !== Number(p109Rate)) { setUnchanged(false); return; }
    if (data.p109Comment !== p109Comment) { setUnchanged(false); return; }
    if (Number(data.p110Rate) !== Number(p110Rate)) { setUnchanged(false); return; }
    if (data.p110Comment !== p110Comment) { setUnchanged(false); return; }
    if (Number(data.p111Rate) !== Number(p111Rate)) { setUnchanged(false); return; }
    if (data.p111Comment !== p111Comment) { setUnchanged(false); return; }
    if (Number(data.p112Rate) !== Number(p112Rate)) { setUnchanged(false); return; }
    if (data.p112Comment !== p112Comment) { setUnchanged(false); return; }
    if (Number(data.p113Rate) !== Number(p113Rate)) { setUnchanged(false); return; }
    if (data.p113Comment !== p113Comment) { setUnchanged(false); return; }
    if (Number(data.p114Rate) !== Number(p114Rate)) { setUnchanged(false); return; }
    if (data.p114Comment !== p114Comment) { setUnchanged(false); return; }
    if (data.p114Other !== p114Other) { setUnchanged(false); return; }    
    if (!isDeepEqual(data.p115Selections, p115Selections)) 
      { setUnchanged(false); return; }
    if (!isDeepEqual(data.p115Comments, p115Comments)) 
      { setUnchanged(false); return; }
    if (Number(data.p116Rate) !== Number(p116Rate)) { setUnchanged(false); return; }
    if (data.p116Comment !== p116Comment) { setUnchanged(false); return; }
    if (Number(data.p117Rate) !== Number(p117Rate)) { setUnchanged(false); return; }
    if (data.p117Comment !== p117Comment) { setUnchanged(false); return; }
    if (Number(data.p118Rate) !== Number(p118Rate)) { setUnchanged(false); return; }
    if (data.p118Comment !== p118Comment) { setUnchanged(false); return; }
    if (Number(data.p119Rate) !== Number(p119Rate)) { setUnchanged(false); return; }
    if (data.p119Comment !== p119Comment) { setUnchanged(false); return; }
    if (Number(data.p120Rate) !== Number(p120Rate)) { setUnchanged(false); return; }
    if (data.p120Comment !== p120Comment) { setUnchanged(false); return; }
    if (Number(data.p121Rate) !== Number(p121Rate)) { setUnchanged(false); return; }
    if (data.p121Comment !== p121Comment) { setUnchanged(false); return; }
    if (Number(data.p122Rate) !== Number(p122Rate)) { setUnchanged(false); return; }
    if (data.p122Comment !== p122Comment) { setUnchanged(false); return; }
    if (data.p122Other !== p122Other) { setUnchanged(false); return; }    
    
    setUnchanged(true);
  }, [    
    p109Rate, p109Comment,
    p110Rate, p110Comment,
    p111Rate, p111Comment,
    p112Rate, p112Comment,
    p113Rate, p113Comment,
    p114Rate, p114Comment, p114Other,
    p115Selections, p115Comments,
    p116Rate, p116Comment,
    p117Rate, p117Comment,
    p118Rate, p118Comment,
    p119Rate, p119Comment,
    p120Rate, p120Comment,
    p121Rate, p121Comment,
    p122Rate, p122Comment, p122Other, pageData
  ]);

  const checkFilled = () => {
    if(!checkRateFilled(p109Rate, p109Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p110Rate, p110Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p111Rate, p111Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p112Rate, p112Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p113Rate, p113Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p114Rate, p114Comment, 1, 5)) {setFilled(false); return;}
    //if(!p114Other) {setFilled(false); return;}
    if(!checkSelectionFilled(p115Selections, p115Comments, 1, 1)) {setFilled(false); return;}
    if(!checkRateFilled(p116Rate, p116Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p117Rate, p117Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p118Rate, p118Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p119Rate, p119Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p120Rate, p120Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p121Rate, p121Comment, 1, 5)) {setFilled(false); return;}
    if(!checkRateFilled(p122Rate, p122Comment, 1, 5)) {setFilled(false); return;}
    //if(!p122Other) {setFilled(false); return;}

    setFilled(true);
  }

  const navigate = (msg) => {
    window.removeEventListener('beforeunload',handleBeforeUnload); 
    if(!msg) msg = 'Proceeding..';
    setOkMessage(msg);
    setTimeout(()=> {
      window.location.href = '/survey?issue=4&page=3';
    }, 1000);
  }

  useEffect(() => {   
    checkFilled();
  });

  let admin = JSON.parse(props.user).admin;
  let isAdmin = admin&&admin==="1";
  
  const handleSubmit = (event) => {
    event.preventDefault();
    if((filled&&unchanged)||isAdmin) { navigate(); return; }
    
    if(!checkRateFilled(p109Rate, p109Comment, 1, 5,
      "Please answer problem 4a.",
      "Please provide your reasoning for problem 4a.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p110Rate, p110Comment, 1, 5,
      "Please answer problem 4b.",
      "Please provide your reasoning for problem 4b.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p111Rate, p111Comment, 1, 5,
      "Please answer problem 4c.",
      "Please provide your reasoning for problem 4c.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p112Rate, p112Comment, 1, 5,
      "Please answer problem 4d.",
      "Please provide your reasoning for problem 4d.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p113Rate, p113Comment, 1, 5,
      "Please answer problem 4e.",
      "Please provide your reasoning for problem 4e.",
      setErrorMessage
    )) return;
    if(p114Other) {
      if(!checkRateFilled(p114Rate, p114Comment, 1, 5,
        "Please answer problem 4f.",
        "Please provide your reasoning for problem 4f.",
        setErrorMessage
      )) return;
    }
    // if(!p114Other) {
    //   setErrorMessage("Please specify the other field in problem 4f.");
    //   return;
    // }
    if(!checkSelectionFilled(p115Selections, p115Comments, 1, 1,
      "Please select three answers for problem 5.",
      "Please provide your reasoning for each selected answer in problem 5.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p116Rate, p116Comment, 1, 5,
      "Please answer problem 6a.",
      "Please provide your reasoning for problem 6a.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p117Rate, p117Comment, 1, 5,
      "Please answer problem 6b.",
      "Please provide your reasoning for problem 6b.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p118Rate, p118Comment, 1, 5,
      "Please answer problem 6c.",
      "Please provide your reasoning for problem 6c.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p119Rate, p119Comment, 1, 5,
      "Please answer problem 6d.",
      "Please provide your reasoning for problem 6d.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p120Rate, p120Comment, 1, 5,
      "Please answer problem 6e.",
      "Please provide your reasoning for problem 6e.",
      setErrorMessage
    )) return;
    if(!checkRateFilled(p121Rate, p121Comment, 1, 5,
      "Please answer problem 6f.",
      "Please provide your reasoning for problem 6f.",
      setErrorMessage
    )) return;
    if(p122Other) {
      if(!checkRateFilled(p122Rate, p122Comment, 1, 5,
        "Please answer problem 6g.",
        "Please provide your reasoning for problem 6g.",
        setErrorMessage
      )) return;
    }
    // if(!p122Other) {
    //   setErrorMessage("Please specify the other field in problem 6g.");
    //   return;
    // }

    let user_id = JSON.parse(props.user).user_id;
    let voteData = [];
    voteData.push({user_id, problem_id:109, vote:p109Rate, comment: p109Comment});
    voteData.push({user_id, problem_id:110, vote:p110Rate, comment: p110Comment});
    voteData.push({user_id, problem_id:111, vote:p111Rate, comment: p111Comment});
    voteData.push({user_id, problem_id:112, vote:p112Rate, comment: p112Comment});
    voteData.push({user_id, problem_id:113, vote:p113Rate, comment: p113Comment});
    if(p114Other)
      voteData.push({user_id, problem_id:114, vote:p114Rate, comment: p114Comment, other:p114Other});
    p115Selections.forEach((selection,index)=>{
      voteData.push({user_id, problem_id:115, vote:selection, comment: p115Comments[index]});
    });
    voteData.push({user_id, problem_id:116, vote:p116Rate, comment: p116Comment});
    voteData.push({user_id, problem_id:117, vote:p117Rate, comment: p117Comment});
    voteData.push({user_id, problem_id:118, vote:p118Rate, comment: p118Comment});
    voteData.push({user_id, problem_id:119, vote:p119Rate, comment: p119Comment});
    voteData.push({user_id, problem_id:120, vote:p120Rate, comment: p120Comment});
    voteData.push({user_id, problem_id:121, vote:p121Rate, comment: p121Comment});
    if(p122Other)
      voteData.push({user_id, problem_id:122, vote:p122Rate, comment: p122Comment, other:p122Other});

    axios.post( backendServer+'/vote.php', {vote_data: JSON.stringify(voteData)})
    .then( (response)=>{
      if(response.data.status==='ok') {        
        //fetchData();
        setFilled(true);
        setFirstRound(false);
        //setOkMessage("Responses saved.");
        navigate('Responses saved.');
      } else {
        setErrorMessage(response.data.message);
      }
    })
    .catch((error)=>{
      setErrorMessage(error.message);
    });
  }

  
  const updateP115Answer = (selection, add) => {
    if(add) {
      let newSelections = [...p115Selections];
      newSelections.push(selection);
      setP115Selections(newSelections);
      let newComments = [...p115Comments];
      let data = pageData?JSON.parse(pageData):[];
      const index = data.p115Selections?data.p115Selections.indexOf(selection):-1;
      newComments.push(index>=0?data.p115Comments[index]:'');
      setP115Comments(newComments);
    } else {
      let index = p115Selections.indexOf(selection);
      if(index>=0) { // sanity check        
        let newSelections = [...p115Selections];
        newSelections.splice(index,1);
        setP115Selections(newSelections);
        let newComments = [...p115Comments];
        newComments.splice(index,1);
        setP115Comments(newComments);
      }
    }
  }

  const updateP115Comment = (answerIndex, comment) => {
    let newComments = [...p115Comments];
    newComments[answerIndex]=comment;
    setP115Comments(newComments);
  }
  
  return (
    <form onSubmit={handleSubmit}>
      <table width="600" align="center" cellPadding={8}>
        <tbody>
          <tr>
            <td>
              <br />
              4. To what extent do you believe the collaboration among APEC
              economies regarding CCS are driving progress towards net-zero
              emissions?
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={109}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p109Rate}
                setRate={setP109Rate}
                comment={p109Comment}
                setComment={setP109Comment}
                text="a. CCS Infrastructure sharing"
                results={p109Results}
                choices={[
                  "Not at all important",
                  "Low important",
                  "Moderately important",
                  "Important",
                  "Very important",
                ]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={110}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p110Rate}
                setRate={setP110Rate}
                comment={p110Comment}
                setComment={setP110Comment}
                text="b. Knowledge sharing"
                results={p110Results}
                choices={[
                  "Not at all important",
                  "Low important",
                  "Moderately important",
                  "Important",
                  "Very important",
                ]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={111}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p111Rate}
                setRate={setP111Rate}
                comment={p111Comment}
                setComment={setP111Comment}
                text="c. Technology transfer"
                results={p111Results}
                choices={[
                  "Not at all important",
                  "Low important",
                  "Moderately important",
                  "Important",
                  "Very important",
                ]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={112}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p112Rate}
                setRate={setP112Rate}
                comment={p112Comment}
                setComment={setP112Comment}
                text="d. Capacity building"
                results={p112Results}
                choices={[
                  "Not at all important",
                  "Low important",
                  "Moderately important",
                  "Important",
                  "Very important",
                ]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={113}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p113Rate}
                setRate={setP113Rate}
                comment={p113Comment}
                setComment={setP113Comment}
                text="e. R&D collaboration"
                results={p113Results}
                choices={[
                  "Not at all important",
                  "Low important",
                  "Moderately important",
                  "Important",
                  "Very important",
                ]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={114}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p114Rate}
                setRate={setP114Rate}
                comment={p114Comment}
                setComment={setP114Comment}
                text="f. Others"
                other={true}
                otherText={p114Other}
                updateOther={setP114Other}
                results={p114Results}
                choices={[
                  "Not at all important",
                  "Low important",
                  "Moderately important",
                  "Important",
                  "Very important",
                ]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <SelectItem
                problem_id={115}
                firstRound={firstRound}
                adminMode={isAdmin}
                max={1}
                updateAnswer={updateP115Answer}
                updateComment={updateP115Comment}
                selections={p115Selections}
                comments={p115Comments}
                text="5. Based on your justification, when do you expect CCS to play a critical role in achieving net-zero emissions in APEC?"
                choices={[
                  "By 2030",
                  "By 2040",
                  "By 2050",
                  "Beyond 2050",
                  "Never",
                ]}
                other={false}
                results={p115Results}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
            6. On a scale of 1 to 5, please rate the following collaborations that your economy requires from APEC economies, to successfully implement CCS.
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={116}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p116Rate}
                setRate={setP116Rate}
                comment={p116Comment}
                setComment={setP116Comment}
                text="a. Financial support"
                results={p116Results}
                choices={["Low", "Rather low", "Moderate", "Rather high", "High"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={117}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p117Rate}
                setRate={setP117Rate}
                comment={p117Comment}
                setComment={setP117Comment}
                text="b. CCS Infrastructure sharing"
                results={p117Results}
                choices={["Low", "Rather low", "Moderate", "Rather high", "High"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={118}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p118Rate}
                setRate={setP118Rate}
                comment={p118Comment}
                setComment={setP118Comment}
                text="c. Knowledge sharing"
                results={p118Results}
                choices={["Low", "Rather low", "Moderate", "Rather high", "High"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={119}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p119Rate}
                setRate={setP119Rate}
                comment={p119Comment}
                setComment={setP119Comment}
                text="d. Technology transfer"
                results={p119Results}
                choices={["Low", "Rather low", "Moderate", "Rather high", "High"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={120}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p120Rate}
                setRate={setP120Rate}
                comment={p120Comment}
                setComment={setP120Comment}
                text="e. Capacity building"
                results={p120Results}
                choices={["Low", "Rather low", "Moderate", "Rather high", "High"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={121}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p121Rate}
                setRate={setP121Rate}
                comment={p121Comment}
                setComment={setP121Comment}
                text="f. R&D collaboration"
                results={p121Results}
                choices={["Low", "Rather low", "Moderate", "Rather high", "High"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <RateItem
                problem_id={122}
                firstRound={firstRound}
                adminMode={isAdmin}
                rate={p122Rate}
                setRate={setP122Rate}
                comment={p122Comment}
                setComment={setP122Comment}
                text="g. Others"
                other={true}
                otherText={p122Other}
                updateOther={setP122Other}
                results={p122Results}
                choices={["Low", "Rather low", "Moderate", "Rather high", "High"]}
              />
              <br />
            </td>
          </tr>
          <tr>
            <td align="right">
            <Message color={messageColor} message={message} />
              <br />
              <NextButtonPanel
                disabled={unchanged}
                onClick={resetValues}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );
};

export default Issue4page2;