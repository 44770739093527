
export const backendServer = 'http://apecctf.net/api'
//export const backendServer = 'http://localhost/api'

export const allEconomies = [
  'Australia',
  'Brunei Darussalam',
  'Canada',
  'Chile',
  'People\'s Republic of China',
  'Hong Kong, China',
  'Indonesia',
  'Japan',
  'Republic of Korea',
  'Malaysia',
  'Mexico',
  'New Zealand',
  'Papua New Guinea',
  'Peru',
  'The Philippines',
  'Russia',
  'Singapore',
  'Chinese Taipei',
  'Thailand',
  'The United States',
  'Viet Nam'
  ];

  export const nonAECCountries = [
    'Afghanistan',
    'Albania',
    'Algeria',
    'Andorra',
    'Angola',
    'Antigua and Barbuda',
    'Argentina',
    'Armenia',
    'Austria',
    'Azerbaijan',
    'The Bahamas',
    'Bahrain',
    'Bangladesh',
    'Barbados',
    'Belarus',
    'Belgium',
    'Belize',
    'Benin',
    'Bhutan',
    'Bolivia',
    'Bosnia and Herzegovina',
    'Botswana',
    'Brazil',
    'Bulgaria',
    'Burkina Faso',
    'Burundi',
    'Cabo Verde',
    'Cambodia',
    'Cameroon',
    'Central African Republic',
    'Chad',
    'Colombia',
    'Comoros',
    'Congo, Democratic Republic of the',
    'Congo, Republic of the',
    'Costa Rica',
    'Côte d’Ivoire',
    'Croatia',
    'Cuba',
    'Cyprus',
    'Czech Republic',
    'Denmark',
    'Djibouti',
    'Dominica',
    'Dominican Republic',
    'East Timor (Timor-Leste)',
    'Ecuador',
    'Egypt',
    'El Salvador',
    'Equatorial Guinea',
    'Eritrea',
    'Estonia',
    'Eswatini',
    'Ethiopia',
    'Fiji',
    'Finland',
    'France',
    'Gabon',
    'The Gambia',
    'Georgia',
    'Germany',
    'Ghana',
    'Greece',
    'Grenada',
    'Guatemala',
    'Guinea',
    'Guinea-Bissau',
    'Guyana',
    'Haiti',
    'Honduras',
    'Hungary',
    'Iceland',
    'India',
    'Iran',
    'Iraq',
    'Ireland',
    'Israel',
    'Italy',
    'Jamaica',
    'Jordan',
    'Kazakhstan',
    'Kenya',
    'Kiribati',
    //'The Democratic People\'s Republic of Korea',
    'Kosovo',
    'Kuwait',
    'Kyrgyzstan',
    'Laos',
    'Latvia',
    'Lebanon',
    'Lesotho',
    'Liberia',
    'Libya',
    'Liechtenstein',
    'Lithuania',
    'Luxembourg',
    'Madagascar',
    'Malawi',
    'Maldives',
    'Mali',
    'Malta',
    'Marshall Islands',
    'Mauritania',
    'Mauritius',
    'Micronesia, Federated States of',
    'Moldova',
    'Monaco',
    'Mongolia',
    'Montenegro',
    'Morocco',
    'Mozambique',
    'Myanmar (Burma)',
    'Namibia',
    'Nauru',
    'Nepal',
    'Netherlands',
    'Nicaragua',
    'Niger',
    'Nigeria',
    'North Macedonia',
    'Norway',
    'Oman',
    'Pakistan',
    'Palau',
    'Panama',
    'Paraguay',
    'Poland',
    'Portugal',
    'Qatar',
    'Romania',
    'Rwanda',
    'Saint Kitts and Nevis',
    'Saint Lucia',
    'Saint Vincent and the Grenadines',
    'Samoa',
    'San Marino',
    'Sao Tome and Principe',
    'Saudi Arabia',
    'Senegal',
    'Serbia',
    'Seychelles',
    'Sierra Leone',
    'Slovakia',
    'Slovenia',
    'Solomon Islands',
    'Somalia',
    'South Africa',
    'Spain',
    'Sri Lanka',
    'Sudan',
    'Sudan, South',
    'Suriname',
    'Sweden',
    'Switzerland',
    'Syria',
    'Tajikistan',
    'Tanzania',
    'Togo',
    'Tonga',
    'Trinidad and Tobago',
    'Tunisia',
    'Turkey',
    'Turkmenistan',
    'Tuvalu',
    'Uganda',
    'Ukraine',
    'United Arab Emirates',
    'United Kingdom',
    'Uruguay',
    'Uzbekistan',
    'Vanuatu',
    'Vatican City',
    'Venezuela',
    'Yemen',
    'Zambia',
    'Zimbabwe'
  ];

  // export const allCountries = [
  //   'Afghanistan',
  //   'Albania',
  //   'Algeria',
  //   'Andorra',
  //   'Angola',
  //   'Antigua and Barbuda',
  //   'Argentina',
  //   'Armenia',
  //   'Australia',
  //   'Austria',
  //   'Azerbaijan',
  //   'The Bahamas',
  //   'Bahrain',
  //   'Bangladesh',
  //   'Barbados',
  //   'Belarus',
  //   'Belgium',
  //   'Belize',
  //   'Benin',
  //   'Bhutan',
  //   'Bolivia',
  //   'Bosnia and Herzegovina',
  //   'Botswana',
  //   'Brazil',
  //   'Brunei',
  //   'Bulgaria',
  //   'Burkina Faso',
  //   'Burundi',
  //   'Cabo Verde',
  //   'Cambodia',
  //   'Cameroon',
  //   'Canada',
  //   'Central African Republic',
  //   'Chad',
  //   'Chile',
  //   'China',
  //   'Colombia',
  //   'Comoros',
  //   'Congo, Democratic Republic of the',
  //   'Congo, Republic of the',
  //   'Costa Rica',
  //   'Côte d’Ivoire',
  //   'Croatia',
  //   'Cuba',
  //   'Cyprus',
  //   'Czech Republic',
  //   'Denmark',
  //   'Djibouti',
  //   'Dominica',
  //   'Dominican Republic',
  //   'East Timor (Timor-Leste)',
  //   'Ecuador',
  //   'Egypt',
  //   'El Salvador',
  //   'Equatorial Guinea',
  //   'Eritrea',
  //   'Estonia',
  //   'Eswatini',
  //   'Ethiopia',
  //   'Fiji',
  //   'Finland',
  //   'France',
  //   'Gabon',
  //   'The Gambia',
  //   'Georgia',
  //   'Germany',
  //   'Ghana',
  //   'Greece',
  //   'Grenada',
  //   'Guatemala',
  //   'Guinea',
  //   'Guinea-Bissau',
  //   'Guyana',
  //   'Haiti',
  //   'Honduras',
  //   'Hungary',
  //   'Iceland',
  //   'India',
  //   'Indonesia',
  //   'Iran',
  //   'Iraq',
  //   'Ireland',
  //   'Israel',
  //   'Italy',
  //   'Jamaica',
  //   'Japan',
  //   'Jordan',
  //   'Kazakhstan',
  //   'Kenya',
  //   'Kiribati',
  //   'Korea, North',
  //   'Kosovo',
  //   'Kuwait',
  //   'Kyrgyzstan',
  //   'Laos',
  //   'Latvia',
  //   'Lebanon',
  //   'Lesotho',
  //   'Liberia',
  //   'Libya',
  //   'Liechtenstein',
  //   'Lithuania',
  //   'Luxembourg',
  //   'Madagascar',
  //   'Malawi',
  //   'Malaysia',
  //   'Maldives',
  //   'Mali',
  //   'Malta',
  //   'Marshall Islands',
  //   'Mauritania',
  //   'Mauritius',
  //   'Mexico',
  //   'Micronesia, Federated States of',
  //   'Moldova',
  //   'Monaco',
  //   'Mongolia',
  //   'Montenegro',
  //   'Morocco',
  //   'Mozambique',
  //   'Myanmar (Burma)',
  //   'Namibia',
  //   'Nauru',
  //   'Nepal',
  //   'Netherlands',
  //   'New Zealand',
  //   'Nicaragua',
  //   'Niger',
  //   'Nigeria',
  //   'North Macedonia',
  //   'Norway',
  //   'Oman',
  //   'Pakistan',
  //   'Palau',
  //   'Panama',
  //   'Papua New Guinea',
  //   'Paraguay',
  //   'Peru',
  //   'Philippines',
  //   'Poland',
  //   'Portugal',
  //   'Qatar',
  //   'Romania',
  //   'Russia',
  //   'Rwanda',
  //   'Saint Kitts and Nevis',
  //   'Saint Lucia',
  //   'Saint Vincent and the Grenadines',
  //   'Samoa',
  //   'San Marino',
  //   'Sao Tome and Principe',
  //   'Saudi Arabia',
  //   'Senegal',
  //   'Serbia',
  //   'Seychelles',
  //   'Sierra Leone',
  //   'Singapore',
  //   'Slovakia',
  //   'Slovenia',
  //   'Solomon Islands',
  //   'Somalia',
  //   'South Africa',
  //   'Spain',
  //   'Sri Lanka',
  //   'Sudan',
  //   'Sudan, South',
  //   'Suriname',
  //   'Sweden',
  //   'Switzerland',
  //   'Syria',
  //   'Taiwan',
  //   'Tajikistan',
  //   'Tanzania',
  //   'Togo',
  //   'Tonga',
  //   'Trinidad and Tobago',
  //   'Tunisia',
  //   'Turkey',
  //   'Turkmenistan',
  //   'Tuvalu',
  //   'Uganda',
  //   'Ukraine',
  //   'United Arab Emirates',
  //   'United Kingdom',
  //   'Uruguay',
  //   'Uzbekistan',
  //   'Vanuatu',
  //   'Vatican City',
  //   'Venezuela',
  //   'Yemen',
  //   'Zambia',
  //   'Zimbabwe'
  // ];
  