import { controlTextWidth } from '../../SurveyUtils';


const Comments = (props) => {

  let commentKey = 1;
  return (
    <>
      {props.comments && props.comments.length > 0 && (
        <table cellPadding={2} cellSpacing={2} width={props.width}>
          <thead>
            <tr bgcolor="#aaaaaa" align="center">
              <td width={props.voteWidth ? props.voteWidth : "140"}>Vote</td>
              <td>&nbsp;&nbsp;Comment</td>
            </tr>
          </thead>
          <tbody bgcolor="#cccccc">
            {props.comments.map((comment) => (
              <>
                {comment.comment.trim().length > 0 && (
                  <tr key={commentKey++}>
                    <td>
                      &nbsp;
                      {controlTextWidth(
                        props.isRate
                          ? Number(comment.vote) +
                              ". " +
                              props.choices[comment.vote-1]
                          : comment.vote
                      )}
                    </td>
                    <td>
                      {props.other ? (
                        <span>
                          &nbsp;<i>[other:{comment.other}]</i> <br />
                        </span>
                      ) : (
                        <></>
                      )}
                      &nbsp;{comment.comment}
                    </td>
                  </tr>
                )}
              </>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export default Comments;